import asyncHook from "@lib/AsyncHook";
import { deleteDoc, DocumentReference } from "firebase/firestore";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import swal from "sweetalert2";

/**
 * Show ok/cancel dialog. If cancel, promise returns false. If ok, deletes document.
 *
 * If document deletion is succcessful, return true (can change if needed). If there's an error, throw error.
 */
interface UseDeleteDocumentParams<T> {
  docRef: DocumentReference<T>;
  onDelete?(): void;
  onError?(form: T, error: any): string | void;
  titleMsg?: string;
  errorMsg?: string;
  showWarning?: boolean;
}

export default function useDeleteDocument<T>({
  docRef,
  onDelete,
  onError,
  titleMsg = "doc_delete_ask",
  errorMsg = "doc_delete_failed",
  showWarning = true,
}: UseDeleteDocumentParams<T>) {
  const { t } = useTranslation("hooks");
  // returns true if deleted. returns an error otherwise
  const doDelete = (form: T): Promise<boolean> => {
    return asyncHook(deleteDoc(docRef)).then(([resp, err]) => {
      if (err) {
        toast.error(t(errorMsg));
        if (onError) {
          onError(form, err);
        }

        return Promise.reject(false);
      }
      if (onDelete) {
        onDelete();
      }
      return Promise.resolve(true);
    });
  };

  const deleteDocument = async (form: T) => {
    if (!showWarning) {
      return doDelete(form);
    }
    return confirmDelete().then(async (resp) => {
      if (resp.isConfirmed) {
        return doDelete(form);
      } else {
        return Promise.resolve(false);
      }
    });
  };

  const confirmDelete = () => {
    return swal.fire({
      title: t(titleMsg),
      text: t("action_irreversible", { ns: "common" }),
      icon: "warning",
      showCancelButton: true,
    });
  };

  return { deleteDocument, confirmDelete };
}
