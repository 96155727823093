import LoadingOverlay from "@components/LoadingOverlay";
import { copyToClipboard } from "@lib/clipboard";
import { OAuthToken } from "@models/oauth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { deleteDoc, QueryDocumentSnapshot } from "firebase/firestore";
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { sanitizeScope } from "../utils/sanitizeScope";
import AppTokenModalEdit from "./AppTokenModalEdit";

interface Props {
  token: QueryDocumentSnapshot<OAuthToken>;
}
const AppTokenCard = ({ token }: Props) => {
  const { t } = useTranslation("developer");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [reveal, setReveal] = useState(false);

  const data = token.data();

  const toggleReveal = () => setReveal((v) => !v);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const deleteToken = async () => {
    const msg = t("type_to_action", {
      value: "delete",
      action: "delete",
      subject: "token",
    });

    const { isConfirmed, value } = await Swal.fire({
      title: "Delete token?",
      text: msg,
      showCancelButton: true,
      input: "text",
      inputValidator: (input) =>
        input !== "delete" ? t("must_type_string", { value: "delete" }) : null,
    });

    if (!isConfirmed || value !== "delete") return;
    setLoading(true);
    try {
      await deleteDoc(token.ref);
    } catch (e) {
      toast.error(t("token_delete_failed"));
    }
    setLoading(false);
  };

  const copy = () => {
    copyToClipboard(data.accessToken);
    toast.success(t("copied"));
  };

  return (
    <Card elevation={0}>
      <LoadingOverlay show={loading} />
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <TextField
              label={t("access_token")}
              value={data.accessToken}
              type={reveal ? "text" : "password"}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <IconButton onClick={toggleReveal} size="small">
                        {reveal ? (
                          <VisibilityOff fontSize="inherit" />
                        ) : (
                          <Visibility fontSize="inherit" />
                        )}
                      </IconButton>
                      <Button onClick={copy} variant="contained" size="small">
                        {t("copy")}
                      </Button>
                    </Stack>
                  </InputAdornment>
                ),
              }}
            />
            <Stack direction="row" spacing={1}>
              <Button onClick={openModal} variant="contained" size="small">
                Edit
              </Button>
              <Button
                onClick={deleteToken}
                variant="outlined"
                color="error"
                size="small"
              >
                Delete
              </Button>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            spacing={4}
            flexWrap="wrap"
            alignItems="flex-start"
          >
            {data.accessTokenExpiresAt && (
              <Stack spacing={1} flexWrap="wrap" alignItems="flex-start">
                <Typography>{t("access_token_expiry")}: </Typography>
                <Typography variant="caption">
                  {format(
                    data.accessTokenExpiresAt.toDate(),
                    t("date_format_mmmm", { ns: "common" })
                  )}
                </Typography>
              </Stack>
            )}

            <Stack spacing={1} flexWrap="wrap" alignItems="flex-start">
              <Typography>{t("scopes")}: </Typography>
              {sanitizeScope(data.scope).map((scope) => (
                <Chip
                  label={scope}
                  key={scope}
                  size="small"
                  sx={{ borderRadius: 0.5 }}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
      <AppTokenModalEdit token={token} open={open} onClose={closeModal} />
    </Card>
  );
};

export default AppTokenCard;
