import { LinkProps } from "@defs/Links";
import { AppDirectory } from "./AppDirectory";
import AppPage from "./AppPage";

const path = "/developer";

export const devRoutes: LinkProps[] = [
  {
    component: <AppDirectory />,
    title: "apps",
    url: `${path}/apps`,
    exact: true,
    onNavbar: true,
    adminOnly: false,
    authenticated: true,
    icon: "🤖",
  },
  {
    component: <AppPage />,
    title: "app_page",
    exact: false,
    url: `${path}/apps/:appId`,
    onNavbar: false,
    adminOnly: false,
    authenticated: true,
  },
];
