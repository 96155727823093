import Sheet from "@components/Sheet";

interface AcadEditDocSectionProps {
  className?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
}

const AcadEditDocSection: React.FC<AcadEditDocSectionProps> = ({
  className = "",
  title = "",
  subtitle = "",
  children,
}) => {
  return (
    <div className="col-span-full grid grid-cols-1 gap-2 mt-2">
      <div className="text-xl text-left col-span-full">{title}</div>
      <div className="text-sm text-left col-span-full text-gray-500">
        {subtitle}
      </div>
      <Sheet className={className}>
        {children}
      </Sheet>
    </div>
  );
};

export default AcadEditDocSection;
