import LoadingScreen from "@components/LoadingScreen";
import { Participant } from "@defs/Participant.type";
import {
  collection,
  CollectionReference,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { createContext, FC } from "react";
import { useFirestore, useFirestoreCollection } from "reactfire";
import { useTournamentEdit } from "../../TournamentEdit";

export const participantContext = createContext<
  QueryDocumentSnapshot<Participant>[]
>([]);

export const ParticipantContextProvider: FC = ({ children }) => {
  const tour = useTournamentEdit();

  return tour?.id ? (
    <DataProvider tourId={tour.id}>{children}</DataProvider>
  ) : (
    <NullProvider />
  );
};

const NullProvider = () => {
  return null;
};

type DataProps = {
  tourId: string;
};

const DataProvider: FC<DataProps> = ({ tourId, children }) => {
  const db = useFirestore();
  const colRef = collection(
    db,
    "tournaments",
    tourId,
    "participants"
  ) as CollectionReference<Participant>;
  const { data, status } = useFirestoreCollection(colRef);
  const docs = data?.docs ?? [];
  return status === "loading" ? (
    <LoadingScreen />
  ) : (
    <participantContext.Provider value={docs}>
      {children}
    </participantContext.Provider>
  );
};
