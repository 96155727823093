import { UserData } from "@defs/User";
import { AcadDirectory, AcadTable } from "@components";
import { HeadCell } from "@components/table/AcadTableHead";
import { Card, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { Check, Person } from "@mui/icons-material";
import { useState } from "react";
import { useSchoolListPending } from "./context";
import PendingSchoolDialog from "./PendingSchoolDialog";
import { useTranslation } from "react-i18next";

const SchoolListPendingDirectory = () => {
  const [open, set] = useState(false);
  const [selected, setSelected] = useState<UserData | null>(null);
  const {
    data,
    loading,
    error,
    cursor,
    nextDisabled,
    next,
    prev,
    prevDisabled,
    limit,
  } = useSchoolListPending();

  const tableData = data?.docs.map((doc) => doc.data()) ?? [];

  const { t } = useTranslation("schools");

  const headCells: HeadCell<UserData>[] = [
    {
      id: "pendingSchoolName",
      label: t("school_name"),
    },
    {
      id: "username",
      label: t("submitted_by"),
    },
    {
      id: "",
      label: t("actions"),
    },
  ];

  const selectPendingSchool = (item: UserData) => () => {
    set(true);
    setSelected(item);
  };

  return (
    <AcadDirectory loading={loading} hasData={!!data?.size} error={error}>
      <Card>
        <AcadTable
          data={tableData}
          orderBy={"pendingSchoolName"}
          disableNext={nextDisabled}
          disablePrev={prevDisabled}
          rowsPerPage={limit}
          {...{ next, prev, cursor, headCells }}
          renderRows={(row, i) => (
            <TableRow key={i}>
              <TableCell>{row.pendingSchoolName}</TableCell>
              <TableCell>{row.username}</TableCell>
              <TableCell padding="checkbox">
                <div className="flex items-center space-x-1">
                  <Tooltip title={t("accept_school")}>
                    <IconButton
                      size="small"
                      color="success"
                      onClick={selectPendingSchool(row)}
                    >
                      <Check fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("check_user")}>
                    <IconButton size="small">
                      <Person fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          )}
        />
      </Card>

      {selected && (
        <PendingSchoolDialog
          open={open}
          pendingSchoolName={selected?.pendingSchoolName}
          uid={selected.uid}
          onClose={() => set(false)}
        />
      )}
    </AcadDirectory>
  );
};

export default SchoolListPendingDirectory;
