import { Button } from "@mui/material";
import { useState } from "react";
import Sheet from "@components/Sheet";
import OrgCreate from "./OrgCreate";
import OrgDirectory from "./OrgDirectory";
import withAdminCheck from "@hocs/withAdminCheck";
import { useTranslation } from "react-i18next";

const OrgsList = withAdminCheck(({ isAdmin, user }) => {
  const [createState, setCreateState] = useState(false);
  const { t } = useTranslation("community");

  return (
    <div className="w-full flex flex-col py-4">
      <Sheet>
        <div className="text-xl mb-2">{t("communities_directory")}</div>
        {isAdmin && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCreateState(true)}
          >
            {t("create_community")}
          </Button>
        )}
      </Sheet>

      <div className="w-full">
        <div className="text-lg mb-2 mt-4">{t("community_directory")}</div>
        <OrgDirectory {...{ isAdmin, user }} />
      </div>

      <OrgCreate open={createState} onClose={() => setCreateState(false)} />
    </div>
  );
});

export default OrgsList;
