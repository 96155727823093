import { LinkProps } from "@defs/Links";
import Region from "@defs/region/region";

export const regionRoleLock = (
  link: LinkProps,
  region: Region,
  isAdmin: boolean
) => {
  return !isAdmin
    ? link.forbidRegions?.length
      ? !link.forbidRegions?.some((regionItem) => regionItem === region)
      : true
    : true;
};
