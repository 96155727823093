import { AcadImageButton, AcadTextField } from "@components";
import LoadingOverlay from "@components/LoadingOverlay";
import { withFileUpload } from "@hocs";
import withUser from "@hocs/withUser";
import { joiResolver } from "@hookform/resolvers/joi";
import useFileUploadContext from "@hooks/use_file_upload_context";
import { stringArray } from "@lib/stringArray";
import {
  OAuthClient,
  OAuthClientCreate,
  OAuthClientUpdate,
  OAUTH_CLIENT_PATH,
} from "@models/oauth";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Stack,
  Switch,
} from "@mui/material";
import { doc, DocumentReference, updateDoc } from "firebase/firestore";
import { MouseEventHandler, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useFirestore } from "reactfire";
import { useOAuthClient } from "./oauthClientContext";

const AppPageForm = withUser(({ isSuperAdmin }) => {
  const client = useOAuthClient();
  const db = useFirestore();
  const { t } = useTranslation("developer");
  const [loading, setLoading] = useState(false);
  const { uploadFilesAndSet } = useFileUploadContext();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    control,
  } = useForm<OAuthClientUpdate>({
    defaultValues: {
      description: client.description,
      icon: client.icon,
      name: client.name,
      redirectUris: stringArray(client.redirectUris),
      privacyPolicyUrl: client.privacyPolicyUrl,
      termsOfServiceUrl: client.termsOfServiceUrl,
      isAdminApp: client.isAdminApp,
      isPartnerApp: client.isPartnerApp,
    },
    resolver: joiResolver(OAuthClientUpdate.schema),
  });

  const save: MouseEventHandler<HTMLButtonElement> = async (e) => {
    setLoading(true);
    await uploadFilesAndSet(setValue);
    saveChanges(e);
  };

  const saveChanges = handleSubmit(
    async (data) => {
      const ref = doc(
        db,
        OAUTH_CLIENT_PATH,
        client.clientId
      ) as DocumentReference<OAuthClient>;
      if (data.isAdminApp === undefined) delete data.isAdminApp;
      if (data.isPartnerApp === undefined) delete data.isPartnerApp;
      try {
        await updateDoc(ref, data);
      } catch (e) {
        console.error(e);
        toast.error((e as any).message);
      }
      setLoading(false);
    },
    (err) => {
      console.error(err);
      toast.error(
        Object.values(err)
          .map((e) =>
            Array.isArray(e) ? e.map((ee) => ee.message) : e.message
          )
          .join("\n")
      );
      setLoading(false);
    }
  );

  return (
    <Card>
      <LoadingOverlay show={loading} />
      <CardHeader title="Client Info" />
      <CardContent>
        <Stack direction="row" spacing={2}>
          <AcadImageButton<OAuthClientCreate>
            name="icon"
            className="h-32 w-32 flex-shrink-0  bg-center bg-no-repeat bg-contain border-solid border rounded border-gray-300"
            path={getValues("icon")}
          />
          <Stack sx={{ width: "100%" }} spacing={2}>
            <AcadTextField {...register("name")} label={t("app_name")} />
            <AcadTextField
              errors={errors}
              multiline
              minRows={3}
              {...register("description")}
              label={t("description", { ns: "common" })}
            />
            <AcadTextField
              {...register("redirectUris.0")}
              label={t("redirect_url")}
              errors={errors}
            />
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              {isSuperAdmin && (
                <Stack direction="row" alignItems="center" sx={{ pt: 2 }}>
                  <Controller
                    control={control}
                    name="isAdminApp"
                    render={({ field }) => (
                      <FormControlLabel
                        label={t("is_admin_app")}
                        control={<Switch {...field} />}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="isPartnerApp"
                    render={({ field }) => (
                      <FormControlLabel
                        label={t("is_partner_app")}
                        control={<Switch {...field} />}
                      />
                    )}
                  />
                </Stack>
              )}
              <Button variant="contained" onClick={save} disabled={loading}>
                Save
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
});
export default withFileUpload(AppPageForm);
