import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { FormEvent, useContext } from "react";
import { useHistory } from "react-router-dom";
import { DocumentReference, writeBatch } from "firebase/firestore";
import { userContext } from "@lib/AuthContext";
import { useFirestore } from "reactfire";
import { useDeleteDocument } from "@hooks";
import {
  AcadDateTimePicker,
  AcadEditDocSection,
  AcadImageButton,
  AcadMultiInput,
  AcadSEO,
  AcadTextField,
} from "@components";
import Scholarship, { ScholarshipPublishState } from "@models/scholarship";
import ScholarshipInfo from "@models/scholarship_info";
import useScholarshipCollections from "./use_scholarship_collections";
import toast from "react-hot-toast";
import asyncHook from "@lib/AsyncHook";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { fileUploadContext } from "@contexts/file_upload";
import { useTranslation } from "react-i18next";
interface ScholarshipEditFormProps {
  scRef: DocumentReference<Scholarship>;
  scInfoRef: DocumentReference<ScholarshipInfo>;
  formData: ScholarshipInfo;
  onSubmit(formData: ScholarshipInfo): void;
}

const ScholarshipEditForm = ({
  onSubmit,
  scRef,
  scInfoRef,
  formData,
}: ScholarshipEditFormProps) => {
  const db = useFirestore();
  const history = useHistory();
  const { isAdmin: admin } = useContext(userContext);
  const { uploadFilesAndSet } = useContext(fileUploadContext);
  const { t } = useTranslation("scholarships");

  const {
    control,
    register,
    getValues,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ScholarshipInfo>({
    defaultValues: formData,
    resolver: joiResolver(ScholarshipInfo.schema),
  });

  const { eligibilities, benefits, requirements, sponsors } =
    useScholarshipCollections({ db });

  // delete
  useDeleteDocument<Scholarship>({
    docRef: scRef,
    showWarning: false,
  });
  const { confirmDelete } = useDeleteDocument<ScholarshipInfo>({
    docRef: scInfoRef,
    titleMsg: t("delete_scholarship_question"),
  });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    await uploadFilesAndSet(setValue);
    const order = getValues("order");
    if (!order) {
      setValue("order", null);
    }
    handleSubmit((value) => {
      onSubmit(value);
    })(e);
  };

  const goBack = () => {
    history.push("/scholarship/scholarships");
  };

  const doDelete = async () => {
    if (await confirmDelete()) {
      // delete scholarshipInfo and scholarship then go back

      // TODO: figure out batch delete in use_delete_document
      const batch = writeBatch(db);
      batch.delete(scRef);
      batch.delete(scInfoRef);
      asyncHook(batch.commit()).then(([, err]) => {
        if (err) {
          toast.error(t("unable_to_delete_document_error", { error: err }));
        }
        goBack();
      });
    }
  };

  const publishStates = Object.values(ScholarshipPublishState);
  return (
    <>
      <AcadImageButton
        name="bannerUrl"
        onImageSelect={() => setValue("bannerUrl", "")}
        path={getValues().bannerUrl}
        style={{ aspectRatio: "16/9" }}
        className="w-full h-72 bg-gray-200 bg-cover bg-center rounded-md"
        noImageText={t("upload_banner")}
      />
      <AcadImageButton
        name="logoUrl"
        onImageSelect={() => setValue("logoUrl", "")}
        path={getValues().logoUrl}
        style={{ aspectRatio: "16/9" }}
        className="shadow-md h-36 bg-white rounded-md self-center sm:self-start sm:ml-4 -mt-14  bg-cover bg-center flex justify-center items-center"
        noImageText={t("upload_logo_app_will_use_sponsor_logo")}
      />

      <form className="grid grid-cols-12 gap-4 pt-4" onSubmit={submit}>
        <AcadEditDocSection
          title={t("scholarship_info")}
          className="col-span-12 grid grid-cols-12 gap-4"
        >
          <AcadTextField
            {...register("name")}
            label={t("name")}
            className="col-span-12 md:col-span-6"
            required
            errors={errors}
          />

          <AcadTextField
            {...register("slug")}
            label={t("slug")}
            className="col-span-12 md:col-span-6"
            required
            errors={errors}
          />

          <AcadDateTimePicker
            controllerProps={{ name: "startDate", control }}
            renderInput={(params) => (
              <AcadTextField
                {...params}
                className="col-span-12 sm:col-span-6"
                name="startDate"
                errors={errors}
                required
              />
            )}
            label={t("start_date")}
          />

          <AcadDateTimePicker
            controllerProps={{ name: "endDate", control }}
            renderInput={(params) => (
              <AcadTextField
                {...params}
                className="col-span-12 sm:col-span-6"
                name="endDate"
                errors={errors}
                required
              />
            )}
            label={t("end_date")}
          />

          <AcadTextField
            {...register("typeformId")}
            label={t("typeform_id")}
            className="col-span-12 sm:col-span-6"
            errors={errors}
          />

          <Controller
            name="publishState"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                disableClearable
                className="col-span-12 sm:col-span-6"
                options={publishStates}
                onChange={(e, value) => {
                  if (!value) return;
                  field.onChange(value);
                }}
                renderInput={(params) => (
                  <AcadTextField
                    className="col-span-12 sm:col-span-6"
                    {...params}
                    label={t("publish_question")}
                    errors={errors}
                  />
                )}
              />
            )}
          />

          <AcadTextField
            {...register("scholarshipDesc")}
            multiline
            rows={9}
            label={t("scholarship_description")}
            className="col-span-12"
            errors={errors}
          />

          <FormControlLabel
            label={t("requires_discord")}
            className="col-span-12 md:col-span-4"
            control={
              <Checkbox
                checked={watch("requiresDiscord")}
                onChange={() =>
                  setValue("requiresDiscord", !getValues().requiresDiscord)
                }
              />
            }
          />
          <FormControlLabel
            label={t("requires_verified_student")}
            className="col-span-12 md:col-span-4"
            control={
              <Checkbox
                checked={watch("requiresVerifiedStudent")}
                onChange={() =>
                  setValue(
                    "requiresVerifiedStudent",
                    !getValues().requiresVerifiedStudent
                  )
                }
              />
            }
          />
          <AcadTextField
            {...register("order")}
            label={t("order")}
            className="col-span-12 md:col-span-4"
            helperText={t("order_helper_text")}
            errors={errors}
          />
          <AcadTextField
            {...register("verifiedStudentDesc")}
            multiline
            rows={3}
            label={t("require_verified_student_desc")}
            className="col-span-12"
            errors={errors}
          />
        </AcadEditDocSection>

        <AcadEditDocSection
          title={t("sponsors")}
          className="col-span-12 grid grid-cols-12 gap-4"
        >
          <Controller
            name="sponsors"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                className="col-span-12"
                options={sponsors}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => {
                  field.onChange(value.map((b) => b.id));
                }}
                value={sponsors.filter((sponsor) =>
                  field.value.includes(sponsor.id)
                )}
                renderInput={(params) => (
                  <AcadTextField
                    {...params}
                    className="col-span-12"
                    name="sponsors"
                    label={t("sponsors")}
                    errors={errors}
                  />
                )}
              />
            )}
          />
        </AcadEditDocSection>

        <AcadEditDocSection
          title={t("benefits")}
          className="col-span-12 grid grid-cols-12 gap-4"
        >
          <Controller
            name="benefits"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                className="col-span-12"
                options={benefits}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => {
                  field.onChange(value.map((b) => b.id));
                }}
                value={benefits.filter((benefit) =>
                  field.value.includes(benefit.id)
                )}
                renderInput={(params) => (
                  <AcadTextField
                    {...params}
                    className="col-span-12"
                    name="benefits"
                    label={t("benefits")}
                    errors={errors}
                  />
                )}
              />
            )}
          />

          <AcadMultiInput
            className="col-span-full"
            label={t("benefit_details")}
            value={getValues().benefitDetails}
            onChange={(value) => {
              setValue("benefitDetails", value);
            }}
          />

          <AcadTextField
            {...register("benefitsDesc")}
            multiline
            rows={9}
            label={t("benefits_description")}
            className="col-span-12"
            errors={errors}
          />
        </AcadEditDocSection>

        <AcadEditDocSection
          title={t("eligibilities_and_requirements")}
          className="col-span-12 grid grid-cols-12 gap-4"
        >
          <Controller
            name="eligibilities"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                className="col-span-12"
                options={eligibilities}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => {
                  field.onChange(value.map((b) => b.id));
                }}
                value={eligibilities.filter((eligibility) =>
                  field.value.includes(eligibility.id)
                )}
                renderInput={(params) => (
                  <AcadTextField
                    {...params}
                    className="col-span-12"
                    name="eligibilities"
                    label={t("eligibilities")}
                    errors={errors}
                  />
                )}
              />
            )}
          />

          <Controller
            name="requirements"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                className="col-span-12"
                options={requirements}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => {
                  field.onChange(value.map((b) => b.id));
                }}
                value={requirements.filter((req) =>
                  field.value.includes(req.id)
                )}
                renderInput={(params) => (
                  <AcadTextField
                    {...params}
                    className="col-span-12"
                    name="requirements"
                    label={t("requirements")}
                    errors={errors}
                  />
                )}
              />
            )}
          />

          <AcadMultiInput
            className="col-span-full"
            label={t("eligibilities_details")}
            value={getValues().eligibilityDetails}
            onChange={(value) => {
              setValue("eligibilityDetails", value);
            }}
          />
          <AcadMultiInput
            className="col-span-full"
            label={t("requirements_details")}
            value={getValues().requirementDetails}
            onChange={(value) => {
              setValue("requirementDetails", value);
            }}
          />
          <AcadTextField
            {...register("reqsAndEligibilityDesc")}
            multiline
            rows={9}
            label={t("eligibilities_and_requirements_description")}
            className="col-span-12"
            errors={errors}
          />
        </AcadEditDocSection>

        <div className="text-xl text-left col-span-full">{t("seo")}</div>

        <AcadSEO
          onChange={(val) => setValue("seo", val)}
          value={getValues().seo}
          className="col-span-full"
        />

        <div className="col-span-12 flex justify-end">
          <Button
            variant="outlined"
            className="mr-4"
            color="secondary"
            onClick={doDelete}
            disabled={!admin}
          >
            {t("delete", { ns: "common" })}
          </Button>
          <Button type="submit" variant="contained" color="primary">
            {t("save", { ns: "common" })}
          </Button>
        </div>
      </form>
    </>
  );
};

export default ScholarshipEditForm;
