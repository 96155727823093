import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { DeepMap, DeepPartial, FieldError } from "react-hook-form";

export type AcadTextFieldProps = {
  errors?: DeepMap<DeepPartial<any>, FieldError>;
} & TextFieldProps;

/**
 * To show error message below the TextField, add a "name" prop.
 * This is automatically added when `register` is used
 * @returns
 */
const AcadTextField = React.forwardRef(
  ({ errors, ...textFieldProps }: AcadTextFieldProps, ref) => {
    const isError = (
      errors?: DeepMap<DeepPartial<any>, FieldError>
    ): boolean => {
      return !!errors && !!textFieldProps.name && !!errors[textFieldProps.name];
    };

    const error = errors && textFieldProps.name && errors[textFieldProps.name];

    return (
      <TextField
        inputRef={ref}
        error={isError(errors)}
        {...textFieldProps}
        helperText={error?.message || textFieldProps.helperText}
      />
    );
  }
);

export default AcadTextField;
