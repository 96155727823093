import AcadConsts from "@consts";
import {
  collection,
  CollectionReference,
  query,
  QueryConstraint,
} from "@firebase/firestore";
import usePaginatedCollection, {
  defaultPaginatedCollectionValues,
  PaginatedCollection,
} from "@hooks/use_paginated_collection";
import ReferralCode from "@models/referral_code";
import { createContext, FC, useContext, useState } from "react";
import { useFirestore } from "reactfire";

interface ReferralCodeTableContext {
  filters: QueryConstraint[];
  setFilters(constraints: QueryConstraint[]): void;
  referralCodes: PaginatedCollection<ReferralCode>;
}

const referralCodeTableContext = createContext<ReferralCodeTableContext>({
  filters: [],
  setFilters: AcadConsts.noop,
  referralCodes: defaultPaginatedCollectionValues<ReferralCode>(),
});

export const ReferralCodeTableProvider: FC = ({ children }) => {
  const db = useFirestore();
  const [filters, setFiltersState] = useState<QueryConstraint[]>([]);
  const ref = collection(
    db,
    ReferralCode.PATH
  ) as CollectionReference<ReferralCode>;
  const q = query(ref);
  const referralCodes = usePaginatedCollection({
    query: q,
    limit: 20,
    orderBy: {
      direction: "asc",
      field: "code",
    },
  });

  const setFilters = (constraints: QueryConstraint[]) => {
    setFiltersState(constraints);
  };

  return (
    <referralCodeTableContext.Provider
      value={{
        filters,
        setFilters,
        referralCodes,
      }}
    >
      {children}
    </referralCodeTableContext.Provider>
  );
};

export const useReferralCodeTable = () => useContext(referralCodeTableContext);
