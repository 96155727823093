import { Stack } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { seriesContext } from ".";
import Round from "./Round";

const Schedule = () => {
  const { seriesList } = useContext(seriesContext);

  const series = seriesList.map((s) => s.data());

  const allGroupMatches = series.filter((s) => !!s.groupId);
  const allPlayoffsMatches = series.filter((s) => !s.groupId);

  const groupRounds = Array.from(new Set(allGroupMatches.map((m) => m.round)));
  const playoffsRounds = Array.from(
    new Set(allPlayoffsMatches.map((s) => s.round))
  );

  const groups = groupRounds.map((round) =>
    allGroupMatches.filter((s) => s.round === round)
  );
  const playoffs = playoffsRounds.map((round) =>
    allPlayoffsMatches.filter((s) => s.round === round)
  );

  const { t } = useTranslation("events");

  return (
    <div>
      {!!groups.length && (
        <Stack>
          <div className="text-xl">{t("groups")}</div>
          {groups.map((groupsSeriesList, i) => (
            <Round key={i} seriesList={groupsSeriesList} />
          ))}
        </Stack>
      )}
      <Stack gap={2}>
        <div className="text-xl">{t("playoffs")}</div>
        {playoffs.map((playoffsSeriesList, i) => (
          <Round key={i} seriesList={playoffsSeriesList} />
        ))}
      </Stack>
    </div>
  );
};

export default Schedule;
