import { DialogProps, OrgProps } from "@defs/Types";
import { doc, getDoc, setDoc } from "@firebase/firestore";
import { useRegion } from "@hooks/use_region";
import getSchools from "@lib/GetSchoolList";
import { slugRE } from "@lib/RegularExpressions";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grow,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFirestore } from "reactfire";

const OrgCreate: FC<DialogProps> = ({ open, onClose }) => {
  const db = useFirestore();
  const history = useHistory();
  const md = useMediaQuery("(min-width: 768px)");
  const [loading, setLoading] = useState(false);
  const { defaultSubregion } = useRegion();
  // "schoolName",
  //         "schoolShortcode",
  //         "Name",
  //         "Shortcode",
  //         "Shortname",
  //         "visibility"
  const [form, setForm] = useState<OrgProps>({
    logo: "",
    name: "",
    shortcode: "",
    shortname: "",
    schoolName: "",
    schoolShortcode: "",
    schoolCampus: "",
    visibility: "draft",
    region: defaultSubregion,
    isAlliance: true,
    star_level: 1,
    admins: [],
    moderators: [],
    description: "",
    cover_photo: "",
    profile_photo: "",
  });

  const [schools, setSchools] = useState<string[]>([]);

  const { t } = useTranslation("community");

  useEffect(() => {
    if (!setSchools) return;

    (async () => {
      setSchools(await getSchools());
    })();
  }, [setSchools]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const ref = doc(db, "orgs", form.schoolShortcode);
    if (!schools.includes(form?.schoolName)) {
      toast.error(t("invalid_school"));
      setLoading(false);
      return;
    }

    if (!slugRE.test(form.schoolShortcode)) {
      toast.error(t("invalid_school_shortcode"));
      setLoading(false);
      return;
    }

    const docPromise = new Promise(async (resolve, reject) => {
      try {
        const snap = await getDoc(ref);

        if (snap.exists()) {
          reject({
            code: t("org_with_school_shortcode_already_exists", {
              schoolShortcode: form.schoolShortcode,
            }),
          });
        } else {
          resolve(snap);
        }
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
    toast.promise(
      docPromise,
      {
        success() {
          toast.promise(setDoc(ref, form), {
            success() {
              onClose();
              setLoading(false);
              history.push(`community/${form.schoolShortcode}`);
              return t("successfully_created_new_org");
            },
            error(error) {
              console.error(error);
              setLoading(false);
              return t("failed_error", { error: error.code });
            },
            loading: t("creating_org"),
          });
          return t("org_can_be_created");
        },
        error(error) {
          console.error(error);
          setLoading(false);
          return t("failed_error", { error: error.code });
        },
        loading: t("checking_if_org_exists"),
      },
      { className: "text-center" }
    );
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <Dialog
      TransitionComponent={Grow}
      open={open}
      onClose={onClose}
      fullScreen={!md}
      maxWidth="sm"
      fullWidth
      scroll="body"
      classes={{ paper: "relative" }}
    >
      <form onSubmit={onSubmit}>
        <Fade in={loading}>
          <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center bg-white bg-opacity-70 z-50"></div>
        </Fade>
        <DialogTitle className="">{t("create_new_org")}</DialogTitle>
        <DialogContent className="overflow-y-visible">
          <div className="grid grid-cols-12 gap-4">
            <div className="text-lg mt-4 mb-2 col-span-full">
              {t("org_info")}
            </div>
            <TextField
              variant="outlined"
              label={t("org_name")}
              className="col-span-full"
              value={form.name}
              name="name"
              onChange={onChange}
              required
            />
            <TextField
              variant="outlined"
              size="small"
              label={t("org_shortname")}
              className="col-span-full md:col-span-6"
              value={form.shortname}
              name="shortname"
              onChange={onChange}
              required
            />
            <TextField
              variant="outlined"
              size="small"
              label={t("org_shortcode")}
              className="col-span-full md:col-span-6"
              value={form.shortcode}
              name="shortcode"
              onChange={onChange}
              required
            />

            <div className="text-lg mt-4 mb-2 col-span-full">
              {t("school_info")}
            </div>
            <Autocomplete
              className="col-span-full"
              options={schools}
              autoSelect
              autoComplete
              disableClearable
              onChange={(event, value) =>
                setForm({ ...form, schoolName: value + "" })
              }
              renderInput={({ size, ...params }) => (
                <TextField
                  required
                  {...params}
                  label={t("school")}
                  variant="outlined"
                />
              )}
            />
            {/* <TextField
              variant="outlined"
              label="School Name"
              className="col-span-full md:col-span-full mt-4"
              value={form.schoolName}
              name="schoolName"
              onChange={onChange}
              required
            /> */}
            <TextField
              variant="outlined"
              size="small"
              label={t("school_branch_or_campus")}
              className="col-span-full md:col-span-6"
              value={form.schoolCampus}
              name="schoolCampus"
              onChange={onChange}
            />
            <TextField
              variant="outlined"
              size="small"
              label={t("school_shortcode")}
              className="col-span-full md:col-span-6"
              value={form.schoolShortcode}
              name="schoolShortcode"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setForm({
                  ...form,
                  schoolShortcode: e.target.value.toUpperCase(),
                })
              }
              helperText={t("example_schools")}
              required
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            {t("save", { ns: "common" })}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default OrgCreate;
