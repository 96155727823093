import Iconify from "@components/Iconify";
import loadingMessage from "@defs/LoadingMessages";
import {
  Button,
  ButtonProps,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { ReactNode } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useFunctions } from "reactfire";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { useTournamentEdit } from "../TournamentEdit";

type Action =
  | "registrationOpen"
  | "registrationClose"
  | "checkInOpen"
  | "checkInClose"
  | "checkInProcess"
  | "checkInAbort"
  | "tournamentStart"
  | "tournamentEnd"
  | "tournamentPublish";

interface QuickActionProps {
  fireTourId: string;
  action: Action;
}

const QuickActions = () => {
  const fn = useFunctions();
  const tourSnap = useTournamentEdit();

  const tour = tourSnap?.data();
  const regOpen = !!tour?.regOpen;

  const { t } = useTranslation("events");

  const actionMsg: Record<Action, Partial<SweetAlertOptions>> = {
    registrationOpen: {
      title: t("open_registration_question"),
      showCancelButton: true,
      confirmButtonText: "open_registration",
    },
    registrationClose: {
      title: t("close_registration_question"),
      showCancelButton: true,
      confirmButtonText: "close_registration",
      confirmButtonColor: "error",
    },
    checkInOpen: {
      title: t("open_check_in_question"),
      text: t("this_will_automatically_close_registration"),
      showCancelButton: true,
      confirmButtonText: t("open_check_in"),
    },
    checkInClose: {
      title: t("pause_check_ins_question"),
      text: t("this_will_not_process_any_check_ins_yet"),
      showCancelButton: true,
      confirmButtonText: t("pause_check_ins"),
      confirmButtonColor: "error",
    },
    checkInProcess: {
      title: t("process_check_ins_question"),
      text: t("the_participants_not_checked_in_moved_to_wait_list"),
      showCancelButton: true,
      confirmButtonText: t("open_check_in"),
    },
    checkInAbort: {
      title: t("abort_check_ins_question"),
      text: t("this_will_abort_all_check_ins"),
      showCancelButton: true,
      confirmButtonText: t("abort_check_ins"),
      confirmButtonColor: "error",
    },
    tournamentStart: {
      title: t("start_tournament_question"),
      showCancelButton: true,
      confirmButtonText: t("start_tournament"),
    },
    tournamentEnd: {
      title: t("end_tournament_question"),
      text: t("this_will_permanently_delete_all_temporary_teams"),
      showCancelButton: true,
      confirmButtonText: t("end_tournament"),
      confirmButtonColor: "error",
    },
    tournamentPublish: {
      title: t("publish_tournament_question"),
      text: t("this_wilL_publish_tournament"),
      showCancelButton: true,
      confirmButtonText: t("publish_tournament"),
    },
  };

  const doAction = (action: Action) => () => {
    Swal.fire(actionMsg[action]).then((result) => {
      if (result.isConfirmed) {
        const callable = httpsCallable<QuickActionProps>(
          fn,
          "tournament-quickAction"
        );

        const promise = callable({ action, fireTourId: tourSnap?.id ?? "" });

        toast.promise(promise, {
          loading: loadingMessage(),
          error: (err) =>
            err.message || t("unable_to_perform_action", { ns: "common" }),
          success: t("done", { ns: "common" }),
        });
      }
    });
  };

  return !!tour ? (
    <Card>
      <CardHeader
        title={
          <Stack direction="row" gap={2}>
            <Typography variant="h6">{t("quick_actions")}</Typography>
            {tour?.visibility === "draft" && (
              <Btn
                size="small"
                variant="outlined"
                startIcon={<Iconify icon="eva:paper-plane-fill" />}
                onClick={doAction("tournamentPublish")}
              >
                {t("publish", { ns: "common" })}
              </Btn>
            )}
          </Stack>
        }
        subheader={t("every_action_discard_unsaved_changes")}
      ></CardHeader>
      <CardContent>
        <Stack gap={2}>
          <Stack gap={2} direction="row" flexWrap="wrap">
            {regOpen ? (
              <Btn
                onClick={doAction("registrationClose")}
                startIcon={<Iconify icon="eva:stop-circle-fill" />}
                disabled={tour.lookingForCheckIns}
                color="error"
              >
                {t("close_registration")}
              </Btn>
            ) : (
              <Btn
                onClick={doAction("registrationOpen")}
                startIcon={<Iconify icon="eva:book-open-fill" />}
                disabled={tour.lookingForCheckIns || !!tour.checkInStartedAt}
              >
                {t("open_registration")}
              </Btn>
            )}
            {tour.lookingForCheckIns ? (
              <>
                <Btn
                  onClick={doAction("checkInClose")}
                  desc={t("momentarily_halt_check_ins")}
                  startIcon={<Iconify icon="eva:pause-circle-fill" />}
                >
                  {t("pause_check_ins")}
                </Btn>
                <Btn
                  onClick={doAction("checkInProcess")}
                  color="primary"
                  desc={t("the_participants_who_have_not_checked_in")}
                >
                  {t("process_check_ins")}
                </Btn>
              </>
            ) : (
              <Btn
                onClick={doAction("checkInOpen")}
                desc={t("this_will_automatically_close_registration")}
                disabled={!!tour.tournament.started_at}
                startIcon={<Iconify icon="eva:log-in-fill" />}
              >
                {tour.checkInStartedAt
                  ? t("reopen_check_in")
                  : t("open_check_in")}
              </Btn>
            )}
            {tour.checkInStartedAt && (
              <Btn
                onClick={doAction("checkInAbort")}
                color="error"
                disabled={!!tour.tournament.started_at}
                desc={t("this_will_abort_all_check_ins")}
              >
                {t("abort_check_in")}
              </Btn>
            )}
            <Btn
              onClick={doAction("tournamentStart")}
              desc={t("check_in_must_be_processed_first")}
              disabled={!tour.checkInProcessed || !!tour.tournament.started_at}
              startIcon={<Iconify icon="eva:play-circle-fill" />}
            >
              {t("start_tournament")}
            </Btn>
            <Btn
              onClick={doAction("tournamentEnd")}
              color="success"
              startIcon={<Iconify icon="eva:award-fill" />}
              desc={t("end_the_tournament_this_wilL_permanently")}
              disabled={
                !tour.tournament.started_at || !!tour.tournament.completed_at
              }
            >
              {t("end_tournament")}
            </Btn>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  ) : null;
};
export default QuickActions;

const Btn = ({ desc, ...props }: ButtonProps & { desc?: ReactNode }) => (
  <Tooltip title={desc ?? ""}>
    <Button
      variant="contained"
      color="inherit"
      size="small"
      {...props}
    ></Button>
  </Tooltip>
);
