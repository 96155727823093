import { PhSubregion } from "./region/ph_subregion";
import { OrgProps } from "./Types";

const placeholder_org: OrgProps = {
  name: "AcadArena",
  shortcode: "AA",
  shortname: "AcadArena",
  schoolName: "AcadArena Technologies Inc.",
  schoolShortcode: "AA",
  logo: "",
  visibility: "public",
  region: PhSubregion.Metro,
};

export default placeholder_org;

// export interface OrgProps {
//     schoolName: string;
//     schoolCampus?: string;
//     schoolShortcode: string;
//     name: string;
//     shortcode: string;
//     shortname: string;
//     logo: string;
//     visibility: "draft" | "public";
//     region: RegionEnum;
//     admins?: string[]; //string of username
//     users?: string[]; //string of username
//     description?: string;
//     description_thumbnail?: string;
//     contact_email?: string;
//     profile_photo?: string;
//     cover_photo?: string;
//     star_level?: number;
//     geography?: string;
//     isAlliance: boolean;
//     moderators: string[];
//     social_links?: Socials;
//   }
