import Joi from "joi";

export const stringExtension: Joi.ExtensionFactory = (joi) => {
  return {
    type: "string",
    base: joi.string(),
    messages: {
      "string.empty": "{{#label}} is required",
    },
  };
};

export default stringExtension;
