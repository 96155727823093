import { Button, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { FrontPage } from "@defs/Frontpage.type";
import {
  arrayRemove,
  doc,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  QueryDocumentSnapshot,
  updateDoc,
} from "firebase/firestore";
import OrgSelect from "./CommunitySelect";
import toast from "react-hot-toast";
import loadingMessage from "@defs/LoadingMessages";
import { Fab } from "@mui/material";
import { Close } from "@mui/icons-material";
import swal from "sweetalert2";
import { OrgProps } from "@defs/Types";
import { useFirestore } from "reactfire";
import OrgCard from "@views/Community/Directory/OrgCard";
import { useTranslation } from "react-i18next";

interface Props {
  frontPage: DocumentSnapshot<FrontPage>;
}

const CommunitySlides = ({ frontPage }: Props) => {
  const db = useFirestore();
  const [state, setState] = useState(false);
  const [selection, setSelection] = useState<
    QueryDocumentSnapshot<OrgProps>[] | DocumentSnapshot<OrgProps>[]
  >([]);

  const { t } = useTranslation("frontpage");

  useEffect(() => {
    if (!frontPage) return;

    let isActiveComponent = true;
    frontPage.data()?.communities.forEach((id) => {
      const ref = doc(db, `orgs/${id}`) as DocumentReference<OrgProps>;
      getDoc(ref).then((res) => {
        if (isActiveComponent) {
          setSelection((sel) => {
            if (sel.find((s) => s.id === id)) return sel;
            return [...sel, res];
          });
        }
      });
    });

    return () => {
      isActiveComponent = false;
    };
  }, [frontPage, db]);

  const save = () => {
    setState(false);
    const ref = doc(db, "public/frontpage") as DocumentReference<FrontPage>;
    const promise = updateDoc(ref, {
      communities: Array.from(new Set(selection.map((s) => s.id))),
    });
    toast.promise(promise, {
      loading: loadingMessage(),
      error: (err) => err.message,
      success: t("saved_successfully", { ns: "common" }),
    });
  };

  const data = frontPage.data();

  const removeOrg = (id: string) => () => {
    swal
      .fire({
        title: t("remove_community_question"),
        text: t("remove_org_from_front_page_question"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#B33A3A",
      })
      .then((result) => {
        if (result.isConfirmed) {
          const ref = doc(
            db,
            "public/frontpage"
          ) as DocumentReference<FrontPage>;
          setSelection(selection.filter((s) => s.id !== id));
          const promise = updateDoc(ref, { communities: arrayRemove(id) });
          toast.promise(promise, {
            loading: t("saving_changes", { ns: "common" }),
            error: (err) => err.message,
            success: t("removed_org"),
          });
        }
      });
  };
  return (
    <div className="mt-8">
      <div className="flex">
        <div className="text-lg">{t("communities")}</div>
        <Button
          size="small"
          variant="outlined"
          className="ml-4"
          onClick={() => setState(true)}
        >
          {t("select", { ns: "common" })}
        </Button>
      </div>
      <div className="flex flex-col mt-2 relative overflow-hidden">
        <div className="p-2 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
          {frontPage?.data()?.communities.map((slide, index) => {
            const data = selection.find((s) => s.id === slide)?.data();

            return data ? (
              <div className="relative" key={slide}>
                <Fab
                  size="small"
                  className="absolute top-3 right-3 z-30"
                  onClick={removeOrg(slide)}
                >
                  <Close />
                </Fab>
                <OrgCard org={data} disableOnClick />
              </div>
            ) : (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={233}
                className="flex justify-center items-center"
                key={slide}
              >
                {slide}
              </Skeleton>
            );
          })}
        </div>
      </div>
      {data && (
        <>
          <OrgSelect
            open={state}
            onClose={() => setState(false)}
            {...{ selection, setSelection, save }}
          />
        </>
      )}
    </div>
  );
};

export default CommunitySlides;
