import { useHistory, useParams } from "react-router-dom";
import { doc, DocumentReference } from "firebase/firestore";
import { useFirestore } from "reactfire";
import { useWriteDocument } from "@hooks";
import { AcadEditDocContainer } from "@components";
import GameEditForm from "./GameEditForm";
import Game from "@models/game";
import { FileUploadProvider } from "@contexts/file_upload";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const GameEdit = () => {
  const db = useFirestore();
  const { id } = useParams<{ id: string }>();

  const docRef = doc(db, `${Game.PATH}/${id}`) as DocumentReference<Game>;

  const { loading, snap, error, writeDocument } = useWriteDocument<Game>({
    docRef,
  });

  const history = useHistory();

  const { t } = useTranslation("games");

  const onSubmit = (formData: Game) => {
    const fieldNames = Object.values(formData.fields ?? {}).map((f) =>
      f.fieldName.toLowerCase()
    );
    const hasDuplicateData = new Set(fieldNames).size !== fieldNames.length;
    if (hasDuplicateData) return toast.error(t("duplicate_field_names"));
    writeDocument(formData, false);
  };

  return (
    <FileUploadProvider>
      <AcadEditDocContainer
        snapshot={snap}
        error={error}
        loading={loading}
        onBackBtn={() => history.push("/game")}
        doesNotExistText={t("game_does_not_exist")}
        backBtnText={t("back_to_games")}
      >
        <GameEditForm
          formData={snap?.data() ?? new Game()}
          onSubmit={onSubmit}
        />
      </AcadEditDocContainer>
    </FileUploadProvider>
  );
};

export default GameEdit;
