import { AcadTable } from "@components";
import LoadingOverlay from "@components/LoadingOverlay";
import { UserData } from "@defs/User";
import { usePaginatedCollection } from "@hooks";
import { flatten } from "@lib/flattenObject";
import { genericConvert } from "@lib/genericConvert";
import { Tournament } from "@models/tournament";
import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";
import { format } from "date-fns";
import { collection } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useFirestore, useFunctions } from "reactfire";

type ExportReq = { tournamentIds: string[] };
type ExportTournamentType = {
  tournamentId?: string;
  tournamentName?: string;
  game?: string;
};
type ExportType = (Pick<
  UserData,
  | "uid"
  | "email"
  | "photoURL"
  | "phoneNumber"
  | "_username"
  | "course"
  | "region"
  | "middleInitial"
  | "lastName"
  | "firstName"
  | "studentYear"
  | "isStudent"
  | "teams"
  | "school"
> &
  ExportTournamentType)[];

const ExportPlayers = () => {
  const { t } = useTranslation(["admin", "common"]);
  const [state, set] = useState(false);
  const [loading, setLoading] = useState(false);
  const open = () => set(true);
  const close = () => set(loading ? state : false);
  const [exportData, setExportData] = useState<ExportType>([]);

  const [selected, setSelected] = useState<string[]>([]);

  const fn = useFunctions();
  const db = useFirestore();
  const ref = collection(db, "tournaments").withConverter(
    genericConvert<Tournament>()
  );

  const {
    cursor,
    data: snap,
    next,
    prev,
    nextDisabled,
    prevDisabled,
    limit,
    loading: tableLoading,
  } = usePaginatedCollection({
    query: ref,
    limit: 20,
    orderBy: {
      direction: "desc",
      field: "startDate",
    },
  });

  const docs = snap?.docs ?? [];
  const data = docs.map((doc) => {
    const data = doc.data();
    return { ...data, name: data.tournament.name, id: doc.id };
  });

  const currentIds = data.map((d) => d.id);

  const toggleSelect = (id = "") => {
    setExportData([]);
    if (selected.includes(id)) {
      return setSelected((ids) =>
        ids.filter((selectedId) => selectedId !== id)
      );
    }

    setSelected((ids) => [...ids, id]);
  };

  const numSelected = currentIds.filter((id) => selected.includes(id)).length;

  const selectAll = () => {
    setExportData([]);
    if (!currentIds.every((id) => selected.includes(id))) {
      return setSelected((ids) => [
        ...ids,
        ...currentIds.filter((id) => !ids.includes(id)),
      ]);
    }
    return setSelected((ids) => ids.filter((id) => !currentIds.includes(id)));
  };

  const loadData = async () => {
    setLoading(true);
    const callable = httpsCallable<ExportReq, ExportType>(
      fn,
      "tournament-exportPlayers"
    );
    const res = await callable({ tournamentIds: selected });
    setExportData(res.data);
    setLoading(false);
  };

  return (
    <Stack alignItems="flex-start" spacing={2}>
      <Button variant="contained" size="small" onClick={open}>
        {t("export_players")}
      </Button>
      <Dialog
        open={state}
        onClose={close}
        maxWidth="xl"
        PaperProps={{ sx: { position: "relative" } }}
      >
        <DialogTitle>{t("select_tournaments")}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <LoadingOverlay show={loading || tableLoading} />
            <Card variant="outlined" sx={{ p: 2 }}>
              <Stack direction="row" justifyContent="space-between">
                <div>
                  {t("selected_count", { numSelected: selected.length })}
                </div>
                <Stack direction="row" spacing={2}>
                  <Button size="small" variant="contained" onClick={loadData}>
                    {t("load_data")}
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    disabled={!exportData.length}
                  >
                    <CSVLink
                      data={exportData.map((d) => flatten(d))}
                      filename="Player Export.csv"
                      style={{ color: "#fff" }}
                    >
                      {t("export_players")}
                    </CSVLink>
                  </Button>
                </Stack>
              </Stack>
            </Card>
            <Card variant="outlined">
              <AcadTable<Tournament & { name: string; id: string }>
                dense
                enableCheckbox
                onSelectAllClick={selectAll}
                numSelected={numSelected}
                {...{
                  cursor,
                  data,
                  disableNext: nextDisabled || tableLoading,
                  disablePrev: prevDisabled || tableLoading,
                  headCells: [
                    { id: "name", label: t("tournament") },
                    {
                      id: "startDate",
                      label: t("start_date", { ns: "common" }),
                    },
                    { id: "endDate", label: t("end_date", { ns: "common" }) },
                  ],
                  next,
                  prev,
                  orderBy: "startDate",
                  renderRows(row) {
                    return (
                      <TableRow
                        key={row.id}
                        selected={selected.includes(row.id)}
                        onClick={() => toggleSelect(row.id)}
                        hover
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={selected.includes(row.id)} />
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {row.tournament.name}
                        </TableCell>
                        <TableCell>
                          {row.startDate &&
                            format(
                              row.startDate.toDate(),
                              t("date_format_mmm", { ns: "common" })
                            )}
                        </TableCell>
                        <TableCell>
                          {row.endDate &&
                            format(
                              row.endDate.toDate(),
                              t("date_format_mmm", { ns: "common" })
                            )}
                        </TableCell>
                      </TableRow>
                    );
                  },
                  rowsPerPage: limit,
                }}
              />
            </Card>
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default ExportPlayers;
