import NextConfigForm from "@components/seo/NextConfigForm";
import SEOForm from "@components/seo/SEOForm";
import { withFileUpload } from "@hocs";
import { joiResolver } from "@hookform/resolvers/joi";
import { useWriteDocument } from "@hooks";
import useFileUploadContext from "@hooks/use_file_upload_context";
import SEO from "@models/seo";
import { AcademyParams } from "@models/syspars/academy_params";
import { Button, Stack } from "@mui/material";
import { doc, DocumentReference, DocumentSnapshot } from "firebase/firestore";
import { MouseEventHandler, useMemo } from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFirestore } from "reactfire";

type DocRef = DocumentReference<AcademyParams>;

const AcademySettings = () => {
  const { t } = useTranslation("common");
  const db = useFirestore();
  const docRef = doc(db, AcademyParams.PATH) as DocRef;
  const {
    setValue,
    reset,
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<AcademyParams>({
    defaultValues: new AcademyParams(),
    resolver: joiResolver(AcademyParams.schema),
  });

  const onSnapshotChanged = useMemo(() => {
    return (snap: DocumentSnapshot<AcademyParams>) => {
      const defaultParams = new AcademyParams();
      const { hero, onboarding, ...props } = snap?.data() || defaultParams;
      const nextConfig = { ...defaultParams.nextConfig, ...props.nextConfig };
      const SEO = { ...defaultParams.SEO, ...props.SEO };
      reset({ nextConfig, SEO });
    };
  }, [reset]);

  const { snap, writeDocument } = useWriteDocument<AcademyParams>({
    docRef,
    onSnapshotChanged,
  });

  const { uploadFilesAndSet } = useFileUploadContext();
  const save: MouseEventHandler<HTMLButtonElement> = async (e) => {
    await uploadFilesAndSet(setValue);
    handleSubmit(async (data) => {
      writeDocument(data);
    }, console.error)(e);
  };

  return (
    <Stack sx={{ p: 2 }}>
      <SEOForm
        errors={errors}
        register={register as UseFormRegister<AcademyParams | { SEO: SEO }>}
        thumbnailUrl={snap?.data()?.SEO?.thumbnail}
      />

      <NextConfigForm control={control} errors={errors} />

      <Stack sx={{ m: 2 }} direction="row" justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={save}>
          {t("save")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default withFileUpload(AcademySettings);
