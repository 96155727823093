import acadJoi from "@acad_joi";
import { Timestamp } from "@firebase/firestore";

export default class ReferralCode {
  static readonly PATH: string = "referralCodes";

  code: string = "";
  owner: string = "";
  createdAt: Timestamp = Timestamp.now();
  count: number = 0;
  endDate?: Timestamp;
  purpose?: string = "";

  static schema = acadJoi.object<ReferralCode>({
    code: acadJoi
      .string()
      .label("Referral Code")
      .required()
      .min(2)
      .alphanum()
      .lowercase(),
    owner: acadJoi.string().label("Referral Code Owner").required(),
    createdAt: acadJoi
      .timestamp()
      .label("Referral Code Create Date")
      .required(),
    count: acadJoi.number().required(),
    endDate: acadJoi
      .timestamp()
      .label("Referral Code End Date")
      .allow("")
      .allow(null),
    purpose: acadJoi.string().label("Referral Code Purpose").allow(""),
  });
}
