import { s3 } from "@acad_axios";
import AcadConsts from "@consts";
import i18n from "../../i18n";

import React, { ChangeEvent } from "react";
import toast from "react-hot-toast";

export type Files<T = any> = {
  [key in keyof T]?: {
    file: File;
    path: string;
  };
};

export type UploadResponse = {
  fileUrl: string;
};

const validateMaxFileSize = (file: File, maxFileSize: number) => {
  if (file.size > maxFileSize) {
    toast.error(i18n.t("file_larger_than", { maxFileSize }), {
      style: {
        backgroundColor: "red",
        color: "#fff",
      },
    });
    return false;
  }
  return true;
};

export const onFileSelect =
  <T = any>(
    onAddFile: React.Dispatch<React.SetStateAction<Files>>,
    onSave: React.Dispatch<React.SetStateAction<T>>,
    maxFileSize: number = AcadConsts.maxFileSize
  ) =>
  (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const key = e.target.name;
      if (!validateMaxFileSize(file, maxFileSize)) {
        return;
      }
      onAddFile((files) => ({
        ...files,
        [e.target.name]: {
          file,
          path: URL.createObjectURL(file),
        },
      }));
      uploadFile(
        file,
        (url) => onSave((state) => ({ ...state, [key]: url })),
        maxFileSize
      );
    }
  };

export const uploadFile = async (
  file: File,
  onSave: (url: string) => void = AcadConsts.noop,
  maxFileSize: number = AcadConsts.maxFileSize
) => {
  if (!validateMaxFileSize(file, maxFileSize)) {
    throw new Error(i18n.t("file_too_large"));
  }

  const formData = new FormData();
  formData.append("file", file);

  const promise = s3.post<UploadResponse>("/upload", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });

  return toast.promise(promise, {
    loading: i18n.t("upload_in_progress"),
    error: (e) => e.message,
    success: (res) => {
      onSave(res.data.fileUrl);
      return i18n.t("upload_success");
    },
  });
};

export const fileSelectAsync = async (
  { target: { files } }: ChangeEvent<HTMLInputElement>,
  onAddFile: (url: string) => void,
  maxFileSize: number = AcadConsts.maxFileSize
) => {
  if (files && files[0]) {
    const file = files[0];
    if (file.size > maxFileSize) {
      toast.error(i18n.t("file_larger_than", { maxFileSize }), {
        style: {
          backgroundColor: "red",
          color: "#fff",
        },
      });
      throw new Error(i18n.t("file_too_large"));
    }
    onAddFile(URL.createObjectURL(file));
    return uploadFileAsync(file);
  }
};

export const uploadFileAsync = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  const promise = s3.post<UploadResponse>("/upload", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
  toast.promise(promise, {
    loading: i18n.t("upload_in_progress"),
    error: (e) => e.message,
    success: i18n.t("upload_success"),
  });
  return promise;
};
