import { UserData } from "@defs/User";
import {
  collection,
  CollectionReference,
  getDocs,
  limit,
  query,
  where,
} from "@firebase/firestore";
import {
  Autocomplete,
  AutocompleteProps,
  Avatar,
  CircularProgress,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useFirestore } from "reactfire";
import UserChip from "./UserChip";

interface Props {
  value: string[];
  onChange: (selected: string[]) => void;
  label?: string;
  /**
   * Must be below 20
   */
  searchLimit?: number;
  blacklist?: string[];
  maxSelection?: number;
  autocompleteProps?: Omit<
    AutocompleteProps<string, true, true, false>,
    "options" | "renderInput"
  >;
}

type UserMap = Record<string, UserData>;

const UserSearch = ({
  value: selected,
  onChange: onSetSelected,
  label,
  searchLimit,
  blacklist = [],
  maxSelection,
  autocompleteProps,
}: Props) => {
  const { t } = useTranslation("components");
  const db = useFirestore();
  const [options, setOptions] = useState<UserMap>({});
  const [loading, setLoading] = useState(false);

  const onInputChange = (str: string) => {
    if (str.length >= 3) {
      setLoading(true);
      search(str);
    }
  };

  const search = useMemo(
    () =>
      debounce((queryStr: string) => {
        if (queryStr.length >= 3) {
          const colRef = collection(
            db,
            "users"
          ) as CollectionReference<UserData>;
          const q = query(
            colRef,
            where("username", ">=", queryStr.toLowerCase()),
            limit(searchLimit || 5)
          );

          getDocs(q)
            .then((snap) => {
              const docs = snap.docs ?? [];
              const results = docs.reduce<UserMap>(
                (acc, doc) => ({ ...acc, [doc.id]: doc.data() }),
                {}
              );
              setOptions(results);
            })
            .catch((err) => {
              toast.error(t("unable_to_complete_search"));
              console.error(err);
            })
            .finally(() => setLoading(false));
        }
      }, 500),
    [db, searchLimit, t]
  );

  return (
    <Autocomplete<string, true, true, false>
      options={Object.keys(options)}
      value={selected}
      onChange={(e, value) =>
        maxSelection && selected.length >= maxSelection
          ? null
          : onSetSelected(value)
      }
      onInputChange={(e, value) => onInputChange(value)}
      loading={loading}
      multiple
      disableClearable
      renderTags={(value, getTagProps) => (
        <>
          {value.map((uid, index) => (
            <UserChip uid={uid} {...getTagProps({ index })} />
          ))}
        </>
      )}
      getOptionLabel={(uid) =>
        options[uid]?._username || options[uid]?.username || uid
      }
      renderOption={(props, uid) => (
        <MenuItem {...props}>
          <ListItemAvatar>
            <Avatar src={options[uid]?.photoURL} />
          </ListItemAvatar>
          <ListItemText
            primary={options[uid]?._username || options[uid]?.username}
            primaryTypographyProps={{ lineHeight: 1, noWrap: true }}
            secondaryTypographyProps={{ lineHeight: 1, noWrap: true }}
            secondary={options[uid]?.displayName}
          />
        </MenuItem>
      )}
      renderInput={(props) => (
        <TextField
          {...props}
          label={label || "Search Users"}
          InputProps={{
            ...props.InputProps,
            endAdornment: loading ? <CircularProgress size={25} /> : null,
          }}
          inputProps={props.inputProps}
        />
      )}
      filterOptions={(options) =>
        options.filter((uid) => !blacklist.includes(uid))
      }
      {...autocompleteProps}
    />
  );
};

export default UserSearch;
