import { AcadDirectory, AcadDirectoryContainer } from "@components";
import PaginationButtons from "@components/PaginationButtons";
import withUser from "@hocs/withUser";
import { usePaginatedCollection } from "@hooks";
import col from "@lib/firestore/col";
import { OAuthClient, OAUTH_CLIENT_PATH } from "@models/oauth";
import { Stack } from "@mui/material";
import AppCard from "@views/Developer/AppDirectory/AppCard";
import { useFirestore } from "reactfire";

const AdminAppDirectory = withUser(({ user }) => {
  const db = useFirestore();
  const ref = col<OAuthClient>(db, OAUTH_CLIENT_PATH);

  const { data, loading, next, nextDisabled, prev, prevDisabled } =
    usePaginatedCollection({
      query: ref,
      limit: 50,
      orderBy: {
        direction: "asc",
        field: "name",
      },
    });

  const apps = data?.docs ?? [];

  return (
    <AcadDirectoryContainer title="All 3rd party clients">
      <AcadDirectory hasData={!!data} loading={loading}>
        <Stack spacing={2}>
          {apps.map((app) => (
            <AppCard key={app.id} app={app.data()} />
          ))}
        </Stack>
      </AcadDirectory>
      <PaginationButtons {...{ next, nextDisabled, prev, prevDisabled }} />
    </AcadDirectoryContainer>
  );
});

export default AdminAppDirectory;
