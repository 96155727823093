import loadingMessage from "@defs/LoadingMessages";
import { DialogProps } from "@defs/Types";
import { Team } from "@models/team";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Step,
  StepButton,
  Stepper,
  Chip,
  Avatar,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useFunctions } from "reactfire";
import ParticipantAddStep1 from "./ParticipantAdd.step1";
import ParticipantAddStep2 from "./ParticipantAdd.step2";

export interface ForceJoinProps {
  teamId: string;
  fireTourId: string;
  active: string[];
  subs: string[];
  captain: string;
  isCheckedIn: boolean;
  seedNumber?: number;
}

interface Props extends DialogProps {
  tournamentId: string;
  tournamentName: string;
}
const ParticipantAddDialog = ({
  tournamentId,
  tournamentName,
  ...props
}: Props) => {
  const fn = useFunctions();
  const [step, setStep] = useState(0);
  const [team, setTeam] = useState<Team | null>(null);

  const selectTeam = (team: Team) => {
    setTeam(team);
    setStep(1);
  };

  const gotoStep = (step: number) => () => {
    setStep(step);
  };

  const { t } = useTranslation("events");

  const onSubmit = (payload: ForceJoinProps) => {
    const path = "tournamentParticipant-forceJoin";
    const callable = httpsCallable<ForceJoinProps>(fn, path);

    const promise = callable(payload);
    toast.promise(promise, {
      loading: loadingMessage(),
      error: (err) => err.message || t("unable_to_force_join_team"),
      success: () => {
        props.onClose?.();
        return t("team_successfully_added_to_tournament");
      },
    });
  };

  return (
    <Dialog {...props} maxWidth="md" fullWidth>
      <DialogTitle>{t("add_team")}</DialogTitle>
      <DialogContent>
        <Stack sx={{ mt: 2 }} gap={2}>
          <Stepper activeStep={step} alternativeLabel>
            <Step index={0}>
              <StepButton onClick={gotoStep(0)}>
                <Stack alignItems="center">
                  <div>{t("select_team")}</div>
                  {team && (
                    <Chip
                      size="small"
                      avatar={<Avatar src={team.logo} />}
                      label={team.name}
                    />
                  )}
                </Stack>
              </StepButton>
            </Step>
            <Step index={1} completed={false}>
              <StepButton disabled={!team} onClick={gotoStep(1)}>
                {t("select_players")}
              </StepButton>
            </Step>
          </Stepper>
          {step === 0 && <ParticipantAddStep1 selectTeam={selectTeam} />}
          {step === 1 && team && (
            <ParticipantAddStep2
              team={team}
              tournamentId={tournamentId}
              submit={onSubmit}
            />
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ParticipantAddDialog;
