import { useRetrieveCollection } from "@hooks";
import GameCard from "./GameCard";
import { AcadDirectory } from "@components";
import { useFirestore } from "reactfire";
import { collection, CollectionReference } from "firebase/firestore";
import Game from "@models/game";
import { useTranslation } from "react-i18next";

const GameDirectory = () => {
  const db = useFirestore();
  const collectionRef = collection(db, Game.PATH) as CollectionReference<Game>;

  const { data, error, loading } = useRetrieveCollection<Game>({
    collectionRef,
  });

  const { t } = useTranslation("games");

  return (
    <AcadDirectory
      loading={loading}
      error={error}
      hasData={(data?.docs?.length ?? 0) > 0}
      loadingEl={<GameCard game={new Game()} />}
      noDataEl={t("no_games")}
    >
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
        {data?.docs.map((doc) => (
          <GameCard game={doc.data()} key={doc.id} />
        ))}
      </div>
    </AcadDirectory>
  );
};

export default GameDirectory;
