import { DialogProps } from "@framework/types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  Grow,
  useMediaQuery,
} from "@mui/material";

interface AcadModalProps extends DialogProps {
  loading?: boolean;
  title?: string;
}

const AcadModal: React.FC<AcadModalProps> = ({
  open,
  onClose,
  loading,
  title,
  children,
}) => {
  const md = useMediaQuery("(min-width: 768px)");
  return (
    <Dialog
      TransitionComponent={Grow}
      open={open ?? false}
      onClose={onClose}
      fullScreen={!md}
      maxWidth="sm"
      fullWidth
      scroll="body"
      classes={{ paper: "relative" }}
      TransitionProps={{ unmountOnExit: true }} // boost perf
    >
      <Fade in={loading ?? false}>
        <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center bg-white bg-opacity-70 z-50"></div>
      </Fade>
      <DialogTitle>{title ?? ""}</DialogTitle>
      <DialogContent>
        <div className="overflow-y-visible">{children}</div>
      </DialogContent>
    </Dialog>
  );
};

export default AcadModal;
