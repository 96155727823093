import { VFC } from "react";
import { useHistory } from "react-router-dom";
import { AcadCard } from "@components";
import ScholarshipEligibility from "@models/scholarship_eligibility";

const EligibilityCard: VFC<{eligibility: ScholarshipEligibility}> = ({ eligibility })=> {
  const history = useHistory();
  return (
    <AcadCard onClick={() => history.push(`/scholarship/eligibilities/${eligibility.id}`)}>
      <div className="flex flex-col pl-4">
        <div className="text-md leading-none text-left">{eligibility.name}</div>
        <div className="text-xs leading-none text-left opacity-40">
          {eligibility.id}
        </div>
      </div>
    </AcadCard>
  );
};

export default EligibilityCard;
