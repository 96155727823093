import { AcadTable } from "@components";
import { HeadCell } from "@components/table/AcadTableHead";
import { collection } from "@firebase/firestore";
import { usePaginatedCollection } from "@hooks";
import ReferralCode from "@models/referral_code";
import ReferralUser from "@models/referral_user";
import { Button, Card, Checkbox, TableCell, TableRow } from "@mui/material";
import { CollectionReference, getDocs, query } from "firebase/firestore";
import { useParams } from "react-router";
import { useFirestore } from "reactfire";
import { parse } from "json2csv";
import toast from "react-hot-toast";
import { promptDownload } from "@lib/download";
import { useTranslation } from "react-i18next";

const headCells: HeadCell<ReferralUser>[] = [
  { id: "username", label: "username" },
  { id: "firstName", label: "first_name" },
  { id: "lastName", label: "last_name" },
  { id: "email", label: "email" },
  { id: "phoneNumber", label: "phone" },
  { id: "verified", label: "verified" },
];

interface ReferralUserRowProps {
  referralUser: ReferralUser;
}

const ReferralUserRow: React.VFC<ReferralUserRowProps> = ({
  referralUser: { username, firstName, lastName, email, phoneNumber, verified },
}) => {
  return (
    <TableRow>
      <TableCell>{username}</TableCell>
      <TableCell>{firstName}</TableCell>
      <TableCell>{lastName}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{phoneNumber}</TableCell>
      <TableCell>
        <Checkbox checked={verified} disabled />
      </TableCell>
    </TableRow>
  );
};

const ReferralUserTable: React.VFC = () => {
  const { code } = useParams<{ code: string }>();
  const db = useFirestore();
  const ref = collection(
    db,
    `${ReferralCode.PATH}/${code}/${ReferralUser.PATH}`
  ) as CollectionReference<ReferralUser>;
  const q = query(ref);
  const {
    data: snap,
    nextDisabled,
    prevDisabled,
    limit,
    ...data
  } = usePaginatedCollection({
    query: q,
    limit: 10,
    orderBy: {
      direction: "asc",
      field: "uid",
    },
  });

  const referralUsers = snap?.docs.map((code) => code.data()) ?? [];

  const { t } = useTranslation("users");

  const exportCsv = async () => {
    const retrieveUsers = await getDocs(query(ref));
    const users = retrieveUsers?.docs.map((code) => code.data()) ?? [];

    const fields = [
      "username",
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "school",
      "verified",
      {
        label: "createdAt",
        value: ({ createdAt }: ReferralUser) => {
          return createdAt ? createdAt.toDate().toLocaleString() : "";
        },
      },
    ];

    try {
      const csv = parse(users, { fields });
      promptDownload(csv, `${code}-referral_users`);
    } catch (err) {
      toast.error(t("unabled_to_export_csv"));
    }
  };

  return (
    <div>
      <div className="my-4 flex flex-row">
        <span className="text-xl flex flex-1">{t("reffered_users")}</span>
        <Button color="primary" variant="contained" onClick={exportCsv}>
          {t("export_csv")}
        </Button>
      </div>
      <Card>
        <AcadTable<ReferralUser>
          {...data}
          dense
          data={referralUsers}
          disableNext={nextDisabled}
          disablePrev={prevDisabled}
          headCells={headCells}
          rowsPerPage={limit}
          renderRows={(referralUser, i) => (
            <ReferralUserRow key={"row" + i} referralUser={referralUser} />
          )}
          orderBy="uid"
        />
      </Card>
    </div>
  );
};

export default ReferralUserTable;
