import { useMediaQuery } from "@mui/material";

export const useScreen = () => {
  const xs = useMediaQuery("(min-width: 480px)");
  const sm = useMediaQuery("(min-width: 640px)");
  const md = useMediaQuery("(min-width: 768px)");
  const lg = useMediaQuery("(min-width: 1024px)");
  const xl = useMediaQuery("(min-width: 1280px)");
  const xxl = useMediaQuery("(min-width: 1536px)");
  const dialog = useMediaQuery("(min-width: 510px)");
  const mobile = useMediaQuery("(min-width: 0)");
  const tablet = useMediaQuery("(min-width: 640px)");
  const laptop = useMediaQuery("(min-width: 1024px)");
  const desktop = useMediaQuery("(min-width: 1200px)");

  return { xs, sm, md, lg, xl, xxl, mobile, dialog, tablet, laptop, desktop };
};
