import Sheet from "@components/Sheet";
import { Stack } from "@mui/material";
import AbortCheckIn from "./AbortCheckIn";
import ProcessCheckIns from "./ProcessCheckIns";

const Actions = () => {
  return (
    <Sheet>
      <Stack gap={2}>
        <ProcessCheckIns />
        <AbortCheckIn />
      </Stack>
    </Sheet>
  );
};

export default Actions;
