import { useRetrieveCollection } from "@hooks";
import BenefitCard from "./BenefitCard";
import { AcadDirectory } from "@components";
import { useFirestore } from "reactfire";
import ScholarshipBenefit from "@models/scholarship_benefit";
import { collection, CollectionReference } from "firebase/firestore";
import { useTranslation } from "react-i18next";

const BenefitDirectory = () => {
  const db = useFirestore();
  const collectionRef = collection(
    db,
    ScholarshipBenefit.PATH
  ) as CollectionReference<ScholarshipBenefit>;

  const { data, error, loading } = useRetrieveCollection<ScholarshipBenefit>({
    collectionRef,
  });

  const { t } = useTranslation("scholarships");

  return (
    <AcadDirectory
      loading={loading}
      error={error}
      hasData={(data?.docs?.length ?? 0) > 0}
      loadingEl={<BenefitCard benefit={new ScholarshipBenefit()} />}
      noDataEl={t("no_benefits")}
    >
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
        {data?.docs.map((doc) => (
          <BenefitCard benefit={doc.data()} key={doc.id} />
        ))}
      </div>
    </AcadDirectory>
  );
};

export default BenefitDirectory;
