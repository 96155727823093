import { User } from "@models/user";
import {
  Avatar,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { doc, DocumentReference } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { useFirestore, useFirestoreDocDataOnce } from "reactfire";
import { PlayerType } from "../ParticipantAdd.step2";

interface Props {
  uid: string;
  type: PlayerType;
  setType: (type: PlayerType) => void;
  isCaptain: boolean;
  setAsCaptain: VoidFunction;
}
const ParticipantAddPlayer = ({
  uid,
  type,
  setType,
  setAsCaptain,
  isCaptain,
}: Props) => {
  const db = useFirestore();
  const ref = doc(db, `users/${uid}`) as DocumentReference<User>;
  const { data, status } = useFirestoreDocDataOnce(ref);

  const changeType = (type: PlayerType) => () => {
    setType(type);
  };

  const { t } = useTranslation("events");

  return (
    <Card sx={{ p: 2 }}>
      <Stack justifyContent="space-between" direction="row">
        <Stack gap={2} direction="row" alignItems="center">
          <Avatar src={data?.photoURL} sx={{ width: 35, height: 35 }} />
          <Typography>
            {data
              ? data.username
              : status === "loading"
              ? t("user_loading", { ns: "common" })
              : t("user_delete", { ns: "common" })}
          </Typography>
        </Stack>
        <Stack gap={2} direction="row" alignItems="flex-end">
          <FormControlLabel
            label={t("captain")}
            onClick={setAsCaptain}
            control={<Radio size="small" checked={isCaptain} />}
          />

          <FormControl size="small">
            <FormLabel sx={{ fontSize: 14 }}>Player Type</FormLabel>
            <RadioGroup row>
              <FormControlLabel
                label={t("active")}
                onClick={changeType("active")}
                control={<Radio size="small" checked={type === "active"} />}
              />
              <FormControlLabel
                label={t("sub")}
                onClick={changeType("sub")}
                control={<Radio size="small" checked={type === "sub"} />}
              />
              <FormControlLabel
                label={t("not_playing")}
                onClick={changeType("notPlaying")}
                control={
                  <Radio
                    size="small"
                    checked={type === "notPlaying"}
                    disabled={isCaptain}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Stack>
    </Card>
  );
};

export default ParticipantAddPlayer;
