import Sheet from "@components/Sheet";
import { Stack } from "@mui/material";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useParticipants, withParticipants } from "../participants/_context";
import ParticipantCard from "./ParticipantCard";

interface Props {
  hideActions?: boolean;
}

const ParticipantList: VFC<Props> = ({ hideActions }) => {
  const participants = useParticipants();

  const { t } = useTranslation("events");

  return (
    <Sheet>
      <Stack gap={2}>
        {participants.length
          ? participants.map((doc) => (
              <ParticipantCard key={doc.id} {...{ doc, hideActions }} />
            ))
          : t("no_team_checked_in_yet")}
      </Stack>
    </Sheet>
  );
};

export default withParticipants(ParticipantList);
