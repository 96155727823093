import { AcadDirectoryContainer } from "@components";
import { OrgProps } from "@defs/Types";
import { useUserContext } from "@lib/AuthContext";
import { Alert } from "@mui/material";
import {
  collection,
  CollectionReference,
  query,
  where,
} from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import LeagueCreate from "./LeagueCreate";
import LeagueDirectory from "./LeagueDirectory";

const Leagues = () => {
  const db = useFirestore();
  const { user, isAdmin } = useUserContext();
  const orgsRef = collection(db, "orgs") as CollectionReference<OrgProps>;
  const q = query(orgsRef, where("admins", "array-contains", user?.id));
  const { data: orgs = [], status } = useFirestoreCollectionData(
    isAdmin ? orgsRef : q,
    { idField: "id" }
  );
  const { t } = useTranslation("events");
  return orgs.length ? (
    <AcadDirectoryContainer
      title={t("leagues")}
      directoryTitle={t("leagues")}
      createModal={
        <LeagueCreate isLoading={status === "loading"} orgs={orgs} />
      }
      createBtnTitle={t("create")}
    >
      {!!orgs.length && (
        <LeagueDirectory orgLoading={status === "loading"} orgs={orgs} />
      )}
    </AcadDirectoryContainer>
  ) : status !== "loading" ? (
    <Alert severity="info" sx={{ my: 2 }}>
      {t("you_are_not_admin_of_any_school_org")}
    </Alert>
  ) : null;
};

export default Leagues;
