import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { ChangeEvent, FC, FormEvent, useState } from "react";
import { EpisodeCreateProps } from "@defs/Types";
import { Episode } from "@defs/Episode";
import toast from "react-hot-toast";
import { onFileSelect, Files } from "@lib/file";
import { useTranslation } from "react-i18next";

// BUG #15 @Manokii episode number should be saved as number
const EpisodeCreate: FC<EpisodeCreateProps> = ({
  open,
  onClose,
  handleSubmit,
  seriesId = "",
  series,
}) => {
  const md = useMediaQuery("(min-width: 768px)");
  const [form, setForm] = useState<Episode>({
    thumbnail: "",
    videoTitle: "",
    videoDescription: "",
    videoLink: "",
    typeformLink: "",
    episodeNumber: 0,
    seriesId,
  });

  const [files, setFiles] = useState<Files<Episode>>({});
  const { t } = useTranslation("boostcamp");

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (series.episodes.find((ep) => ep.episodeNumber === form.episodeNumber))
      return toast.error(t("episode_number_already_exists"));
    handleSubmit(form);
    setForm({
      thumbnail: "",
      videoTitle: "",
      videoDescription: "",
      videoLink: "",
      typeformLink: "",
      episodeNumber: 0,
      seriesId,
    });
    setFiles({});
    onClose();
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]:
        e.target.type === "number" ? e.target.valueAsNumber : e.target.value,
    });
  };

  return (
    <Dialog
      TransitionComponent={Grow}
      open={open}
      onClose={() => {
        onClose();
        setForm({
          thumbnail: "",
          videoTitle: "",
          videoDescription: "",
          videoLink: "",
          typeformLink: "",
          episodeNumber: 0,
          seriesId,
        });
        setFiles({});
      }}
      fullScreen={!md}
      maxWidth="sm"
      fullWidth
      scroll="body"
      classes={{ paper: "relative" }}
    >
      <DialogTitle className="">{t("create_new_episode")}</DialogTitle>
      <DialogContent className="overflow-y-visible">
        <div className="grid grid-cols-12 gap-4">
          <h2 className="text-lg mt-4 mb-2 col-span-full">
            {t("episode_information")}
          </h2>
          <ButtonBase
            component="label"
            className="shadow-md h-36 col-span-full bg-white rounded-md self-center sm:self-start bg-cover bg-center flex justify-center items-center"
            sx={{
              backgroundImage: `url("${
                files?.thumbnail?.path || form.thumbnail
              }")`,
            }}
          >
            <input
              hidden
              type="file"
              onChange={onFileSelect(setFiles, setForm)}
              name="thumbnail"
              accept="image/*"
            />
            {files.thumbnail?.path
              ? ""
              : form?.thumbnail
              ? ""
              : t("plus_add_thumbnail")}
          </ButtonBase>
          <TextField
            variant="outlined"
            label={t("video_title")}
            className="col-span-full"
            value={form.videoTitle}
            name="videoTitle"
            onChange={onChange}
            required
          />
          <TextField
            variant="outlined"
            label={t("video_link")}
            className="col-span-full"
            value={form.videoLink}
            name="videoLink"
            onChange={onChange}
            required
          />
          <TextField
            variant="outlined"
            size="small"
            label={t("video_description")}
            className="col-span-full"
            value={form.videoDescription}
            name="videoDescription"
            onChange={onChange}
            multiline={true}
            required
          />
          <TextField
            variant="outlined"
            size="small"
            label={t("typeform_id")}
            className="col-span-full"
            value={form.typeformLink}
            name="typeformLink"
            onChange={onChange}
          />
          <TextField
            variant="outlined"
            size="small"
            label={t("episode_number")}
            className="col-span-full"
            value={form.episodeNumber}
            type="number"
            name="episodeNumber"
            onChange={onChange}
            required
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          disabled={
            !form.thumbnail ||
            !form.videoTitle ||
            !form.videoLink ||
            !form.videoDescription
          }
        >
          {t("save", { ns: "common" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EpisodeCreate;
