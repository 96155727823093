import Sheet from "@components/Sheet";
import loadingMessage from "@defs/LoadingMessages";
import { Stack, Button, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useFunctions } from "reactfire";
import Swal from "sweetalert2";
import { useTournamentEdit } from "../../TournamentEdit";

const ProcessCheckIns = () => {
  const fn = useFunctions();
  const snap = useTournamentEdit();

  const { t } = useTranslation("events");

  const processCheckIns = () => {
    Swal.fire({
      title: t("process_check_ins_question"),
      showCloseButton: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: t("process_check_ins"),
    }).then((res) => {
      if (res.isConfirmed) {
        if (snap) {
          const callable = httpsCallable<{ fireTourId: string }>(
            fn,
            "tournament-processCheckIns"
          );

          const promise = callable({ fireTourId: snap.id });
          toast.promise(promise, {
            loading: loadingMessage(),
            error: (err) => err.message,
            success: t("done", { ns: "common" }),
          });
        }
      }
    });
  };
  return (
    <Sheet>
      <Stack gap={2} alignItems="flex-start">
        <Button
          variant="contained"
          onClick={processCheckIns}
          className="flex-shrink-0"
        >
          {t("process_check_ins")}
        </Button>
        <Stack>
          <Typography className="text-sm">
            {t("participants_who_have_not_checked_in")}
          </Typography>
        </Stack>
      </Stack>
    </Sheet>
  );
};

export default ProcessCheckIns;
