import { Slide, Snackbar, SnackbarProps } from "@mui/material";
import equal from "fast-deep-equal/es6";
import React from "react";
import { useTranslation } from "react-i18next";

interface SaveSnackProps<T = any> extends SnackbarProps {
  stateA: T;
  stateB: T;
}

const AcadSaveSnack = React.memo(
  ({ stateA, stateB, action }: SaveSnackProps) => {
    const { t } = useTranslation("components");
    return (
      <Snackbar
        open={!equal(stateA, stateB)}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        action={action}
        message={t("you_have_unsaved_changes")}
      ></Snackbar>
    );
  },
  (prevProps, nextProps) =>
    equal(prevProps.stateA, prevProps.stateB) ===
    equal(nextProps.stateA, nextProps.stateB)
);

export default AcadSaveSnack;
