import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Theme from "./Theme";
import { StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import RegionProvider from "@components/region/RegionProvider";
import FirebaseController from "@components/FirebaseController";
import UnsupportedRegion from "@components/UnsupportedRegion";
import 'flag-icons/css/flag-icons.min.css';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/:region(ph|br)">
          <RegionProvider>
            <StyledEngineProvider injectFirst>
              <Theme>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <CssBaseline />
                  <FirebaseController>
                    <App />
                  </FirebaseController>
                </LocalizationProvider>
              </Theme>
            </StyledEngineProvider>
          </RegionProvider>
        </Route>
        <Route path="*" exact>
          <UnsupportedRegion />
        </Route>
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
