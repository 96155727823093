import acadJoi from "@acad_joi";

enum SignUpStatus {
  SignedUp = "S",
  Accepted = "A",
  Rejected = "R",
}
export class AcademyCbSignUp {
  static readonly PATH: string = "/academyCbSignUps";
  uid!: string;
  status!: SignUpStatus;

  static schema = acadJoi.object<AcademyCbSignUp>({
    uid: acadJoi.string().required(),
    status: acadJoi.string().valid(...Object.values(SignUpStatus)),
  });
}
