import { doc, DocumentReference } from "@firebase/firestore";
import { ReadOnlySchools } from "@views/Schools/SchoolList";
import { createContext, FC, useContext } from "react";
import { useFirestore, useFirestoreDocData } from "reactfire";

const schoolListContext = createContext<string[]>([]);

const SchoolListContextProvider: FC = ({ children }) => {
  const db = useFirestore();
  const ref = doc(
    db,
    "read_only/schools"
  ) as DocumentReference<ReadOnlySchools>;
  const { data } = useFirestoreDocData(ref);

  const schools = data?.schools ?? [];
  return (
    <schoolListContext.Provider value={schools}>
      {children}
    </schoolListContext.Provider>
  );
};

export default SchoolListContextProvider;

// Hook
export const useSchoolList = (showAcadArena?: boolean) => {
  const data = useContext(schoolListContext);
  return showAcadArena
    ? data
    : data.filter((school) => school.toLowerCase() !== "acadarena");
};
