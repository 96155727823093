import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
interface Props {
  isVerified: boolean | undefined;
  setIsVerified: Dispatch<SetStateAction<boolean | undefined>>;
}

function VerificationFilter({ isVerified, setIsVerified }: Props) {
  const { t } = useTranslation("users");
  return (
    <FormControl
      className="w-full col-span-2"
      component="fieldset"
      variant="standard"
    >
      <FormLabel component="legend">{t("verification")}</FormLabel>
      <RadioGroup className="flex-row">
        <FormControlLabel
          label={t("verified")}
          control={
            <Radio
              checked={isVerified === true}
              onChange={() => setIsVerified(true)}
            />
          }
        />
        <FormControlLabel
          label={t("unverified")}
          control={
            <Radio
              checked={isVerified === false}
              onChange={() => setIsVerified(false)}
            />
          }
        />
        <FormControlLabel
          label={t("both")}
          control={
            <Radio
              checked={isVerified === undefined}
              onChange={() => setIsVerified(undefined)}
            />
          }
        />
      </RadioGroup>
    </FormControl>
  );
}

export default VerificationFilter;
