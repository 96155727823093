import EpisodeCard from "@views/Boostcamp/components/EpisodeCard";
import { Episode } from "@defs/Episode";
import { FrontPage } from "@defs/Frontpage.type";
import loadingMessage from "@defs/LoadingMessages";
import { BoostcampSeries } from "@defs/Types";
import { Fab } from "@mui/material";
import { Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import {
  doc,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  QueryDocumentSnapshot,
  updateDoc,
} from "firebase/firestore";
import React, { useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useFirestore } from "reactfire";
import swal from "sweetalert2";
import BoostcampEpisodesAdd from "./BoostcampEpisodesAdd";
import { useTranslation } from "react-i18next";

const BoostcampEpisodes: React.VFC<{
  frontPage: DocumentSnapshot<FrontPage>;
}> = ({ frontPage }) => {
  const db = useFirestore();
  const [state, setState] = useState(false);
  const [loadedSeries, setLoadedSeries] = useState<
    | QueryDocumentSnapshot<BoostcampSeries>[]
    | DocumentSnapshot<BoostcampSeries>[]
  >([]);

  const { t } = useTranslation("frontpage");
  /**
   * Boostcamp stores all of the its episodes
   * inside it's own document. Meaning
   * 1 document contains a whole series of episodes,
   *
   * In this case, we're only featuring episodes
   * Episodes may come from different series
   *
   * We are only going to load included series
   * of the featured episodes
   */
  useEffect(() => {
    if (!frontPage) return;
    let isActiveComponent = true;
    const episodes = frontPage.data()?.boostcampEpisodes ?? [];
    episodes.forEach((ep) => {
      if (loadedSeries.map((series) => series.id).includes(ep.seriesId ?? ""))
        return;

      const ref = doc(
        db,
        `boostcamp/${ep.seriesId}`
      ) as DocumentReference<BoostcampSeries>;
      getDoc(ref).then((snap) => {
        if (isActiveComponent && snap.exists()) {
          setLoadedSeries((ls) => [...ls, snap]);
        }
      });
    });
    return () => {
      isActiveComponent = false;
    };
  }, [frontPage, loadedSeries, db]);

  const frontPageData = frontPage.data();
  const findSeriesName = (episode: Episode) => {
    return (
      loadedSeries
        .find((series) =>
          Boolean(
            series
              .data()
              ?.episodes.find(
                (ep) =>
                  ep.seriesId === episode.seriesId &&
                  ep.episodeNumber === episode.episodeNumber
              )
          )
        )
        ?.data()?.name ?? ""
    );
  };

  const removeEpisode = (episode: Episode, index: number) => () => {
    swal
      .fire({
        title: t("remove_episode_question"),
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#B33A3A",
      })
      .then((result) => {
        if (result.isConfirmed) {
          const ref = doc(db, "public/frontpage");
          const promise = updateDoc(ref, {
            boostcampEpisodes: frontPage
              .data()
              ?.boostcampEpisodes.filter(
                (ep) =>
                  ep.episodeNumber !== episode.episodeNumber &&
                  ep.seriesId !== episode.seriesId
              ),
          });

          toast.promise(promise, {
            loading: loadingMessage(),
            error: (err) => err.message,
            success: t("removed_bootcamp_episode"),
          });
        }
      });
  };

  return (
    <div className="mt-8">
      <div className="flex">
        <div className="text-lg">{t("featured_boostcamp_episodes")}</div>
        <Button
          size="small"
          variant="outlined"
          className="ml-4 color-acad-primary"
          onClick={() => setState(true)}
        >
          {t("select", { ns: "common" })}
        </Button>
      </div>
      <div className="flex flex-col mt-2 relative overflow-hidden">
        <div className="p-2 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
          {frontPage.data()?.boostcampEpisodes.map((episode, index) => (
            <div className="relative" key={`episode-${index}`}>
              <Fab
                size="small"
                className="absolute top-3 right-3 z-30"
                onClick={removeEpisode(episode, index)}
              >
                <Close />
              </Fab>
              <EpisodeCard
                episode={episode}
                onClick={() => {}}
                key={index}
                seriesName={findSeriesName(episode)}
              />
            </div>
          ))}
        </div>
      </div>

      {frontPageData && (
        <BoostcampEpisodesAdd
          open={state}
          onClose={() => setState(false)}
          frontPage={frontPageData}
          {...{ loadedSeries, setLoadedSeries }}
        />
      )}
    </div>
  );
};

export default BoostcampEpisodes;
