import { Button, ButtonProps, Stack, StackProps } from "@mui/material";
import { ReactNode } from "react";

export interface AcadButtonGroupItem<T> {
  label: ReactNode;
  value: T;
  ButtonProps?: ButtonProps;
}

export interface AcadButtonGroupProps<T = any>
  extends Omit<StackProps, "onClick"> {
  items: AcadButtonGroupItem<T>[];
  value: T;
  ButtonProps?: ((isSelected: boolean) => ButtonProps) | ButtonProps;
  onClick: (value: T) => void;
}
const AcadButtonGroup = <T,>({
  items,
  value,
  ButtonProps,
  onClick,
  ...props
}: AcadButtonGroupProps<T>) => {
  const isButtonPropsFunction = typeof ButtonProps === "function";

  return (
    <Stack gap={1} direction="row" flexWrap="wrap" {...props}>
      {items.map((item) => (
        <Button
          variant="contained"
          className={`${value !== item.value && "bg-white"}`}
          color={value === item.value ? "primary" : "inherit"}
          {...(isButtonPropsFunction
            ? ButtonProps(item.value === value)
            : ButtonProps)}
          {...item.ButtonProps}
          onClick={() => onClick(item.value)}
        >
          {item.label}
        </Button>
      ))}
    </Stack>
  );
};

export default AcadButtonGroup;
