import Environment from "@env";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { FC } from "react";
import {
  AuthProvider,
  FirestoreProvider,
  FunctionsProvider,
  useFirebaseApp,
} from "reactfire";

const FirebaseComponentsProvider: FC = ({ children }) => {
  const app = useFirebaseApp();

  const storage = getStorage(app);
  const auth = getAuth(app);
  //   const db  = getDatabase(app)
  const functions = getFunctions(app, Environment.region);
  //   const analytics = getAnalytics(app);
  const firestore = getFirestore(app);

  if (Boolean(process.env.REACT_APP_EMULATED)) {
    connectFirestoreEmulator(firestore, "localhost", 8080);
    connectStorageEmulator(storage, "localhost", 9199);
    connectAuthEmulator(auth, "http://localhost:9099", {
      disableWarnings: true,
    });
    connectFunctionsEmulator(functions, "localhost", 5001);
  }

  return (
    <FirestoreProvider sdk={firestore}>
      <AuthProvider sdk={auth}>
        <FunctionsProvider sdk={functions}>{children}</FunctionsProvider>
      </AuthProvider>
    </FirestoreProvider>
  );
};

export default FirebaseComponentsProvider;
