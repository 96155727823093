import { AcadDirectoryContainer } from "@components";
import { useTranslation } from "react-i18next";
import RoleAdd from "./RoleAdd";
import RoleDirectory from "./RoleDirectory";

const Roles = () => {
  const { t } = useTranslation("admin");
  return (
    <AcadDirectoryContainer
      title={`⚔️ ${t("admin_role_page_title")}`}
      createBtnTitle={`⚔️ ${t("role_add")}`}
      createModal={<RoleAdd />}
    >
      <RoleDirectory />
    </AcadDirectoryContainer>
  );
};

export default Roles;
