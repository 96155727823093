import Sheet from "@components/Sheet";
import { TournamentDoc } from "@defs/Tournament.type";
import {
  Button,
  Checkbox,
  Divider,
  Fade,
  FormControlLabel,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import Fields from "./Fields";
import { nanoid } from "nanoid/non-secure";
import ParticipantList from "../components/ParticipantList";
import { useTranslation } from "react-i18next";

interface CustomFieldsProps {
  setForm: React.Dispatch<React.SetStateAction<TournamentDoc>>;
  form: TournamentDoc;
}

const TournamentCustomFields = ({ form, setForm }: CustomFieldsProps) => {
  const [state, set] = useState<"fields" | "submissions">("fields");
  // const [submissions, setSubmissions] = useState([])
  const { t } = useTranslation("events");
  const toggle = () => {
    setForm((state) => ({
      ...state,
      enableCustomFields: !state.enableCustomFields,
    }));
  };

  const add = () => {
    setForm((state) => ({
      ...state,
      customFields: {
        ...state.customFields,
        [nanoid()]: {
          fieldName: t("field_name"),
          perPlayer: true,
          type: "text",
          description: "",
          isPublic: false,
        },
      },
    }));
  };

  return (
    <div className="w-full flex flex-col space-y-4 mb-4">
      <Sheet>
        <div className="flex space-x-4">
          <Button
            variant={state === "fields" ? "contained" : "outlined"}
            onClick={() => set("fields")}
          >
            {t("fields")}
          </Button>
          <Button
            variant={state === "submissions" ? "contained" : "outlined"}
            onClick={() => set("submissions")}
          >
            {t("submissions")}
          </Button>
        </div>

        {state === "fields" && (
          <>
            <Divider className="w-full mt-4 mb-2" />
            <div className="flex justify-between w-full">
              <FormControlLabel
                label={t("enable_custom_fields")}
                control={
                  <Checkbox
                    checked={form.enableCustomFields ?? false}
                    onChange={toggle}
                  />
                }
              />
              <Button variant="outlined" onClick={add}>
                {t("add_custom_field")}
              </Button>
            </div>
          </>
        )}
      </Sheet>

      <Fade in={state === "fields"} unmountOnExit>
        <div className="w-full">
          <Fields {...{ form, setForm }} />
        </div>
      </Fade>
      <Fade in={state === "submissions"} unmountOnExit>
        <div className="w-full">
          <ParticipantList hideActions />
        </div>
      </Fade>
    </div>
  );
};

export default TournamentCustomFields;
