import { AcadEditDocSection, AcadTextField } from "@components";
import { DocumentReference } from "@firebase/firestore";
import { joiResolver } from "@hookform/resolvers/joi";
import { useDeleteDocument } from "@hooks";
import { userContext } from "@lib/AuthContext";
import ScholarshipBenefit, {
  scholarshipBenefitSchema,
} from "@models/scholarship_benefit";
import { Button } from "@mui/material";
import { FormEvent, useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

interface BenefitEditFormProps {
  docRef: DocumentReference<ScholarshipBenefit>;
  formData: ScholarshipBenefit;
  onSubmit(formData: ScholarshipBenefit): void;
}

const BenefitEditForm = ({
  docRef,
  onSubmit,
  formData,
}: BenefitEditFormProps) => {
  const { isAdmin: admin } = useContext(userContext);

  const history = useHistory();

  const { deleteDocument } = useDeleteDocument<ScholarshipBenefit>({
    docRef,
    titleMsg: "Delete Benefit?",
    onDelete: () => {
      history.push("/scholarship/benefits");
    },
  });

  const { t } = useTranslation("scholarships");

  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<ScholarshipBenefit>({
    defaultValues: formData,
    resolver: joiResolver(scholarshipBenefitSchema),
  });

  const innerOnSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleSubmit((benefit) => onSubmit(benefit))(e);
  };

  return (
    <form className="grid grid-cols-12 gap-4" onSubmit={innerOnSubmit}>
      <AcadEditDocSection
        title={t("benefit_info")}
        className="col-span-full grid grid-cols-12 gap-4"
      >
        <AcadTextField
          label={t("benefit_name")}
          className="col-span-12"
          {...register("name")}
          required
          errors={errors}
        />
      </AcadEditDocSection>
      <div className="col-span-12 flex justify-end">
        <Button
          variant="outlined"
          className="mr-4"
          color="secondary"
          onClick={() => deleteDocument(getValues())}
          disabled={!admin}
        >
          {t("delete", { ns: "common" })}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isDirty}
        >
          {t("save", { ns: "common" })}
        </Button>
      </div>
    </form>
  );
};

export default BenefitEditForm;
