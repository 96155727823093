import { AcadDirectory, AcadTable } from "@components";
import { HeadCell } from "@components/table/AcadTableHead";
import loadingMessage from "@defs/LoadingMessages";
import { usePaginatedCollection } from "@hooks";
import GlobalRole, { GlobalRoleColRef } from "@models/global_role";
import { DeleteForever } from "@mui/icons-material";
import {
  Card,
  Chip,
  IconButton,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";
import { collection, deleteDoc, doc } from "firebase/firestore";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useFirestore } from "reactfire";
import Swal from "sweetalert2";

const RoleDirectory = () => {
  const { t } = useTranslation(["admin", "common"]);
  const db = useFirestore();
  const ref = collection(db, "roles") as GlobalRoleColRef;
  const {
    data: snapshot,
    error,
    cursor,
    limit,
    loading,
    nextDisabled,
    prevDisabled,
    next,
    prev,
  } = usePaginatedCollection({
    query: ref,
    limit: 20,
    orderBy: { direction: "asc", field: "email" },
  });

  const headCells: HeadCell<GlobalRole>[] = [
    {
      id: "email",
      label: t("email"),
    },
    {
      id: "roles",
      label: t("role"),
    },
    {
      id: "",
      label: "",
      disablePadding: true,
    },
  ];

  const data = (snapshot?.docs ?? []).map((doc) => doc.data());

  const deleteRole = (uid: string, email: string) => () => {
    Swal.fire<string>({
      title: t("role_remove_ask"),
      html: t("role_remove_confirm"),
      input: "text",
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value === email) {
          const docRef = doc(db, "roles", uid);
          const promise = deleteDoc(docRef);
          toast.promise(promise, {
            loading: loadingMessage(),
            error: (err) => err.message,
            success: t("done"),
          });
          return;
        }
        Swal.fire(t("confirmation_mismatch"), "", "error");
      }
    });
  };

  return (
    <AcadDirectory hasData={!!snapshot?.size} loading={loading} error={error}>
      <Card>
        <AcadTable<GlobalRole>
          {...{
            data,
            cursor,
            next,
            prev,
          }}
          rowsPerPage={limit}
          headCells={headCells}
          disableNext={nextDisabled}
          disablePrev={prevDisabled}
          renderRows={(user, i) => (
            <TableRow key={i}>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                <Stack spacing={1} direction="row">
                  {user.roles.map((role) => (
                    <Chip
                      key={role}
                      size="small"
                      label={role}
                      className="capitalize"
                    />
                  ))}
                </Stack>
              </TableCell>
              <TableCell padding="checkbox">
                <IconButton
                  sx={{ m: 0 }}
                  onClick={deleteRole(user.uid, user.email)}
                >
                  <DeleteForever fontSize="inherit" />
                </IconButton>
              </TableCell>
            </TableRow>
          )}
          orderBy="email"
        />
      </Card>
    </AcadDirectory>
  );
};
export default RoleDirectory;
