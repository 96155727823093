import { Card } from "@mui/material";
import React, { FC } from "react";

const Sheet: FC<{
  disablePadding?: boolean;
  fullHeight?: boolean;
  className?: string;
  [key: string]: any;
}> = ({ disablePadding, children, fullHeight, className, ...props }) => {
  return (
    <Card
      className={`bg-white p-4 md:p-6 ${disablePadding && "p-0"} ${
        fullHeight && "h-full"
      } ${className ? className : " mb-5 "} overflow-hidden`}
      {...props}
    >
      {children}
    </Card>
  );
};

export default Sheet;
