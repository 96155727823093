import { VFC } from "react";
import { AcadCard } from "@components";
import { CarouselSlide } from "@defs/carousel_slide";

interface CarouselCardProps {
  slide: CarouselSlide;
  onClick?(): void;
  className?: string;
}

const CarouselCard: VFC<CarouselCardProps> = ({
  slide,
  onClick = () => {},
  className = "",
}) => {
  return (
    <div className={className}>
      <AcadCard onClick={onClick} coverPhoto={slide?.image}>
        <div className="flex flex-col pl-4">
          <div className="text-md leading-none text-left">{slide?.title}</div>
        </div>
      </AcadCard>
    </div>
  );
};

export default CarouselCard;
