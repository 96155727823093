import acadJoi from "@acad_joi";
import { CarouselSlide } from "@defs/carousel_slide";
import { AcadBaseDocument } from "@framework/models";
import { SchemaMap } from "joi";

class Revalidation {
  list: number = 60;
  page: number = 1;

  static schema = acadJoi.object({
    list: acadJoi.number().label("List Revalidation Seconds").min(1),
    page: acadJoi.number().label("Page Revalidation Seconds").min(1),
  });
}

export default class ScholarshipParams extends AcadBaseDocument {
  static readonly PATH: string = "syspars/scholarshipParams";

  carousel: CarouselSlide[] = [];
  description: string = "";
  revalidation: Revalidation = {
    list: 0,
    page: 0,
  };

  static schemaMap: SchemaMap<ScholarshipParams> = {
    carousel: acadJoi.array().items(CarouselSlide.schema).label("Carousel"),
    description: acadJoi.string().allow("").label("Description"),
    revalidation: Revalidation.schema,
  };

  static schema = acadJoi.object(ScholarshipParams.schemaMap);
}
