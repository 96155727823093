import acadJoi from "@acad_joi";
import { AcadBaseDocument } from "@framework/models";

export default class ScholarshipBenefit extends AcadBaseDocument {
  static readonly PATH: string = "scholarshipBenefits";
  id: string = "";
  name: string = "";
}

export const scholarshipBenefitSchema = acadJoi.object<ScholarshipBenefit>({
  id: acadJoi.string().label("Benefit ID").trim().required(),
  name: acadJoi.string().label("Benefit Display Name").trim().required(),
});
