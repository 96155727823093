import LoadingIndicator from "@components/LoadingIndicator";
import doc from "@lib/firestore/doc";
import { OAuthClient, OAUTH_CLIENT_PATH } from "@models/oauth";
import { Alert, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFirestore, useFirestoreDocData } from "reactfire";
import AppCredentials from "./AppCredentials";
import AppPageForm from "./AppPageForm";
import AppTokenDirectory from "./AppTokenDirectory";
import OAuthClientProvider from "./oauthClientContext";

type Params = { appId: string };

function AppPage() {
  const { t } = useTranslation("developer");
  const db = useFirestore();
  const { appId } = useParams<Params>();
  const {
    data: client,
    status,
    error,
  } = useFirestoreDocData(doc<OAuthClient>(db, OAUTH_CLIENT_PATH, appId));

  return status === "loading" ? (
    <LoadingIndicator sx={{ p: 2 }} />
  ) : !client || error ? (
    <Alert severity={error ? "error" : "info"}>
      {error?.message || t("page_not_found", { ns: "common" })}
    </Alert>
  ) : (
    <OAuthClientProvider value={client}>
      <Stack sx={{ py: 2 }} spacing={2}>
        <AppPageForm />
        <AppCredentials />
        <AppTokenDirectory />
      </Stack>
    </OAuthClientProvider>
  );
}

export default AppPage;
