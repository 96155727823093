import { Timestamp } from "@firebase/firestore";
import DateTimestampTransformer from "@transformers/date_timestamp_transformer";
import Joi, { Reference } from "joi";

export interface TimestampSchema extends Joi.AnySchema {
  invalid(): this;
  min(other: Date | Timestamp | Reference): this;
  max(other: Date | Timestamp | Reference): this;
}

export const timestampExtension: Joi.ExtensionFactory = (joi) => {
  return {
    type: "timestamp",
    base: joi.date(),
    prepare: (value, helpers) => {
      if (value instanceof Date) {
        return { value };
      }
      // convert timestamp to date
      return { value: DateTimestampTransformer.toDate(value) };
    },
  };
};
