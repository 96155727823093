import Environment, { isEmu } from "@env";
import axios from "axios";

/**
 * Dashboard's own default axios configuration.
 * Define interceptors for request/response here if needed,
 * as well as general error catching.
 */

export const s3 = axios.create({
  baseURL: Environment?.cdn,
  headers: { "X-Requested-With": "AcadArena@1.0" },
});

export const api = axios.create({
  baseURL: isEmu() ? "http://localhost:5000/" : Environment?.api,
  headers: { "X-Requested-With": "AcadArena@1.0" },
});
