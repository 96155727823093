import { CollectionReference, query } from "@firebase/firestore";
import {  Query } from "firebase/firestore";
import { useFirestoreCollection } from "reactfire";

interface UseRetrieveCollectionParams<T> {
  collectionRef: CollectionReference<T>;
  getQuery?(reference: CollectionReference<T>): Query<T>;
}

/**
 * Retrieve Firebase Collection.
 * @param getQuery if specified, will override default. Can apply query filters here.
 * @returns
 */
export default function useRetrieveCollection<T>({
  collectionRef,
  getQuery,
}: UseRetrieveCollectionParams<T>) {
  const q = getQuery ? getQuery(collectionRef) : query(collectionRef);
  const { status, data, error } = useFirestoreCollection(q);
  const loading = status === "loading";

  return { status, data, error, loading };
}
