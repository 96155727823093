import AcadConsts from "@consts";
import Optional from "@defs/optional";
import { Timestamp } from "@firebase/firestore";
import { DateTimePickerProps } from "@mui/lab";
import DateTimestampTransformer from "@transformers/date_timestamp_transformer";
import { Controller, ControllerProps } from "react-hook-form";
import BaseAcadDateTimePicker from "./AcadBaseDateTimePicker";

interface AcadDateTimePickerProps<T>
  extends Omit<Optional<DateTimePickerProps<Date>, "onChange">, "value"> {
  controllerProps: Omit<ControllerProps<T>, "render">;
}

/**
 * DateTimePicker integrated with react-hook-form.
 * @param param0
 * @returns
 */
function AcadDateTimePicker<
  T,
  DateType extends Date | Timestamp | null = Date
>({
  controllerProps,
  onChange = AcadConsts.noop,
  ...dateTimePickerProps
}: AcadDateTimePickerProps<T>) {
  return (
    <Controller
      {...controllerProps}
      render={({ field }) => {
        const value = field.value as DateType;
        return (
          <BaseAcadDateTimePicker<Date | null>
            {...field}
            {...dateTimePickerProps}
            value={DateTimestampTransformer.anyToDate(value)}
            onChange={(val) => {
              onChange(val);
              field.onChange(DateTimestampTransformer.anyToDate(val));
            }}
          />
        );
      }}
    />
  );
}

export default AcadDateTimePicker;
