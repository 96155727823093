import { Avatar, ButtonBase, Card } from "@mui/material";
import React, { VFC } from "react";
import { useHistory } from "react-router-dom";
import { OrgProps } from "@defs/Types";

const OrgCard: VFC<{ org: OrgProps; disableOnClick?: boolean }> = ({
  org,
  disableOnClick,
}) => {
  const history = useHistory();
  return (
    <Card
      className="flex flex-col items-start overflow-hidden relative w-full"
      elevation={0}
      component={ButtonBase}
      onClick={
        disableOnClick
          ? undefined
          : () => history.push(`/community/directory/${org.schoolShortcode}`)
      }
    >
      <div
        className="h-40 bg-gray-200 w-full bg-cover bg-center"
        style={{ backgroundImage: `url("${org.cover_photo}")` }}
      ></div>
      <div className="p-4 flex items-center">
        <Avatar src={org.logo} variant="rounded">
          {org.schoolShortcode}
        </Avatar>
        <div className="flex flex-col pl-4">
          <div className="text-md leading-none text-left">{org.name}</div>
          <div className="text-xs leading-none text-left opacity-40">
            {org.schoolShortcode} | {org.shortname}{" "}
            {org.visibility === "draft" && "| draft"}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default OrgCard;
