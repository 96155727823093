import { collection, query, where, WhereFilterOp } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

interface Props {
  school: string;
  fieldName: string;
  path: string;
  operationString?: WhereFilterOp;
}

function AffectedFields({ school, fieldName, path, operationString }: Props) {
  const db = useFirestore();
  const ref = collection(db, path);
  const constraints = [
    where(fieldName, operationString ? operationString : "==", school),
  ];
  const userQuery = query(ref, ...constraints);
  const reactFire = useFirestoreCollectionData(userQuery);
  const { status, data, error } = reactFire;

  const { t } = useTranslation("schools");

  return (
    <div className="w-full text-center text-sm">
      {status === "loading"
        ? t("loading_affected_path", { path: path })
        : status === "error"
        ? t("error_loading_path_error", {
            path: path,
            error: error?.message,
          })
        : status === "success"
        ? t("length_path_affected", { length: data.length, path: path })
        : null}
    </div>
  );
}

export default AffectedFields;
