import acadJoi from "@acad_joi";
import { Timestamp } from "@firebase/firestore";

export default class ReferralUser {
  static readonly PATH: string = "referralUsers";

  uid: string = "";
  username: string = "";
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  phoneNumber?: string = "";
  school?: string = "";
  verified: boolean = false;
  createdAt: Timestamp = Timestamp.now();

  static schema = acadJoi.object<ReferralUser>({
    uid: acadJoi.string().label("UID").required(),
    username: acadJoi.string().label("Username").required(),
    firstName: acadJoi.string().label("First Name").required(),
    lastName: acadJoi.string().label("Last Name").required(),
    email: acadJoi
      .string()
      .label("Email")
      .required()
      .email({ tlds: { allow: false } }),
    phoneNumber: acadJoi.string().label("Phone Number").required(),
    school: acadJoi.string().label("School").allow(""),
    verified: acadJoi.boolean().label("Is Verified?").required(),
    createdAt: acadJoi.timestamp().label("User Created At").required(),
  });
}
