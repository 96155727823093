import TextField from "@components/MemoizedTextfield";
import Sheet from "@components/Sheet";
import {
  CustomFieldPrizeCategory,
  Prize,
  PrizeItem,
} from "@defs/Tournament.type";
import { DialogProps } from "@defs/Types";
import { onFileSelect, Files } from "@lib/file";
import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { OpenInNew, Close, Add } from "@mui/icons-material";
import equal from "fast-deep-equal";
import { useState, ChangeEvent, memo, useEffect } from "react";
import { PrizeProps } from ".";
import { Container } from "../TournamentEdit";
import swal from "sweetalert2";
import PrizeIconPopup from "./PrizeIconPopup";
import { useTranslation, Trans } from "react-i18next";

interface AddPrizeProps extends DialogProps, PrizeProps {}

const AddPrize = ({ form, setForm, open, onClose }: AddPrizeProps) => {
  const defaultState: Prize = {
    placement: "",
    name: "",
    cash: "",
    category: "primary",
    items: [],
    description: "",
    winner: "",
    defaultImage: "",
    icon: "",
  };
  const [prize, setPrize] = useState<Prize>(defaultState);
  const [files, setFiles] = useState<Files<Prize>>({});
  const [state, setState] = useState(false);

  const smUp = useMediaQuery("(min-width: 768px)");

  const handleText = (e: ChangeEvent<HTMLInputElement>) =>
    setPrize((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));

  const save = () => {
    setForm((state) => ({
      ...state,
      prizes: [...(state?.prizes ?? []), prize],
    }));
    onClose();
    setPrize(defaultState);
    setFiles({});
  };

  const addItem = () =>
    setPrize((state) => ({
      ...state,
      items: [
        ...state.items,
        { count: 1, name: "Item Name", description: "", image: "", url: "" },
      ],
    }));

  const { t } = useTranslation("events");

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={!smUp}
    >
      <DialogTitle>{t("add_prize")}</DialogTitle>
      <DialogContent>
        <div className="grid grid-cols-1 pt-2 gap-4">
          <div className="w-full flex justify-center">
            <ButtonBase
              component="label"
              style={{ aspectRatio: "16/9" }}
              className="w-full bg-gray-200 rounded-md flex flex-col justify-center items-center text-center text-sm bg-center bg-contain bg-no-repeat"
              sx={{
                backgroundImage: `url("${
                  files.defaultImage?.path || prize.defaultImage
                }")`,
              }}
            >
              <input
                hidden
                type="file"
                accept="image/*"
                name="defaultImage"
                onChange={onFileSelect(setFiles, setPrize)}
              />
              {!files.defaultImage && !prize.defaultImage && (
                <>
                  <Trans
                    i18nKey="events:click_here_to_upload_default_thumnail"
                    components={{
                      span: <span />,
                    }}
                  />
                </>
              )}
            </ButtonBase>
          </div>

          <div className="w-full flex space-x-2">
            <ButtonBase
              component="div"
              className="w-16 bg-gray-200 shadow-sm flex justify-center items-center leading-none rounded bg-contain bg-center bg-no-repeat"
              style={{ backgroundImage: `url("${prize.icon}")` }}
              onClick={() => setState(true)}
            >
              {!!prize.icon || t("icon", { ns: "common" })}
            </ButtonBase>
            <TextField
              value={prize.placement}
              label={t("placement")}
              onChange={handleText}
              fullWidth
              name="placement"
              required
              placeholder={t("placement_placeholder")}
            />
          </div>

          <TextField
            value={prize.name}
            label={t("prize_name")}
            name="name"
            required
            placeholder={t("prize_name_placeholder")}
            onChange={handleText}
          />
          <TextField
            value={prize.cash}
            label={t("cash_prize")}
            name="cash"
            placeholder={t("cash_prize_placeholder")}
            onChange={handleText}
            helperText={t("cash_prize_helper_text")}
          />
          <FormControl variant="outlined">
            <InputLabel>{t("category")}</InputLabel>
            <Select
              label={t("category")}
              value={prize.category}
              onChange={(e) =>
                setPrize((state) => ({
                  ...state,
                  category:
                    (e.target.value as CustomFieldPrizeCategory) ?? "primary",
                }))
              }
            >
              <MenuItem value="primary">{t("primary")}</MenuItem>
              <MenuItem value="secondary">{t("secondary")}</MenuItem>
              <MenuItem value="giveaway">{t("giveaway")}</MenuItem>
            </Select>
          </FormControl>

          <div className="text-lg mt-4 px-2">{t("items")}</div>
          <Container dark>
            <div className="col-span-full grid grid-cols-1 gap-4">
              {prize.items.map((item, index) => (
                <PrizeItemComponent
                  key={index}
                  tournamentId={form.id ?? ""}
                  {...{ setPrize, index, item }}
                />
              ))}
              <Button
                variant="contained"
                color="inherit"
                fullWidth
                className="h-20 shadow-md"
                startIcon={<Add />}
                onClick={addItem}
              >
                {t("add_item")}
              </Button>
            </div>
          </Container>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={save}
          disabled={!prize.name || !prize.placement}
        >
          {t("add", { ns: "common" })}
        </Button>
      </DialogActions>

      <PrizeIconPopup
        open={state}
        onClose={() => setState(false)}
        setPrize={setPrize}
      />
    </Dialog>
  );
};

interface PrizeItemProps {
  setPrize: React.Dispatch<React.SetStateAction<Prize>>;
  tournamentId: string;
  index: number;
  item: PrizeItem;
}

export const PrizeItemComponent = memo(
  ({ setPrize, index, item, tournamentId }: PrizeItemProps) => {
    const [itemFiles, setItemFiles] = useState<Files<PrizeItem>>({});

    const [itemState, setItemState] = useState<PrizeItem>(item);

    const handleText = ({
      target: { name, valueAsNumber, value, type },
    }: ChangeEvent<HTMLInputElement>) => {
      setPrize((state) => ({
        ...state,
        items: state.items.map((item, i) =>
          index === i
            ? { ...item, [name]: type === "number" ? valueAsNumber : value }
            : item
        ),
      }));
    };

    useEffect(() => {
      if (!itemState) return;
      setPrize((state) => ({
        ...state,
        items: state.items.map((obj, i) => (index === i ? itemState : obj)),
      }));
    }, [itemState, setPrize, index]);

    const { t } = useTranslation("events");

    const removeItem = () => {
      swal
        .fire({
          title: t("remove_prize_item_question"),
          text: item.name,
          showCancelButton: true,
        })
        .then((res) => {
          if (res.isConfirmed) {
            setPrize((state) => ({
              ...state,
              items: state.items.filter((value, i) => i !== index),
            }));
          }
        });
    };

    const preview = () => {
      window.open(item.image, "_blank");
      return;
    };

    return (
      <Sheet className="p-4 flex flex-col md:flex-row w-full shadow-md">
        <div className="flex flex-col w-full md:w-36 flex-shrink-0 space-y-2">
          <ButtonBase
            className="h-40 md:h-20 w-full bg-gray-200 rounded bg-contain bg-center bg-no-repeat text-center"
            component="label"
            sx={{
              backgroundImage: `url("${itemFiles.image?.path || item.image}")`,
            }}
          >
            <input
              hidden
              type="file"
              name="image"
              onChange={onFileSelect(setItemFiles, setItemState)}
            />
            {!itemFiles.image && !item.image && t("upload_product_image")}
          </ButtonBase>
          {item.image && (
            <Button
              size="small"
              color="inherit"
              variant="contained"
              startIcon={<OpenInNew fontSize="inherit" />}
              disabled={!item.image}
              className="bg-white"
              onClick={preview}
            >
              {t("preview", { ns: "common" })}
            </Button>
          )}
          <div className="flex-1 md:flex flex-col-reverse hidden">
            <Button
              color="error"
              size="small"
              startIcon={<Close fontSize="inherit" />}
              onClick={removeItem}
            >
              {t("delete", { ns: "common" })}
            </Button>
          </div>
        </div>

        <div className="grid gap-3 px-2 w-full pt-4 md:pt-0">
          <TextField
            fullWidth
            label={t("product_name")}
            value={item.name}
            onChange={handleText}
            name="name"
            required
            size="small"
          />
          <TextField
            fullWidth
            label={t("item_count")}
            type="number"
            value={item.count}
            onChange={handleText}
            name="count"
            required
            size="small"
            InputProps={{
              startAdornment: <span className="text-gray-400">x</span>,
            }}
          />
          <TextField
            multiline
            minRows={3}
            maxRows={5}
            fullWidth
            label={t("item_description")}
            type="number"
            value={item.description}
            onChange={handleText}
            name="description"
            size="small"
          />
          <Button
            color="error"
            size="small"
            className="sm:hidden flex"
            startIcon={<Close fontSize="inherit" />}
            onClick={removeItem}
          >
            {t("delete", { ns: "common" })}
          </Button>
        </div>
      </Sheet>
    );
  },
  (prev, next) => equal(prev.item, next.item)
);

export default AddPrize;
