import { Alert, Fade, Skeleton } from "@mui/material";
import PlaylistCard from "@views/Boostcamp/components/PlaylistCard";
import placeholder_playlist from "@defs/placeholder_playlist";
import { BoostcampSeries } from "@defs/Types";
import { collection, CollectionReference } from "firebase/firestore";
import { useFirestore, useFirestoreCollection } from "reactfire";
import { useTranslation } from "react-i18next";

const BoostcampDirectory = () => {
  const db = useFirestore();
  const path = "boostcamp";
  const ref = collection(db, path) as CollectionReference<BoostcampSeries>;
  const { status, data: snapshot, error } = useFirestoreCollection(ref);
  const loading = status === "loading";
  const data = snapshot?.docs.map((doc) => doc.data()) ?? [];
  const { t } = useTranslation("boostcamp");

  const Loading = (
    <Fade in appear>
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
        {placeholder_playlist.map((pl, i) => (
          <Skeleton
            variant="rectangular"
            className="rounded-md"
            animation="wave"
            width="100%"
            key={i}
          >
            <PlaylistCard playlist={pl} />
          </Skeleton>
        ))}
      </div>
    </Fade>
  );

  const Error = <Alert severity="error">{error?.message}</Alert>;

  const Empty = (
    <div className="w-full h-52 text-center flex justify-center items-center text-xl bg-gray-200 rounded-md">
      {t("no_playlists")}
    </div>
  );

  const Populated = (data: BoostcampSeries[]) => (
    <Fade in>
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
        {data?.map((playlist, i) => (
          <PlaylistCard playlist={playlist} key={1} />
        ))}
      </div>
    </Fade>
  );

  return loading ? (
    <>{Loading}</>
  ) : error ? (
    <>{Error}</>
  ) : !data.length ? (
    <>{Empty}</>
  ) : (
    <>{Populated(data)}</>
  );
};

export default BoostcampDirectory;
