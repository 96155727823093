import AuthGuard from "@components/AuthGuard";
import Region from "@defs/region/region";
import Academy from "@views/Academy";
import AdminControl from "@views/Admin";
import SignIn from "@views/Auth/SignIn";
import Boostcamp from "@views/Boostcamp";
import BoostcampPage from "@views/Boostcamp/BoostcampPage";
import CommunityDashboard from "@views/Community";
import Dashboard from "@views/Dashboard";
import Developer from "@views/Developer";
import TournamentsAndLeagues from "@views/Events";
import Frontpage from "@views/Frontpage";
import Games from "@views/Games";
import GameEdit from "@views/Games/GameEdit";
import PartnerPage from "@views/Partners/PartnerPage";
import Scholarships from "@views/Scholarships";
import SchoolsPage from "@views/Schools";
import StudentVerification from "@views/StudentVerification";
import Users from "@views/Users";

export interface LinkProps {
  url: string;
  component: JSX.Element;
  exact?: boolean;
  /**
   * this uses TFunction key
   */
  title: string;
  adminOnly?: boolean;
  icon?: string;
  onNavbar?: boolean;
  allowedRoles?: string[];
  superAdminOnly?: boolean;
  authenticated?: boolean;
  forbidRegions?: Region[];
}

export const links: LinkProps[] = [
  {
    url: "dashboard",
    component: <Dashboard />,
    exact: true,
    title: "dashboard",
    adminOnly: false,
    onNavbar: true,
    icon: "🏠",
  },
  {
    url: "frontpage",
    component: <Frontpage />,
    exact: true,
    title: "front_page",
    icon: "📄",
    adminOnly: true,
    onNavbar: true,
  },
  {
    url: "community",
    component: (
      <AuthGuard>
        <CommunityDashboard />
      </AuthGuard>
    ),
    exact: false,
    title: "community",
    authenticated: true,
    icon: "🔥",
    onNavbar: true,
  },
  {
    url: "events",
    component: <TournamentsAndLeagues />,
    exact: false,
    title: "events",
    adminOnly: false,
    onNavbar: true,
    icon: "🏆",
    forbidRegions: [Region.BR],
  },
  {
    url: "boostcamp",
    component: <Boostcamp />,
    exact: true,
    title: "boostcamp",
    adminOnly: true,
    onNavbar: true,
    icon: "🚀",
  },
  {
    url: "verification",
    component: <StudentVerification />,
    exact: false,
    title: "verification",
    adminOnly: false,
    onNavbar: true,
    icon: "👩🏻‍🎓",
    allowedRoles: ["moderator", "admin"],
  },
  {
    url: "schools",
    component: <SchoolsPage />,
    exact: false,
    title: "schools",
    adminOnly: true,
    onNavbar: true,
    icon: "🏫",
  },
  {
    url: "users",
    component: <Users />,
    exact: false,
    title: "users",
    adminOnly: true,
    onNavbar: true,
    icon: "👾",
  },
  {
    url: "scholarship",
    component: <Scholarships />,
    exact: false,
    title: "scholarship",
    adminOnly: true,
    onNavbar: true,
    icon: "🎓",
  },
  {
    url: "game",
    component: <Games />,
    exact: true,
    title: "games",
    adminOnly: true,
    onNavbar: true,
    icon: "🎮",
  },
  {
    url: "academy",
    component: <Academy />,
    exact: false,
    title: "academy",
    adminOnly: true,
    onNavbar: true,
    icon: "🎓",
  },
  {
    url: "developer",
    component: <Developer />,
    exact: false,
    title: "developer",
    adminOnly: false,
    onNavbar: true,
    icon: "💻",
  },
  {
    url: "admin",
    component: <AdminControl />,
    exact: false,
    title: "admin",
    adminOnly: true,
    onNavbar: true,
    icon: "⚙️",
    superAdminOnly: true,
  },
];

export const OtherRoutes: Partial<LinkProps>[] = [
  {
    url: "login",
    component: <SignIn />,
  },
  {
    url: "partner/:partnerId",
    component: <PartnerPage />,
  },
  {
    url: "boostcamp/:boostcampId",
    component: <BoostcampPage />,
  },
  {
    url: "game/:id",
    component: <GameEdit />,
  },
];
