import acadJoi from "@acad_joi";
import { SchemaMap } from "joi";

export class CarouselSlide {
  id: string = "";
  title: string = "";
  description: string = "";
  image: string = "";
  url?: string = "";

  static schemaMap: SchemaMap = {
    id: acadJoi.string().allow("").label("Id"),
    title: acadJoi.string().allow("").label("Title"),
    description: acadJoi.string().allow("").label("Description"),
    image: acadJoi.string().allow("").label("Image"),
    url: acadJoi.string().allow("").label("URL").optional(),
  };

  static schema = acadJoi.object<CarouselSlide>(CarouselSlide.schemaMap);
}
