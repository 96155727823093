import RoleGuard from "@components/guard_components/RoleGuard";
import { LinkProps } from "@defs/Links";
import All from "./All";
import VerificationSettings from "./Settings/VerificationSettings";

const studentVerificationRoutes: LinkProps[] = [
  {
    url: "/verification/documents",
    component: (
      <RoleGuard allowedRoles={["moderator", "admin"]}>
        <All />
      </RoleGuard>
    ),
    title: "all_documents",
    icon: "📃",
    exact: true,
    onNavbar: true,
    adminOnly: false,
    allowedRoles: ["moderator", "admin"],
  },
  {
    url: "/verification/settings",
    component: (
      <RoleGuard allowedRoles={["admin"]}>
        <VerificationSettings />
      </RoleGuard>
    ),
    title: "settings",
    icon: "⚙",
    exact: true,
    onNavbar: true,
    adminOnly: true,
    allowedRoles: ["admin"],
  },
];

export default studentVerificationRoutes;
