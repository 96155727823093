import { doc, DocumentReference } from "@firebase/firestore";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { DialogProps } from "@framework/types";
import { useCreateDocument } from "@hooks";
import { AcadDateTimePicker, AcadModal, AcadTextField } from "@components";
import { useFirestore } from "reactfire";
import { ChangeEvent } from "react";
import { useHistory } from "react-router";
import { writeBatch } from "firebase/firestore";
import toast from "react-hot-toast";
import ScholarshipInfo from "@models/scholarship_info";
import Scholarship from "@models/scholarship";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useTranslation } from "react-i18next";

const ScholarshipCreate = ({ open, onClose }: DialogProps) => {
  const db = useFirestore();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    formState: { errors },
  } = useForm<ScholarshipInfo>({
    defaultValues: new ScholarshipInfo(),
    resolver: joiResolver(ScholarshipInfo.schema),
  });

  const { t } = useTranslation("scholarships");

  // create scholarship
  const { loading, docExists: scExists } = useCreateDocument<Scholarship>({
    onCreated: () => {
      if (onClose) {
        onClose();
      }
      return t("successfully_created_scholarship");
    },
    docExistsMsg: t("scholarship_with_id_already_exists", {
      id: getValues().id,
    }),
  });

  // create scholarship info
  const { docExists: scInfoExists } = useCreateDocument<ScholarshipInfo>({
    onCreated: () => {
      if (onClose) {
        onClose();
      }
      return t("successfully_created_scholarship_info");
    },
    docExistsMsg: t("scholarship_info_with_id_already_exists", {
      id: getValues().id,
    }),
  });

  const doDocsExist = async (
    scRef: DocumentReference<Scholarship>,
    scInfoRef: DocumentReference<ScholarshipInfo>
  ) => {
    return (await scExists(scRef)) || (await scInfoExists(scInfoRef));
  };

  const createDoc = async (info: ScholarshipInfo) => {
    const scRef = doc(
      db,
      Scholarship.PATH,
      info.id
    ) as DocumentReference<Scholarship>;

    const scInfoRef = doc(
      db,
      `${Scholarship.PATH}/${info.id}/${ScholarshipInfo.PATH}`,
      info.id
    ) as DocumentReference<ScholarshipInfo>;

    if (await doDocsExist(scRef, scInfoRef)) {
      return;
    }

    const batch = writeBatch(db);
    batch.set(scRef, { ...Scholarship.fromScholarshipInfo(info) });
    batch.set(scInfoRef, info);

    toast
      .promise(batch.commit(), {
        loading: t("creating_scholarship"),
        success: t("successfully_created_scholarship"),
        error: (err) => t("failed_code", { code: err.code }),
      })
      .then(() => {
        history.push({
          pathname: `/scholarship/scholarships/${info.slug}`,
          state: {
            id: info.id,
          },
        });
      });
  };

  const slugRegister = register("slug");

  const updateSlug = (e: ChangeEvent<HTMLInputElement>) => {
    // update id when slug is updated
    slugRegister.onChange(e);
    setValue("id", e.target.value);
  };

  return (
    <AcadModal open={open} onClose={onClose} loading={loading}>
      <DialogContent className="overflow-y-visible">
        <div className="grid grid-cols-12 gap-4">
          <div className="text-lg mt-4 mb-2 col-span-full">
            {t("scholarship_info")}
          </div>
          <AcadTextField
            variant="outlined"
            size="medium"
            label={t("scholarship_display_name")}
            className="col-span-full sm:col-span-6"
            {...register("name")}
            helperText={t("scholarship_display_name_helper")}
            required
            errors={errors}
          />
          <AcadTextField
            {...slugRegister}
            variant="outlined"
            size="medium"
            label={t("scholarship_slug")}
            className="col-span-full sm:col-span-6"
            onChange={updateSlug}
            helperText={t("scholarship_slug_helper")}
            required
            errors={errors}
          />
          <AcadDateTimePicker
            label={t("start_date")}
            controllerProps={{ name: "startDate", control }}
            renderInput={(params) => (
              <AcadTextField
                {...params}
                name="startDate"
                className="col-span-12 sm:col-span-6"
                errors={errors}
                required
              />
            )}
          />
          <AcadDateTimePicker
            label={t("end_date")}
            controllerProps={{ name: "endDate", control }}
            renderInput={(params) => (
              <AcadTextField
                {...params}
                name="endDate"
                className="col-span-12 sm:col-span-6"
                errors={errors}
                required
              />
            )}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(createDoc)}
        >
          {t("save", { ns: "common" })}
        </Button>
      </DialogActions>
    </AcadModal>
  );
};

export default ScholarshipCreate;
