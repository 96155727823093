import { Menu } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { getAuth } from "firebase/auth";
import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useUser } from "reactfire";
import RegionSelect from "./RegionSelect";
import { useRegion } from "@hooks/use_region";

export interface AppBarProps {
  drawerState: boolean;
  setDrawerState: React.Dispatch<React.SetStateAction<boolean>> | (() => {});
}

export const AppBar: FC<AppBarProps> = ({ drawerState, setDrawerState }) => {
  const { t } = useTranslation("common");
  const auth = getAuth();
  const onClick = () => {
    setDrawerState((state) => !state);
  };
  const history = useHistory();
  const { region } = useRegion();

  const { data: user } = useUser();
  // const [user] = useAuthState(auth);

  const signOut = async () => {
    await auth.signOut();
    window.location.reload();
  };

  const signIn = () => {
    history.push("/login");
    // toast.promise(signInWithPopup(auth, new GoogleAuthProvider()), {
    //   loading: "Signing In...",
    //   success: () => {
    //     window.location.reload();
    //     return "Successfully signed in";
    //   },
    //   error: (err) => err.message,
    // });
  };

  return (
    <div
      className={`w-full bg-white shadow-sm relative z-40 transform transition-all ${
        drawerState ? "translate-x-64" : ""
      }`}
    >
      <div className="sticky top-0 z-40 lg:z-40 w-full max-w-8xl mx-auto bg-white flex-none flex">
        {/* <div className="sticky top-0 z-40 lg:z-40 w-full mx-auto bg-white flex-none flex"> */}
        <div className="flex-none hidden lg:flex pl-4 sm:pl-6 xl:pl-8 items-center border-b border-gray-200 lg:border-b-0 lg:w-64">
          <div
            className="h-16 w-full max-w-sm bg-contain bg-no-repeat bg-center cursor-pointer"
            onClick={() =>
              (window.location.href =
                window.location.hostname === "localhost"
                  ? "http://localhost:3000"
                  : "https://acadarena.com")
            }
            style={{ backgroundImage: `url()` }}
          ></div>
        </div>
        <div className="flex-auto border-b border-gray-200 h-16 flex items-center justify-between px-4 sm:px-6 lg:mx-6 lg:px-0 xl:mx-8">
          <IconButton
            className="lg:hidden xl:hidden"
            onClick={onClick}
            size="large"
          >
            <Menu />
          </IconButton>
          <div className="flex-grow pl-4 flex flex-row items-center gap-4">
            <img
              src="/images/acadarena_logo.png"
              width="155"
              height="40"
              alt="Logo"
            />
            <RegionSelect value={region} />
          </div>
          <div className="lg:w-64 pl-2 flex-shrink-0 flex items-center justify-end space-x-6">
            {user ? (
              <Button
                className="justify-self-end"
                variant="outlined"
                onClick={signOut}
              >
                {t("logout")}
              </Button>
            ) : (
              <Button onClick={signIn}>{t("login")}</Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppBar;
