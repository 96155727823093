import { CircularProgress, Stack, StackProps } from "@mui/material";
import { ReactNode, VFC } from "react";

interface Props extends StackProps {
  fullScreen?: boolean;
  loader?: ReactNode;
}
const LoadingScreen: VFC<Props> = ({ fullScreen, loader, ...props }) => {
  return (
    <Stack
      {...props}
      sx={{
        height: fullScreen ? "100%" : "auto",
        width: "100%",
        position: fullScreen ? "fixed" : "relative",
        minHeight: 300,
        top: 0,
        left: 0,
        alignItems: "center",
        justifyContent: "center",
        ...props.sx,
      }}
    >
      {loader || <CircularProgress />}
    </Stack>
  );
};
export default LoadingScreen;
