import { doc, DocumentReference } from "@firebase/firestore";
import { Button, DialogActions, DialogContent, TextField } from "@mui/material";
import { DialogProps } from "@framework/types";
import { useCreateDocument, useForm } from "@hooks";
import { AcadModal } from "@components";
import { useFirestore } from "reactfire";
import ScholarshipRequirement from "@models/scholarship_requirement";
import { useTranslation } from "react-i18next";

const RequirementCreate = ({ open, onClose }: DialogProps) => {
  const db = useFirestore();
  const { t } = useTranslation("scholarships");
  const { form, updateFormField } = useForm<ScholarshipRequirement>(
    new ScholarshipRequirement()
  );
  const { loading, createDocument } = useCreateDocument<ScholarshipRequirement>(
    {
      onCreated: () => {
        if (onClose) {
          onClose();
        }
        return t("successfully_created_new_requirement");
      },
      docExistsMsg: t("requirement_with_id_already_exists", { id: form.id }),
    }
  );

  const createDoc = () => {
    if (!form.id) {
      return;
    }
    createDocument(
      form,
      doc(
        db,
        ScholarshipRequirement.PATH,
        form.id
      ) as DocumentReference<ScholarshipRequirement>
    );
  };

  return (
    <AcadModal open={open} onClose={onClose} loading={loading}>
      <DialogContent className="overflow-y-visible">
        <div className="grid grid-cols-12 gap-4">
          <div className="text-lg mt-4 mb-2 col-span-full">
            Requirement Info
          </div>
          <TextField
            variant="outlined"
            size="medium"
            label={t("id")}
            className="col-span-full"
            value={form.id}
            name="id"
            onChange={updateFormField}
            helperText={t("requirement_id_helper")}
            required
          />
          <TextField
            variant="outlined"
            size="medium"
            label={t("requirement_display_name")}
            className="col-span-full"
            value={form.name}
            name="name"
            onChange={updateFormField}
            helperText={t("requirement_display_name_helper")}
            required
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={createDoc}>
          {t("save", { ns: "common" })}
        </Button>
      </DialogActions>
    </AcadModal>
  );
};

export default RequirementCreate;
