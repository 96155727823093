import { VFC } from "react";
import { useHistory } from "react-router-dom";
import { AcadCard } from "@components";
import ScholarshipRequirement from "@models/scholarship_requirement";

const RequirementCard: VFC<{ requirement: ScholarshipRequirement }> = ({
  requirement,
}) => {
  const history = useHistory();
  return (
    <AcadCard
      onClick={() =>
        history.push(`/scholarship/requirements/${requirement.id}`)
      }
    >
      <div className="flex flex-col pl-4">
        <div className="text-md leading-none text-left">{requirement.name}</div>
        <div className="text-xs leading-none text-left opacity-40">
          {requirement.id}
        </div>
      </div>
    </AcadCard>
  );
};

export default RequirementCard;
