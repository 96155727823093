import acadJoi from "@acad_joi";
import { CollectionReference, DocumentReference } from "@firebase/firestore";

export type GlobalRoleType = "admin" | "moderator";

export default class GlobalRole {
  roles: GlobalRoleType[] = [];
  uid: string = "";
  email: string = "";
  admin?: boolean;
  moderator?: boolean;

  static schema = acadJoi
    .object<GlobalRole>({
      roles: acadJoi
        .array()
        .items(acadJoi.string().valid("admin", "moderator"))
        .required(),
      uid: acadJoi.string().required(),
      email: acadJoi
        .string()
        .label("Email")
        .email({ tlds: { allow: false } })
        .required(),
      admin: acadJoi.boolean(),
      moderator: acadJoi.boolean(),
    })
    .required();

  static addRoleSchema = acadJoi.object({
    role: acadJoi.string().valid("moderator", "admin").required(),
    email: acadJoi
      .string()
      .email({ tlds: { allow: false } })
      .label("Email")
      .required(),
  });
}

export interface IAddRoleForm {
  role: GlobalRoleType;
  email: string;
}

export type GlobalRoleColRef = CollectionReference<GlobalRole>;
export type GlobalRoleDocRef = DocumentReference<GlobalRole>;
