/**
 * HIGHER-ORDER COMPONENT for {@link FileUploadProvider}
 * this is to avoid creating a new component just to
 * wrap the main component that will use fileUploadContext
 */
import { Alert } from "@mui/material";
import { User } from "firebase/auth";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useSigninCheck } from "reactfire";

type WithAdminCheckProps = { isAdmin: boolean; user?: User | null };

function withAdminCheck<T>(
  ReactComponent: FunctionComponent<T & WithAdminCheckProps>
) {
  return ((props) => {
    const { t } = useTranslation("common");
    const { data, status } = useSigninCheck();
    const regex = /^.+@acadarena\.com/;
    const isAcadStaff =
      !!data?.user?.emailVerified && regex.test(data?.user?.email ?? "");

    return status === "loading" ? null : status === "error" ? (
      <Alert>{t("user_load_failed")}</Alert>
    ) : (
      <ReactComponent {...props} isAdmin={isAcadStaff} user={data.user} />
    );
  }) as FunctionComponent<T>;
}

export default withAdminCheck;
