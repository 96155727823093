import { AcadTable } from "@components";
import { AcadTableProps } from "@components/table/AcadTable";
import { HeadCell } from "@components/table/AcadTableHead";
import { USERDOC_ACTIONS } from "@consts/callables";
import loadingMessage from "@defs/LoadingMessages";
import useUserData from "@lib/AuthHooks";
import {
  serializeUserDocument,
  UserVerificationDocument,
} from "@models/user_document";
import { Check, Close } from "@mui/icons-material";
import { Card, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Dispatch, ReactNode, Reducer, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { useFunctions } from "reactfire";
import Swal from "sweetalert2";
import { useUserDocSchools } from "./UserDocSchoolsCtxProvider";
import { UserDocCallableProps } from "./UserDocumentActionDialog";
import UserDocumentTableRow from "./UserDocumentTableRow";
import { useTranslation } from "react-i18next";

const disableSort = false;

export type UserVerifDocWithId<P extends string = "docId"> =
  UserVerificationDocument & Record<P, string>;

interface UserDocumentTableProps<T>
  extends Omit<AcadTableProps<T>, "renderRows" | "headCells" | "data"> {
  SearchComponent?: ReactNode;
  data: UserVerifDocWithId[];
}

type TableReducer = Reducer<State, Action>;
type State = UserVerifDocWithId[];
type Action =
  | { type: "toggle"; payload: UserVerifDocWithId }
  | { type: "toggleAll"; payload: UserVerifDocWithId[] }
  | { type: "clear" };
export type TableDispatch = Dispatch<Action>;
const reducer: TableReducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return [];
    case "toggle":
      if (!!state.find((doc) => doc.docId === action.payload.docId)) {
        return state.filter((doc) => doc.docId !== action.payload.docId);
      }
      return [...state, action.payload];
    case "toggleAll":
      const payloadIds = action.payload.map((doc) => doc.docId);
      const stateIds = state.map((doc) => doc.docId);
      const allSelected = payloadIds.every((id) => stateIds.includes(id));
      if (!allSelected) {
        return action.payload;
      }
      return [];

    default:
      return state;
  }
};

const UserDocumentTable = ({
  SearchComponent,
  data,
  ...props
}: UserDocumentTableProps<UserVerificationDocument>) => {
  const fn = useFunctions();
  const { isSchoolPending } = useUserDocSchools();
  const { user } = useUserData();
  const { t } = useTranslation("student_verification");
  const [selected, dispatch] = useReducer<TableReducer>(reducer, []);
  const [headCells] = useState<HeadCell<UserVerificationDocument>[]>([
    { id: "lastName", label: t("full_name"), sticky: "left" },
    { id: "school", label: t("school") },
    { id: "regForm", label: t("proof"), disableSort },
    { id: "username", label: t("username", { ns: "common" }) },
    { id: "enrollDate", label: t("enroll_date") },
    { id: "submitDate", label: t("submit_date") },
  ]);

  const isRowSelected = (id: string) =>
    !!selected.find((doc) => doc.docId === id);

  const createActionData = (
    action: UserDocCallableProps["context"]["action"],
    documentId: string,
    doc: UserVerificationDocument
  ): UserDocCallableProps => {
    return {
      context: {
        action,
        documentId,
        userId: doc.owner,
        removeVerification: false,
      },
      document: {
        ...serializeUserDocument(doc),
        reviewer:
          user?.data()?._username || user?.data()?.username || user?.id || "-",
        dateReviewed: new Date(),
        verified: action === "accept",
        rejected: action === "reject",
      },
    };
  };

  const goToast = (promise: Promise<any>) => {
    return toast.promise(promise, {
      loading: loadingMessage(),
      error: (err) => {
        console.error(err);
        return err.message;
      },
      success: () => {
        dispatch({ type: "clear" });
        return t("done", { ns: "common" });
      },
    });
  };

  const accept = () => {
    const pendingSchoolSelected = selected.filter((doc) =>
      isSchoolPending(doc.school)
    );
    const pendingSchoolUNames = pendingSchoolSelected.map(
      (doc) => doc.username
    );

    const text = pendingSchoolSelected.length
      ? t("the_following_users_wont_be_verified", {
          usernames: pendingSchoolUNames.join(", "),
        })
      : t("length_selected_documents", { length: selected.length });

    Swal.fire({
      title: t("accept_selected_documents_question"),
      text,
      confirmButtonText: t("accept", { ns: "common" }).toLocaleUpperCase(),
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        doAction(
          "accept",
          selected.filter((doc) => !isSchoolPending(doc.school))
        );
      }
    });
  };

  const reject = () => {
    Swal.fire({
      title: t("reject_selected_documents_question"),
      text: `${selected.length} selected documents`,
      confirmButtonText: t("reject", { ns: "common" }).toLocaleUpperCase(),
      confirmButtonColor: "red",
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        doAction("reject", selected);
      }
    });
  };

  const doAction = (
    action: "accept" | "reject",
    toUpdate: UserVerifDocWithId<"docId">[]
  ) => {
    if (!toUpdate.length) return toast.error("No selected documents.");
    const callable = httpsCallable<UserDocCallableProps>(fn, USERDOC_ACTIONS);

    const promises = toUpdate.map((doc) => {
      const { docId, ...document } = doc;
      return callable(createActionData(action, docId, document));
    });
    // TODO: transform userDocuments-adminActions to take in an array of documents
    // to avoid multiple calls to the endpoint
    return goToast(Promise.all(promises));
  };

  return (
    <Card>
      <Stack gap={2}>
        <Stack
          sx={{
            p: 2,
            backgroundColor: !!selected.length
              ? "background.neutral"
              : "inherit",
            borderRadius: 2,
            width: "100%",
          }}
          gap={2}
          direction="row"
          alignItems="center"
        >
          {!!selected.length && (
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              className="w-full"
            >
              <Typography>
                {t("length_selected", { length: selected.length })}
              </Typography>
              <Stack
                direction="row"
                gap={1}
                justifyContent="flex-end"
                className="flex-1"
              >
                <Tooltip title={t("accept_selected")} placement="top">
                  <IconButton onClick={accept}>
                    <Check fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("reject_selected")} placement="top">
                  <IconButton onClick={reject}>
                    <Close fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          )}
          {!!!selected.length && SearchComponent}
        </Stack>
        <AcadTable
          dense
          headCells={headCells}
          data={data}
          enableCheckbox
          numSelected={selected.length}
          {...props}
          onSelectAllClick={() =>
            dispatch({ type: "toggleAll", payload: data })
          }
          renderRows={(row, i) => (
            <UserDocumentTableRow
              row={row}
              key={i}
              hover
              tableDispatch={dispatch}
              selected={isRowSelected(row.docId)}
            />
          )}
        />
      </Stack>
    </Card>
  );
};

export default UserDocumentTable;
