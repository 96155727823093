import { LinkProps } from "@defs/Links";
import OrgsList from "./Directory";
import OrgEditPage from "./Directory/OrgEdit";
import { Settings } from "./Settings";

const path = "/community";

export const OrgRoutes: LinkProps[] = [
  {
    component: <OrgsList />,
    title: "communities",
    url: `${path}/directory`,
    adminOnly: false,
    authenticated: true,
    exact: true,
    onNavbar: true,
    icon: "🎉",
  },
  {
    component: <Settings />,
    title: "settings",
    url: `${path}/settings`,
    adminOnly: true,
    authenticated: true,
    exact: true,
    onNavbar: true,
    icon: "🎉",
  },

  {
    url: `${path}/directory/:orgId`,
    component: <OrgEditPage />,
    title: "org_page",
    authenticated: true,
  },
];
