import { Button } from "@mui/material";
import { doc, DocumentReference, setDoc } from "firebase/firestore";
import toast from "react-hot-toast";
import Sheet from "@components/Sheet";
import { FrontPage } from "@defs/Frontpage.type";
import loadingMessage from "@defs/LoadingMessages";
import BoostcampEpisodes from "./BoostcampEpisodes/BoostcampEpisodes";
import Carousel from "./Carousel/Carousel";
import CommunitySlides from "./Communities/Communities";
import TournamentSlides from "./Tournaments/Tournaments";
import { useFirestore, useFirestoreDoc } from "reactfire";
import { useTranslation } from "react-i18next";

const Frontpage = () => {
  const db = useFirestore();
  const path = "public/frontpage";
  const ref = doc(db, path) as DocumentReference<FrontPage>;
  const { status, data: frontPage, error } = useFirestoreDoc(ref);
  const loading = status === "loading";

  const { t } = useTranslation("frontpage");

  const createDocument = () => {
    const ref = doc(db, path) as DocumentReference<FrontPage>;
    const promise = setDoc(ref, {
      carousel: [],
      boostcampEpisodes: [],
      communities: [],
      tournaments: [],
    });

    toast.promise(promise, {
      loading: loadingMessage(),
      error: (err) => err.message,
      success: t("successfully_initialized_front_page_document"),
    });
  };

  if (!frontPage?.exists()) {
    return (
      <Sheet>
        <Button variant="outlined" onClick={createDocument}>
          {t("create_front_page_document")}
        </Button>
      </Sheet>
    );
  }

  if (loading) {
    return <div>{t("loading")}</div>;
  }
  if (error) {
    return <div>{t("something_went_wrong", { ns: "common" })}</div>;
  }

  return (
    <div>
      <Sheet>
        <div className="text-xl">{t("front_page_settings_here")}</div>
      </Sheet>

      {frontPage && (
        <>
          <Carousel frontPage={frontPage} />

          <TournamentSlides frontPage={frontPage} />
          <CommunitySlides frontPage={frontPage} />
          <BoostcampEpisodes frontPage={frontPage} />
        </>
      )}
    </div>
  );
};

export default Frontpage;
