import Sheet from "@components/Sheet";
import { UserData } from "@defs/User";
import { userContext } from "@lib/AuthContext";
import { emailRE } from "@lib/RegularExpressions";
import { Button, TextField } from "@mui/material";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import {
  collection,
  CollectionReference,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { useAuth, useFirestore } from "reactfire";

const FacebookProvider = new FacebookAuthProvider();
const GoogleProvider = new GoogleAuthProvider();

const SignIn = () => {
  const auth = useAuth();
  const db = useFirestore();
  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user } = useContext(userContext);
  const history = useHistory();

  useEffect(() => {
    if (!user) return;

    history.push("/dashboard");
  }, [user, history]);

  const isEmail = (str: string) => {
    return emailRE.test(str);
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    let email = "";
    if (isEmail(usernameOrEmail)) {
      email = usernameOrEmail;
    } else {
      const ref = collection(db, "users") as CollectionReference<UserData>;
      const q = query(
        ref,
        where("username", "==", usernameOrEmail.toLowerCase()),
        limit(1)
      );

      const [usernameSnap] = (await getDocs(q)).docs ?? [];

      if (usernameSnap) {
        email = usernameSnap.data().email;
      } else {
        return toast.error("Username does not exist");
      }
    }
    toast.promise(signInWithEmailAndPassword(auth, email, password), {
      error: "Login failed!",
      loading: "Signing in...",
      success: () => {
        return "Successfully signed in!";
      },
    });
  };

  // login with google
  const loginWithGoogle = () => {
    toast.promise(signInWithPopup(auth, GoogleProvider), {
      error(error) {
        return "Error: " + error.code;
      },
      loading: "Signing In...",
      success: "Signed in successfully",
    });
  };
  // login with facebook
  const loginWithFacebook = () => {
    toast.promise(signInWithPopup(auth, FacebookProvider), {
      error(error) {
        return "Error: " + error.code;
      },
      loading: "Signing In...",
      success: "Signed in successfully",
    });
  };
  return (
    <form
      onSubmit={onSubmit}
      className="mt-7 w-full flex justify-center items-center"
    >
      <Sheet className="flex flex-col">
        <div className="text-lg">Login is Required</div>
        <TextField
          className="mt-4 w-full sm:w-72"
          size="small"
          variant="outlined"
          fullWidth
          label="Username / Email"
          value={usernameOrEmail}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setUsernameOrEmail(e.target.value)
          }
        />
        <TextField
          className="mt-2 w-full sm:w-72"
          size="small"
          variant="outlined"
          fullWidth
          type="password"
          label="Password"
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
        />
        <Button
          type="submit"
          variant="contained"
          className="mt-4 w-full sm:w-72"
          disabled={!password || !usernameOrEmail}
        >
          Login
        </Button>
        <Button
          onClick={loginWithGoogle}
          variant="contained"
          className="mt-6 w-full sm:w-72"
        >
          Login with Google
        </Button>
        <Button
          onClick={loginWithFacebook}
          variant="contained"
          className="mt-1 w-full sm:w-72"
        >
          Login with Facebook
        </Button>
      </Sheet>
    </form>
  );
};

export default SignIn;
