import { AcadDateTimePicker, AcadTextField } from "@components";
import { AcadSelect } from "@components/select";
import AcadAutocomplete from "@components/textfield/AcadAutocomplete";
import { useSchoolList } from "@contexts/schoolList/SchoolListContextProvider";
import {
  AcademyCbSignUpData,
  SignUpStatus,
  SignUpStatusMap,
} from "@defs/academy/academy_cb_sign_up";
import loadingMessage from "@defs/LoadingMessages";
import Region from "@defs/region/region";
import { Subregion } from "@defs/region/subregion";
import { DialogProps } from "@defs/Types";
import { UserData } from "@defs/User";
import { doc, DocumentReference, updateDoc } from "@firebase/firestore";
import { joiResolver } from "@hookform/resolvers/joi";
import { useRegion } from "@hooks/use_region";
import useStudentYear from "@hooks/use_student_year";
import { AcademyCbSignUp } from "@models/academy_cb_sign_up";
import { StudentYearEnum, User } from "@models/user";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grow,
  MenuItem,
  Stack,
  Switch,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { sanitize } from "@transformers/sanitize_data";
import { setDoc } from "firebase/firestore";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useFirestore } from "reactfire";

interface Props extends DialogProps {
  user?: UserData;
  cbSignUp?: AcademyCbSignUpData;
  refAcademyCbSignUp: DocumentReference<AcademyCbSignUp>;
}

const UserEdit = ({
  user,
  cbSignUp,
  refAcademyCbSignUp,
  open,
  onClose,
}: Props) => {
  const db = useFirestore();
  const { getIngressYearChoices } = useStudentYear();
  const { region } = useRegion();

  const {
    formState: { errors },
    register,
    reset,
    control,
    getValues,
    handleSubmit,
    clearErrors,
  } = useForm<UserData>({
    defaultValues: sanitize(user || {}),
    resolver: joiResolver(
      User.schema.unknown(true).options({ stripUnknown: true })
    ),
  });

  const {
    control: controlAcademyCbSignUp,
    handleSubmit: handleSubmitAcademyCbSignUp,
    reset: resetAcademyCbSignUp,
    clearErrors: clearErrorsAcademyCbSignUp,
  } = useForm<AcademyCbSignUpData>({
    defaultValues: sanitize(cbSignUp || { uid: user?.uid }),
    resolver: joiResolver(
      AcademyCbSignUp.schema.unknown(true).options({ stripUnknown: true })
    ),
  });

  useEffect(() => {
    reset(sanitize(user || {}));
    clearErrors();
    resetAcademyCbSignUp(sanitize(cbSignUp || { uid: user?.uid }));
    clearErrorsAcademyCbSignUp();
  }, [
    user,
    clearErrors,
    reset,
    resetAcademyCbSignUp,
    cbSignUp,
    clearErrorsAcademyCbSignUp,
  ]);

  const { t } = useTranslation("users");

  const submitStatus = handleSubmitAcademyCbSignUp(
    (data) => {
      if (data.status) {
        const newData = {
          uid: user?.uid,
          status: data.status,
        };
        const promise = setDoc(refAcademyCbSignUp, sanitize(newData));
        toast.promise(promise, {
          loading: loadingMessage(),
          error: t("failed_to_save"),
          success: () => {
            onClose();
            return t("updated_to_status", {
              status: t(SignUpStatusMap[newData.status]),
            });
          },
        });
      } else {
        onClose();
      }
    },
    (err) => {
      console.log(err);
    }
  );

  const submit = handleSubmit(
    (data) => {
      const newData = {
        ...data,
        _username: `${data._username || data.username}`,
        username: `${data._username?.toLocaleLowerCase() || data.username}`,
      };

      const ref = doc(
        db,
        "users",
        user?.uid + ""
      ) as DocumentReference<UserData>;

      const promise = updateDoc(ref, sanitize(newData));
      toast.promise(promise, {
        loading: loadingMessage(),
        error: t("failed_to_save"),
        success: () => {
          submitStatus();
          return t("saved");
        },
      });
    },
    (err) => {
      console.log(err);
    }
  );

  const schools = useSchoolList();

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const { subregions, isPh } = useRegion();

  return (
    <Dialog
      {...{ open, onClose }}
      TransitionComponent={Grow}
      TransitionProps={{
        unmountOnExit: true,
      }}
      maxWidth={"sm"}
      fullWidth
      fullScreen={!smUp}
    >
      <DialogTitle>
        {t("edit_username", { username: user?._username || user?.username })}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
            <AcadTextField
              {...register("firstName")}
              label={t("first_name")}
              fullWidth
              errors={errors}
            />
            <AcadTextField
              {...register("middleInitial")}
              label={t("middle_initial")}
              errors={errors}
              fullWidth={!smUp}
            />
            <AcadTextField
              {...register("lastName")}
              label={t("last_name")}
              fullWidth
              errors={errors}
            />
          </Stack>
          <Stack spacing={2}>
            <AcadTextField
              defaultValue={getValues("email")}
              name="email"
              label={t("email")}
              disabled
              fullWidth
              errors={errors}
            />
            <AcadAutocomplete<UserData, string>
              control={control}
              name="school"
              freeSolo
              fullWidth
              options={[...schools, ""]}
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={(props) => (
                <AcadTextField {...props} label={t("school")} />
              )}
            />
          </Stack>
          <Stack spacing={2} direction={{ md: "row" }}>
            <AcadTextField
              defaultValue={getValues("_username")}
              {...register("_username")}
              label={t("username")}
              fullWidth
              errors={errors}
            />

            {isPh ? (
              <AcadSelect<UserData, StudentYearEnum>
                control={control}
                name="studentYear"
                label={t("student_year")}
                fullWidth
              >
                <MenuItem value={StudentYearEnum.JuniorHighschool}>
                  {t("junior_highschool")}
                </MenuItem>
                <MenuItem value={StudentYearEnum.SeniorHighschool}>
                  {t("senior_highschool")}
                </MenuItem>
                <MenuItem value={StudentYearEnum.FirstYear}>
                  {t("first_year")}
                </MenuItem>
                <MenuItem value={StudentYearEnum.SecondYear}>
                  {t("second_year")}
                </MenuItem>
                <MenuItem value={StudentYearEnum.ThirdYear}>
                  {t("third_year")}
                </MenuItem>
                <MenuItem value={StudentYearEnum.FourthYear}>
                  {t("fourth_year")}
                </MenuItem>
                <MenuItem value={StudentYearEnum.FifthYear}>
                  {t("fifth_year")}
                </MenuItem>
                <MenuItem value={StudentYearEnum.PostGraduate}>
                  {t("post_graduate")}
                </MenuItem>
              </AcadSelect>
            ) : (
              <AcadSelect<UserData, StudentYearEnum>
                control={control}
                name="ingressYear"
                label={t("ingress_year")}
                fullWidth
              >
                {getIngressYearChoices().map((year) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))}
              </AcadSelect>
            )}

            <AcadSelect<UserData, Subregion>
              control={control}
              name="region"
              label={t("region")}
              fullWidth
            >
              {subregions.map((subregion) => (
                <MenuItem key={subregion} value={subregion}>
                  {subregion}
                </MenuItem>
              ))}
            </AcadSelect>
          </Stack>
          <Stack spacing={2} direction={{ md: "row" }}>
            <FormControlLabel
              label={t("verified")}
              control={
                <Controller
                  name="verified"
                  control={control}
                  render={({ field: { onChange, ...field } }) => (
                    <Switch
                      {...field}
                      checked={field.value}
                      onChange={(e, v) => onChange(v)}
                    />
                  )}
                />
              }
            />
            <AcadDateTimePicker<UserData>
              controllerProps={{ name: "verificationExpiry", control }}
              renderInput={(props) => (
                <AcadTextField
                  {...props}
                  label={t("verification_expiry")}
                  fullWidth
                  name="verificationExpiry"
                />
              )}
            />
          </Stack>
          {region === Region.PH && (
            <Stack gap={1}>
              <Typography variant="h6">{t("academy_onboarding")}</Typography>
              <AcadSelect<AcademyCbSignUpData, SignUpStatus>
                control={controlAcademyCbSignUp}
                name="status"
                label={t("onboarding_status")}
                fullWidth
              >
                {Object.values(SignUpStatus).map((signUpStatus) => (
                  <MenuItem key={signUpStatus} value={signUpStatus}>
                    {t(SignUpStatusMap[signUpStatus])}
                  </MenuItem>
                ))}
              </AcadSelect>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("close", { ns: "common" })}</Button>
        <Button variant="contained" onClick={submit}>
          {t("save", { ns: "common" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserEdit;
