import {
  collection,
  CollectionReference,
  Firestore,
} from "@firebase/firestore";
import { useRetrieveCollection } from "@hooks";
import ScholarshipBenefit from "@models/scholarship_benefit";
import ScholarshipEligibility from "@models/scholarship_eligibility";
import ScholarshipRequirement from "@models/scholarship_requirement";
import Sponsor from "@models/sponsor";

interface ScholarshipCollectionsProps {
  db: Firestore;
}

function useGetData<T>(db: Firestore, path: string): T[] {
  const collectionRef = collection(db, path) as CollectionReference<T>;

  const { data } = useRetrieveCollection({
    collectionRef,
  });
  return data?.docs?.map((doc) => doc.data()) ?? [];
}

export default function useScholarshipCollections({
  db,
}: ScholarshipCollectionsProps) {
  // TODO: may need to do paging/search like for users? this is a lot of retrieves... oh well, each collection
  // apart from sponsors is expected to not have a lot of documents anyway.
  const benefits = useGetData<ScholarshipBenefit>(db, ScholarshipBenefit.PATH);
  const eligibilities = useGetData<ScholarshipEligibility>(
    db,
    ScholarshipEligibility.PATH
  );
  const requirements = useGetData<ScholarshipRequirement>(
    db,
    ScholarshipRequirement.PATH
  );
  const sponsors = useGetData<Sponsor>(db, Sponsor.PATH);

  return { benefits, eligibilities, requirements, sponsors };
}
