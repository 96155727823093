import { Button, TextField } from "@mui/material";
import { FormEvent, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { doc, DocumentReference } from "firebase/firestore";
import { userContext } from "@lib/AuthContext";
import { useFirestore } from "reactfire";
import { useDeleteDocument, useForm, useWriteDocument } from "@hooks";
import { AcadEditDocContainer, AcadEditDocSection } from "@components";
import ScholarshipEligibility from "@models/scholarship_eligibility";
import { useTranslation } from "react-i18next";

const EligibilityEdit = () => {
  const db = useFirestore();
  const { eligibilityId } = useParams<{ eligibilityId: string }>();

  const { form, updateFormField, onSnapshotChanged } =
    useForm<ScholarshipEligibility>(new ScholarshipEligibility());

  const docRef = doc(
    db,
    `${ScholarshipEligibility.PATH}/${eligibilityId}`
  ) as DocumentReference<ScholarshipEligibility>;
  const { deleteDocument } = useDeleteDocument<ScholarshipEligibility>({
    docRef,
    titleMsg: "Delete Eligibility?",
    onDelete: () => {
      history.push("/scholarship/eligibilities");
    },
  });

  const { loading, snap, error, writeDocument } =
    useWriteDocument<ScholarshipEligibility>({
      docRef,
      onSnapshotChanged,
    });

  const history = useHistory();

  const { isAdmin: admin } = useContext(userContext);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    writeDocument(form);
  };

  const { t } = useTranslation("scholarships");

  return (
    <AcadEditDocContainer
      snapshot={snap}
      error={error}
      loading={loading}
      onBackBtn={() => history.push("/scholarship/eligibilities")}
      doesNotExistText="Eligibility does not exist"
      backBtnText="Back to Eligibilities"
    >
      <form className="grid grid-cols-12 gap-4" onSubmit={onSubmit}>
        <AcadEditDocSection
          title={t("eligibility_info")}
          className="col-span-full grid grid-cols-12 gap-4"
        >
          <TextField
            name="name"
            label={t("eligibility_name")}
            className="col-span-12"
            value={form?.name}
            required
            onChange={updateFormField}
          />
        </AcadEditDocSection>

        <div className="col-span-12 flex justify-end">
          <Button
            variant="outlined"
            className="mr-4"
            color="secondary"
            onClick={() => deleteDocument(form)}
            disabled={!admin}
          >
            {t("delete", { ns: "common" })}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={form === snap?.data()}
          >
            {t("save", { ns: "common" })}
          </Button>
        </div>
      </form>
    </AcadEditDocContainer>
  );
};

export default EligibilityEdit;
