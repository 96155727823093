import { UserData } from "@defs/User";
import {
  collection,
  CollectionReference,
  query,
  where,
} from "@firebase/firestore";
import usePaginatedCollection, {
  PaginatedCollection,
} from "@hooks/use_paginated_collection";
import { doc, DocumentReference } from "firebase/firestore";
import { createContext, FC, useContext } from "react";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { ReadOnlySchools } from "../SchoolList";

type Props = PaginatedCollection<UserData> & {
  schoolList: string[];
};

const blankPromise = async () => {};

export const schoolListPendingContext = createContext<Props>({
  cursor: 0,
  loading: false,
  next: async () => {},
  prev: () => {},
  prevDisabled: false,
  nextDisabled: false,
  page: 0,
  status: "loading",
  hasEmitted: false,
  firstValuePromise: blankPromise(),
  error: undefined,
  isComplete: false,
  limit: 1,
  schoolList: [],
});

export const SchoolListPendingProvider: FC = ({ children }) => {
  const db = useFirestore();
  const ref = collection(db, "users") as CollectionReference<UserData>;
  const q = query(ref, where("pendingSchoolName", "!=", ""));
  const value = usePaginatedCollection<UserData>({
    query: q,
    limit: 10,
    orderBy: {
      field: "pendingSchoolName",
      direction: "asc",
    },
  });
  const schoolListRef = doc(
    db,
    "read_only/schools"
  ) as DocumentReference<ReadOnlySchools>;
  const { data: schoolListDoc } = useFirestoreDocData(schoolListRef);

  const schoolList = schoolListDoc?.schools ?? [];
  return (
    <schoolListPendingContext.Provider value={{ ...value, schoolList }}>
      {children}
    </schoolListPendingContext.Provider>
  );
};

export const useSchoolListPending = () => {
  const data = useContext(schoolListPendingContext);
  return data;
};
