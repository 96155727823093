import ImagePreview from "@components/ImagePreview";
import { FunctionComponent, useContext, useState } from "react";
import { createContext } from "react";

type ImagePreviewContext = (url: string) => void;
export const imagePreviewContext = createContext<ImagePreviewContext>(() => {});
export const useImagePreview = () => useContext(imagePreviewContext);
export function withImagePreview<T>(Node: FunctionComponent<T>) {
  return (props: T) => {
    const [open, setOpen] = useState(false);
    const openDialog = () => setOpen(true);
    const closeDialog = () => setOpen(false);
    const [url, setUrl] = useState("");

    const contextOpen: ImagePreviewContext = (url) => {
      setUrl(url);
      openDialog();
    };

    return (
      <>
        <imagePreviewContext.Provider value={contextOpen}>
          <Node {...props} />
        </imagePreviewContext.Provider>
        <ImagePreview onClose={closeDialog} imageUrl={url} open={open} />
      </>
    );
  };
}
