import { Button, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FrontPage } from "@defs/Frontpage.type";
import {
  arrayRemove,
  doc,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  QueryDocumentSnapshot,
  updateDoc,
} from "firebase/firestore";
import TournamentSelect from "./TournamentSelect";
import toast from "react-hot-toast";
import loadingMessage from "@defs/LoadingMessages";
import TournamentCard from "@views/Events/Tournaments/components/TournamentCard.old";
import { TournamentDoc } from "@defs/Tournament.type";
import { Fab } from "@mui/material";
import { Close } from "@mui/icons-material";
import swal from "sweetalert2";
import { useFirestore } from "reactfire";
import { useTranslation } from "react-i18next";

interface Props {
  frontPage: DocumentSnapshot<FrontPage>;
}

const TournamentSlides = ({ frontPage }: Props) => {
  const db = useFirestore();
  const [state, setState] = useState(false);
  const [selection, setSelection] = useState<
    QueryDocumentSnapshot<TournamentDoc>[] | DocumentSnapshot<TournamentDoc>[]
  >([]);

  useEffect(() => {
    if (!frontPage) return;
    let isActiveComponent = true;
    frontPage.data()?.tournaments.forEach((id) => {
      const ref = doc(
        db,
        `tournaments/${id}`
      ) as DocumentReference<TournamentDoc>;
      getDoc(ref).then((res) => {
        if (isActiveComponent) {
          setSelection((sel) => {
            if (sel.find((s) => s.id === id)) return sel;
            return [...sel, res];
          });
        }
      });
    });

    return () => {
      isActiveComponent = false;
    };
  }, [frontPage, db]);

  const { t } = useTranslation("frontpage");

  const save = () => {
    setState(false);
    const ref = doc(db, "public/frontpage") as DocumentReference<FrontPage>;
    const promise = updateDoc(ref, {
      tournaments: Array.from(new Set(selection.map((s) => s.id))),
    });
    toast.promise(promise, {
      loading: loadingMessage(),
      error: (err) => err.message,
      success: t("saved_successfully", { ns: "common" }),
    });
  };

  const data = frontPage.data();

  const removeTournament = (id: string) => () => {
    swal
      .fire({
        title: t("remove_tournament_question"),
        text: t("remove_tournament_from_front_page_question"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#B33A3A",
      })
      .then((result) => {
        if (result.isConfirmed) {
          const ref = doc(
            db,
            "public/frontpage"
          ) as DocumentReference<FrontPage>;
          setSelection(selection.filter((s) => s.id !== id));
          const promise = updateDoc(ref, { tournaments: arrayRemove(id) });
          toast.promise(promise, {
            loading: t("saving_changes", { ns: "common" }),
            error: (err) => err.message,
            success: t("removed_tournament"),
          });
        }
      });
  };
  return (
    <div className="mt-8">
      <div className="flex">
        <div className="text-lg">{t("tournaments")}</div>
        <Button
          size="small"
          variant="outlined"
          className="ml-4"
          onClick={() => setState(true)}
        >
          {t("select", { ns: "common" })}
        </Button>
      </div>
      <div className="flex flex-col mt-2 relative overflow-hidden">
        <div className="p-2 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
          {frontPage?.data()?.tournaments.map((slide, index) => {
            const data = selection.find((s) => s.id === slide);

            return data ? (
              <div className="relative" key={slide}>
                <Fab
                  size="small"
                  className="absolute top-3 right-3 z-30"
                  onClick={removeTournament(slide)}
                >
                  <Close />
                </Fab>
                <TournamentCard tournament={data} disableOnClick />
              </div>
            ) : (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={233}
                className="flex justify-center items-center"
                key={slide}
              >
                {slide}
              </Skeleton>
            );
          })}
        </div>
      </div>
      {data && (
        <>
          <TournamentSelect
            open={state}
            onClose={() => setState(false)}
            {...{ selection, setSelection, save }}
          />
        </>
      )}
    </div>
  );
};

export default TournamentSlides;
