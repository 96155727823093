import { VFC } from "react";
import { useHistory } from "react-router-dom";
import { AcadCard } from "@components";
import Game from "@models/game";

const GameCard: VFC<{ game: Game }> = ({ game }) => {
  const history = useHistory();
  return (
    <AcadCard
      onClick={() => history.push(`/game/${game.id}`)}
      avatar={game.icon}
    >
      <div className="flex flex-col pl-4">
        <div className="text-md leading-none text-left">{game.title}</div>
        <div className="text-xs leading-none text-left opacity-40">
          {game.publisher}
        </div>
      </div>
    </AcadCard>
  );
};

export default GameCard;
