import Region from "@defs/region/region";
import { EnvConfig } from "./env_config";

const EnvProd: Record<Region, EnvConfig> = {
  [Region.PH]: {
    appURL: "https://app.acadarena.com",
    cdn: "https://cdn.acadarena.com/",
    api: "https://waypoint-api.web.app/",
    region: "asia-east2",
    firebaseConfig: {
      apiKey: "AIzaSyCBLUDZCrkYHnq-aZ-Luyesx5bu0VeXf2Y",
      authDomain: "dashboard.acadarena.com",
      databaseURL:
        "https://waypoint-acadarena-default-rtdb.asia-southeast1.firebasedatabase.app",
      projectId: "waypoint-acadarena",
      storageBucket: "storage.acadarena.com",
      messagingSenderId: "785788635765",
      appId: "1:785788635765:web:0190fdd7e20b9a229fc63c",
      measurementId: "G-XV1GP9S7PF",
    },
  },
  [Region.BR]: {
    appURL: "https://app.acadarena.com",
    cdn: "https://cdn.acadarena.com/",
    api: "https://waypoint-api-br.web.app/",
    region: "southamerica-east1",
    firebaseConfig: {
      apiKey: "AIzaSyBxq-lLCJsy-8pqWvnv-q0oKUOni1d9L5k",
      authDomain: "aa-brazil.firebaseapp.com",
      projectId: "aa-brazil",
      storageBucket: "aa-brazil.appspot.com",
      messagingSenderId: "830366135950",
      appId: "1:830366135950:web:fe4bdfb9903ae5b4943bdf",
      measurementId: "G-56S582J5J8",
    },
  },
};

export default EnvProd;
