import { UserData } from "@defs/User";
import { DocumentSnapshot } from "@firebase/firestore";
import { createContext, useContext } from "react";
export interface UserContextProps {
  user: DocumentSnapshot<UserData> | null;
  username: string | null;
  loading: boolean;
  isAdmin: boolean;
  claimsLoading: boolean;
  isSuperAdmin: boolean;
  isGlobalMod: boolean;
  roles: string[];
  claims?: Record<string, any>;
}

export const userContext = createContext<UserContextProps>({
  user: null,
  username: null,
  loading: false,
  isAdmin: false,
  claimsLoading: false,
  isSuperAdmin: false,
  isGlobalMod: false,
  roles: [],
});

export const useUserContext = () => {
  const data = useContext(userContext);
  return data;
};
