import { OAuthClient } from "@models/oauth";
import { createContext, PropsWithChildren, useContext } from "react";

export const oauthClientContext = createContext<OAuthClient>({
  clientId: "",
  clientSecret: "",
  description: "",
  grants: "client_credentials",
  icon: "",
  id: "",
  name: "",
  owner: "",
  privacyPolicyUrl: "",
  tags: [],
  termsOfServiceUrl: "",
});

export const useOAuthClient = () => useContext(oauthClientContext);

interface Props {
  value: OAuthClient;
}
const OAuthClientProvider = ({ value, children }: PropsWithChildren<Props>) => {
  return (
    <oauthClientContext.Provider value={value}>
      {children}
    </oauthClientContext.Provider>
  );
};

export default OAuthClientProvider;
