import {
  CircularProgress,
  CircularProgressProps,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface LoadingIndicatorProps extends StackProps {
  LoaderProps?: CircularProgressProps;
}
const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  children,
  LoaderProps,
  ...props
}) => {
  const { t } = useTranslation("common");
  return (
    <Stack gap={2} direction="row" alignItems="center" {...props}>
      <CircularProgress {...LoaderProps} />
      {!!children ? (
        <>{children}</>
      ) : (
        <Typography>{t("loading")}...</Typography>
      )}
    </Stack>
  );
};

export default LoadingIndicator;
