/**
 *
 * <------------------------------------------>
 * TODO [ACA-107]: CODE SPLIT, CLEAN THE CODE
 * <------------------------------------------>
 *
 */

import { AcadSEO } from "@components";
import loadingMessage from "@defs/LoadingMessages";
import { OrgProps } from "@defs/Types";
import { useRegion } from "@hooks/use_region";
import asyncHook from "@lib/AsyncHook";
import { userContext } from "@lib/AuthContext";
import { Files, onFileSelect } from "@lib/file";
import { getSchools } from "@lib/GetSchoolList";
import { slugRE } from "@lib/RegularExpressions";
import { ArrowLeft } from "@mui/icons-material";
import { Alert, Button, ButtonBase, Fade, Skeleton } from "@mui/material";
import { deleteDoc, doc, DocumentReference, setDoc } from "firebase/firestore";
import { FormEvent, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useFirestore, useFirestoreDoc } from "reactfire";
import swal from "sweetalert2";
import OrgMainFields from "./OrgMainFields";

enum Tab {
  main = "Org",
  seo = "SEO",
}

const OrgEditPage = () => {
  const db = useFirestore();
  const { orgId } = useParams<{ orgId: string }>();
  const [tabValue, setTabValue] = useState<Tab>(Tab.main);
  const { defaultSubregion } = useRegion();

  const p = `orgs/${orgId}`;
  const r = doc(db, p) as DocumentReference<OrgProps>;
  const { status, data: snap, error: err } = useFirestoreDoc(r);
  const loading = status === "loading";

  const history = useHistory();
  const org = snap?.data();
  const [form, setForm] = useState<OrgProps>({
    name: "",
    shortcode: "",
    shortname: "",
    schoolName: "",
    schoolShortcode: "",
    schoolCampus: "",
    isAlliance: true,
    logo: "",
    moderators: [],
    officers: [],
    region: defaultSubregion,
    visibility: "draft",
    admins: [],
    contact_email: "",
    cover_photo: "",
    description_thumbnail: "",
    description: "",
    geography: "",
    profile_photo: "",
    star_level: 1,
    users: [],
    social_links: {
      facebook: "",
      twitter: "",
      discord: "",
      instagram: "",
      tiktok: "",
    },
    games: [],
    activities: [],
    seo: {
      description: "",
      thumbnail: "",
      title: "",
    },
  });
  const [files, setFiles] = useState<Files<OrgProps>>({});
  const [schools, setSchools] = useState<string[]>([]);

  const { user, isAdmin } = useContext(userContext);

  const { t } = useTranslation("community");

  useEffect(() => {
    if (!setSchools) return;

    (async () => {
      setSchools(await getSchools());
    })();
  }, [setSchools]);

  useEffect(() => {
    if (!snap?.exists()) return;
    setForm(snap.data());
  }, [snap]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!schools.includes(form?.schoolName)) {
      toast.error(t("invalid_school"));
      return;
    }

    if (!slugRE.test(form.schoolShortcode)) {
      toast.error(t("invalid_school_shortcode"));
      return;
    }

    const ref = doc(db, `orgs/${orgId}`) as DocumentReference<OrgProps>;
    const promise = setDoc(ref, form, { merge: true });
    toast.promise(promise, {
      loading: loadingMessage(),
      error: (err) => err.message,
      success: t("saved_successfully"),
    });
  };

  const deleteOrg = () => {
    swal
      .fire({
        title: t("delete_org"),
        text: t("this_action_is_irreversible"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#B33A3A",
      })
      .then(async (resp) => {
        if (resp.isConfirmed) {
          const ref = doc(db, `orgs/${orgId}`) as DocumentReference<OrgProps>;
          const [, err] = await asyncHook(deleteDoc(ref));
          if (err) {
            toast.error(t("unable_to_delete_org"));
            return;
          }
          history.push("/community");
        }
      });
  };

  return !user ? (
    <Fade in appear>
      <div className="flex flex-col justify-center items-center">
        <div className="">{t("sign_in_required")}</div>
      </div>
    </Fade>
  ) : loading ? (
    <Fade in appear>
      <div className="flex flex-col">
        <Skeleton
          variant="rectangular"
          className="w-full h-52 rounded-md"
        ></Skeleton>
      </div>
    </Fade>
  ) : err ? (
    <Fade in appear>
      <Alert severity="warning">{err.message}</Alert>
    </Fade>
  ) : !snap?.exists() ? (
    <Fade in appear>
      <Alert severity="warning">{t("this_org_does_not_exist")}</Alert>
    </Fade>
  ) : (
    <Fade in appear>
      <div className="flex flex-col py-4">
        <div className="flex  mb-2 ">
          <Button
            startIcon={<ArrowLeft />}
            variant="outlined"
            onClick={() => history.push(isAdmin ? "/community" : "/dashboard")}
          >
            {isAdmin ? t("go_back_to_community") : t("go_back_to_dashboard")}
          </Button>
        </div>

        {/* Cover Photo */}
        <ButtonBase
          className="w-full h-52 bg-gray-200 bg-cover bg-center rounded-md"
          component="label"
          style={{
            backgroundImage: `url("${
              files.cover_photo?.path || org?.cover_photo
            }")`,
          }}
        >
          <input
            type="file"
            hidden
            name="cover_photo"
            onChange={onFileSelect(setFiles, setForm)}
            accept="image/*"
          />
          {Boolean(files.cover_photo)
            ? ""
            : org?.cover_photo
            ? ""
            : t("click_here_to_add_cover_photo")}
        </ButtonBase>

        {/* Profile Photo */}

        <ButtonBase
          className="shadow-md h-36 w-36 bg-white rounded-md self-center sm:self-start sm:ml-4 -mt-14  bg-cover bg-center flex justify-center items-center"
          style={{ backgroundImage: `url("${files.logo?.path || org?.logo}")` }}
          component="label"
        >
          <input
            type="file"
            hidden
            name="logo"
            onChange={onFileSelect(setFiles, setForm)}
            accept="image/*"
          />

          {Boolean(files.logo) ? "" : org?.logo ? "" : t("add_logo")}
        </ButtonBase>

        <div className="flex flex-wrap"></div>
        {/* Fields */}
        <form
          className="grid grid-cols-12 mx-0 md:mx-4 mt-8 gap-4"
          onSubmit={onSubmit}
        >
          <div className="flex space-x-2 col-span-full">
            <Button
              variant="contained"
              className={`${tabValue !== Tab.main && "bg-white"}`}
              color={tabValue === Tab.main ? "primary" : "inherit"}
              onClick={() => setTabValue(Tab.main)}
            >
              {t("settings")}
            </Button>
            <Button
              variant="contained"
              className={`${tabValue !== Tab.seo && "bg-white"}`}
              color={tabValue === Tab.seo ? "primary" : "inherit"}
              onClick={() => setTabValue(Tab.seo)}
            >
              {t("seo")}
            </Button>
          </div>

          <Fade in={tabValue === Tab.main} unmountOnExit>
            <div className="grid grid-cols-12 gap-4 col-span-full">
              <OrgMainFields {...{ form, setForm, org, schools }} />
            </div>
          </Fade>
          <Fade in={tabValue === Tab.seo} unmountOnExit>
            <div className="col-span-full">
              <AcadSEO
                value={form.seo}
                onChange={(seo) => setForm((state) => ({ ...state, seo }))}
              />
            </div>
          </Fade>

          <div className="col-span-12 flex justify-end mt-4">
            <Button
              variant="outlined"
              className="mr-4"
              color="secondary"
              onClick={deleteOrg}
              disabled={!isAdmin}
            >
              {t("delete", { ns: "common" })}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={form === snap?.data()}
            >
              {t("save", { ns: "common" })}
            </Button>
          </div>
        </form>
      </div>
    </Fade>
  );
};

export default OrgEditPage;
