// Hooks
import { useState } from "react";

// Components
import { Button } from "@mui/material";
import Sheet from "@components/Sheet";
import BoostcampCreate from "./BoostcampCreate";
import BoostcampDirectory from "./BoostcampDirectory";
import { useTranslation } from "react-i18next";

export default function Boostcamp(): JSX.Element {
  const [createState, setCreateState] = useState(false);
  const { t } = useTranslation("boostcamp");
  return (
    <div className="w-full flex flex-col">
      <Sheet>
        <div className="text-xl mb-2">{t("emoji_boostcamp_title")}</div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setCreateState(true)}
        >
          {t("create_new_season")}
        </Button>
      </Sheet>

      <div className="w-full">
        <div className="text-lg mb-2 mt-4">{t("all_seasons")}</div>
        <BoostcampDirectory />
      </div>

      <BoostcampCreate
        open={createState}
        onClose={() => setCreateState(false)}
      />
    </div>
  );
}
