import Sheet from "@components/Sheet";
import loadingMessage from "@defs/LoadingMessages";
import { Participant } from "@defs/Participant.type";
import { httpsCallable } from "@firebase/functions";
import {
  PlayerCustomFieldAnswer,
  PlayerFieldWithAnswer,
  TeamCustomFieldAnswer,
  TeamFieldWithAnswer,
} from "@models/custom_field";
import { Check, Close } from "@mui/icons-material";
import {
  Stack,
  Typography,
  Chip,
  Tooltip,
  IconButton,
  Divider,
} from "@mui/material";
import { QueryDocumentSnapshot } from "firebase/firestore";
import { VFC } from "react";
import toast from "react-hot-toast";
import { useFunctions } from "reactfire";
import CustomFieldAnswers from "./CustomFieldAnswers.Accordion";
import { useTournamentEdit } from "../TournamentEdit";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

interface CheckInProps {
  fireTourId: string;
  fireParId: string;
  chalParId: number;
}

interface Props {
  doc: QueryDocumentSnapshot<Participant>;
  hideActions?: boolean;
}
const ParticipantCard: VFC<Props> = ({ doc, hideActions }) => {
  const fn = useFunctions();

  const data = doc.data();

  const { t } = useTranslation("events");
  const tour = useTournamentEdit();
  const checkIn =
    (fireParId: string, chalParId: number = 0) =>
    () => {
      const name = "tournamentParticipant-checkIn";
      const callable = httpsCallable<CheckInProps>(fn, name);
      const promise = callable({
        fireTourId: tour?.id ?? "",
        chalParId,
        fireParId,
      });

      toast.promise(promise, {
        loading: loadingMessage(),
        error: (err) => err.message,
        success: t("done", { ns: "common" }),
      });
    };

  const undoCheckIn =
    (fireParId: string, chalParId: number = 0) =>
    () => {
      Swal.fire({
        title: t("undo_check_in_question"),
        text: t("are_you_sure_to_undo_check_in"),
        confirmButtonText: t("undo", { ns: "common" }),
        confirmButtonColor: "warning",
        showCancelButton: true,
      }).then((res) => {
        if (res.isConfirmed) {
          const name = "tournamentParticipant-undoCheckIn";
          const callable = httpsCallable<CheckInProps>(fn, name);
          const promise = callable({
            fireTourId: tour?.id ?? "",
            chalParId,
            fireParId,
          });

          toast.promise(promise, {
            loading: loadingMessage(),
            error: (err) => err.message,
            success: t("done", { ns: "common" }),
          });
        }
      });
    };

  const answers = data.customFieldAnswers ?? {};

  const teamFieldAnswers = Object.entries(
    answers
  ).reduce<TeamCustomFieldAnswer>((acc, [id, fieldAnswer]) => {
    if (typeof fieldAnswer.answer === "string")
      return { ...acc, [id]: fieldAnswer as TeamFieldWithAnswer };
    return acc;
  }, {});

  const playerFieldAnswers = Object.entries(
    answers
  ).reduce<PlayerCustomFieldAnswer>((acc, [id, fieldAnswer]) => {
    if (typeof fieldAnswer.answer !== "string")
      return { ...acc, [id]: fieldAnswer as PlayerFieldWithAnswer };
    return acc;
  }, {});

  const checkInDisabled = !tour?.data()?.lookingForCheckIns;
  return (
    <Sheet>
      <Stack direction="row" alignItems="center" gap={2}>
        <div
          className="h-16 w-16 bg-contain bg-center bg-gray-100 rounded-lg flex-shrink-0"
          style={{ backgroundImage: `url("${doc.data().logo}")` }}
        ></div>
        <Stack className="flex-1">
          <Stack gap={1} direction="row">
            <Typography>{doc.data().name}</Typography>
            {!!doc.data().checkInAt && (
              <Chip
                label={t("checked_in")}
                size="small"
                color="primary"
                className="rounded text-xs"
              />
            )}
          </Stack>
          <Typography variant="caption">{doc.data().school}</Typography>
        </Stack>
        {!hideActions && (
          <Stack gap={1} direction="row">
            <Tooltip
              title={
                checkInDisabled ? t("enable_check_in_first") : t("check_in")
              }
            >
              <IconButton
                disabled={checkInDisabled}
                size="small"
                onClick={checkIn(doc.id, doc.data().chalId)}
              >
                <Check fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                checkInDisabled
                  ? t("enable_check_in_first")
                  : t("undo_check_in")
              }
            >
              <IconButton
                disabled={checkInDisabled}
                size="small"
                onClick={undoCheckIn(doc.id, doc.data().chalId)}
              >
                <Close fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </Stack>

      {!!Object.values(data.customFieldAnswers ?? {}).length ? (
        <CustomFieldAnswers
          teamFieldAnswers={teamFieldAnswers}
          perPlayerFieldAnswers={playerFieldAnswers}
        />
      ) : (
        <Stack gap={2} sx={{ mt: 2, width: "100%" }}>
          <Divider />
          <Typography alignItems="center" variant="caption">
            {t("no_custom_field_answers_yet")}
          </Typography>
        </Stack>
      )}
    </Sheet>
  );
};

export default ParticipantCard;
