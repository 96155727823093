import { DocumentSnapshot } from "firebase/firestore";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from "react";

export default function useForm<T>(initdata: T) {
  const [form, setForm] = useState<T>(initdata);
  /**
   * Update form field. By default, it takes the e.name as the key and e.value as value.
   * Specify `override` for special logic
   * @param e
   * @param override
   */
  const updateFormField = (
    e: ChangeEvent<HTMLInputElement>,
    override?: (form: T, setForm: Dispatch<SetStateAction<T>>) => void
  ) => {
    if (override) {
      override(form, setForm);
    } else {
      setForm({ ...form, [e.target.name as keyof T]: e.target.value });
    }
  };

  /**
   * Reset form back to its initial state
   */
  const reset = () => {
    setForm({ ...initdata });
  };

  /**
   * Used for when a snapshot is updated, updated form.
   */
  const onSnapshotChanged = useCallback<(snap: DocumentSnapshot<T>) => void>(
    (snap) => {
      if (!snap?.exists()) return;
      setForm(snap.data());
    },
    [setForm]
  );

  return { form, setForm, updateFormField, reset, onSnapshotChanged };
}
