import { AcadEditDocContainer } from "@components";
import { useWriteDocument } from "@hooks";
import ReferralCode from "@models/referral_code";
import { doc, DocumentReference } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useFirestore } from "reactfire";
import ReferralCodeEditForm from "./ReferralCodeEditForm";
import ReferralUserTable from "./ReferralUserTable";

const ReferralCodeEdit: React.VFC = () => {
  const db = useFirestore();
  const history = useHistory();
  const { code } = useParams<{ code: string }>();
  const docRef = doc(
    db,
    `${ReferralCode.PATH}/${code}`
  ) as DocumentReference<ReferralCode>;

  const { loading, snap, error, writeDocument } =
    useWriteDocument<ReferralCode>({
      docRef,
    });

  const onSubmit = (referralCode: ReferralCode) => {
    if (!referralCode.endDate) {
      delete referralCode.endDate;
    }
    writeDocument(referralCode);
  };
  const { t } = useTranslation("users");

  return (
    <AcadEditDocContainer
      snapshot={snap}
      error={error}
      loading={loading}
      onBackBtn={() => history.push("/users/referrals")}
      doesNotExistText={t("referral_code_does_not_exist")}
      backBtnText={t("back_to_referral_codes")}
    >
      <ReferralCodeEditForm
        docRef={docRef}
        formData={snap?.data() ?? new ReferralCode()}
        onSubmit={onSubmit}
      />

      <ReferralUserTable />
    </AcadEditDocContainer>
  );
};

export default ReferralCodeEdit;
