import { AcadSplitButton, AcadTextField } from "@components";
import ImagePreview from "@components/ImagePreview";
import loadingMessage from "@defs/LoadingMessages";
import useUserData from "@lib/AuthHooks";
import {
  SerializedUserVerificationDocument,
  serializeUserDocument,
} from "@models/user_document";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Dialog,
  DialogContent,
  DialogProps,
  FormControlLabel,
  Stack,
  Switch,
} from "@mui/material";
import { addYears, format } from "date-fns";
import { httpsCallable } from "firebase/functions";
import { MouseEvent, useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useFunctions } from "reactfire";
import { Detail } from "./UserDocumentActionDetail";
import UserDocumentStatus from "./UserDocumentStatus";
import { UserVerifDocWithId } from "./UserDocumentTable";
import { USERDOC_ACTIONS } from "@consts/callables";
import DateTimestampTransformer from "@transformers/date_timestamp_transformer";
import PendingSchoolDialog from "@views/Schools/SchoolListPending/PendingSchoolDialog";
import { useUserDocSchools } from "./UserDocSchoolsCtxProvider";
import { DatePicker } from "@mui/lab";
import { Timestamp } from "firebase/firestore";
import { useTranslation } from "react-i18next";

export interface UserDocCallableProps {
  context: {
    userId: string;
    documentId: string;
    action: "accept" | "reject" | "pending";
    removeVerification: boolean;
  };
  document: SerializedUserVerificationDocument;
}

interface UserDocumentActionDialogProps extends DialogProps {
  document: UserVerifDocWithId;
  onClose: VoidFunction;
}
const UserDocumentActionDialog = ({
  document,
  ...props
}: UserDocumentActionDialogProps) => {
  const fn = useFunctions();
  const { user: userData } = useUserData();
  const { docId, school } = document;
  const [previewState, setPreviewState] = useState(false);
  const [removeVerification, setRemoveVerification] = useState(false);
  const [actionIndex, setActionIndex] = useState(0);
  const { isSchoolPending } = useUserDocSchools();

  const [showVerifyModal, setShowVerifyModal] = useState(false);

  const user = userData?.data();

  const isUserSchoolPending = isSchoolPending(school);

  const { control, register, handleSubmit, setValue, watch, reset } =
    useForm<SerializedUserVerificationDocument>({
      defaultValues: {
        ...serializeUserDocument(document),
        documentExpiryDate: addYears(new Date(), 1),
      },
    });

  const defaultValues = useMemo(() => {
    const { docId, ...newDoc } = document;
    return {
      ...serializeUserDocument(newDoc),
      documentExpiryDate: addYears(new Date(), 1),
    };
  }, [document]);

  useEffect(() => {
    if (!props.open || !reset) return;

    reset(defaultValues);
  }, [props.open, reset, defaultValues]);

  const userDocumentExpiryDate = watch("documentExpiryDate");

  const { t } = useTranslation("student_verification");

  const submit =
    (
      action: "verify" | "reject" | "pending",
      removeVerification: boolean = false
    ) =>
    (e: MouseEvent) => {
      handleSubmit((doc) => {
        const data = {
          ...doc,
          reviewer: user?._username || user?.username || userData?.id,
        } as SerializedUserVerificationDocument;

        switch (action) {
          case "verify":
            if (isUserSchoolPending) {
              return toast.error(t("cannot_verify_student_document"));
            }
            return verifyDocument(data);
          case "reject":
            return rejectDocument(data, removeVerification);
          case "pending":
            return setAsPending(data, removeVerification);
        }
      }, console.error)(e);
    };

  const verifyDocument = (data: SerializedUserVerificationDocument) => {
    Swal.fire({
      title: "Verify Document?",
      icon: "info",
      text: t("verification_expiry", {
        expiryDate: format(
          userDocumentExpiryDate ?? new Date(),
          t("date_format_mmmm", { ns: "common" })
        ),
      }),
      showCancelButton: true,
      customClass: { container: "z-50" },
    }).then((res) => {
      if (res.isConfirmed) {
        const callable = httpsCallable<UserDocCallableProps>(
          fn,
          USERDOC_ACTIONS
        );
        const document: SerializedUserVerificationDocument = {
          ...data,
          submitDate: data.submitDate,
          enrollDate: data.enrollDate,
          documentExpiryDate: data.documentExpiryDate,
          dateReviewed: new Date(),
          verified: true,
          rejected: false,
        };
        const promise = callable({
          context: {
            action: "accept",
            documentId: docId,
            userId: data.owner,
            removeVerification: false,
          },
          document,
        });

        toast.promise(promise, {
          loading: loadingMessage(),
          error: (err) => err.message,
          success: () => {
            props.onClose();
            return t("done", { ns: "common" });
          },
        });
      }
    });
  };

  const rejectDocument = (
    data: SerializedUserVerificationDocument,
    removeVerification: boolean
  ) => {
    Swal.fire({
      title: t("reject_document"),
      icon: "info",
      showCancelButton: true,
      customClass: { container: "z-50" },
      confirmButtonColor: "#B33A3A",
    }).then((res) => {
      if (res.isConfirmed) {
        const callable = httpsCallable<UserDocCallableProps>(
          fn,
          USERDOC_ACTIONS
        );
        const document: SerializedUserVerificationDocument = {
          ...data,
          submitDate: data.submitDate,
          enrollDate: data.enrollDate,
          documentExpiryDate: data.documentExpiryDate,
          dateReviewed: new Date(),
          verified: false,
          rejected: true,
        };
        const promise = callable({
          context: {
            action: "reject",
            documentId: docId,
            userId: data.owner,
            removeVerification,
          },
          document,
        });

        toast.promise(promise, {
          loading: loadingMessage(),
          error: (err) => err.message,
          success: () => {
            props.onClose();
            return t("done", { ns: "common" });
          },
        });
      }
    });
  };
  const setAsPending = (
    data: SerializedUserVerificationDocument,
    removeVerification: boolean
  ) => {
    Swal.fire({
      title: t("set_as_pending_document"),
      icon: "info",
      showCancelButton: true,
      customClass: { container: "z-50" },
    }).then((res) => {
      if (res.isConfirmed) {
        const callable = httpsCallable<UserDocCallableProps>(
          fn,
          USERDOC_ACTIONS
        );
        const document: SerializedUserVerificationDocument = {
          ...data,
          submitDate: data.submitDate,
          enrollDate: data.enrollDate,
          documentExpiryDate: data.documentExpiryDate,
          dateReviewed: new Date(),
          verified: false,
          rejected: false,
        };
        const promise = callable({
          context: {
            action: "pending",
            documentId: docId,
            userId: data.owner,
            removeVerification,
          },
          document,
        });

        toast.promise(promise, {
          loading: loadingMessage(),
          error: (err) => err.message,
          success: () => {
            props.onClose();
            return t("done", { ns: "common" });
          },
        });
      }
    });
  };

  const formatDate = (date?: Timestamp | null) =>
    date ? format(date.toDate(), t("date_format_mmmm", { ns: "common" })) : "-";

  return (
    <Dialog
      className="z-40"
      {...props}
      TransitionProps={{ unmountOnExit: true }}
    >
      <DialogContent>
        <Stack className="space-y-8">
          <Stack className="flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 items-start md:items-center bg-white shadow-md p-4 rounded">
            <Card className="w-full md:w-auto">
              <CardActionArea>
                <Box
                  className="w-full md:w-40 bg-contain bg-center bg-no-repeat bg-gray-200 flex-shrink-0 flex flex-col justify-center items-center"
                  onClick={() => setPreviewState(true)}
                  sx={{
                    backgroundImage: `url("${document.regForm}")`,
                    pb: "164.71%",
                  }}
                ></Box>
              </CardActionArea>
            </Card>
            <div className="grid grid-cols-2 md:grid-cols-2 gap-y-4 w-full md:w-auto md:flex-grow-0 md:gap-x-16">
              <Detail
                label={t("status", { ns: "common" })}
                value={UserDocumentStatus(document)}
              />
              <Detail
                label={t("submit_date")}
                value={formatDate(document.submitDate)}
              />
              <Detail
                label={t("enroll_date")}
                value={formatDate(document.enrollDate)}
              />
              <Detail
                label={t("suggested_verification_expiry")}
                value={formatDate(document.documentExpiryDate)}
              />
              <Detail
                label={t("full_name")}
                value={`${document.firstName} ${document.lastName}`}
              />
              <Detail label={t("school")} value={document.school} />
              <Detail
                label={t("username", { ns: "common" })}
                value={document.username}
              />
              <Detail
                label={t("email", { ns: "common" })}
                value={document.email}
              />
              {document.authorComments && (
                <Detail
                  className="col-span-2"
                  label={t("author_message")}
                  value={document.authorComments}
                />
              )}
            </div>
          </Stack>

          <div className="grid grid-cols-1 gap-y-4">
            <AcadTextField
              {...register("reviewer")}
              label={t("previous_reviewer_alias")}
              disabled
            />
            <Controller
              control={control}
              name="documentExpiryDate"
              render={({ field }) => {
                return (
                  <DatePicker
                    {...field}
                    shouldDisableDate={(date) =>
                      (document?.enrollDate &&
                        date &&
                        document.enrollDate.toDate() > date) ??
                      false
                    }
                    inputFormat={t("date_format_mmmm", { ns: "common" })}
                    onChange={(date) => {
                      setValue(
                        "documentExpiryDate",
                        DateTimestampTransformer.anyToDate(date) ?? new Date()
                      );
                    }}
                    value={DateTimestampTransformer.anyToDate(field.value)}
                    renderInput={(props) => (
                      <AcadTextField
                        {...props}
                        label={t("suggested_verification_expiry")}
                      />
                    )}
                  />
                );
              }}
            />

            <AcadTextField
              {...register("reviewComments")}
              label={t("review_comments")}
              multiline
              minRows={3}
              maxRows={6}
            />
          </div>
          {isUserSchoolPending && (
            <Stack direction="row">
              <Button
                variant="outlined"
                color="info"
                onClick={() => setShowVerifyModal(true)}
              >
                {t("accept_users_pending_school")}
              </Button>
              <PendingSchoolDialog
                open={showVerifyModal}
                pendingSchoolName={document.school}
                uid={document.owner}
                onClose={() => setShowVerifyModal(false)}
              />
            </Stack>
          )}
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            {actionIndex !== 0 && (
              <FormControlLabel
                labelPlacement="start"
                label={t("remove_verification")}
                control={
                  <Switch
                    color="error"
                    checked={removeVerification}
                    onChange={(e) => setRemoveVerification(e.target.checked)}
                  />
                }
              />
            )}
            <AcadSplitButton
              selected={actionIndex}
              setSelected={setActionIndex}
              options={[
                {
                  label: t("verify", { ns: "common" }),
                  onClick: submit("verify"),
                  buttonGroupProps: { color: "primary" },
                },
                {
                  label: t("reject", { ns: "common" }),
                  onClick: submit("reject", removeVerification),
                  buttonGroupProps: { color: "error" },
                },
                {
                  label: t("set_as_pending"),
                  onClick: submit("pending", removeVerification),
                  buttonGroupProps: { color: "inherit" },
                },
              ]}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <ImagePreview
        open={previewState}
        onClose={() => setPreviewState(false)}
        imageUrl={document.regForm}
      />
    </Dialog>
  );
};

export default UserDocumentActionDialog;
