import { Avatar, ButtonBase, Card } from "@mui/material";
interface AcadCardProps {
  onClick?(): void;
  coverPhoto?: string;
  avatar?: string;
  avatarAltText?: string;
}

const AcadCard: React.FC<AcadCardProps> = ({
  onClick,
  coverPhoto,
  avatar,
  avatarAltText,
  children,
}) => {
  return (
    <Card
      className="flex flex-col items-start overflow-hidden  relative w-full"
      elevation={0}
      component={ButtonBase}
      onClick={onClick}
    >
      {coverPhoto && (
        <div
          className="h-40 bg-gray-200 w-full bg-cover bg-center"
          style={{ backgroundImage: `url("${coverPhoto}")` }}
        ></div>
      )}
      <div className="p-4 flex items-center">
        {avatar && (
          <Avatar src={avatar} variant="rounded">
            {avatarAltText}
          </Avatar>
        )}
        {children}
      </div>
    </Card>
  );
};

export default AcadCard;
