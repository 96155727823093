import { useHistory, useLocation } from "react-router-dom";
import { doc, DocumentReference, writeBatch } from "firebase/firestore";
import { useFirestore } from "reactfire";
import { useWriteDocument } from "@hooks";
import { AcadEditDocContainer } from "@components";
import Scholarship from "@models/scholarship";
import ScholarshipInfo from "@models/scholarship_info";
import toast from "react-hot-toast";
import loadingMessage from "@defs/LoadingMessages";
import ScholarshipEditForm from "./ScholarshipEditForm";
import { FileUploadProvider } from "@contexts/file_upload";
import { useTranslation } from "react-i18next";

interface LocationState {
  id: string;
}

const ScholarshipEdit = () => {
  const db = useFirestore();
  const history = useHistory();
  const { state: locationState } = useLocation<LocationState>();
  const scholarshipId = locationState.id;
  const { t } = useTranslation("scholarships");
  // document references
  const scRef = doc(
    db,
    `${Scholarship.PATH}/${scholarshipId}`
  ) as DocumentReference<Scholarship>;
  const scInfoRef = doc(
    db,
    // TODO: should pass variable when getting path so that it won't have to be this hard-coded
    `${Scholarship.PATH}/${scholarshipId}/${ScholarshipInfo.PATH}/${scholarshipId}`
  ) as DocumentReference<ScholarshipInfo>;

  const {
    loading: scInfoLoading,
    snap: scInfoSnap,
    error: scInfoError,
  } = useWriteDocument<ScholarshipInfo>({
    docRef: scInfoRef,
  });

  const onSubmit = (formData: ScholarshipInfo) => {
    // TODO: figure out batch writes with use_write_document. At least
    // make the loading message and toast display more streamlined?
    // For waypoint refactor :)
    const batch = writeBatch(db);
    batch.update(scRef, { ...Scholarship.fromScholarshipInfo(formData) });
    batch.update(scInfoRef, formData);

    toast.promise(batch.commit(), {
      loading: loadingMessage(),
      error: (err) => err.message,
      success: t("saved_successfully", { ns: "common" }),
    });
  };

  const goBack = () => {
    history.push("/scholarship/scholarships");
  };

  return (
    <FileUploadProvider>
      <AcadEditDocContainer
        snapshot={scInfoSnap}
        error={scInfoError}
        loading={scInfoLoading}
        onBackBtn={goBack}
        doesNotExistText={t("scholarship_does_not_exist")}
        backBtnText={t("back_to_scholarships")}
      >
        <ScholarshipEditForm
          scRef={scRef}
          scInfoRef={scInfoRef}
          formData={scInfoSnap?.data() ?? new ScholarshipInfo()}
          onSubmit={onSubmit}
        />
      </AcadEditDocContainer>
    </FileUploadProvider>
  );
};

export default ScholarshipEdit;
