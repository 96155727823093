import Sheet from "@components/Sheet";
import { Prize, TournamentDoc } from "@defs/Tournament.type";

import { Button, ButtonBase, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

import React, { useMemo, useState } from "react";
import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import AddPrize from "./PrizeAdd";
import PrizeEdit from "./PrizeEdit";

export interface PrizeProps {
  setForm: React.Dispatch<React.SetStateAction<TournamentDoc>>;
  form: TournamentDoc;
}

const TournamentPagePrizes = ({ form, setForm }: PrizeProps) => {
  const [open, setState] = useState(false);
  const onClose = () => setState(false);

  const [editState, setEditState] = useState<number | null>(null);

  const { t } = useTranslation("events");

  return (
    <div className="flex flex-col text-black">
      <Sheet className="flex flex-row-reverse w-full">
        <Button variant="outlined" onClick={() => setState(true)}>
          {t("add_prize")}
        </Button>
      </Sheet>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 py-4 justify-stretch gap-4">
        {!Boolean(form.prizes?.length) ? (
          <div className="col-span-full p-4 text-center">
            <Trans
              i18nKey="events:no_prizes_yet_add_prize"
              components={{
                button: <Button onClick={() => setState(true)} />,
              }}
            />
          </div>
        ) : (
          form.prizes?.map((prize, index) => (
            <div key={index} className="h-full pt-6">
              <ButtonBase
                onClick={() => setEditState(index)}
                className="h-full rounded flex flex-col justify-start w-full bg-white shadow overflow-visible relative"
              >
                <PrizeCard {...{ prize, index, form, setForm }} />
              </ButtonBase>
              {index === editState && (
                <PrizeEdit
                  {...{
                    form,
                    setForm,
                    index,
                    open: editState === index,
                    prize,
                    onClose: () => setEditState(null),
                  }}
                />
              )}
            </div>
          ))
        )}
      </div>
      <AddPrize {...{ form, setForm, open, onClose }} />
    </div>
  );
};

interface PrizeCardProps extends PrizeProps {
  prize: Prize;
  index: number;
}

const styles = makeStyles({
  scrollbar: {
    "&::-webkit-scrollbar": {
      height: 8,
      opacity: 0,
      "&-thumb": {
        backgroundColor: "rgba(33, 150, 243, 0.2)",
        borderRadius: 4,
      },
    },

    "&:hover::-webkit-scrollbar-thumb": {
      backgroundColor: "#2196f3",
    },
  },
});

const PrizeCard: FC<PrizeCardProps> = ({ prize }) => {
  const classes = styles();
  const memoizedPrize = useMemo(() => prize, [prize]);

  return (
    <>
      <div className="absolute bg-white p-2 shadow rounded shadowself-center top-0 transform-gpu -translate-y-1/2 z-50 ">
        <div
          className="bg-center bg-contain bg-no-repeat  h-10 w-10 "
          style={{
            backgroundImage: `url("${prize.icon}")`,
          }}
        ></div>
      </div>
      <div
        className="h-40 w-full bg-gray-200 bg-contain bg-center bg-no-repeat rounded-t"
        style={{
          backgroundImage: `url("${memoizedPrize.defaultImage}")`,
        }}
      ></div>
      <div className="flex-1"></div>
      {Boolean(memoizedPrize.items.length) && (
        <div
          className={
            "overflow-x-auto p-2 flex space-x-2 w-full " + classes.scrollbar
          }
        >
          {memoizedPrize.items.map((item, i) => (
            <Tooltip
              key={i}
              title={`${item.name} x${item.count}`}
              placement="top"
            >
              <div
                className="bg-contain bg-center bg-no-repeat h-16 w-16 rounded bg-gray-200 flex-shrink-0"
                style={{ backgroundImage: `url("${item.image}")` }}
              ></div>
            </Tooltip>
          ))}
        </div>
      )}

      {Boolean(memoizedPrize.cash) && (
        <div className="px-4 pt-4 text-left w-full text-lg">
          {memoizedPrize.cash}
        </div>
      )}
      <div className="px-4 py-4 flex flex-col w-full">
        <div className="text-left">{memoizedPrize.name}</div>
        <div className="text-left text-gray-400 text-xs">
          {memoizedPrize.placement}
        </div>
      </div>
    </>
  );
};

export default TournamentPagePrizes;
