/**
 * HIGHER-ORDER COMPONENT
 * the wrapped component ensures that the user is logged in
 */
import LoadingIndicator from "@components/LoadingIndicator";
import { UserData } from "@defs/User";
import { UserContextProps } from "@lib/AuthContext";
import useUserData from "@lib/AuthHooks";
import { QueryDocumentSnapshot } from "firebase/firestore";
import { FunctionComponent } from "react";

type InjectedProps = Omit<
  UserContextProps,
  "user" | "username" | "loading" | "claimsLoading"
> & {
  user: QueryDocumentSnapshot<UserData>;
};

function withUser<T>(ReactComponent: FunctionComponent<T & InjectedProps>) {
  return (props: T) => {
    const userData = useUserData();
    const { loading, user, isAdmin, isGlobalMod, isSuperAdmin, roles, claims } =
      userData;

    return loading || !user || !user.exists() ? (
      <LoadingIndicator />
    ) : (
      <ReactComponent
        {...{
          ...props,
          user,
          isAdmin,
          isGlobalMod,
          isSuperAdmin,
          roles,
          claims,
        }}
      />
    );
  };
}

export default withUser;
