import TextField from "@components/MemoizedTextfield";
import { CustomFieldPrizeCategory, Prize } from "@defs/Tournament.type";
import { DialogProps } from "@defs/Types";
import { onFileSelect, Files } from "@lib/file";
import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { Add, Save, DeleteForever } from "@mui/icons-material";
import equal from "fast-deep-equal";
import { useState, ChangeEvent } from "react";
import { PrizeProps } from ".";
import { Container } from "../TournamentEdit";
import { PrizeItemComponent } from "./PrizeAdd";
import PrizeIconPopup from "./PrizeIconPopup";
import { useTranslation, Trans } from "react-i18next";

interface PrizeEditProps extends DialogProps, PrizeProps {
  prize: Prize;
  index: Number;
}

const PrizeEdit = ({
  form,
  setForm,
  open,
  onClose,
  index,
  prize,
}: PrizeEditProps) => {
  const [prizeInput, setPrize] = useState<Prize>(prize);
  const [files, setFiles] = useState<Files<Prize>>({});
  const [state, setState] = useState(false);

  const smUp = useMediaQuery("(min-width: 768px)");

  const { t } = useTranslation("events");

  const handleText = (e: ChangeEvent<HTMLInputElement>) =>
    setPrize((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));

  const save = () => {
    setForm((state) => ({
      ...state,
      prizes: state.prizes?.map((p, i) =>
        equal(p, prize) && i === index ? prizeInput : p
      ),
    }));
    onClose();
    setFiles({});
  };
  const deletePrize = () => {
    setForm((state) => ({
      ...state,
      prizes: state.prizes?.filter((p, i) => i !== index),
    }));
    onClose();
    setFiles({});
  };

  const addItem = () =>
    setPrize((state) => ({
      ...state,
      items: [
        ...state.items,
        { count: 1, name: "Item Name", description: "", image: "", url: "" },
      ],
    }));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={!smUp}
    >
      <DialogTitle>{t("add_prize")}</DialogTitle>
      <DialogContent>
        <div className="grid grid-cols-1 pt-2 gap-4">
          <div className="w-full flex justify-center">
            <ButtonBase
              component="label"
              style={{ aspectRatio: "16/9" }}
              className="w-full bg-gray-200 rounded-md flex flex-col justify-center items-center text-center text-sm bg-center bg-contain bg-no-repeat"
              sx={{
                backgroundImage: `url("${
                  files.defaultImage?.path || prizeInput.defaultImage
                }")`,
              }}
            >
              <input
                hidden
                type="file"
                accept="image/*"
                name="defaultImage"
                onChange={onFileSelect(setFiles, setPrize)}
              />
              {!files.defaultImage && !prizeInput.defaultImage && (
                <>
                  <Trans
                    i18nKey="events:click_here_to_upload_default_thumnail"
                    components={{
                      span: <span />,
                    }}
                  />
                </>
              )}
            </ButtonBase>
          </div>

          <div className="w-full flex space-x-2">
            <ButtonBase
              component="div"
              className="w-16 bg-gray-200 shadow-sm flex justify-center items-center leading-none rounded bg-contain bg-center bg-no-repeat"
              style={{ backgroundImage: `url("${prizeInput.icon}")` }}
              onClick={() => setState(true)}
            >
              {!!prizeInput.icon || t("icon", { ns: "common" })}
            </ButtonBase>

            <TextField
              value={prizeInput.placement}
              label={t("placement")}
              onChange={handleText}
              name="placement"
              required
              fullWidth
              placeholder={t("placement_placeholder")}
            />
          </div>
          <TextField
            value={prizeInput.name}
            label={t("prize_name")}
            name="name"
            required
            placeholder={t("prize_name_placeholder")}
            onChange={handleText}
          />
          <TextField
            value={prizeInput.cash}
            label={t("cash_prize")}
            name="cash"
            placeholder={t("cash_prize_placeholder")}
            onChange={handleText}
            helperText={t("cash_prize_helper_text")}
          />
          <FormControl variant="outlined">
            <InputLabel>{t("category")}</InputLabel>
            <Select
              label="Category"
              value={prizeInput.category}
              onChange={(e) =>
                setPrize((state) => ({
                  ...state,
                  category:
                    (e.target.value as CustomFieldPrizeCategory) ?? "primary",
                }))
              }
            >
              <MenuItem value="primary">{t("primary")}</MenuItem>
              <MenuItem value="secondary">{t("secondary")}</MenuItem>
              <MenuItem value="giveaway">{t("giveaway")}</MenuItem>
            </Select>
          </FormControl>

          <div className="text-lg mt-4 px-2">{t("items")}</div>
          <Container dark>
            <div className="col-span-full grid grid-cols-1 gap-4">
              {prizeInput.items.map((item, index) => (
                <PrizeItemComponent
                  key={index}
                  tournamentId={form.id ?? ""}
                  {...{ setPrize, index, item }}
                />
              ))}
              <Button
                variant="contained"
                color="inherit"
                fullWidth
                className="h-20 shadow-md"
                startIcon={<Add />}
                onClick={addItem}
              >
                {t("add_item")}
              </Button>
            </div>
          </Container>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t("close", { ns: "common" })}</Button>
        <Button
          variant="contained"
          onClick={deletePrize}
          color="error"
          startIcon={<DeleteForever />}
        >
          {t("delete_prize")}
        </Button>
        <Button
          variant="contained"
          onClick={save}
          startIcon={<Save />}
          disabled={!prizeInput.name || !prizeInput.placement}
        >
          {t("save", { ns: "common" })}
        </Button>
      </DialogActions>
      <PrizeIconPopup
        open={state}
        onClose={() => setState(false)}
        setPrize={setPrize}
      />
    </Dialog>
  );
};

export default PrizeEdit;
