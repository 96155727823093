import loadingMessage from "@defs/LoadingMessages";
import { ChalTourRes } from "@defs/Tournament.type";
import { Refresh } from "@mui/icons-material";
import { Button, Card, Stack, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useContext } from "react";
import toast from "react-hot-toast";
import { useFunctions } from "reactfire";
import Swal from "sweetalert2";
import { seriesContext } from ".";
import StartIcon from "@mui/icons-material/Start";
import { useTranslation } from "react-i18next";

interface CallableProps {
  fireTourId: string;
}

const Actions = () => {
  const fn = useFunctions();
  const { tournament, refreshIframe } = useContext(seriesContext);
  const fireTourId = tournament?.id ?? "";

  const { t } = useTranslation("events");

  const refresh = () => {
    const callable = httpsCallable<CallableProps, ChalTourRes>(
      fn,
      "tournamentSeries-refresh"
    );
    const promise = callable({ fireTourId });
    toast.promise(promise, {
      loading: "Refreshing tournament",
      error: (err) => err.message,
      success: () => {
        return "Done";
      },
    });
  };

  const reset = () => {
    Swal.fire({
      title: t("reset_tournament_question"),
      text: t("this_will_reset_all_matches"),
      showConfirmButton: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        callResetFn();
      }
    });
  };

  const startTournament = () => {
    Swal.fire({
      title: t("start_tournament_question"),
      text: t("no_longer_able_to_add_participants_after_starting"),
      showConfirmButton: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        const callable = httpsCallable<CallableProps>(fn, "tournament-start");
        const promise = callable({
          fireTourId: tournament?.id ?? "",
        });

        toast.promise(promise, {
          loading: loadingMessage(),
          error: (err) => {
            console.error(err);
            return err.message;
          },
          success: t("done", { ns: "common" }),
        });
      }
    });
  };

  const callResetFn = () => {
    const callable = httpsCallable<CallableProps>(fn, "tournament-reset");
    const promise = callable({ fireTourId });
    toast.promise(promise, {
      loading: loadingMessage(),
      error: (err) => {
        console.error(err);
        return err.message;
      },
      success: () => {
        refreshIframe();
        return t("done", { ns: "common" });
      },
    });
  };

  const shuffleParticipants = () => {
    Swal.fire({
      title: t("shuffle_participants_question"),
      showConfirmButton: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        const callable = httpsCallable<CallableProps>(
          fn,
          "tournamentParticipant-randomize"
        );
        const promise = callable({
          fireTourId: tournament?.id ?? "",
        });

        toast.promise(promise, {
          loading: loadingMessage(),
          error: (err) => {
            console.error(err);
            return err.message;
          },
          success: () => {
            refreshIframe();
            return t("done", { ns: "common" });
          },
        });
      }
    });
  };

  const clearParticipants = () => {
    Swal.fire({
      title: t("clear_participants_question"),
      text: t("the_participants_will_be_demoted_to_registrants"),
      showConfirmButton: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        const callable = httpsCallable<CallableProps>(
          fn,
          "tournamentParticipant-clear"
        );
        const promise = callable({ fireTourId });
        toast.promise(promise, {
          loading: loadingMessage(),
          error: (err) => {
            console.error(err);
            return err.message;
          },
          success: () => {
            refreshIframe();
            return t("done", { ns: "common" });
          },
        });
      }
    });
  };

  return (
    <Card sx={{ p: 3 }}>
      <Stack gap={1}>
        <Typography variant="h6">{t("actions")}</Typography>
        <Stack direction="row" justifyContent="space-between">
          <Stack gap={2} direction="row">
            {!tournament?.tournament.started_at && (
              <>
                <Button
                  variant="contained"
                  startIcon={<StartIcon />}
                  onClick={startTournament}
                >
                  {t("start")}
                </Button>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={shuffleParticipants}
                >
                  {t("shuffle")}
                </Button>
              </>
            )}

            <Button
              variant="contained"
              color="inherit"
              startIcon={<Refresh />}
              onClick={refresh}
            >
              {t("refresh")}
            </Button>
          </Stack>
          <Stack gap={2} direction="row">
            {!tournament?.tournament.started_at && (
              <Button
                variant="contained"
                color="error"
                onClick={clearParticipants}
              >
                {t("clear_participants")}
              </Button>
            )}
            {!!tournament?.tournament.started_at && (
              <Button variant="contained" color="error" onClick={reset}>
                {t("reset", { ns: "common" })}
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default Actions;
