// icons
import { Icon, IconifyIcon } from "@iconify/react";
// @mui
import { Box, BoxProps } from "@mui/material";

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  icon: IconifyIcon | string;
}

export default function Iconify({ icon, ...other }: Props) {
  return <Box component={Icon} icon={icon} {...other} />;
}
