export enum SignUpStatus {
  SignedUp = "S",
  Accepted = "A",
  Rejected = "R",
}

export const SignUpStatusMap: Record<SignUpStatus, string> = {
  [SignUpStatus.SignedUp]: "signed_up_pending",
  [SignUpStatus.Accepted]: "accepted",
  [SignUpStatus.Rejected]: "rejected",
};
export interface AcademyCbSignUpData {
  readonly PATH?: string;
  uid: string;
  status: SignUpStatus;
}
