export const ingressYearMin = 2000;
export const noValStudentYear = -1;
export const noValIngressYear = 0;

export default function useStudentYear() {
  const getIngressYearChoices = () => {
    const arr = [];
    let i = ingressYearMin;
    while (i <= new Date().getFullYear()) {
      arr.push(i);
      i++;
    }
    return arr;
  };

  return { getIngressYearChoices };
}
