import { AcadImageButton, AcadTextField } from "@components";
import Sheet from "@components/Sheet";
import AcadConsts from "@consts";
import SEO from "@models/seo";
import { ChangeEvent, useState } from "react";
import { DeepMap, DeepPartial, FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface AcadSEOProps {
  fieldName?: string;
  className?: string;
  onChange?(value: SEO): void;
  value?: SEO;
  errors?: DeepMap<DeepPartial<any>, FieldError>;
}

/**
 * Specify fieldname of model this seo object is attached to.
 * Default is "seo".
 * @param param0
 * @returns
 */
const AcadSEO = ({
  fieldName = "seo",
  onChange = AcadConsts.noop,
  value,
  className = "",
  errors,
}: AcadSEOProps) => {
  const { t } = useTranslation("components");
  const [seo, setSeo] = useState(value ?? new SEO());

  const updateField =
    (name: string) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      const tempSeo = { ...seo, [name]: value };
      setSeo(tempSeo);
      onChange(tempSeo);
    };

  return (
    <Sheet className={className}>
      <div className="w-full grid grid-cols-12 gap-4">
        <AcadTextField
          className="col-span-full"
          value={seo?.title || ""}
          onChange={updateField("title")}
          name={`${fieldName}.title`}
          label={t("title_optional")}
          errors={errors}
        />
        <AcadImageButton
          name={`${fieldName}.thumbnail`}
          path={seo.thumbnail}
          className="col-span-4 h-40 bg-gray-200 bg-cover bg-center rounded-md"
          noImageText={t("upload_thumbnail")}
        />
        <AcadTextField
          multiline
          rows={6}
          className="col-span-8"
          label={t("description")}
          name={`${fieldName}.description`}
          value={seo.description}
          onChange={updateField("description")}
          inputProps={{ maxLength: 140 }}
          errors={errors}
        />
      </div>
    </Sheet>
  );
};
export default AcadSEO;
