import { ReadOnlySchools } from "@views/Schools/SchoolList";
import { doc, DocumentReference } from "firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";

export default function useSchoolList() {
  const ref = doc(
    useFirestore(),
    "read_only",
    "schools"
  ) as DocumentReference<ReadOnlySchools>;

  const { data } = useFirestoreDocData(ref);

  const schools = data?.schools ?? [];
  return { schools };
}
