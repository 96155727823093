import { httpsCallable } from "firebase/functions";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import loadingMessage from "@defs/LoadingMessages";
import AcadHotLink, { AcadHotLinkProps } from "@components/AcadHotLink";
import { doc } from "firebase/firestore";
import { useFirestore, useFirestoreDoc, useFunctions } from "reactfire";
import { useTranslation } from "react-i18next";

const AdminView = () => {
  const functions = useFunctions();
  const db = useFirestore();

  const p = `read_only/schools`;
  const r = doc(db, p);
  const { data: schools, status, error: err } = useFirestoreDoc(r);
  const loading = status === "loading";

  const router = useHistory();
  const { t } = useTranslation("dashboard");

  const initSchools = () => {
    const promise = httpsCallable(functions, "init-schools")();
    toast.promise(promise, {
      loading: loadingMessage(),
      error: (err) => err.message,
      success: t("done"),
    });
  };

  const goto = (url: string) => () => {
    router.push(url);
  };

  const hotLinks: AcadHotLinkProps[] = [
    {
      title: t("front_page"),
      icon: "📄",
      onClick: goto("/frontpage"),
    },
    {
      title: t("community"),
      icon: "🔥",
      onClick: goto("/community"),
    },
    {
      title: t("events"),
      icon: "🏆",
      onClick: goto("/events/tournaments"),
    },
    {
      title: t("boostcamp"),
      icon: "🚀",
      onClick: goto("/boostcamp"),
    },
    {
      title: t("schools"),
      icon: "🏫",
      onClick: goto("/schools"),
    },
    {
      title: t("users"),
      icon: "👾",
      onClick: goto("/users"),
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-4">
      {!schools?.exists() && !loading && !err && (
        <AcadHotLink
          icon="⚡"
          title={t("initialize_schools")}
          description={t("this_will_initialize_list_of_schools")}
          onClick={initSchools}
        />
      )}
      {hotLinks.map((hl, i) => (
        <AcadHotLink {...hl} key={i} />
      ))}
    </div>
  );
};

export default AdminView;
