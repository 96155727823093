import acadJoi from "@acad_joi";
import { PartialSchemaMap } from "joi";

export default class SEO {
  title?: string;
  description: string = "";
  thumbnail: string = "";

  static schemaMap: PartialSchemaMap<SEO> = {
    title: acadJoi.string().label("SEO Title").max(100),
    description: acadJoi.string().label("SEO Description").allow("").max(160),
    thumbnail: acadJoi.string().label("SEO Thumbnail").allow(""),
  };

  static schema = acadJoi.object<SEO>(SEO.schemaMap);
}

export class SEOExtend {
  SEO: SEO = new SEO();
}
