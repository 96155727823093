import { TextField } from "@mui/material";
import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  useMediaQuery,
} from "@mui/material";
import { doc, DocumentReference, updateDoc } from "firebase/firestore";
import { ChangeEvent, useState } from "react";
import { FormEvent } from "react";
import toast from "react-hot-toast";
import { FrontPage } from "@defs/Frontpage.type";
import loadingMessage from "@defs/LoadingMessages";
import { DialogProps } from "@defs/Types";
import { onFileSelect, Files } from "@lib/file";
import { useFirestore } from "reactfire";
import { CarouselSlide } from "@defs/carousel_slide";
import { useTranslation } from "react-i18next";
interface CarouselCreateProps extends DialogProps {
  frontPage: FrontPage;
}

const CarouselCreate = ({ open, onClose, frontPage }: CarouselCreateProps) => {
  const db = useFirestore();
  const [form, setForm] = useState<CarouselSlide>(new CarouselSlide());

  const [files, setFiles] = useState<Files<CarouselSlide>>({});
  const smUp = useMediaQuery("(min-width: 640px)");

  const { t } = useTranslation("frontpage");

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const ref = doc(db, "public/frontpage") as DocumentReference<FrontPage>;
    const promise = updateDoc(ref, { carousel: [...frontPage.carousel, form] });

    toast.promise(promise, {
      loading: loadingMessage(),
      error: (err) => err.message,
      success: () => {
        onClose();
        return t("successfully_added_a_slide");
      },
    });
  };

  const handleText = (e: ChangeEvent<HTMLInputElement>) => {
    setForm((f) => ({ ...f, [e.target.name]: e.target.value }));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Grow}
      maxWidth="sm"
      fullWidth
      fullScreen={!smUp}
    >
      <DialogTitle>{t("add_slide_in_carousel")}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent className="grid grid-cols-12 gap-4">
          <ButtonBase
            component="label"
            className="shadow-md h-56 col-span-full bg-white rounded-md self-center sm:self-start bg-cover bg-center flex justify-center items-center"
            sx={{
              backgroundImage: `url("${files?.image?.path || form.image}")`,
            }}
          >
            <input
              hidden
              type="file"
              name="image"
              accept="image/*"
              onChange={onFileSelect(setFiles, setForm)}
            />
            {files.image?.path ? "" : form?.image ? "" : "+ Add Image"}
          </ButtonBase>

          <TextField
            name="title"
            value={form.title}
            onChange={handleText}
            variant="outlined"
            label={t("title", { ns: "common" })}
            className="col-span-full"
          />
          <TextField
            multiline
            minRows={4}
            maxRows={8}
            name="description"
            value={form.description}
            onChange={handleText}
            variant="outlined"
            label={t("description", { ns: "common" })}
            className="col-span-full"
          />
          <TextField
            name="url"
            value={form.url}
            onChange={handleText}
            variant="outlined"
            label={t("url", { ns: "common" })}
            className="col-span-full"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit">
            {t("submit", { ns: "common" })}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CarouselCreate;
