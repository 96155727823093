import { LeagueSnap } from "@models/league";
import { Avatar, Card, CardActionArea, CardHeader } from "@mui/material";
import { useHistory } from "react-router";

interface LeagueCardProps {
  league: LeagueSnap;
}

const LeagueCard = ({ league }: LeagueCardProps) => {
  const history = useHistory();

  const onClick = () => {
    history.push(`/events/leagues/${league.id}`);
  };
  return (
    <Card elevation={0}>
      <CardActionArea onClick={onClick}>
        <CardHeader
          avatar={
            <Avatar src={league.data().logo}>{league.data().title[0]}</Avatar>
          }
          title={league.data().title}
          titleTypographyProps={{
            fontSize: 16,
            className: "leading-none truncate",
          }}
          sx={{ p: 2 }}
          subheader={league.data().hostName}
          subheaderTypographyProps={{
            fontSize: 12,
            className: "leading-1 truncate",
          }}
        />
      </CardActionArea>
    </Card>
  );
};

export default LeagueCard;
