import { useFirestore, useFirestoreDoc } from "reactfire";
import {
  collectionGroup,
  doc,
  Query,
  query,
  QueryConstraint,
  where,
} from "firebase/firestore";
import { usePaginatedCollection } from "@hooks";
import { UserVerificationDocument } from "@models/user_document";
import { MouseEvent, Reducer, useReducer, useState } from "react";
import UserDocumentTable, {
  UserVerifDocWithId,
} from "../components/UserDocumentTable";
import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import VerifyFilter, { VerifyFilters } from "../components/VerifyFilter";
import { useTranslation } from "react-i18next";

type CallBack = (q: Query) => any;
type QueryAction = "queryPending" | "queryVerified" | "queryRejected";
type Action = { type: QueryAction; callback?: CallBack };

type State = {
  ref: Query<UserVerificationDocument>;
  query: Query<UserVerificationDocument>;
  mode: QueryAction;
};

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case "queryPending": {
      const q = query(
        state.ref,
        where("verified", "==", false),
        where("rejected", "==", false)
      );
      action.callback?.(q);
      return {
        ...state,
        query: q,
        mode: action.type,
      };
    }
    case "queryVerified": {
      const q = query(state.ref, where("verified", "==", true));
      action.callback?.(q);
      return {
        ...state,
        query: q,
        mode: action.type,
      };
    }
    case "queryRejected": {
      const q = query(state.ref, where("rejected", "==", true));
      action.callback?.(q);
      return {
        ...state,
        query: q,
        mode: action.type,
      };
    }
    default: {
      return state;
    }
  }
};

const DocumentsDirectory = () => {
  const db = useFirestore();

  const { data: pendingCountSnap } = useFirestoreDoc(
    doc(db, "read_only/verification")
  );

  const collectionRef = collectionGroup(
    db,
    "user_documents"
  ) as Query<UserVerificationDocument>;
  const [state, dispatch] = useReducer(reducer, {
    ref: collectionRef,
    query: query(
      collectionRef,
      where("verified", "==", false),
      where("rejected", "==", false)
    ),
    mode: "queryPending",
  });

  const [filterConstraints, setFilterConstraints] = useState<QueryConstraint[]>(
    []
  );

  const {
    data,
    next,
    nextDisabled,
    prev,
    prevDisabled,
    update,
    cursor,
    limit,
  } = usePaginatedCollection({
    query: state.query,
    limit: 20,
    orderBy: {
      direction: "asc",
      field: "submitDate",
    },
  });

  const docs: UserVerifDocWithId[] = (data?.docs ?? []).map((doc) => ({
    ...doc.data(),
    docId: doc.id,
  }));

  const newQuery = async (e: MouseEvent, action: QueryAction) => {
    dispatch({
      type: action,
      callback: (q) => {
        // apply filter constraints on query
        if (q && update) {
          const finalQ = query(q, ...filterConstraints);
          update?.(finalQ);
        }
      },
    });
  };

  const filter = ({ username, school }: VerifyFilters) => {
    const newFilters = [
      username && where("username", "==", username.toLocaleLowerCase()),
      school && where("_school", "==", school.toLocaleLowerCase()),
    ].filter(Boolean) as QueryConstraint[];

    setFilterConstraints(newFilters);
    const q = query(state.query, ...newFilters);
    update?.(q);
  };

  const { t } = useTranslation("student_verification");

  return (
    <Stack gap={2}>
      <Box>
        <Typography variant="subtitle2">
          {t("pending_verification_docs_left_count", {
            pendingCount: pendingCountSnap?.data()?.pendingCount,
          })}
        </Typography>
      </Box>
      <ToggleButtonGroup
        className="text-sm"
        value={state.mode}
        exclusive
        onChange={newQuery}
      >
        <ToggleButton value={"queryPending"}>{t("pending")}</ToggleButton>
        <ToggleButton value={"queryVerified"}>{t("verified")}</ToggleButton>
        <ToggleButton value={"queryRejected"}>{t("rejected")}</ToggleButton>
      </ToggleButtonGroup>
      <VerifyFilter onFilter={filter} />
      <UserDocumentTable
        {...{
          cursor,
          data: docs,
          disableNext: nextDisabled,
          disablePrev: prevDisabled,
          next,
          orderBy: "submitDate",
          prev,
          rowsPerPage: limit,
        }}
      />
    </Stack>
  );
};

export default DocumentsDirectory;
