import { AcadDirectoryContainer } from "@components";
import { useTranslation } from "react-i18next";
import GameCreate from "./GameCreate";
import GamesDirectory from "./GameDirectory";

export default function Games() {
  const { t } = useTranslation("games");
  return (
    <AcadDirectoryContainer
      title={t("emoji_games_title")}
      directoryTitle={t("games_directory")}
      createBtnTitle={t("add_game")}
      createModal={<GameCreate />}
    >
      <GamesDirectory />
    </AcadDirectoryContainer>
  );
}
