import { AcadDirectoryContainer } from "@components";
import { useTranslation } from "react-i18next";
import RequirementCreate from "./RequirementCreate";
import RequirementDirectory from "./RequirementDirectory";

export default function Requirements() {
  const { t } = useTranslation("scholarships");
  return (
    <AcadDirectoryContainer
      title={t("emoji_requirements_title")}
      directoryTitle={t("requirements_directory")}
      createBtnTitle={t("add_requirement")}
      createModal={<RequirementCreate />}
    >
      <RequirementDirectory />
    </AcadDirectoryContainer>
  );
}
