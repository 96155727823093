import { VFC } from "react";
import { useHistory } from "react-router-dom";
import { AcadCard } from "@components";
import Scholarship from "@models/scholarship";

const ScholarshipCard: VFC<{ scholarship: Scholarship }> = ({
  scholarship,
}) => {
  const history = useHistory();
  return (
    <AcadCard
      onClick={() =>
        history.push({
          pathname: `/scholarship/scholarships/${scholarship.slug}`,
          state: {
            id: scholarship.id,
          },
        })
      }
    >
      <div className="flex flex-col pl-4">
        <div className="text-md leading-none text-left">{scholarship.name}</div>
        <div className="text-xs leading-none text-left opacity-40">
          {scholarship.slug}
        </div>
      </div>
    </AcadCard>
  );
};

export default ScholarshipCard;
