import { LinkProps } from "@defs/Links";
import { useRegion } from "@hooks/use_region";
import { useUserContext } from "@lib/AuthContext";
import { regionRoleLock } from "@lib/regionRoleLock";
import { Tab, Tabs } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import AuthGuard from "./AuthGuard";

interface AcadNavTabsProps {
  routes: LinkProps[];
  onChange?(value: number): void;
}

/**
 * A reusable tab navigator. Simply provide routes, this component will do the rest
 * @param param0
 * @returns
 */
const AcadNavTabs: React.FC<AcadNavTabsProps> = ({
  routes,
  onChange,
}: AcadNavTabsProps) => {
  const history = useHistory();
  const { isAdmin } = useUserContext();
  const { region } = useRegion();
  const navTabs = routes.filter(
    (route) => route.onNavbar && (route.adminOnly ? isAdmin : true)
  );
  const { t } = useTranslation("routes");

  const getCurrentTab = useCallback((): number => {
    const index =
      navTabs.findIndex((route) =>
        history.location.pathname.startsWith(route.url)
      ) ?? 0;
    return index < 0 ? 0 : index;
  }, [navTabs, history]);

  const [value, setValue] = useState(getCurrentTab());

  const listenToHistory = useCallback(() => {
    return history.listen(() => {
      setValue(getCurrentTab());
    });
  }, [history, setValue, getCurrentTab]);

  // listen to history change. when history changes, so does the currently selected tab
  useEffect(() => {
    return listenToHistory();
  }, [listenToHistory]);

  function tabs(): JSX.Element[] {
    return navTabs.map((route, i) => (
      <Tab
        key={`${route.title}-tab-${i}`}
        label={t("route_icon_title", {
          icon: route.icon,
          title: t(route.title),
        })}
        onClick={() => {
          if (onChange) {
            onChange(i);
          }
          history.push(route.url);
        }}
      />
    ));
  }

  return (
    <>
      <Tabs value={value}>{tabs()}</Tabs>

      <Switch>
        {routes.map((route) => (
          <Route key={route.url} path={route.url} exact={route.exact}>
            {route.authenticated || route.superAdminOnly || route.adminOnly ? (
              <AuthGuard
                requireSuperAdmin={route.superAdminOnly}
                requireAdmin={route.adminOnly}
                regionRoleLocked={regionRoleLock(route, region, isAdmin)}
              >
                {route.component}
              </AuthGuard>
            ) : (
              route.component
            )}
          </Route>
        ))}

        {/* default route is first route unless filteredRoutes is empty */}
        <Route path="*" exact>
          {navTabs.length === 0 ? (
            <div>{t("no_routes_to_display")}</div>
          ) : (
            <Redirect to={navTabs[0].url} />
          )}
        </Route>
      </Switch>
    </>
  );
};

export default AcadNavTabs;
