import { Alert, Skeleton, useMediaQuery } from "@mui/material";
import { Suspense, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import AppBar from "./components/appbar/AppBar";
import SideBar from "./components/SideBar";
import Error from "./components/Error";
import { links, OtherRoutes } from "./defs/Links";
import { userContext } from "./lib/AuthContext";
import useUserData from "./lib/AuthHooks";

function App() {
  const [drawerState, setDrawerState] = useState(false);
  const user = useUserData();
  const lg = useMediaQuery("(min-width: 1024px)");
  useEffect(() => {
    if (!lg) return;
    setDrawerState(false);
  }, [lg]);

  return (
    <Suspense fallback="Loading...">
      <userContext.Provider value={user}>
        <div className="min-w-screen min-h-screen bg-gray-50">
          <AppBar {...{ drawerState, setDrawerState }} />
          <div className="w-full max-w-8xl mx-auto">
            {/* <div className="w-full mx-auto"> */}
            <div className="lg:flex">
              <SideBar></SideBar>
              <div
                id="content-wrapper"
                className={`
                  z-30
                  relative
                  shadow-lg
                  lg:shadow-none
                  min-h-screen
                  lg:h-auto
                  bg-gray-50
                  transition-all
                  min-w-0
                  w-full
                  flex-auto
                  lg:static
                  lg:max-h-full
                  lg:overflow-visible
                  px-4
                  py-5
                  lg:px-6
                  xl:px-8
                  ${drawerState ? "transform translate-x-64" : ""}`}
              >
                {/* Routing */}
                <Switch>
                  <Redirect path="/" exact to="/dashboard" />
                  {/* Sidebar Routes */}
                  {links.map((link) =>
                    link.adminOnly && !link.superAdminOnly ? (
                      <Route
                        key={link.url}
                        path={`/${link.url}`}
                        exact={link.exact}
                      >
                        {user.loading ? (
                          <Skeleton
                            animation="wave"
                            className="w-full"
                          ></Skeleton>
                        ) : user.isAdmin ? (
                          link.component
                        ) : (
                          <Alert severity="warning">Permission denied</Alert>
                        )}
                      </Route>
                    ) : link.superAdminOnly ? (
                      <Route
                        key={link.url}
                        path={`/${link.url}`}
                        exact={link.exact}
                      >
                        {user.loading ? (
                          <Skeleton
                            animation="wave"
                            className="w-full"
                          ></Skeleton>
                        ) : user.isSuperAdmin ? (
                          link.component
                        ) : (
                          <Alert severity="warning">Permission denied</Alert>
                        )}
                      </Route>
                    ) : (
                      <Route
                        key={link.url}
                        path={`/${link.url}`}
                        exact={link.exact}
                      >
                        {link.component}
                      </Route>
                    )
                  )}

                  {/* Other Routes */}
                  {OtherRoutes.map((r) => (
                    <Route key={r.url} path={`/${r.url}`}>
                      {r.component}
                    </Route>
                  ))}
                  <Route path="*" exact>
                    <Error title="Page Not Found" />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
          <Toaster
            position="bottom-left"
            toastOptions={{
              style: {
                borderRadius: "1000px",
                background: "#333",
                color: "#fff",
              },
            }}
          />
        </div>
      </userContext.Provider>
    </Suspense>
  );
}

export default App;
