import { Participant } from "@defs/Participant.type";
import {
  Grow,
  ButtonBase,
  Checkbox,
  Button,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { QueryDocumentSnapshot } from "firebase/firestore";
import { useState } from "react";
import { AcadSelect } from "@components/select";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useRegion } from "@hooks/use_region";

interface Props {
  teams: QueryDocumentSnapshot<Participant>[];
  selected: string[];
  updateSelect: (arg: string) => () => void;
  save: VoidFunction;
  buttonText: string;
  headerText: string;
}

const ParticipantList = ({
  teams,
  save,
  selected,
  updateSelect,
  buttonText,
  headerText,
}: Props) => {
  const { t } = useTranslation("events");
  const [order, setOrder] = useState<string>("ascending");
  const { control } = useForm();
  const { locale } = useRegion();
  const onChange = ({ target: { value } }: SelectChangeEvent<string>) => {
    setOrder(value);
  };

  const sortedTeams = Object.values(teams).sort((teamA, teamB) => {
    if (order === "descending") {
      return (
        teamB.data().createdAt.toMillis() - teamA.data().createdAt.toMillis()
      );
    }
    return (
      teamA.data().createdAt.toMillis() - teamB.data().createdAt.toMillis()
    );
  });

  return (
    <div className="flex flex-col w-full my-4">
      <Stack gap={2} direction="row" alignItems="center">
        <div className="text-xl">{headerText}</div>
        <AcadSelect
          control={control}
          name="order"
          label={t("order_teams")}
          onChange={onChange}
          defaultValue="ascending"
        >
          <MenuItem value="ascending">{t("ascending")}</MenuItem>
          <MenuItem value="descending">{t("descending")}</MenuItem>
        </AcadSelect>
        <Grow in={Boolean(selected.length)}>
          <Button variant="contained" onClick={save} color="error">
            {buttonText}
          </Button>
        </Grow>
      </Stack>
      <div className="p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
        {sortedTeams.map((registrant) => (
          <ButtonBase
            key={registrant.id}
            onClick={updateSelect(registrant.id)}
            className="flex flex-col rounded-lg overflow-hidden bg-white shadow-sm relative"
          >
            <Grow in={selected.includes(registrant.id)}>
              <Checkbox
                size="medium"
                checked
                className="absolute top-1 right-1"
              />
            </Grow>
            <div
              className="h-40 w-full bg-gray-200 bg-center bg-no-repeat"
              style={{
                backgroundImage: `url("${registrant.data().logo}")`,
                backgroundSize: "auto 85%",
              }}
            ></div>
            <div className="p-3">{registrant.data().name}</div>
            <Typography variant="caption">
              {t("registered_date_time", {
                date: registrant
                  .data()
                  .createdAt.toDate()
                  .toLocaleDateString(locale),
                time: registrant
                  .data()
                  .createdAt.toDate()
                  .toLocaleTimeString(locale),
              })}
            </Typography>
          </ButtonBase>
        ))}
      </div>
    </div>
  );
};

export default ParticipantList;
