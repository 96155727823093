import acadJoi from "@acad_joi";
import { CarouselSlide } from "@defs/carousel_slide";
import { CommonPageParams } from "./common_page_params";

const { id, ...CommunitySlideSchema } = CarouselSlide.schemaMap;
export type CommunitySlide = Omit<CarouselSlide, "id">;
export class CommunityParams extends CommonPageParams {
  static readonly PATH: string = "syspars/Community";
  slides: CommunitySlide[] = [];

  static schema = acadJoi.object<CommunityParams>({
    ...CommonPageParams.schemaMap,
    slides: acadJoi.array().items(CommunitySlideSchema),
  });
}
