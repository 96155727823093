import OrgCard from "@views/Community/Directory/OrgCard";
import { DialogProps, OrgProps } from "@defs/Types";
import {
  Button,
  DialogActions,
  DialogContent,
  LinearProgress,
  useMediaQuery,
} from "@mui/material";
import { Dialog, DialogTitle } from "@mui/material";
import {
  collection,
  CollectionReference,
  DocumentSnapshot,
  limit,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import { Dispatch, SetStateAction } from "react";
import { useFirestore, useFirestoreCollection } from "reactfire";
import { useTranslation } from "react-i18next";

interface Props extends DialogProps {
  selection: QueryDocumentSnapshot<OrgProps>[] | DocumentSnapshot<OrgProps>[];
  setSelection: Dispatch<
    SetStateAction<
      QueryDocumentSnapshot<OrgProps>[] | DocumentSnapshot<OrgProps>[]
    >
  >;
  save: () => void;
}

const OrgSelect = ({ open, onClose, selection, setSelection, save }: Props) => {
  const db = useFirestore();

  const p = "orgs";
  const r = collection(db, p) as CollectionReference<OrgProps>;
  const q = query(r, limit(25), where("visibility", "==", "public"));
  const { status, data: snap, error } = useFirestoreCollection(q);
  const loading = status === "loading";

  const mdUp = useMediaQuery("(min-width: 768px)");

  const clickHandler = (org: QueryDocumentSnapshot<OrgProps>) => () => {
    if (selection.map((s) => s.id).includes(org.id)) {
      setSelection(selection.filter((s) => s.id !== org.id));
      return;
    }

    setSelection([...selection, org]);
  };

  const { t } = useTranslation("frontpage");

  return (
    <Dialog
      fullScreen={!mdUp}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      classes={{ paper: "relative" }}
    >
      {loading && <LinearProgress className="absolute bottom-0 left-0" />}
      <DialogTitle>{t("select_org")}</DialogTitle>
      <DialogContent>
        {error && (
          <div>{t("unable_to_load_org_error", { error: error.message })}</div>
        )}
        {snap && (
          <div className="p-2 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
            {snap.docs.length ? (
              snap.docs.map((docSnap) => (
                <div
                  className={`rounded-md ${
                    selection.map((s) => s.id).includes(docSnap.id)
                      ? "border-2 border-solid border-gray-500"
                      : ""
                  }`}
                  key={docSnap.id}
                  onClick={clickHandler(docSnap)}
                >
                  <OrgCard org={docSnap.data()} disableOnClick />
                </div>
              ))
            ) : (
              <div className="col-span-full text-center">
                {t("no_public_org_is_found")}
              </div>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={save}>
          {t("save", { ns: "common" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrgSelect;
