import { FunctionComponent } from "react";
import { ParticipantContextProvider } from "./";

export function withParticipants<T>(Node: FunctionComponent<T>) {
  return (props: T) => {
    return (
      <ParticipantContextProvider>
        <Node {...props} />
      </ParticipantContextProvider>
    );
  };
}
