import { Box, MenuItem, Select } from "@mui/material";
import Region, { regionData } from "@defs/region/region";
import { useLocation } from "react-router-dom";

interface RegionSelectProps {
  value?: string;
}

export default function RegionSelect({ value }: RegionSelectProps) {
  const { pathname } = useLocation();
  const regions = Object.values(Region);

  return (
    <Select
      size="small"
      value={value}
      onChange={(e) => {
        const val = e.target.value;
        if (val) {
          localStorage.setItem("selectedRegion", val);
          window.location.href = `/${val}${pathname}`;
        }
      }}
    >
      <MenuItem value="">&nbsp;</MenuItem>
      {regions.map((r) => {
        const region = regionData[r];
        return (
          <MenuItem value={r} key={r}>
            <Box sx={{ display: "flex" }}>
              <span className={`fi fi-${region.icon}`} />
              &nbsp;
            </Box>
          </MenuItem>
        );
      })}
    </Select>
  );
}
