import { useUserContext } from "@lib/AuthContext";
import { Alert, CircularProgress } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface AuthGuardProps {
  requireAdmin?: boolean;
  requireSuperAdmin?: boolean;
  regionRoleLocked?: boolean;
}

const AuthGuard: FC<AuthGuardProps> = ({
  children,
  requireSuperAdmin,
  requireAdmin,
  regionRoleLocked,
}) => {
  const { t } = useTranslation("components");
  const { user, loading, claimsLoading, isSuperAdmin, isAdmin } =
    useUserContext();

  const alert = (
    <Alert severity="error" sx={{ m: 2 }}>
      {t("no_permission_page")}
    </Alert>
  );
  const loginRequired = (
    <Alert severity="info" sx={{ m: 2 }}>
      {t("login_required_to_view")}
    </Alert>
  );

  return loading ? (
    <CircularProgress />
  ) : requireSuperAdmin ? (
    claimsLoading ? (
      <CircularProgress />
    ) : isSuperAdmin ? (
      <>{children}</>
    ) : (
      alert
    )
  ) : requireAdmin ? (
    isAdmin ? (
      <>{children}</>
    ) : (
      alert
    )
  ) : regionRoleLocked ? (
    <>{children}</>
  ) : user ? (
    <>{children}</>
  ) : (
    loginRequired
  );
};

export default AuthGuard;
