import loadingMessage from "@defs/LoadingMessages";
import { Icon } from "@iconify/react";
import { Series } from "@models/series";
import { Replay } from "@mui/icons-material";
import {
  Button,
  Card,
  createTheme,
  PaperProps,
  Stack,
  Theme,
  ThemeProvider,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { useContext } from "react";
import toast from "react-hot-toast";
import { useFunctions } from "reactfire";
import Swal from "sweetalert2";
import palette from "../../../../theme/palette";
import componentOverride from "../../../../theme/overrides";
import { themeOptions } from "../../../../Theme";
import shadows, { customShadows } from "../../../../theme/shadows";
import { seriesContext } from "./";
import { useTournamentEdit } from "../TournamentEdit";
import RoundSeriesMatchDialog from "./RoundSeriesMatchDialog";
import ScoreReportItem from "./ScoreReportItem";
import RoundSeriesTeam from "./RoundSeriesTeam";
import { useTranslation } from "react-i18next";

const RoundSeries = ({ series }: { series: Series }) => {
  const fn = useFunctions();
  const tour = useTournamentEdit();
  const { participants } = useContext(seriesContext);
  const [open, setState] = useState(false);
  const team = (id: number | null) => participants.find((p) => p.chalId === id);
  const teamA = team(series.teamA.id);
  const teamB = team(series.teamB.id);

  const { t } = useTranslation("events");

  const runSeriesAction =
    (option: "markAsUnderway" | "unmarkAsUnderway" | "reopen") => () => {
      if (option === "reopen") {
        Swal.fire({
          title: t("reopen_series_question"),
          text: t("this_will_reset_matches_from_series"),
          confirmButtonText: t("reopen"),
        }).then((res) => {
          if (res.isConfirmed) {
            fireAction(option);
          }
        });

        return;
      }
      fireAction(option);
    };

  const fireAction = (
    option: "markAsUnderway" | "unmarkAsUnderway" | "reopen"
  ) => {
    type Props = {
      seriesId: number;
      fireTourId: string;
    };

    const callable = httpsCallable<Props>(fn, `tournamentSeries-${option}`);
    toast.promise(
      callable({
        seriesId: series.id,
        fireTourId: tour?.id ?? "",
      }),
      {
        loading: loadingMessage(),
        error: (err) => err.message,
        success: t("done", { ns: "common" }),
      }
    );
  };

  const isSeriesReady = !!series.teamA.id && !!series.teamB.id;

  const toggleSeriesCheckIn = () => {
    const callable = httpsCallable<{
      tournamentId: string;
      seriesId: string;
      state: boolean;
    }>(fn, "tournamentSeries-toggleSeriesCheckIn");
    const promise = callable({
      tournamentId: tour?.id ?? "",
      seriesId: `${series.id}`,
      state: !series.checkInOpen,
    });
    toast.promise(promise, {
      loading: loadingMessage(),
      error: (err) => err.message || t("unable_to_change_check_in_state"),
      success: t("done", { ns: "common" }),
    });
  };

  const theme = createTheme({
    ...themeOptions,
    palette:
      !!series?.winnerId || !!series?.underwayAt ? palette.dark : palette.light,
  });

  const boxShadow = !!series?.winnerId
    ? customShadows.dark.success
    : !!series?.underwayAt
    ? customShadows.dark.info
    : series?.checkInOpen
    ? customShadows.light.warning
    : shadows.light;

  const sx: PaperProps["sx"] = {
    backgroundColor: !!series?.winnerId
      ? "success.dark"
      : !!series?.underwayAt
      ? "info.dark"
      : series?.checkInOpen
      ? "warning.light"
      : "background.paper",
    borderRadius: 1,
    boxShadow,
  };
  const MuiPaper = {
    ...theme.components?.MuiPaper,
    defaultProps: {
      ...theme.components?.MuiPaper?.defaultProps,
      sx: {
        ...theme.components?.MuiPaper?.defaultProps?.sx,
        ...sx,
      },
    },
  };
  const MuiAccordion = {
    ...theme.components?.MuiAccordion,
    styleOverrides: {
      ...theme.components?.MuiAccordion?.styleOverrides,
      boxShadow,
    },
  };
  const containedTheme = {
    ...theme,
    components: {
      ...componentOverride(theme),
      MuiPaper,
      MuiAccordion,
    } as Theme["components"],
  };

  return (
    <>
      <ThemeProvider theme={containedTheme}>
        <Card>
          <Stack>
            <Stack
              direction="row"
              sx={{
                p: 1,
                "& .Mui-expanded": {
                  minHeight: 0,
                  my: 0,
                },
              }}
            >
              <Stack sx={{ width: "100%" }}>
                <RoundSeriesTeam chalId={series.teamA.id} series={series} />
                <RoundSeriesTeam
                  chalId={series.teamB.id}
                  series={series}
                  sx={{ mt: 1, "&.Mui-expanded": { mt: 1 } }}
                />
              </Stack>
            </Stack>
            {isSeriesReady && (
              <Stack sx={{ p: 1 }} direction="row" flexWrap="wrap" gap={1}>
                {series.winnerId ? (
                  <Button
                    startIcon={<Replay />}
                    onClick={runSeriesAction("reopen")}
                    variant="contained"
                    size="small"
                    color="error"
                  >
                    {t("reset", { ns: "common" })}
                  </Button>
                ) : (
                  <Button
                    startIcon={
                      series.underwayAt ? (
                        <Icon icon="eva:radio-button-on-fill" />
                      ) : (
                        <Icon icon="eva:radio-button-off-fill" />
                      )
                    }
                    onClick={
                      series.underwayAt
                        ? runSeriesAction("unmarkAsUnderway")
                        : runSeriesAction("markAsUnderway")
                    }
                    variant="contained"
                    size="small"
                    color="inherit"
                  >
                    {t("underway")}
                  </Button>
                )}
                {!series.underwayAt && !series.winnerId && (
                  <Button
                    startIcon={
                      series.checkInOpen ? (
                        <Icon icon="eva:radio-button-on-fill" />
                      ) : (
                        <Icon icon="eva:radio-button-off-fill" />
                      )
                    }
                    onClick={toggleSeriesCheckIn}
                    variant="contained"
                    size="small"
                    color="inherit"
                  >
                    {t("check_in")}
                  </Button>
                )}
                <Button
                  startIcon={<Icon icon="eva:edit-fill" />}
                  color="inherit"
                  size="small"
                  variant="contained"
                  onClick={() => setState(true)}
                >
                  {t("update_scores")}
                </Button>
                {Object.entries(series.scoreReports ?? {}).map(
                  ([uid, report]) => (
                    <ScoreReportItem
                      key={uid}
                      data={report}
                      seriesIdentifier={series.identifier}
                      reportId={uid}
                      series={series}
                    />
                  )
                )}
              </Stack>
            )}
          </Stack>
        </Card>
      </ThemeProvider>
      <RoundSeriesMatchDialog
        onClose={() => setState(false)}
        match={series}
        open={open}
        teamA={teamA}
        teamB={teamB}
      />
    </>
  );
};

export default RoundSeries;
