import { AcadDirectoryContainer } from "@components";
import { useTranslation } from "react-i18next";
import ReferralCodeCreate from "./ReferralCodeCreate";
import ReferralCodeTable from "./ReferralCodeTable";
import { ReferralCodeTableProvider } from "./ReferralCodeTableContext";
import ReferralCodeUrlGenerator from "./ReferralCodeUrlGenerator";

const ReferralCode: React.VFC = () => {
  const { t } = useTranslation("users");
  return (
    <ReferralCodeTableProvider>
      <AcadDirectoryContainer
        title={t("emoji_referral_code_title")}
        createBtnTitle={t("add_referral")}
        createModal={<ReferralCodeCreate />}
      >
        <div className="flex flex-col gap-4">
          <ReferralCodeUrlGenerator />
          <div className="text-xl mb-2">{t("referral_code_directory")}</div>
          <ReferralCodeTable />
        </div>
      </AcadDirectoryContainer>
    </ReferralCodeTableProvider>
  );
};

export default ReferralCode;
