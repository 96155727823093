import { Team } from "@models/team";
import { Button, FormControlLabel, Stack, Switch } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ParticipantAddPlayer from "./components/ParticipantAdd.player";
import { ForceJoinProps } from "./ParticipantAdd.dialog";

export type PlayerType = "active" | "sub" | "notPlaying";
type PlayerSelect = Record<string, PlayerType>;

interface Props {
  team: Team;
  tournamentId: string;
  submit: (payload: ForceJoinProps) => void;
}
const ParticipantAddStep2 = ({ team, tournamentId, submit }: Props) => {
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [captain, setCaptain] = useState(team.memberIds[0] ?? "");
  const [players, setPlayers] = useState<PlayerSelect>(
    team.memberIds.reduce<PlayerSelect>((acc, id) => {
      return { ...acc, [id]: id === captain ? "active" : "notPlaying" };
    }, {})
  );

  const { t } = useTranslation("events");

  const setType = (uid: string) => {
    return (type: PlayerType) => {
      setPlayers({ ...players, [uid]: type });
    };
  };

  const setAsCaptain = (uid: string) => {
    return () => {
      setCaptain(uid);
    };
  };

  const getPlayersByType = (type: PlayerType) => {
    return Object.entries(players)
      .filter(([, t]) => t === type)
      .map(([uid]) => uid);
  };

  const onSubmit = () => {
    const payload: ForceJoinProps = {
      teamId: team.id,
      fireTourId: tournamentId,
      active: getPlayersByType("active"),
      subs: getPlayersByType("sub"),
      captain,
      isCheckedIn,
    };

    submit(payload);
  };

  return (
    <Stack gap={2}>
      <Stack gap={2}>
        {Object.entries(players).map(([uid, type]) => (
          <ParticipantAddPlayer
            key={uid}
            uid={uid}
            type={type}
            isCaptain={captain === uid}
            setAsCaptain={setAsCaptain(uid)}
            setType={setType(uid)}
          />
        ))}
      </Stack>

      <FormControlLabel
        label={t("check_in_the_team_question")}
        control={
          <Switch
            checked={isCheckedIn}
            onChange={() => setIsCheckedIn((s) => !s)}
          />
        }
      />

      <Stack direction="row-reverse">
        <Button onClick={onSubmit} variant="contained">
          {t("submit", { ns: "common" })}
        </Button>
      </Stack>
    </Stack>
  );
};

export default ParticipantAddStep2;
