import { AcadTextField } from "@components";
import { withFileUpload } from "@hocs";
import { joiResolver } from "@hookform/resolvers/joi";
import { useWriteDocument } from "@hooks";
import {
  AcademyOnboardingStatus,
  AcademyParams,
} from "@models/syspars/academy_params";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { doc, DocumentReference, DocumentSnapshot } from "firebase/firestore";
import { MouseEventHandler, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFirestore } from "reactfire";

const AcademyOnboarding = () => {
  const { t } = useTranslation(["academy", "common"]);
  const db = useFirestore();
  const docRef = doc(
    db,
    AcademyParams.PATH
  ) as DocumentReference<AcademyParams>;
  const {
    reset,
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<AcademyParams["onboarding"]>({
    defaultValues: { ...new AcademyParams().onboarding },
    resolver: joiResolver(AcademyParams.onboardingSchema),
  });

  const onSnapshotChanged = useMemo(() => {
    return (snap: DocumentSnapshot<AcademyParams>) => {
      reset(snap?.data()?.onboarding ?? new AcademyParams().onboarding);
    };
  }, [reset]);

  const { writeDocument } = useWriteDocument<AcademyParams>({
    docRef,
    onSnapshotChanged,
  });

  const save: MouseEventHandler<HTMLButtonElement> = async (e) => {
    handleSubmit(async (onboarding) => {
      writeDocument({ onboarding });
    }, console.error)(e);
  };

  return (
    <Stack sx={{ py: 2 }} gap={4}>
      <Stack gap={2}>
        <AcadTextField
          label={t("headline")}
          {...register("headline")}
          errors={errors}
        />
        <AcadTextField
          multiline
          minRows={3}
          label={t("body")}
          {...register("body")}
          errors={errors}
          helperText="Markdown supported"
        />
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <>
              <FormControl size="small">
                <InputLabel>{t("status")}</InputLabel>
                <Select {...field} label="Status">
                  <MenuItem value={AcademyOnboardingStatus.ClosedBetaSignUps}>
                    {t("closed_beta_sign_ups")}
                  </MenuItem>
                  <MenuItem value={AcademyOnboardingStatus.ClosedBeta}>
                    {t("closed_beta")}
                  </MenuItem>
                </Select>
              </FormControl>
              <div className="pt-1 text-red-600">{errors["status"]}</div>
            </>
          )}
        />
      </Stack>

      <Button
        sx={{ alignSelf: "flex-start" }}
        variant="contained"
        onClick={save}
      >
        {t("save")}
      </Button>
    </Stack>
  );
};

export default withFileUpload(AcademyOnboarding);
