import { useRetrieveCollection } from "@hooks";
import RequirementCard from "./RequirementCard";
import { AcadDirectory } from "@components";
import { useFirestore } from "reactfire";
import { collection, CollectionReference } from "firebase/firestore";
import ScholarshipRequirement from "@models/scholarship_requirement";
import { useTranslation } from "react-i18next";

const RequirementDirectory = () => {
  const db = useFirestore();
  const collectionRef = collection(
    db,
    ScholarshipRequirement.PATH
  ) as CollectionReference<ScholarshipRequirement>;
  const { t } = useTranslation("scholarships");

  const { data, error, loading } =
    useRetrieveCollection<ScholarshipRequirement>({
      collectionRef,
    });
  return (
    <AcadDirectory
      loading={loading}
      error={error}
      hasData={(data?.docs?.length ?? 0) > 0}
      loadingEl={<RequirementCard requirement={new ScholarshipRequirement()} />}
      noDataEl={t("no_requirements")}
    >
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
        {data?.docs.map((doc) => (
          <RequirementCard requirement={doc.data()} key={doc.id} />
        ))}
      </div>
    </AcadDirectory>
  );
};

export default RequirementDirectory;
