import AcadConsts from "@consts";
import { Participant, ParticipantCol } from "@defs/Participant.type";
import { TournamentDoc } from "@defs/Tournament.type";
import { collection } from "@firebase/firestore";
import { SeriesCol, SeriesSnap } from "@models/series";
import { Stack } from "@mui/material";
import { createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useFirestore,
  useFirestoreCollection,
  useFirestoreCollectionData,
} from "reactfire";
import ChallongeBracketIframe from "../components/ChallongeBracketIframe";
import Actions from "./Actions";
import Schedule from "./Schedule";

export const seriesContext = createContext<{
  tournament: TournamentDoc | null;
  participants: Participant[];
  seriesList: SeriesSnap[];
  refreshIframe: VoidFunction;
}>({
  tournament: null,
  participants: [],
  seriesList: [],
  refreshIframe: AcadConsts.noop,
});

const TournamentSchedule = ({
  tournamentId,
  tournament,
}: {
  tournamentId: string;
  tournament: TournamentDoc;
}) => {
  const db = useFirestore();
  const [state, set] = useState<number>(0);
  const refreshIframe = () => {
    set((n) => n + 1);
  };
  const seriesListRef = collection(
    db,
    "tournaments",
    tournamentId,
    "series"
  ) as SeriesCol;

  const participantsRef = collection(
    db,
    "tournaments",
    tournamentId,
    "participants"
  ) as ParticipantCol;

  const {
    data: seriesSnap,
    status: seriesStatus,
    error: seriesErr,
  } = useFirestoreCollection(seriesListRef);

  const {
    data: participants,
    status: participantStatus,
    error: participantsErr,
  } = useFirestoreCollectionData(participantsRef);

  const loading = seriesStatus === "loading" || participantStatus === "loading";
  const error = seriesErr || participantsErr;

  const { t } = useTranslation("events");

  return loading ? (
    <div className="w-full p-4 text-center text-lg">
      {t("loading", { ns: "common" })}
    </div>
  ) : error ? (
    <div className="w-full p-4 text-center text-lg">{error?.message}</div>
  ) : (
    <seriesContext.Provider
      value={{
        tournament,
        participants: participants ?? [],
        seriesList: seriesSnap?.docs ?? [],
        refreshIframe,
      }}
    >
      <Stack gap={4}>
        <Actions />
        {tournament.tournament.started_at ? (
          <Schedule />
        ) : (
          <div className="w-full text-center">
            {t("tournament_havent_started_yet")}
          </div>
        )}
        {/* <Button onClick={refresh} sx={{ m: 2, alignSelf: "center" }}>
          Refresh
        </Button> */}
      </Stack>
      <ChallongeBracketIframe
        chalUrl={tournament.tournament.url}
        refresher={state}
      />
    </seriesContext.Provider>
  );
};

export default TournamentSchedule;
