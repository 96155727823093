import { Subregion } from "@defs/region/subregion";
import { useRegion } from "@hooks/use_region";
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  regionFilter: Subregion[];
  setRegionFilter: Dispatch<SetStateAction<Subregion[]>>;
}

function RegionFilter({ regionFilter, setRegionFilter }: Props) {
  /**
   * Selects Individual filter
   * @param event
   * @param checked
   * @returns void
   */
  function selectRegion(
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const value = event.target.name as Subregion;
    if (!checked) {
      if (regionFilter.length === 1) return;
      setRegionFilter((state) => state.filter((r) => r !== value));
      return;
    }
    setRegionFilter((state) => [...state, value]);
  }

  const { t } = useTranslation("users");
  const { subregions } = useRegion();

  return (
    <FormControl
      className="w-full col-span-2"
      component="fieldset"
      variant="standard"
    >
      <FormLabel component="legend">{t("region")}</FormLabel>
      <FormGroup className="flex-row">
        {subregions.map((subregion) => (
          <FormControlLabel
            key={subregion}
            label={subregion}
            control={
              <Checkbox
                checked={regionFilter.includes(subregion)}
                name={subregion}
                onChange={selectRegion}
              />
            }
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

export default RegionFilter;
