export type Activity = {
  name: string;
  i18nKey?: string;
  icon?: string;
};

export const activityList: Activity[] = [
  {
    name: "Feeding Program",
    i18nKey: "feeding_program",
    icon: "",
  },
  {
    name: "Movie Nights",
    i18nKey: "movie_nights",
    icon: "",
  },
  {
    name: "Community Game Nights",
    i18nKey: "community_game_nights",
    icon: "",
  },
  {
    name: "Discord Chats or Discussions",
    i18nKey: "dc_chats_discussions",
    icon: "",
  },
  {
    name: "Outreach",
    i18nKey: "outreach",
    icon: "",
  },
  {
    name: "Seminars",
    i18nKey: "seminars",
    icon: "",
  },
];

// - Feeding Programs
// - Movie Nights
// - Community Game Nights
// - Discord Chats or Discussions
// - Outreach
// - Seminars
