import { AcadNavTabs } from "@components";
import AuthGuard from "@components/AuthGuard";
import { devRoutes } from "./DeveloperRoutes";

function Developer() {
  return (

  <AuthGuard>
    <AcadNavTabs routes={devRoutes} />
  </AuthGuard>

)
}

export default Developer;
