import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import toast from "react-hot-toast";
import { useFunctions } from "reactfire";
import { useTranslation } from "react-i18next";

export default function VerificationSettings() {
  const fn = useFunctions();
  const { t } = useTranslation("student_verification");

  const removeDuplicates = () => {
    const callable = httpsCallable(fn, "userDocuments-removeDuplicates");
    toast.promise(callable(), {
      loading: t("clearing_duplicates"),
      error: (err) => err.message,
      success: t("cleared_duplicates"),
    });
  };

  return (
    <Paper>
      <Stack p={2} gap={1}>
        <Typography variant="h6">
          {t("clear_verification_document_duplicates")}
        </Typography>
        <Typography variant="body1">
          {t("do_not_clear_if_you_are_unsure_of_side_effects")}
        </Typography>
        <Box>
          <Button onClick={removeDuplicates} variant="contained">
            {t("clear", { ns: "common" })}
          </Button>
        </Box>
      </Stack>
    </Paper>
  );
}
