import { LinkProps } from "@defs/Links";
import SchoolList from "./SchoolList";
import SchoolListPending from "./SchoolListPending";

const SchoolRoutes: LinkProps[] = [
  {
    component: <SchoolList />,
    title: "schools_list",
    url: "/schools/list",
    icon: "🏫",
    adminOnly: true,
    onNavbar: true,
    exact: true,
  },
  {
    component: <SchoolListPending />,
    title: "pending_schools",
    url: "/schools/pending",
    icon: "⌛",
    adminOnly: true,
    onNavbar: true,
    exact: true,
  },
];

export default SchoolRoutes;
