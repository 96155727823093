import {
  AcadEditDocContainer,
  AcadEditDocSection,
  AcadImageButton,
  AcadSelect,
  AcadTextField,
} from "@components";
import loadingMessage from "@defs/LoadingMessages";
import { OrgProps } from "@defs/Types";
import {
  collection,
  CollectionReference,
  doc,
  query,
  setDoc,
  Timestamp,
  where,
} from "@firebase/firestore";
import { withFileUpload } from "@hocs";
import { joiResolver } from "@hookform/resolvers/joi";
import { useDeleteDocument } from "@hooks";
import useFileUploadContext from "@hooks/use_file_upload_context";
import { useUserContext } from "@lib/AuthContext";
import { League, LeagueDoc } from "@models/league";
import { LoadingButton } from "@mui/lab";
import {
  MenuItem,
  SelectChangeEvent,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FormEvent, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDoc,
} from "reactfire";

interface Params {
  leagueId: string;
}

const LeagueEdit = () => {
  const db = useFirestore();

  const { user, isAdmin } = useUserContext();
  const orgsRef = collection(db, "orgs") as CollectionReference<OrgProps>;
  const q = query(orgsRef, where("admins", "array-contains", user?.id));
  const { data: orgs = [] } = useFirestoreCollectionData(
    isAdmin ? orgsRef : q,
    { idField: "id" }
  );

  const { leagueId } = useParams<Params>();
  const docRef = doc(db, "leagues", leagueId) as LeagueDoc;
  const { data: snapshot, status, error } = useFirestoreDoc(docRef);

  const data = snapshot?.data();

  return (
    <AcadEditDocContainer
      {...{ loading: status === "loading", error, snapshot }}
    >
      {data && <LeagueEditForm docRef={docRef} formData={data} orgs={orgs} />}
    </AcadEditDocContainer>
  );
};

export default withFileUpload(LeagueEdit);

interface LeagueEditFormProps {
  docRef: LeagueDoc;
  formData: League;
  orgs: OrgProps[];
}
const LeagueEditForm = ({ docRef, formData, orgs }: LeagueEditFormProps) => {
  const {
    setValue,
    getValues,
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<League>({
    defaultValues: formData,
    resolver: joiResolver(League.schema),
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation("events");

  const { deleteDocument } = useDeleteDocument({
    docRef,
    onDelete() {
      history.push("/events/leagues");
    },
    titleMsg: t("delete_league"),
  });

  const onDelete = () => {
    deleteDocument(formData);
  };

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const { uploadFilesAndSet } = useFileUploadContext();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitLoading(true);
    setValue("_title", getValues("title").toLowerCase());
    setValue("_slug", getValues("slug").toLowerCase());
    setValue("updatedAt", Timestamp.now());
    await uploadFilesAndSet(setValue);
    handleSubmit(
      (data) => {
        const promise = setDoc(docRef, data);

        toast.promise(promise, {
          loading: loadingMessage(),
          error: (err) => {
            console.error(err);
            setSubmitLoading(false);
            return t("update_failed");
          },
          success: () => {
            setSubmitLoading(false);
            return t("league_successfully_updated");
          },
        });
      },
      (error) => {
        console.error(error);
        setSubmitLoading(false);
      }
    )(e);
  };

  const onSelect = ({ target: { value } }: SelectChangeEvent<string>) => {
    const hostName =
      orgs.find((org) => org.schoolShortcode === value)?.name ?? "";
    setValue("hostId", value);
    setValue("hostName", hostName);
    setValue("_hostName", hostName.toLowerCase());
  };

  return (
    <Stack component="form" onSubmit={onSubmit}>
      <AcadEditDocSection>
        <Stack sx={{ mt: 1 }} gap={2}>
          <Stack
            gap={2}
            direction={{
              xs: "column-reverse",
              sm: "row",
              default: "column-reverse",
            }}
          >
            <AcadImageButton
              name="logo"
              path={getValues("logo")}
              noImageText={t("logo")}
              className={`h-40 ${
                smUp ? "w-40" : "w-full"
              } flex-shrink-0 bg-gray-200 rounded-md bg-contain bg-no-repeat bg-center`}
            />
            <AcadImageButton
              name="cover"
              noImageText={t("cover")}
              path={getValues("cover")}
              className="h-40 w-full p-20 flex-1 bg-gray-200 rounded-md bg-cover bg-center"
            />
          </Stack>
          <AcadTextField
            {...register("title")}
            errors={errors}
            label={t("league_title")}
          />
          <AcadSelect<League, string>
            control={control}
            name="hostId"
            label={t("host_org")}
            onChange={onSelect}
            disabled
          >
            {orgs.map((org, i) => (
              <MenuItem key={i} value={org.schoolShortcode}>
                {org.name}
              </MenuItem>
            ))}
          </AcadSelect>
          <Stack gap={2} direction="row">
            <AcadTextField
              {...register("slug")}
              errors={errors}
              label={t("slug")}
              fullWidth
            />
            <AcadTextField
              errors={errors}
              {...register("initialMMR")}
              label={t("initial_team_mmr")}
              type="number"
              fullWidth
            />
          </Stack>
          <AcadTextField
            multiline
            minRows={4}
            maxRows={6}
            errors={errors}
            {...register("description")}
            label={t("league_description")}
          />
          <AcadSelect<League, string>
            control={control}
            name="visibility"
            label={t("visibility", { ns: "common" })}
          >
            <MenuItem value="draft">{t("hidden", { ns: "common" })}</MenuItem>
            <MenuItem value="public">{t("public", { ns: "common" })}</MenuItem>
          </AcadSelect>
        </Stack>
      </AcadEditDocSection>

      <Stack direction="row" gap={2} justifyContent="flex-end">
        <LoadingButton onClick={onDelete} color="error">
          {t("delete", { ns: "common" })}
        </LoadingButton>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={submitLoading}
        >
          {t("save", { ns: "common" })}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
