import { useRetrieveCollection } from "@hooks";
import EligibilityCard from "./EligibilityCard";
import { AcadDirectory } from "@components";
import { useFirestore } from "reactfire";
import ScholarshipEligibility from "@models/scholarship_eligibility";
import { collection, CollectionReference } from "@firebase/firestore";
import { useTranslation } from "react-i18next";

const EligibilityDirectory = () => {
  const db = useFirestore();
  const collectionRef = collection(
    db,
    ScholarshipEligibility.PATH
  ) as CollectionReference<ScholarshipEligibility>;

  const { data, error, loading } =
    useRetrieveCollection<ScholarshipEligibility>({
      collectionRef,
    });

  const { t } = useTranslation("scholarships");

  return (
    <AcadDirectory
      loading={loading}
      error={error}
      hasData={(data?.docs?.length ?? 0) > 0}
      loadingEl={<EligibilityCard eligibility={new ScholarshipEligibility()} />}
      noDataEl={t("no_eligibility")}
    >
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
        {data?.docs.map((doc) => (
          <EligibilityCard eligibility={doc.data()} key={doc.id} />
        ))}
      </div>
    </AcadDirectory>
  );
};

export default EligibilityDirectory;
