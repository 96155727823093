import { ButtonBase } from "@mui/material";
import Sheet from "./Sheet";

export interface AcadHotLinkProps {
  icon: string;
  title: string;
  onClick?: (args?: any) => void;
  description?: string;
}

const AcadHotLink: React.VFC<AcadHotLinkProps> = (props) => {
  return (
    <ButtonBase
      onClick={props.onClick}
      className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 rounded-md"
    >
      <Sheet fullHeight className="w-full flex flex-col mb-0">
        <div className="w-full flex justify-center items-center p-10 text-7xl">
          {props.icon}
        </div>
        <div className="text-center w-full">
          <div className="text-xl text-black">{props.title}</div>
          {props.description && (
            <div className="text-xs text-gray-400">{props.description}</div>
          )}
        </div>
      </Sheet>
    </ButtonBase>
  );
};

export default AcadHotLink;
