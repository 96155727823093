import { useHistory, useParams } from "react-router-dom";
import { doc, DocumentReference } from "firebase/firestore";
import {} from "@lib/AuthContext";
import { useFirestore } from "reactfire";
import { useWriteDocument } from "@hooks";
import { AcadEditDocContainer } from "@components";
import ScholarshipBenefit from "@models/scholarship_benefit";
import BenefitEditForm from "./BenefitEditForm";
import { useTranslation } from "react-i18next";

const BenefitEdit = () => {
  const db = useFirestore();
  const { benefitId } = useParams<{ benefitId: string }>();
  const docRef = doc(
    db,
    `${ScholarshipBenefit.PATH}/${benefitId}`
  ) as DocumentReference<ScholarshipBenefit>;

  const { loading, snap, error, writeDocument } =
    useWriteDocument<ScholarshipBenefit>({
      docRef,
    });

  const { t } = useTranslation("scholarships");
  const history = useHistory();

  const onSubmit = (formData: ScholarshipBenefit) => {
    writeDocument(formData);
  };

  return (
    <AcadEditDocContainer
      snapshot={snap}
      error={error}
      loading={loading}
      onBackBtn={() => history.push("/scholarship/benefits")}
      doesNotExistText={t("benefit_does_not_exist")}
      backBtnText={t("back_to_benefits")}
    >
      <BenefitEditForm
        docRef={docRef}
        formData={snap?.data() ?? new ScholarshipBenefit()}
        onSubmit={onSubmit}
      />
    </AcadEditDocContainer>
  );
};

export default BenefitEdit;
