import {
  AcadImageButton,
  AcadModal,
  AcadSelect,
  AcadTextField,
} from "@components";
import { OrgProps } from "@defs/Types";
import { DialogProps } from "@framework/types";
import { joiResolver } from "@hookform/resolvers/joi";
import useFileUploadContext from "@hooks/use_file_upload_context";
import { League, LeagueCol } from "@models/league";
import {
  Alert,
  MenuItem,
  SelectChangeEvent,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useFirestore } from "reactfire";
import { withFileUpload } from "@hocs";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import loadingMessage from "@defs/LoadingMessages";
import { useTranslation } from "react-i18next";

interface LeagueCreateProps extends DialogProps {
  orgs: OrgProps[];
  isLoading: boolean;
}

const LeagueCreate = ({
  open,
  onClose = () => {},
  orgs,
  isLoading,
}: LeagueCreateProps) => {
  const db = useFirestore();
  const [loading, setLoading] = useState(false);

  const { uploadFilesAndSet } = useFileUploadContext();

  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
  } = useForm<League>({
    defaultValues: { ...new League() },
    resolver: joiResolver(League.schema),
  });

  const setOrg = useMemo(() => {
    return (orgId: string, orgName: string) => {
      setValue("hostId", orgId);
      setValue("hostName", orgName);
      setValue("_hostName", orgName.toLowerCase());
    };
  }, [setValue]);

  useEffect(() => {
    if (!orgs || !orgs.length) return;
    const [org] = orgs;
    setOrg(org.schoolShortcode, org.name);
  }, [orgs, setOrg]);

  const { t } = useTranslation("events");

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setValue("_title", getValues("title").toLowerCase());
    setValue("_slug", getValues("slug").toLowerCase());
    setValue("createdAt", Timestamp.now());
    setValue("updatedAt", Timestamp.now());
    await uploadFilesAndSet(setValue);
    handleSubmit(
      (data) => {
        const ref = collection(db, "leagues") as LeagueCol;
        const promise = addDoc(ref, data);

        toast.promise(promise, {
          loading: loadingMessage(),
          error: (err) => {
            console.error(err);
            setLoading(false);
            return t("create_failed");
          },
          success: () => {
            onClose();
            reset();
            setLoading(false);
            return t("league_successfully_created");
          },
        });
      },
      (error) => {
        console.error(error);
        setLoading(false);
      }
    )(e);
  };

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const onSelect = ({ target: { value } }: SelectChangeEvent<string>) => {
    const hostName =
      orgs.find((org) => org.schoolShortcode === value)?.name ?? "";
    setValue("hostId", value);
    setValue("hostName", hostName);
    setValue("_hostName", hostName.toLowerCase());
  };

  return (
    <AcadModal
      {...{ open, onClose }}
      loading={isLoading}
      title={t("create_league")}
    >
      {orgs.length ? (
        <form onSubmit={onSubmit}>
          <Stack sx={{ mt: 1 }} gap={2}>
            <Stack
              gap={2}
              direction={{
                xs: "column-reverse",
                sm: "row",
                default: "column-reverse",
              }}
            >
              <AcadImageButton
                name="logo"
                path={getValues("logo")}
                noImageText={t("logo")}
                className={`h-40 ${
                  smUp ? "w-40" : "w-full"
                } flex-shrink-0 bg-gray-200 rounded-md bg-contain bg-no-repeat bg-center`}
              />
              <AcadImageButton
                name="cover"
                noImageText={t("cover")}
                path={getValues("cover")}
                className="h-40 w-full p-20 flex-1 bg-gray-200 rounded-md bg-cover bg-center"
              />
            </Stack>
            <AcadTextField
              {...register("title")}
              errors={errors}
              label={t("league_title")}
            />
            <AcadSelect<League, string>
              control={control}
              name="hostId"
              onChange={onSelect}
              label={t("host_org")}
            >
              {orgs.map((org, i) => (
                <MenuItem key={i} value={org.schoolShortcode}>
                  {org.name}
                </MenuItem>
              ))}
            </AcadSelect>
            <Stack gap={2} direction="row">
              <AcadTextField
                {...register("slug")}
                errors={errors}
                label={t("slug")}
                fullWidth
              />
              <AcadTextField
                errors={errors}
                {...register("initialMMR")}
                label={t("initial_team_mmr")}
                type="number"
                fullWidth
              />
            </Stack>
            <AcadTextField
              multiline
              minRows={4}
              maxRows={6}
              errors={errors}
              {...register("description")}
              label={t("league_description")}
            />
            <LoadingButton variant="contained" type="submit" loading={loading}>
              {t("submit", { ns: "common" })}
            </LoadingButton>
          </Stack>
        </form>
      ) : (
        <Alert severity="info">
          {t("not_admin_of_any_org_required_to_create_league")}
        </Alert>
      )}
    </AcadModal>
  );
};

export default withFileUpload(LeagueCreate);
