import Environment from "@env";
import { useRegion } from "@hooks/use_region";
import { copyToClipboard } from "@lib/clipboard";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useOAuthClient } from "./oauthClientContext";

const AppCredentials = () => {
  const { clientId, clientSecret } = useOAuthClient();
  const { t } = useTranslation("developer");
  const { region } = useRegion();
  const copy =
    (str: string = "") =>
    () => {
      copyToClipboard(str);
      toast.success(t("copied"));
    };

  const url = `${Environment.appURL}/${region}/auth/${clientId}?scope=user:read:public user:read:email`;

  return (
    <Card>
      <CardHeader title="Client Credentials" />
      <CardContent>
        <Stack spacing={2}>
          <TextField
            label={t("client_id")}
            value={clientId}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={copy(clientId)}
                    size="small"
                    variant="contained"
                  >
                    {t("copy")}
                  </Button>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label={t("client_secret")}
            value={clientSecret}
            type="password"
            InputProps={{
              endAdornment: (
                <Button
                  onClick={copy(clientSecret)}
                  size="small"
                  variant="contained"
                >
                  {t("copy")}
                </Button>
              ),
            }}
          />
          <TextField
            label={t("oauth_path")}
            value={url}
            InputProps={{
              endAdornment: (
                <Button onClick={copy(url)} size="small" variant="contained">
                  {t("copy")}
                </Button>
              ),
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default AppCredentials;
