import { UserData } from "@defs/User";
import {
  doc,
  DocumentReference,
  DocumentSnapshot,
  onSnapshot,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuth, useFirestore, useUser } from "reactfire";
import { UserContextProps } from "./AuthContext";

export const useUserData = (): UserContextProps => {
  const auth = useAuth();
  const db = useFirestore();
  const { data: user, status } = useUser();
  const userloading = status === "loading";
  const [userDoc, setUserDoc] = useState<DocumentSnapshot<UserData> | null>(
    null
  );
  const [username, setUsername] = useState<string | null>("");
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isGlobalMod, setIsGlobalMod] = useState(false);
  const [claimsLoading, setClaimsLoading] = useState(true);
  const [claims, setClaims] = useState<Record<string, any>>({});

  useEffect(() => {
    if (userloading) {
      setClaimsLoading(false);
      setLoading(true);
      return;
    }
    if (!user) {
      setClaimsLoading(false);
      setLoading(false);
      setUserDoc(null);
      setUsername(null);
      return;
    }
    const ref = doc(db, "users", user.uid) as DocumentReference<UserData>;
    const unsubscribe = onSnapshot(
      ref,
      (snap) => {
        setLoading(false);
        const data = snap.data();
        if (snap.exists()) {
          setUserDoc(snap);
          setUsername(data?.username || "");
        } else {
          setLoading(false);
          setUserDoc(null);
          setUsername(null);
        }
      },
      () => {
        setLoading(false);
        setUserDoc(null);
        setUsername(null);
      }
    );

    auth.currentUser
      ?.getIdTokenResult()
      .then((idTokenResult) => {
        setClaims(idTokenResult.claims);
        setIsSuperAdmin(!!idTokenResult.claims.admin);
        setIsGlobalMod(!!idTokenResult.claims.moderator);
      })
      .catch(console.error)
      .finally(() => setClaimsLoading(false));

    const regularExpression = /@acadarena\.com/g;

    if (user.emailVerified && regularExpression.test(user.email ?? "")) {
      setIsAdmin(true);
    }

    return () => {
      unsubscribe();
      // unsubscribe2();
    };
  }, [user, userloading, auth.currentUser, db]);

  return {
    user: userDoc,
    username,
    loading,
    isAdmin,
    claimsLoading,
    isSuperAdmin,
    isGlobalMod,
    claims,
    roles: Array.from(
      new Set([isGlobalMod && "moderator", isAdmin && "admin"])
    ).reduce<string[]>((acc, current) => {
      if (!current) return acc;
      return [...acc, current];
    }, []),
  };
};

export default useUserData;
