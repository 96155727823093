import { Team } from "@models/team";
import {
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Avatar,
} from "@mui/material";
import {
  collection,
  CollectionReference,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { debounce } from "lodash-es";
import { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFirestore } from "reactfire";

interface Props {
  selectTeam: (team: Team) => void;
}
const ParticipantAddStep1 = ({ selectTeam }: Props) => {
  const db = useFirestore();
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState<Team[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("events");

  const findTeams = useMemo(() => {
    const debouncedSearch = debounce((searchString: string) => {
      setLoading(true);
      if (searchString.length < 3) {
        setLoading(false);
      }

      const ref = collection(db, "teams") as CollectionReference<Team>;
      const q = query(
        ref,
        where("_name", ">=", searchString),
        where("isVarsity", "==", true)
      );
      getDocs(q)
        .then((snap) => {
          setOptions(snap.docs.map((d) => d.data()));
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
    }, 500);
    return debouncedSearch;
  }, [db]);

  useEffect(() => {
    if (!search || search.length < 3) return;
    findTeams(search);
  }, [findTeams, search]);

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearch(e.target.value);
  };

  const onClick = (team: Team) => () => {
    selectTeam(team);
  };

  return (
    <Stack gap={2}>
      <TextField
        value={search}
        onChange={onInputChange}
        label={t("search_team")}
        InputProps={{
          endAdornment: loading ? (
            <InputAdornment position="end">
              <CircularProgress />
            </InputAdornment>
          ) : null,
        }}
      />
      {loading ? (
        <Stack>
          <Typography align="center">
            {t("loading", { ns: "common" })}
          </Typography>
        </Stack>
      ) : options.length ? (
        <Stack gap={2}>
          {options.map((team) => (
            <Card>
              <CardActionArea onClick={onClick(team)}>
                <CardContent>
                  <Stack direction="row" gap={2} alignItems="center">
                    <Avatar src={team.logo} />
                    <Stack>
                      <Typography variant="h6">{team.name}</Typography>
                    </Stack>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Stack>
      ) : (
        <Stack>
          <Typography align="center">{t("no_teams_found")}</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default ParticipantAddStep1;
