import { Button, Stack, MenuItem, createFilterOptions } from "@mui/material";
import { CSVLink } from "react-csv";
import { flatten } from "@lib/flattenObject";
import { useState, useMemo } from "react";
import { httpsCallable } from "firebase/functions";
import { useFunctions } from "reactfire";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "@components/LoadingOverlay";
import { format } from "date-fns";
import toast from "react-hot-toast";
import { AcadSelect } from "@components/select";
import { useForm } from "react-hook-form";
import useSchoolList from "@hooks/use_school_list";
import AcadAutoComplete from "@components/textfield/AcadAutocomplete";
import { AcadDateTimePicker, AcadTextField } from "@components";

type ExportReq = {
  filteredSchools: string[];
  userLimit: number;
  order: "asc" | "desc";
  minCreatedAt: Date;
  maxCreatedAt: Date;
};
type ExportRes = string[];
type ExportType = string[];

const defaultValues: ExportReq = {
  filteredSchools: [],
  userLimit: 0,
  order: "asc",
  minCreatedAt: new Date(2021, 8, 1),
  maxCreatedAt: new Date(),
};

const ExportUsersByFilter = () => {
  const [exportData, setExportData] = useState<ExportType>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const fn = useFunctions();
  const { schools } = useSchoolList();
  const { t } = useTranslation("admin");

  const {
    control,
    getValues,
    reset,
    register,
    formState: { errors },
  } = useForm<ExportReq>({
    defaultValues,
  });

  const OPTIONS_LIMIT = 10;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
  });

  const resetForm = useMemo(() => {
    return () => {
      setExportData([]);
      reset(defaultValues);
    };
  }, [reset]);

  const loadData = async () => {
    setLoading(true);
    const callable = httpsCallable<ExportReq, ExportRes>(
      fn,
      "user-exportUsersByFilter"
    );
    const { filteredSchools, userLimit, order, minCreatedAt, maxCreatedAt } =
      getValues();
    toast.promise(
      callable({
        filteredSchools,
        userLimit,
        order,
        minCreatedAt,
        maxCreatedAt,
      }),
      {
        error: () => {
          setLoading(false);
          return t("loading_users_failed");
        },
        loading: t("loading_users"),
        success: (response) => {
          setExportData(response.data);
          setLoading(false);
          return t("users_ready_for_export", { count: response.data.length });
        },
      }
    );
  };

  return (
    <Stack gap={2}>
      <LoadingOverlay show={loading} />
      <Stack direction="column" gap={2}>
        <Stack direction="row" gap={2}>
          <AcadTextField
            fullWidth
            type="number"
            label={t("limit_users")}
            {...register("userLimit", {
              min: 0,
              valueAsNumber: true,
            })}
          />
          <AcadSelect
            fullWidth
            control={control}
            name="order"
            label={t("order_users_by_registration_date")}
            defaultValue="asc"
          >
            <MenuItem value="asc">{t("ascending")}</MenuItem>
            <MenuItem value="desc">{t("descending")}</MenuItem>
          </AcadSelect>
        </Stack>
        <Stack direction="row" gap={2}>
          <AcadDateTimePicker
            label={t("users_registered_after_date")}
            controllerProps={{ name: "minCreatedAt", control }}
            renderInput={(params) => (
              <AcadTextField
                {...params}
                fullWidth
                className="col-span-12 sm:col-span-6"
                errors={errors}
                {...register("minCreatedAt", {
                  valueAsDate: true,
                })}
              />
            )}
          />
          <AcadDateTimePicker
            label={t("users_registered_before_date")}
            controllerProps={{ name: "maxCreatedAt", control }}
            minDate={defaultValues.minCreatedAt}
            renderInput={(params) => (
              <AcadTextField
                {...params}
                fullWidth
                className="col-span-12 sm:col-span-6"
                errors={errors}
                {...register("maxCreatedAt", {
                  valueAsDate: true,
                })}
              />
            )}
          />
        </Stack>
      </Stack>

      <AcadAutoComplete
        multiple
        filterOptions={filterOptions}
        control={control}
        name="filteredSchools"
        options={schools}
        getOptionDisabled={() =>
          getValues()?.filteredSchools?.length >= 10 ? true : false
        }
        renderInput={(props) => (
          <AcadTextField {...props} label={t("schools")} />
        )}
      />

      <Stack gap={2} direction="row">
        <Button size="small" variant="contained" onClick={loadData}>
          {t("filter_users")}
        </Button>
        <Button
          size="small"
          disabled={exportData.length === 0}
          variant="contained"
        >
          <CSVLink
            data={exportData.map((d) => flatten(d))}
            filename={`Filtered_Users_Export_${format(
              Date.now(),
              t("date_format", { ns: "common" })
            )}.csv`}
            style={{ color: "#fff" }}
          >
            {t("export_filtered_users")}
          </CSVLink>
        </Button>
        <Button
          size="small"
          variant="contained"
          color="error"
          onClick={resetForm}
        >
          {t("reset_fields")}
        </Button>
      </Stack>
      <Stack></Stack>
    </Stack>
  );
};

export default ExportUsersByFilter;
