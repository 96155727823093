import { Skeleton } from "@mui/material";
import React, { useContext } from "react";
import Sheet from "@components/Sheet";
import { userContext } from "@lib/AuthContext";
import AdminView from "./AdminView";
import UserView from "./UserView";
import { AcadHotLink } from "@components";
import { useTranslation, Trans } from "react-i18next";

const Dashboard = () => {
  const {
    user,
    isAdmin: admin,
    claimsLoading: loading,
  } = useContext(userContext);
  const { t } = useTranslation("dashboard");
  return (
    <div>
      <Sheet>
        <div className="text-xl">
          <Trans
            i18nKey="dashboard:welcome_to_aa_admin_dashboard"
            components={{
              a: <a href="mailto:justin@acadarena" />,
            }}
          />
        </div>
      </Sheet>

      {!user ? (
        <div>{t("login_required")}</div>
      ) : loading ? (
        <div className="grid grid-cols-12 gap-4">
          {[...Array(3)].map((_, i) => (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={200}
              animation="wave"
              className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3"
            >
              <AcadHotLink title="" icon="" />
            </Skeleton>
          ))}
        </div>
      ) : admin ? (
        <AdminView />
      ) : (
        <UserView />
      )}
    </div>
  );
};

export default Dashboard;
