import { useTranslation } from "react-i18next";

interface Props {
  chalUrl: string;
  refresher: number;
}
const ChallongeBracketIframe = ({ chalUrl, refresher }: Props) => {
  const { t } = useTranslation("events");

  return (
    <iframe
      key={refresher}
      src={`https://challonge.com/${chalUrl}/module`}
      width="100%"
      height="500"
      title={t("challonge_bracket")}
      frameBorder="0"
      className="pt-7"
    ></iframe>
  );
};

export default ChallongeBracketIframe;
