import { Alert } from "@mui/material";
import {
  collection,
  CollectionReference,
  query,
  where,
} from "firebase/firestore";
import { useContext } from "react";
import OrgCard from "@views/Community/Directory/OrgCard";
import { OrgProps } from "@defs/Types";
import { userContext } from "@lib/AuthContext";
import { useFirestore, useFirestoreCollection } from "reactfire";
import { useTranslation } from "react-i18next";

const UserView = () => {
  const db = useFirestore();
  const { user } = useContext(userContext);

  /**
   * Checks if the user is an admin on any orgs
   */

  const ref = collection(db, "orgs") as CollectionReference<OrgProps>;
  const q = query(ref, where("admins", "array-contains", user?.id));
  const { data } = useFirestoreCollection(q);
  const userOrgsAsAdmin = data?.docs ?? [];

  /**
   * Checks if the user is moderator on any orgs
   */
  const modRef = collection(db, "orgs") as CollectionReference<OrgProps>;
  const qq = query(modRef, where("moderators", "array-contains", user?.id));
  const { data: modData } = useFirestoreCollection(qq);
  const userOrgsAsModerator = modData?.docs ?? [];

  const { t } = useTranslation("dashboard");

  return (
    <div className="flex flex-col">
      {userOrgsAsAdmin.length || userOrgsAsModerator.length ? (
        <>
          <div className="text-xl mb-2 mt-4">
            {t("orgs_you_manage_as_admin_or_moderator")}
          </div>

          <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
            {userOrgsAsAdmin.map((orgSnapshot) => (
              <OrgCard org={orgSnapshot.data()} />
            ))}
            {userOrgsAsModerator.map((orgSnapshot) => (
              <OrgCard org={orgSnapshot.data()} />
            ))}
          </div>
        </>
      ) : (
        <Alert severity="info">{t("you_are_not_admin_of_org")}</Alert>
      )}
    </div>
  );
};

export default UserView;
