import { useRetrieveCollection } from "@hooks";
import ScholarshipCard from "./ScholarshipCard";
import { AcadDirectory } from "@components";
import { useFirestore } from "reactfire";
import { collection, CollectionReference } from "firebase/firestore";
import Scholarship from "@models/scholarship";
import { useTranslation } from "react-i18next";

const ScholarshipDirectory = () => {
  const db = useFirestore();
  const collectionRef = collection(
    db,
    Scholarship.PATH
  ) as CollectionReference<Scholarship>;

  const { data, error, loading } = useRetrieveCollection<Scholarship>({
    collectionRef,
  });
  const { t } = useTranslation("scholarships");
  return (
    <AcadDirectory
      loading={loading}
      error={error}
      hasData={(data?.docs?.length ?? 0) > 0}
      loadingEl={<ScholarshipCard scholarship={new Scholarship()} />}
      noDataEl={t("no_scholarships")}
    >
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
        {data?.docs.map((doc) => (
          <ScholarshipCard scholarship={doc.data()} key={doc.id} />
        ))}
      </div>
    </AcadDirectory>
  );
};

export default ScholarshipDirectory;
