import { TournamentDoc } from "@defs/Tournament.type";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useState, VFC } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useFunctions } from "reactfire";
import { useTournamentEdit } from "../TournamentEdit";

interface Props extends DialogProps {
  roundNumber: number;
  roundLabel?: string;
  bestOf?: number;
  onClose: VoidFunction;
}
const RoundInfoDialog: VFC<Props> = ({
  roundLabel,
  bestOf,
  roundNumber,
  ...props
}) => {
  const [label, setLabel] = useState(roundLabel ?? "");
  const [bo, setBestOf] = useState(bestOf ?? 1);

  const fn = useFunctions();
  const tourSnap = useTournamentEdit();
  const { t } = useTranslation("events");

  const updateRoundData = () => {
    const callable = httpsCallable<{ data: Partial<TournamentDoc> }>(
      fn,
      "tournament-update"
    );
    if (!tourSnap) return toast.error(t("error_updating_tournament"));
    const promise = callable({
      data: {
        ...tourSnap.data(),
        roundData: {
          ...tourSnap.data()?.roundData,
          [`${roundNumber}`]: {
            bestOf: bo,
            label,
          },
        },
      },
    });
    toast.promise(promise, {
      loading: t("updating_round_info"),
      error: (err) => err.message || t("error_updating_round_info"),
      success: () => {
        props.onClose();
        return t("round_info_updated");
      },
    });
  };

  return (
    <Dialog {...props}>
      <DialogTitle>
        {t("update_round_info", { roundNumber: roundNumber })}
      </DialogTitle>
      <DialogContent>
        <Stack gap={2} sx={{ mt: 2 }}>
          <TextField
            label={t("round_label")}
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />

          <FormControl>
            <InputLabel>{t("best_of")}</InputLabel>
            <Select
              label={t("best")}
              value={bo}
              onChange={(e) => setBestOf(e.target.value as number)}
            >
              <MenuItem value={1}>{t("best_of_count", { count: 1 })}</MenuItem>
              <MenuItem value={3}>{t("best_of_count", { count: 3 })}</MenuItem>
              <MenuItem value={5}>{t("best_of_count", { count: 5 })}</MenuItem>
              <MenuItem value={7}>{t("best_of_count", { count: 7 })}</MenuItem>
              <MenuItem value={9}>{t("best_of_count", { count: 9 })}</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{t("close", { ns: "common" })}</Button>
        <Button variant="contained" onClick={updateRoundData}>
          {t("save", { ns: "common" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RoundInfoDialog;
