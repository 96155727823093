import { BrSubregion, brSubregions } from "./br_subregion";
import { PhSubregion, phSubregions } from "./ph_subregion";
import { Subregion } from "./subregion";

enum Region {
  PH = "ph",
  BR = "br",
}

// region, to be used outside of the react context, is based on the subpath, i.e. /ph or /br
export const region: Region = window.location.pathname.split("/")[1] as Region;

export default Region;

export interface RegionData {
  icon: string;
  defaultSubregion: Subregion;
  subregions: Subregion[];
  locale: string;
}

export const regionData: Record<Region, RegionData> = {
  [Region.PH]: {
    icon: "ph",
    defaultSubregion: PhSubregion.Metro,
    subregions: phSubregions,
    locale: "en",
  },
  [Region.BR]: {
    icon: "br",
    defaultSubregion: BrSubregion.SP,
    subregions: brSubregions,
    locale: "pt-BR",
  },
};
