import AcadConsts from "@consts";
import { UserData } from "@defs/User";
import {
  collection,
  CollectionReference,
  query,
  QueryConstraint,
  where,
} from "@firebase/firestore";
import usePaginatedCollection, {
  defaultPaginatedCollectionValues,
  PaginatedCollection,
} from "@hooks/use_paginated_collection";
import { useRegion } from "@hooks/use_region";
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { useFirestore } from "reactfire";

interface IUserTableContext {
  filters: QueryConstraint[];
  setFilters: Dispatch<SetStateAction<QueryConstraint[]>>;
  users: PaginatedCollection<UserData>;
}

const userTableContext = createContext<IUserTableContext>({
  filters: [],
  setFilters: AcadConsts.noop,
  users: defaultPaginatedCollectionValues<UserData>(),
});

export const UserTableProvider: FC = ({ children }) => {
  const db = useFirestore();
  const { subregions, isBr } = useRegion();
  // TODO: Brazil region filters
  const [filters, setFilters] = useState(
    isBr ? [] : [where("region", "in", subregions)]
  );
  const ref = collection(db, "users") as CollectionReference<UserData>;
  const q = query(ref, ...filters);
  const users = usePaginatedCollection({
    query: q,
    limit: 20,
    orderBy: {
      direction: "asc",
      field: "username",
    },
  });

  return (
    <userTableContext.Provider
      value={{
        filters,
        setFilters,
        users,
      }}
    >
      {children}
    </userTableContext.Provider>
  );
};

export const useUserTable = () => {
  const context = useContext(userTableContext);
  return context;
};
