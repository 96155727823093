import { LinkProps } from "@defs/Links";
import Leagues from "./Leagues";
import LeagueEdit from "./Leagues/LeagueEdit";
import CompeteSettings from "./Settings";
import EventSettingsRoute from "./Settings/EventSettingsRoute";
import Tournaments from "./Tournaments";
import TournamentEdit from "./Tournaments/TournamentEdit";

const route = "/events";

export const TournamentAndLeaguesRoutes: LinkProps[] = [
  {
    component: <Tournaments />,
    title: "tournaments",
    url: `${route}/tournaments`,
    exact: true,
    adminOnly: false,
    onNavbar: true,
    icon: "🎮",
    authenticated: true,
  },

  {
    component: <Leagues />,
    title: "leagues",
    url: `${route}/leagues`,
    exact: true,
    adminOnly: false,
    onNavbar: true,
    icon: "⚽",
    authenticated: true,
  },
  {
    component: <LeagueEdit />,
    title: "league",
    url: `${route}/leagues/:leagueId`,
    adminOnly: false,
    authenticated: true,
  },
  {
    component: <TournamentEdit />,
    title: "tournament",
    url: `${route}/tournaments/:tournamentId`,
    adminOnly: false,
    authenticated: true,
  },

  {
    component: <CompeteSettings />,
    title: "settings",
    url: `${route}/settings`,
    exact: false,
    adminOnly: true,
    onNavbar: true,
    icon: "⚙️",
    authenticated: true,
  },

  ...EventSettingsRoute.map((r) => ({ ...r, onNavbar: false })),
];

export default TournamentAndLeaguesRoutes;
