import { AcadDirectory, AcadDirectoryContainer } from "@components";
import LoadingIndicator from "@components/LoadingIndicator";
import withUser from "@hocs/withUser";
import q from "@lib/firestore/q";
import {
  OAuthClient,
  OAuthTeam,
  OAUTH_CLIENT_PATH,
  OAUTH_TEAMS_PATH,
} from "@models/oauth";
import { Stack } from "@mui/material";
import { where } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { useFirestore, useFirestoreCollection } from "reactfire";
import AppCard from "./AppCard";
import AppCreateModal from "./AppCreateModal";
import TeamApps from "./TeamApps";

const AppDirectory = withUser(({ user }) => {
  const db = useFirestore();
  const { t } = useTranslation("developer");

  const qPersonal = q<OAuthClient>(db, OAUTH_CLIENT_PATH, { owner: user.id });

  const qTeams = q<OAuthTeam>(db, OAUTH_TEAMS_PATH, [
    where("admins", "array-contains", user.id),
  ]);

  const { data: personalAppsSnap, status: personalAppsStatus } =
    useFirestoreCollection(qPersonal);

  const { data: teamsSnap, status: teamStatus } =
    useFirestoreCollection(qTeams);

  const personalApps = personalAppsSnap?.docs.map((doc) => doc.data()) ?? [];
  console.log({ personalApps });
  const teams = teamsSnap?.docs.map((doc) => doc.data()) ?? [];

  return (
    <Stack spacing={4}>
      <AcadDirectoryContainer
        title={t("app_list")}
        createBtnTitle={t("create")}
        createModal={<AppCreateModal />}
        directoryTitle={t("personal_apps")}
      >
        <Stack spacing={2}>
          <AcadDirectory
            loading={personalAppsStatus === "loading"}
            hasData={!!personalApps.length}
          >
            <Stack spacing={2}>
              {personalApps.map((app) => (
                <AppCard app={app} key={app.id} />
              ))}
            </Stack>
          </AcadDirectory>
        </Stack>
      </AcadDirectoryContainer>
      {teamStatus === "loading" ? (
        <LoadingIndicator />
      ) : (
        teams.map((team) => <TeamApps team={team} key={team.id} />)
      )}
    </Stack>
  );
});

export default AppDirectory;
