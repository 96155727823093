import {
  Button,
  ButtonBase,
  TextField,
  Fade,
  Chip,
  Divider,
  Autocomplete,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { ArrowLeft } from "@mui/icons-material";
import { Skeleton, Alert } from "@mui/material";
import { ChangeEvent, FormEvent, useContext, useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Sheet from "@components/Sheet";
import { BoostcampSeries } from "@defs/Types";
import { Episode } from "@defs/Episode";
import { userContext } from "@lib/AuthContext";
import toast from "react-hot-toast";
import { deleteDoc, doc, DocumentReference, setDoc } from "firebase/firestore";
import loadingMessage from "@defs/LoadingMessages";
import { Select } from "@mui/material";
import swal from "sweetalert2";
import asyncHook from "@lib/AsyncHook";
import { onFileSelect, Files } from "@lib/file";
import { useFirestore, useFirestoreDoc } from "reactfire";
import { Visibility } from "@defs/Tournament.type";
import EpisodeDirectory from "./components/EpisodeDirectory";
import EpisodeCreate from "./components/EpisodeCreate";
import EpisodeEdit from "./components/EpisodeEdit";
import { useTranslation } from "react-i18next";

const BoostcampPage = () => {
  const db = useFirestore();
  const { boostcampId } = useParams<{ boostcampId: string }>();
  const { user } = useContext(userContext);
  const history = useHistory();

  const [createState, setCreateState] = useState(false);
  const [editState, setEditState] = useState<{
    episode: Episode;
    index: number;
  } | null>(null);

  const p = `boostcamp/${boostcampId}`;
  const r = doc(db, p) as DocumentReference<BoostcampSeries>;
  const { status, data: snap, error: err } = useFirestoreDoc(r);
  const loading = status === "loading";

  const [form, setForm] = useState<BoostcampSeries>({
    thumbnail: "",
    name: "",
    description: "",
    tags: [],
    category: "",
    presented: "",
    episodes: [],
    slug: "",
    visibility: "draft",
  });

  const { t } = useTranslation("boostcamp");

  const addEpisode = (episode: Episode) => {
    if (
      form.episodes.find((ep) => ep.episodeNumber === episode.episodeNumber)
    ) {
      return toast.error(t("episode_already_exists"));
    }
    setForm((prevState) => ({
      ...prevState,
      episodes: [
        ...prevState.episodes,
        { ...episode, seriesId: prevState.slug },
      ],
    }));
  };

  const updateEpisode = (episode: Episode, index: number) => {
    setForm({
      ...form,
      episodes: form.episodes.map((ep, i) =>
        index === i ? { ...episode, seriesId: form.slug } : ep
      ),
    });
  };

  const deleteEpisode = (episode: Episode, index: number) => {
    setForm((f) => ({
      ...f,
      episodes: f.episodes.filter(
        (ep, i) => index !== i && ep.episodeNumber !== episode.episodeNumber
      ),
    }));
  };

  const [files, setFiles] = useState<Files<BoostcampSeries>>({});

  useEffect(() => {
    if (!snap?.exists()) return;
    setForm(snap.data());
  }, [snap]);

  const classes = {
    orgInfo: "text-xl text-left col-span-full",
    description_thumbnail:
      "bg-gray-200 col-span-full h-52 bg-cover bg-center rounded",
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const ref = doc(
      db,
      `boostcamp/${boostcampId}`
    ) as DocumentReference<BoostcampSeries>;
    const promise = setDoc(ref, form, { merge: true });
    toast.promise(promise, {
      loading: loadingMessage(),
      error: (err) => err.message,
      success: t("saved_successfully", { ns: "common" }),
    });
  };

  const deleteBoostcampSeries = () => {
    swal
      .fire({
        title: t("delete_boostcamp_question"),
        text: t("this_action_is_irreversible"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#B33A3A",
      })
      .then(async (resp) => {
        if (resp.isConfirmed) {
          const ref = doc(db, `boostcamp/${boostcampId}`);
          const [, err] = await asyncHook(deleteDoc(ref));
          if (err) {
            toast.error(t("unable_to_delete_boostcamp"));
            return;
          }
          history.push("/boostcamp");
        }
      });
  };

  const handleText = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name as keyof BoostcampSeries]: e.target.value,
    });
  };

  const NoUser = (
    <Fade in appear>
      <div className="flex flex-col justify-center items-center">
        <div className="">{t("sign_in_required")}</div>
      </div>
    </Fade>
  );

  const Loading = (
    <Fade in appear>
      <div className="flex flex-col">
        <Skeleton
          variant="rectangular"
          className="w-full h-52 rounded-md"
        ></Skeleton>
      </div>
    </Fade>
  );

  const Error = (
    <Fade in appear>
      <Alert severity="warning">{err?.message}</Alert>
    </Fade>
  );

  const Missing = (
    <Fade in appear>
      <Alert severity="warning">{t("this_playlist_does_not_exist")}</Alert>
    </Fade>
  );

  const Populated = (
    <Fade in appear>
      <div className="flex flex-col">
        <div className="flex  mb-2 ">
          <Button
            startIcon={<ArrowLeft />}
            variant="outlined"
            onClick={() => history.push("/boostcamp")}
          >
            {t("go_back_to_boostcamp")}
          </Button>
        </div>

        {/* Fields */}
        <form
          className="grid grid-cols-12 mx-0 md:mx-4 mt-8 gap-4"
          onSubmit={onSubmit}
        >
          <div className={classes.orgInfo}>{t("playlist_info")}</div>

          {/* Thumbnail */}
          <ButtonBase
            component="label"
            className={classes.description_thumbnail}
            sx={{
              backgroundImage: `url("${
                files.thumbnail?.path || form.thumbnail
              }")`,
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={onFileSelect(setFiles, setForm)}
              hidden
              name="thumbnail"
            />
            {Boolean(files?.thumbnail?.path)
              ? ""
              : form?.thumbnail
              ? ""
              : t("plus_add_thumbnail")}
          </ButtonBase>

          {/* Name */}
          <TextField
            name="name"
            label={t("playlist_name")}
            className={"col-span-12"}
            value={form?.name}
            required
            onChange={handleText}
          />

          {/* Description */}
          <TextField
            name="description"
            label={t("playlist_description")}
            className={"col-span-12"}
            value={form?.description}
            required
            onChange={handleText}
          />
          {/* Category */}
          <TextField
            name="category"
            label={t("playlist_category")}
            className={"col-span-12"}
            value={form?.category}
            onChange={handleText}
          />
          {/* Presented */}
          <TextField
            name="presented"
            label={t("presented_by")}
            className={"col-span-12"}
            value={form?.presented}
            required
            onChange={handleText}
          />

          {/* Tags */}

          <Autocomplete
            multiple
            options={["esports", "org", "team", "management", "production"]}
            freeSolo
            className="col-span-full"
            value={form.tags ?? []}
            onChange={(event, value) => setForm({ ...form, tags: value })}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  size="small"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => <TextField {...params} label="Tags" />}
          />

          <FormControl className="col-span-full">
            <InputLabel>{t("visibility")}</InputLabel>
            <Select
              label={t("visibility")}
              value={form.visibility ?? "draft"}
              onChange={(e) =>
                setForm((form) => ({
                  ...form,
                  visibility: e.target.value as Visibility,
                }))
              }
            >
              <MenuItem value="draft">{t("draft", { ns: "common" })}</MenuItem>
              <MenuItem value="public">
                {t("public", { ns: "common" })}
              </MenuItem>
            </Select>
          </FormControl>

          <Divider className="col-span-full my-8" />
          <div className="col-span-full flex flex-col">
            <Sheet>
              <div className="text-xl mb-2">{t("emoji_playlist_title")}</div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setCreateState(true)}
              >
                {t("add_new_episode")}
              </Button>
            </Sheet>
          </div>

          <div className="col-span-full w-full">
            <div className="text-lg mb-2 mt-4">{t("all_episodes")}</div>
            <EpisodeDirectory
              episodes={form?.episodes}
              clickHandler={(index: number) =>
                setEditState({ episode: form.episodes[index], index })
              }
            />
          </div>

          <EpisodeCreate
            open={createState}
            onClose={() => setCreateState(false)}
            handleSubmit={addEpisode}
            seriesId={boostcampId}
            series={form}
          />

          <EpisodeEdit
            open={Boolean(editState)}
            onClose={() => setEditState(null)}
            episode={editState?.episode}
            handleSubmit={updateEpisode}
            deleteEpisode={deleteEpisode}
            episodeIndex={editState?.index}
            seriesId={boostcampId}
            series={form}
          />

          <div className="col-span-12 flex justify-end mt-4">
            <Button
              variant="outlined"
              className="mr-4"
              color="secondary"
              onClick={deleteBoostcampSeries}
            >
              {t("delete", { ns: "common" })}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t("save", { ns: "common" })}
            </Button>
          </div>
        </form>
      </div>
    </Fade>
  );

  return !user ? (
    <>{NoUser}</>
  ) : loading ? (
    <>{Loading}</>
  ) : err ? (
    <>{Error}</>
  ) : !snap?.exists() ? (
    <>{Missing}</>
  ) : (
    <>{Populated}</>
  );
};

export default BoostcampPage;
