import { AcadDirectoryContainer } from "@components";
import { useTranslation } from "react-i18next";
import EligibilityCreate from "./EligibilityCreate";
import EligibilityDirectory from "./EligibilityDirectory";

export default function Eligibilities() {
  const { t } = useTranslation("scholarships");

  return (
    <AcadDirectoryContainer
      title={t("emoji_eligibilities_title")}
      directoryTitle={t("eligibility_directory")}
      createBtnTitle={t("add_eligibility")}
      createModal={<EligibilityCreate />}
    >
      <EligibilityDirectory />
    </AcadDirectoryContainer>
  );
}
