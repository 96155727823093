import acadJoi from "@acad_joi";
import { Visibility } from "@defs/Tournament.type";
import { slugRE } from "@lib/RegularExpressions";
import {
  collection,
  CollectionReference,
  doc as document,
  DocumentReference,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";

export type LeagueCol = CollectionReference<League>;
export type LeagueDoc = DocumentReference<League>;
export type LeagueSnap = QueryDocumentSnapshot<League>;
export class League {
  title: string = "";
  _title: string = "";
  slug: string = "";
  _slug: string = "";
  hostId: string = "";
  hostName: string = "";
  _hostName: string = "";
  description: string = "";
  logo: string = "";
  cover: string = "";
  initialMMR: number = 1200;
  events: string[] = [];
  visibility: Visibility = "draft";
  createdAt: Timestamp = Timestamp.now();
  updatedAt: Timestamp = Timestamp.now();

  constructor(host?: { name: string; id: string }) {
    this.hostId = host?.id ?? "";
    this.hostName = host?.name ?? "";
    this._hostName = host?.name.toLowerCase() ?? "";
  }

  static path = "leagues";

  static schema = acadJoi.object<League>({
    title: acadJoi.string().required().label("Title"),
    slug: acadJoi.string().regex(slugRE).required().label("Slug").messages({
      "string.pattern.base":
        "Invalid slug. Alphanumeric only, '-' and '_' is allowed.",
    }),
    initialMMR: acadJoi.number().min(0).label("Initial MMR").default(1200),
    description: acadJoi.string().allow(""),
    logo: acadJoi.string().allow(""),
    cover: acadJoi.string().allow(""),
    hostId: acadJoi.string().required(),
    hostName: acadJoi.string().required(),
    _hostName: acadJoi.string().required(),
    _slug: acadJoi.string().required(),
    _title: acadJoi.string().required(),
    visibility: acadJoi.string().valid("draft", "public"),
    events: acadJoi.array().items(acadJoi.string()),
    createdAt: acadJoi.timestamp(),
    updatedAt: acadJoi.timestamp(),
  });

  static col(db: Firestore) {
    const colRef = collection(db, this.path) as LeagueCol;
    return colRef;
  }

  static doc(db: Firestore, id: string) {
    const docRef = document(db, this.path, id) as LeagueDoc;
    return docRef;
  }
}
