import AcadConsts from "@consts";
import { Subregion } from "@defs/region/subregion";
import { Button, TextField } from "@mui/material";
import {
  collection,
  limit,
  query,
  QueryConstraint,
  where,
} from "firebase/firestore";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFirestore } from "reactfire";
import { useUserTable } from "../UserTableContext";
import RegionFilter from "./RegionFilter";
import SchoolFilter from "./SchoolFilter";
import VerificationFilter from "./VerificationFilter";
import { useRegion } from "@hooks/use_region";

function Filters() {
  const {
    setFilters,
    users: { update = AcadConsts.noop },
  } = useUserTable();
  const db = useFirestore();
  const { subregions, isPh } = useRegion();

  const [regionFilter, setRegionFilter] = useState<Subregion[]>(subregions);
  const regionProps = { regionFilter, setRegionFilter };

  const [schoolFilter, setSchoolFilter] = useState<string | null>(null);
  const schoolProps = { schoolFilter, setSchoolFilter };

  const [isVerified, setIsVerified] = useState<boolean | undefined>(undefined);
  const verifiedFilterProps = { isVerified, setIsVerified };

  const [username, setUsername] = useState("");
  const { t } = useTranslation("users");
  /**
   * Applies the filter. We are avoiding to
   * automatically apply it upon rerender
   * to save READS.
   */
  function apply() {
    let constraints: QueryConstraint[] = [];

    // TODO: Brazil region filter
    if (isPh) {
      constraints.push(where("region", "in", regionFilter));
    }

    if (schoolFilter) {
      constraints.push(where("school", "==", schoolFilter));
    }

    if (isVerified !== undefined) {
      constraints.push(where("verified", "==", isVerified));
    }

    if (username) {
      constraints.push(where("username", ">=", username.toLowerCase()));
    }
    setFilters(constraints);
    const ref = collection(db, "users");
    update(query(ref, ...constraints));
  }

  /**
   * Resets filter to default state
   */
  function reset() {
    let constraints: QueryConstraint[] = [limit(20)];

    // TODO: Brazil region filter
    if (isPh) {
      constraints.push(where("region", "in", subregions));
      setRegionFilter(subregions);
    }

    setSchoolFilter(null);
    setUsername("");
    setFilters(constraints);
    setIsVerified(undefined);
    const ref = collection(db, "users");
    update(query(ref, ...constraints));
  }

  return (
    <div className="w-full">
      <div>{t("filters")}</div>
      <div className="grid gap-4 xl:grid-cols-4 w-full p-4">
        <TextField
          label={t("username")}
          value={username}
          className="col-span-2"
          onChange={(e) => setUsername(e.target.value)}
        />
        <SchoolFilter {...schoolProps} />
        {isPh && <RegionFilter {...regionProps} />}

        <VerificationFilter {...verifiedFilterProps} />
      </div>
      <div className="flex flex-row-reverse">
        <Button variant="contained" onClick={apply}>
          {t("apply", { ns: "common" })}
        </Button>
        <Button variant="outlined" className="mr-2" onClick={reset}>
          {t("reset", { ns: "common" })}
        </Button>
      </div>
    </div>
  );
}

export default Filters;
