import Sheet from "@components/Sheet";
import loadingMessage from "@defs/LoadingMessages";
import { Participant } from "@defs/Participant.type";
import { DialogProps } from "@defs/Types";
import { Series } from "@models/series";
import { Add, DeleteForever } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useFunctions } from "reactfire";
import { useTournamentEdit } from "../TournamentEdit";

interface RoundSeriesMatchDialogProps extends DialogProps {
  match: Series;
  teamA?: Participant;
  teamB?: Participant;
}
type MatchInput = {
  tournament_id: number;
  id: number;
  scores_csv: string;
  winner_id: number | null;
  [key: string]: any;
};

const RoundSeriesMatchDialog = ({
  open,
  onClose,
  match,
  teamA,
  teamB,
}: RoundSeriesMatchDialogProps) => {
  const fn = useFunctions();
  const [form, setForm] = useState<MatchInput>({
    id: match.id,
    tournament_id: match.tournamentId,
    scores_csv: "0-0",
    winner_id: null,
  });
  const tournamentSnap = useTournamentEdit();

  const { t } = useTranslation("events");

  useEffect(() => {
    if (!match) return;
    setForm({
      id: match.id,
      tournament_id: match.tournamentId,
      scores_csv: match.scores.join(",") || "0-0",
      winner_id: match.winnerId,
    });
  }, [match]);

  const handleChange =
    (playerIndex: number, scoreIndex: number) =>
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      let scoreArray = form.scores_csv.split(",").map((s, i) => {
        if (i !== scoreIndex) return s;
        let ss = s.match(/^(\d*)-(\d*)/);
        let SSSarray = ss
          ?.map((sss, ii) => {
            if (ii !== playerIndex) return sss;
            if (isNaN(parseInt(value))) {
              return 0;
            }
            return parseInt(value) < 0 ? 0 : parseInt(value);
          })
          .filter((sss) => sss !== s);

        return SSSarray?.join("-");
      });
      setForm((state) => ({ ...state, scores_csv: scoreArray.join(",") }));
    };

  const addMatch = () => {
    setForm((state) => ({ ...state, scores_csv: state.scores_csv + ",0-0" }));
  };

  const setWinner = (id: number) => () => {
    setForm((state) => ({
      ...state,
      winner_id: id === state.winner_id ? null : id,
    }));
  };

  const save = () => {
    if (form.scores_csv.split(",").includes("0-0"))
      return toast.error(t("remove_all_zero_zero_scores"));

    const updateScore = httpsCallable(fn, "tournamentSeries-update");
    const promise = updateScore({
      match: form,
      documentPath: tournamentSnap?.ref.path,
    });
    toast.promise(promise, {
      loading: t("saving", { ns: "common" }),
      error: (err) => {
        console.error(err);
        return err.message;
      },
      success: (data) => {
        console.log(data);
        onClose();
        return t("match_successfully_updated");
      },
    });
  };

  const runSeriesAction =
    (option: "markAsUnderway" | "unmarkAsUnderway" | "reopen") => () => {
      type Props = {
        seriesId: number;
        fireTourId: string;
      };
      const callable = httpsCallable<Props>(fn, `tournamentSeries-${option}`);
      toast.promise(
        callable({ seriesId: match.id, fireTourId: tournamentSnap?.id ?? "" }),
        {
          loading: loadingMessage(),
          error: (err) => err.message,
          success: t("done", { ns: "common" }),
        }
      );
    };

  const removeRound = (index: number) => () => {
    setForm((state) => ({
      ...state,
      scores_csv:
        state.scores_csv
          .split(",")
          .filter((score, i) => i !== index)
          .join(",") || "0-0",
    }));
  };

  return (
    <Dialog {...{ open, onClose }}>
      <DialogTitle>
        Match {match.identifier}: {teamA?.name || "TBD"} vs{" "}
        {teamB?.name || "TBD"}
      </DialogTitle>

      <DialogContent>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ p: 2, pb: 4 }}
          >
            <div
              className="bg-contain bg-center h-28 w-28 bg-gray-100 rounded-lg flex justify-center items-center text-xl font-bold"
              style={{ backgroundImage: `url(${teamA?.logo})` }}
            >
              {!teamA && "TBD"}
            </div>
            <Typography className="text-2xl font-bold mx-4">VS</Typography>
            <div
              className="bg-contain bg-center h-28 w-28 bg-gray-100 rounded-lg flex justify-center items-center text-xl font-bold"
              style={{ backgroundImage: `url(${teamB?.logo})` }}
            >
              {!teamB && "TBD"}
            </div>
          </Stack>
          {teamA && teamB && (
            <Stack>
              <Sheet>
                <Stack direction="row" gap={1} alignItems="center">
                  <div className="text-xl">{t("scores")}</div>

                  <Button
                    className="mt-2"
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={addMatch}
                    size="small"
                  >
                    {t("add_match")}
                  </Button>
                </Stack>

                {form.scores_csv.split(",").map((score, i) => {
                  let ss = score.match(/^(\d*)-(\d*)/);
                  let team1 = ss?.length ? ss[1] : 0;
                  let team2 = ss?.length ? ss[2] : 0;
                  return (
                    <Stack
                      gap={2}
                      sx={{ mt: 2 }}
                      direction="row"
                      alignItems="center"
                    >
                      <TextField
                        label={`${teamA?.name} Game${i + 1}`}
                        value={team1}
                        onChange={handleChange(1, i)}
                        type="number"
                      />
                      <TextField
                        label={`${teamB?.name} Game${i + 1}`}
                        value={team2}
                        onChange={handleChange(2, i)}
                        type="number"
                      />
                      <IconButton
                        sx={{ flexShrink: 0 }}
                        onClick={removeRound(i)}
                      >
                        <DeleteForever />
                      </IconButton>
                    </Stack>
                  );
                })}
              </Sheet>

              <Sheet>
                <div className="text-xl">{t("select_winner")}</div>
                <div className="grid grid-cols-2 gap-2">
                  <Button
                    variant={
                      teamA?.chalId === form.winner_id
                        ? "contained"
                        : "outlined"
                    }
                    onClick={setWinner(teamA?.chalId ?? 0)}
                    className={`${
                      form.winner_id === teamA?.chalId &&
                      "border-1 border-gray-200"
                    }`}
                  >
                    {teamA?.name}
                  </Button>
                  <Button
                    variant={
                      teamB?.chalId === form.winner_id
                        ? "contained"
                        : "outlined"
                    }
                    onClick={setWinner(teamB?.chalId ?? 0)}
                    className={`${
                      form.winner_id === teamA?.chalId &&
                      "border-1 border-gray-200"
                    }`}
                  >
                    {teamB?.name}
                  </Button>
                </div>
              </Sheet>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Stack direction="row" gap={1} sx={{ mr: 1 }}>
            <Button
              variant={!!match.underwayAt ? "contained" : "outlined"}
              disabled={match.state === "complete" || !teamA || !teamB}
              onClick={runSeriesAction(
                !!match.underwayAt ? "unmarkAsUnderway" : "markAsUnderway"
              )}
            >
              {!!match.underwayAt
                ? t("unmark_as_underway")
                : t("mark_as_underway")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={match.state !== "complete"}
              onClick={runSeriesAction("reopen")}
            >
              {t("reopen_or_reset")}
            </Button>
          </Stack>
          <Button
            variant="contained"
            disabled={!teamA || !teamB}
            onClick={save}
          >
            {t("save", { ns: "common" })}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default RoundSeriesMatchDialog;
