import { AcadTextField } from "@components";
import Iconify from "@components/Iconify";
import { GameField } from "@models/game";
import { Button, Card } from "@mui/material";
import { Path, UseFormRegisterReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

interface GameFieldFormProps {
  register: (path: Path<GameField>) => UseFormRegisterReturn;
  removeField: () => void;
}

export default function GameFieldForm({
  register,
  removeField,
}: GameFieldFormProps) {
  const { t } = useTranslation("games");

  const deleteField = () => {
    Swal.fire({
      title: t("delete_field_question"),
      text: t("are_you_sure_you_want_to_delete_this_field"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "error",
    }).then((res) => {
      if (res.isConfirmed) {
        removeField();
      }
    });
  };
  return (
    <Card className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
      <AcadTextField
        label={t("field_name")}
        size="small"
        {...register("fieldName")}
        required
      />
      <AcadTextField
        multiline
        label={t("description", { ns: "common" })}
        size="small"
        className="col-span-full"
        minRows={3}
        maxRows={5}
        {...register("description")}
      />
      <Button
        onClick={deleteField}
        startIcon={<Iconify icon="eva:trash-2-fill" />}
        sx={{ justifySelf: "flex-start" }}
        color="error"
        variant="outlined"
        size="small"
      >
        {t("remove", { ns: "common" })}
      </Button>
    </Card>
  );
}
