import * as Joi from "joi";
import stringExtension from "./extensions/string";
import { timestampExtension, TimestampSchema } from "./extensions/timestamp";

interface IAcadJoi extends Joi.Root {
  timestamp(): TimestampSchema;
}

/**
 * AcadArena's custom Joi overrides. Use acadJoi over just Joi.
 * @param schema
 * @returns
 */
const acadJoi: IAcadJoi = Joi.extend(
  // firestore timestamp validator
  timestampExtension,
  stringExtension
);

export default acadJoi;
