import { AcadNavTabs } from "@components";
import { LinkProps } from "@defs/Links";
import { ReferralCode } from "./referrals/referral_code";
import { ReferralCodeEdit } from "./referrals/referral_code_edit";
import UserList from "./UserList";

const routes: LinkProps[] = [
  {
    url: "/users/user-list",
    component: <UserList />,
    exact: true,
    title: "user_list",
    adminOnly: true,
    icon: "👾",
    onNavbar: true,
  },
  {
    url: "/users/referrals",
    component: <ReferralCode />,
    exact: true,
    title: "referrals",
    adminOnly: true,
    icon: "💫",
    onNavbar: true,
  },
  {
    url: "/users/referrals/:code",
    component: <ReferralCodeEdit />,
    title: "referrals",
    adminOnly: true,
  },
];

const Users: React.VFC = () => {
  return <AcadNavTabs routes={routes} />;
};

export default Users;
