import { useScreen } from "@hooks";
import { Stack, Card, CardProps, Typography } from "@mui/material";
import { forwardRef } from "react";

interface HeroCardProps extends CardProps {
  headline: string;
  backgroundImage: string;
  subHeadline?: string;
}

/**
 * This is a Card style Hero section component
 * click here for reference:
 * https://www.figma.com/file/c1LyU6sS77YZyyBZX3tR6p/The-Academy?node-id=304%3A26685
 */
const HeroCard = forwardRef<"div", HeroCardProps>(
  ({ children, headline, subHeadline, backgroundImage, ...props }, ref) => {
    const { tablet } = useScreen();
    return (
      <Card
        sx={{
          position: "relative",
          borderRadius: 3,
          border: 0,
          height: 411,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url("${backgroundImage}")`,
          padding: tablet ? 10 : 4,
          "&::after": {
            content: "''",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            height: "100%",
            width: "100%",
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0) 25.31%, rgba(0, 0, 0, 0.663636) 50.31%, rgba(0, 0, 0, 0.8) 84.69%);",
          },
          zIndex: 2,
          ...props.sx,
        }}
      >
        <Stack
          gap={1}
          sx={{
            justifyContent: "flex-end",
            alignItems: "flex-start",
            position: "relative",
            height: "100%",
            zIndex: 3,
          }}
        >
          <Stack gap={0.5} sx={{ maxWidth: 628 }}>
            <div
              style={{
                fontFamily: "'Druk Wide'",
                fontWeight: 700,
                fontSize: tablet ? 28 : 20,
                textTransform: "uppercase",
                color: "#F9FAFB",
                textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                lineHeight: 1.2,
              }}
            >
              {headline}
            </div>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "'DM Sans'",
                color: "#F9FAFB",
                textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              {subHeadline}
            </Typography>
          </Stack>
          {children}
        </Stack>
      </Card>
    );
  }
);

export default HeroCard;
