import { ReactNode } from "react";

interface DetailProps {
  label?: ReactNode;
  value?: ReactNode;
  className?: string;
}

export const Detail = ({ label, value, className }: DetailProps) => (
  <div className={`flex flex-col text-left items-start ${className}`}>
    <div className="leading-1 text-gray-400 text-xs">{label}</div>
    <div className="leading-none text-gray-700 text-sm">{value}</div>
  </div>
);
