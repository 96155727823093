import { useSchoolList } from "@contexts/schoolList/SchoolListContextProvider";
import { Autocomplete, TextField } from "@mui/material";
import { SyntheticEvent } from "react";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  schoolFilter: string | null;
  setSchoolFilter: Dispatch<SetStateAction<string | null>>;
}

function SchoolFilter(props: Props) {
  const schools = useSchoolList();

  function onChange(event: SyntheticEvent, value: string | null) {
    props.setSchoolFilter(value);
  }
  const { t } = useTranslation("users");

  return (
    <Autocomplete
      className="col-span-2"
      value={props.schoolFilter}
      options={schools}
      onChange={onChange}
      renderInput={(params) => (
        <TextField label={t("school")} {...params} fullWidth />
      )}
    />
  );
}

export default SchoolFilter;
