import {
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  setDoc,
  WithFieldValue,
} from "@firebase/firestore";
import { Dispatch, SetStateAction, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface UseCreateDocumentParams {
  onDocAlreadyExists?(): void;
  onCreated?(): string;
  onError?(error: any): string | void;
  existsCheckMsg?: string;
  creatingMsg?: string;
  docExistsMsg?: string;
}

/**
 * Responsible for managing the document creation life cycle,
 * from checking if the document exists, to error handling, to
 * toast display, up to successful document creation.
 * @param params
 * @returns
 */
export default function useCreateDocument<T>(params: UseCreateDocumentParams) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("hooks");
  /**
   * retrieves document. If document already exists, throws an error.
   * @param param0
   * @param form
   * @param docReference
   * @returns
   */
  function retrieveDocument<T>(
    { onDocAlreadyExists, docExistsMsg }: UseCreateDocumentParams,
    docReference: DocumentReference<T>
  ): Promise<DocumentSnapshot<T>> {
    return new Promise<DocumentSnapshot<T>>(async (resolve, reject) => {
      try {
        const snap = await getDoc(docReference);
        if (snap.exists()) {
          if (onDocAlreadyExists) {
            onDocAlreadyExists();
          }
          reject({
            code: docExistsMsg ?? t("doc_exists"),
          });
        } else {
          resolve(snap);
        }
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  }

  async function doesDocExist<T>(
    params: UseCreateDocumentParams,
    docRef: DocumentReference<T>
  ) {
    const { existsCheckMsg } = params;
    const retrievePromise = retrieveDocument<T>(params, docRef);
    try {
      await toast.promise(
        retrievePromise,
        {
          success() {
            return t("doc_available");
          },
          error(error) {
            console.error(error);
            return t("failed_error", { error: error.code });
          },
          loading: existsCheckMsg ?? t("doc_check_exists"),
        },
        { className: "text-center" }
      );
      return false;
    } catch (e) {
      return true;
    }
  }

  /**
   * Apart from saving the document, it also shows a toast of its progress.
   * @param param0
   * @param setLoading
   * @param documentRef
   * @param form
   */
  function saveDocument<T>(
    { onCreated, onError, creatingMsg }: UseCreateDocumentParams,
    setLoading: Dispatch<SetStateAction<boolean>>,
    documentRef: DocumentReference<T>,
    form: WithFieldValue<T>
  ) {
    return toast.promise(setDoc(documentRef, form), {
      success() {
        setLoading(false);
        if (onCreated) {
          return onCreated();
        }
        return t("doc_create_success");
      },
      error(error) {
        console.error(error);
        setLoading(false);

        if (onError) {
          return onError(error) ?? t("failed_error", { error: error.code });
        }
        return t("failed_error", { error: error.code });
      },
      loading: creatingMsg ?? t("doc_creating"),
    });
  }

  async function showCreateToast<T>(
    params: UseCreateDocumentParams,
    setLoading: Dispatch<SetStateAction<boolean>>,
    form: WithFieldValue<T>,
    docRef: DocumentReference<T>
  ) {
    if (await doesDocExist<T>(params, docRef)) {
      setLoading(false);
      return false;
    }
    return saveDocument<T>(params, setLoading, docRef, form);
  }

  const createDocument = (
    form: WithFieldValue<T>,
    docRef: DocumentReference<T>
  ) => {
    setLoading(true);
    return showCreateToast<T>(params, setLoading, form, docRef);
  };

  const docExists = (docRef: DocumentReference<T>) => {
    return doesDocExist(params, docRef);
  };

  return { loading, createDocument, docExists };
}
