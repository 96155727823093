import { LinkProps } from "@defs/Links";
import Sponsors from "./Sponsors";
import Benefits from "./Benefits";
import SponsorEdit from "./Sponsors/SponsorEdit";
import BenefitEdit from "./Benefits/BenefitEdit";
import Eligibilities from "./Eligibilities";
import EligibilityEdit from "./Eligibilities/EligibilityEdit";
import Requirements from "./Requirements";
import RequirementEdit from "./Requirements/RequirementEdit";
import Scholarships from "./Scholarships";
import ScholarshipEdit from "./Scholarships/ScholarshipWrite/ScholarshipEdit";
import ScholarshipSettings from "./Settings/ScholarshipSettings";

const routes: LinkProps[] = [
  {
    url: "/scholarship/scholarships",
    component: <Scholarships />,
    exact: true,
    title: "scholarships",
    adminOnly: true,
    icon: "🎓",
    onNavbar: true,
  },
  {
    url: "/scholarship/benefits",
    component: <Benefits />,
    exact: true,
    title: "benefits",
    adminOnly: true,
    icon: "💸",
    onNavbar: true,
  },
  {
    url: "/scholarship/eligibilities",
    component: <Eligibilities />,
    exact: true,
    title: "eligibilities",
    adminOnly: true,
    icon: "⚠",
    onNavbar: true,
  },
  {
    url: "/scholarship/requirements",
    component: <Requirements />,
    exact: true,
    title: "requirements",
    adminOnly: true,
    icon: "📃",
    onNavbar: true,
  },
  {
    url: "/scholarship/sponsors",
    component: <Sponsors />,
    exact: true,
    title: "sponsors",
    adminOnly: true,
    icon: "💰",
    onNavbar: true,
  },
  {
    url: "/scholarship/settings",
    component: <ScholarshipSettings />,
    exact: true,
    title: "settings",
    adminOnly: true,
    icon: "⌚",
    onNavbar: true,
  },
  {
    url: "/scholarship/sponsors/:sponsorId",
    component: <SponsorEdit />,
    title: "sponsors",
    adminOnly: true,
  },
  {
    url: "/scholarship/benefits/:benefitId",
    component: <BenefitEdit />,
    title: "benefits",
    adminOnly: true,
  },
  {
    url: "/scholarship/eligibilities/:eligibilityId",
    component: <EligibilityEdit />,
    title: "eligibilities",
    adminOnly: true,
  },
  {
    url: "/scholarship/requirements/:requirementId",
    component: <RequirementEdit />,
    title: "requirements",
    adminOnly: true,
  },
  {
    url: "/scholarship/scholarships/:scholarshipSlug",
    component: <ScholarshipEdit />,
    title: "scholarships",
    adminOnly: true,
  },
];

export default routes;
