/**
 * There is a lot of type assertion in
 * this file, that is because Path<T> is
 * not picking up extended types
 */

import { AcadEditDocSection, AcadTextField } from "@components";
import { NextConfigExtend } from "@models/next_server_config";
import {
  Card,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import {
  Control,
  Controller,
  DeepMap,
  DeepPartial,
  FieldError,
  Path,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

interface AcadNextConfigFormProps<T extends NextConfigExtend> {
  control: Control<T>;
  errors: DeepMap<DeepPartial<T>, FieldError>;
}
function NextConfigForm<T extends NextConfigExtend>({
  errors,
  control,
}: AcadNextConfigFormProps<T>) {
  const { t } = useTranslation("components");
  return (
    <AcadEditDocSection title="NextJS Static Configuration">
      <Stack gap={4} sx={{ flex: 1 }}>
        <Stack>
          <Typography>⏲️ {t("revalidate_duration")}</Typography>
          <Card sx={{ p: 2 }}>
            <Stack direction={{ mobile: "column", tablet: "row" }} gap={2}>
              <AcadTextField
                label={t("directory_revalidate_duration")}
                {...control.register(
                  "nextConfig.list.revalidate" as Path<NextConfigExtend> as Path<T>
                )}
                errors={errors}
              />
              <AcadTextField
                label={t("page_revalidate_duration")}
                {...control.register(
                  "nextConfig.page.revalidate" as Path<NextConfigExtend> as Path<T>
                )}
                errors={errors}
              />
            </Stack>
          </Card>
        </Stack>
        <Stack>
          <Typography>❔{t("hide_pages")}</Typography>
          <Card>
            <Stack sx={{ p: 2 }}>
              <ConfigCheckbox
                name="nextConfig.list.notFound"
                label={t("directory_hidden")}
                control={control}
              />

              <ConfigCheckbox
                name="nextConfig.page.notFound"
                label={t("individual_pages_hidden")}
                control={control}
              />
            </Stack>
          </Card>
        </Stack>
        <Stack>
          <Typography>⚠️ {t("maintenance_mode")}</Typography>
          <Card>
            <Stack sx={{ p: 2 }}>
              <ConfigCheckbox
                name="nextConfig.list.underMaintenance"
                label={t("directory_under_maintenance")}
                control={control}
              />

              <ConfigCheckbox
                name="nextConfig.page.underMaintenance"
                label={t("pages_under_maintenance")}
                control={control}
              />
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </AcadEditDocSection>
  );
}

interface ConfigCheckboxProps<T> {
  name: Path<NextConfigExtend>;
  label: string;
  control: Control<T>;
}
function ConfigCheckbox<T>({ control, name, label }: ConfigCheckboxProps<T>) {
  return (
    <FormControlLabel
      label={label}
      control={
        <Controller
          control={control}
          name={name as Path<T>}
          render={({ field }) => (
            <Switch
              {...field}
              checked={field.value as boolean}
              onChange={(e) => field.onChange(e.target.checked as boolean)}
            />
          )}
        />
      }
    />
  );
}

export default NextConfigForm;
