import { Timestamp } from "firebase/firestore";
import { DiscordUser } from "./discord";
// import { Game } from "./Games";
import IGN from "./ign";
import { Subregion } from "./region/subregion";
import { OrgProps } from "./Types";

interface CountryPhoneNumberData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}
export interface UserData {
  displayName: string;
  email: string;
  photoURL: string;
  uid: string;
  username: string;
  _username?: string;
  verified: boolean;
  verificationExpiry?: Timestamp;
  flags: string[];
  igns: IGN[];
  photoMain: string;
  photoSub: string;
  photoHeadshot: string;
  firstTimeSetup: boolean;
  phoneNumber: string;
  phoneNumberFormatted?: string;
  phoneNumberCountryData?: Partial<CountryPhoneNumberData>;
  isStudent: boolean;
  studentYear?: StudentYearEnum;
  ingressYear?: number;
  school?: string;
  pendingSchoolName?: string;
  course?: string;
  isWaypointUser?: string;
  wasInCollege: boolean;
  tags: UserTags[];
  isOrgMember: boolean;
  org?: Partial<OrgProps>;

  firstName: string;
  middleInitial: string;
  lastName: string;

  region: Subregion;
  geography: string;
  statusText: string;
  coverPhoto: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  teams: string[];

  discord?: DiscordUser;
  isVarsity?: boolean;

  nonStudentType?: NonStudentTypes;
}

export enum NonStudentTypes {
  Alumni = "alumni",
  Staff = "staff",
}

// interface IGNProps {
//   game: Game;
//   ign: string;
// }

export enum StudentYearEnum {
  junior_highschool,
  grade_11,
  grade_12,
  first_year,
  second_year,
  third_year,
  fourth_year,
  fifth_year,
  post_graduate,
}

export enum UserTags {
  student_leader = "Student Leader", //student
  club_member = "Club Member", //student
  student_competitor = "Student Competitor", // student
  aspiring_professional = "Aspiring Professional",
  casual_gamer = "Casual Gamer",
  content_creator = "Content Creator",
  non_gamer = "Non-gamer",
  teacher = "Teacher", // non-student
  professional = "Professional", // non-student
  parent = "Parent", // non-student
}
