import loadingMessage from "@defs/LoadingMessages";
import {
  DocumentReference,
  DocumentSnapshot,
  PartialWithFieldValue,
  setDoc,
} from "firebase/firestore";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useFirestoreDoc } from "reactfire";

interface UseWriteDocumentParams<T> {
  docRef: DocumentReference<T>;
  onSnapshotChanged?(snap: DocumentSnapshot<T>): void;
  successMsg?: string;
  loadingMsg?: string;
}

/**
 * Write on Document Reference
 * @param param0
 * @returns
 */
export default function useWriteDocument<T>({
  docRef,
  onSnapshotChanged,
  successMsg = "Saved successfully",
  loadingMsg = loadingMessage(),
}: UseWriteDocumentParams<T>) {
  const { status, data: snap, error } = useFirestoreDoc(docRef);
  const loading = status === "loading";

  useEffect(() => {
    if (onSnapshotChanged) {
      onSnapshotChanged(snap);
    }
  }, [snap, onSnapshotChanged]);

  function writeDocument(form: PartialWithFieldValue<T>, merge = true) {
    const promise = setDoc(docRef, form, { merge });

    return toast.promise(promise, {
      loading: loadingMsg,
      error: (err) => err.message,
      success: successMsg,
    });
  }

  return { status, loading, snap, error, writeDocument };
}
