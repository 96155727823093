import { doc, DocumentReference } from "@firebase/firestore";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { DialogProps } from "@framework/types";
import { useHistory } from "react-router-dom";
import Sponsor from "@models/sponsor";
import { useCreateDocument } from "@hooks";
import { AcadImageButton, AcadModal, AcadTextField } from "@components";
import { useFirestore } from "reactfire";
import { useContext } from "react";
import { fileUploadContext, FileUploadProvider } from "@contexts/file_upload";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useTranslation } from "react-i18next";

const SponsorCreateContent = ({ open, onClose }: DialogProps) => {
  const history = useHistory();
  const db = useFirestore();
  const { uploadFilesAndSet } = useContext(fileUploadContext);
  const {
    getValues,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Sponsor>({
    defaultValues: new Sponsor(),
    resolver: joiResolver(Sponsor.schema),
  });

  const { t } = useTranslation("scholarships");

  const { loading, createDocument } = useCreateDocument<Sponsor>({
    onCreated: () => {
      if (onClose) {
        onClose();
      }
      history.push(`/scholarship/sponsors/${getValues().id}`);
      return t("successfully_created_new_sponsor");
    },
    docExistsMsg: t("sponsor_with_id_already_exists", { id: getValues().id }),
  });

  const createDoc = async () => {
    await uploadFilesAndSet(setValue);
    handleSubmit((value) => {
      createDocument(
        value,
        doc(db, Sponsor.PATH, value.id) as DocumentReference<Sponsor>
      );
    })();
  };

  return (
    <AcadModal open={open} onClose={onClose} loading={loading}>
      <DialogContent className="overflow-y-visible">
        <div className="grid grid-cols-12 gap-4">
          <div className="text-lg mt-4 mb-2 col-span-full">
            {t("sponsor_info")}
          </div>
          <AcadImageButton
            name="logoUrl"
            path={getValues().logoUrl}
            className="col-span-4 row-span-2 bg-gray-200 bg-cover bg-center rounded-md"
            noImageText="Upload Sponsor Logo"
          />
          <AcadTextField
            variant="outlined"
            size="medium"
            label={t("id")}
            className="col-span-8"
            {...register("id")}
            helperText={t("sponsor_id_helper")}
            required
            errors={errors}
          />
          <AcadTextField
            variant="outlined"
            size="medium"
            label={t("sponsor_name")}
            className="col-span-8"
            {...register("name")}
            helperText={t("sponsor_name_helper")}
            required
            errors={errors}
          />
          <AcadTextField
            multiline
            rows={9}
            variant="outlined"
            size="medium"
            label={t("sponsor_description")}
            className="col-span-full"
            {...register("description")}
            helperText={t("sponsor_description_helper")}
            required
            errors={errors}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={createDoc}>
          {t("save", { ns: "common" })}
        </Button>
      </DialogActions>
    </AcadModal>
  );
};

const SponsorCreate = (props: DialogProps) => {
  return (
    <FileUploadProvider>
      <SponsorCreateContent {...props} />
    </FileUploadProvider>
  );
};

export default SponsorCreate;
