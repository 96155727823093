import { AcadDirectoryContainer } from "@components";
import { useTranslation } from "react-i18next";
import UserDocSchoolsCtxProvider from "../components/UserDocSchoolsCtxProvider";
import DocumentsDirectory from "./VerificationDocumentsDirectory";

const Pending = () => {
  const { t } = useTranslation("student_verification");
  return (
    <UserDocSchoolsCtxProvider>
      <AcadDirectoryContainer
        title={t("emoji_pending_documents_title")}
        directoryTitle={t("pending_documents")}
      >
        <DocumentsDirectory />
      </AcadDirectoryContainer>
    </UserDocSchoolsCtxProvider>
  );
};
export default Pending;
