import NextConfigForm from "@components/seo/NextConfigForm";
import SEOForm from "@components/seo/SEOForm";
import { withFileUpload } from "@hocs";
import { joiResolver } from "@hookform/resolvers/joi";
import { useWriteDocument } from "@hooks";
import useFileUploadContext from "@hooks/use_file_upload_context";
import SEO from "@models/seo";
import { CommunityParams } from "@models/syspars/community_params";
import { Button, Stack } from "@mui/material";
import { doc, DocumentReference, DocumentSnapshot } from "firebase/firestore";
import { MouseEventHandler, useMemo } from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useFirestore } from "reactfire";
import CommunitySlides from "./CommunitySlides";

type DocRef = DocumentReference<CommunityParams>;

const CommunitySettings = () => {
  const db = useFirestore();
  const docRef = doc(db, CommunityParams.PATH) as DocRef;
  const {
    setValue,
    reset,
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<CommunityParams>({
    defaultValues: new CommunityParams(),
    resolver: joiResolver(CommunityParams.schema),
  });

  const onSnapshotChanged = useMemo(() => {
    return (snap: DocumentSnapshot<CommunityParams>) => {
      reset(snap?.data() ?? new CommunityParams());
    };
  }, [reset]);

  const { snap, writeDocument } = useWriteDocument<CommunityParams>({
    docRef,
    onSnapshotChanged,
  });

  const { t } = useTranslation("community");
  const { uploadFilesAndSet } = useFileUploadContext();
  const save: MouseEventHandler<HTMLButtonElement> = async (e) => {
    await uploadFilesAndSet(setValue);
    handleSubmit(async (data) => {
      const promise = writeDocument(data);
      toast.promise(promise, {
        loading: t("saving_changes", { ns: "common" }),
        error: (err) => {
          console.error(err);
          return t("unable_to_save_changes", { ns: "common" });
        },
        success: t("done", { ns: "common" }),
      });
    }, console.error)(e);
  };

  return (
    <Stack sx={{ p: 2 }}>
      <CommunitySlides control={control} />

      <SEOForm
        errors={errors}
        register={register as UseFormRegister<CommunityParams | { SEO: SEO }>}
        thumbnailUrl={snap?.data()?.SEO?.thumbnail}
      />

      <NextConfigForm control={control} errors={errors} />

      <Stack sx={{ m: 2 }} direction="row" justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={save}>
          {t("save", { ns: "common" })}
        </Button>
      </Stack>
    </Stack>
  );
};

export default withFileUpload(CommunitySettings);
