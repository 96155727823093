import { Alert, Card, Fade, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

interface AcadDirectoryProps {
  loading: boolean;
  hasData: boolean;
  error?: Error;
  noDataEl?: JSX.Element | string;
  loadingEl?: JSX.Element;
  errorEl?: JSX.Element;
  className?: string;
}

const InnerAcadDirectory: React.FC<AcadDirectoryProps> = ({
  loading,
  error,
  hasData,
  children,
  noDataEl,
  loadingEl,
  errorEl,
}) => {
  const { t } = useTranslation("common");
  if (loading) {
    return !!loadingEl ? (
      <Fade in appear>
        {/* make this more robust and changeable in the future. Currently it assumes we have the card format for everything */}
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
          {[...Array(3)].map((__, i) => (
            <Skeleton
              variant="rectangular"
              className="rounded-md"
              animation="wave"
              width="100%"
              key={i}
            >
              {loadingEl}
            </Skeleton>
          ))}
        </div>
      </Fade>
    ) : (
      <></>
    );
  }

  if (error && errorEl) {
    return errorEl ?? <Alert severity="error">{error.message}</Alert>;
  }

  if (!hasData) {
    return (
      <Card className="w-full h-52 text-center flex justify-center items-center text-xl bg-gray-200 shadow-none">
        {noDataEl ?? t("no_data")}
      </Card>
    );
  }

  return (
    <Fade in appear>
      <div>{children}</div>
    </Fade>
  );
};

const AcadDirectory: React.FC<AcadDirectoryProps> = (props) => {
  const { className = "" } = props;

  return (
    <div className={className}>
      <InnerAcadDirectory {...props} />
    </div>
  );
};

export default AcadDirectory;
