import { collection, limit, query, where } from "@firebase/firestore";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  MenuItem,
  useMediaQuery,
  ListItemText,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { FormEvent, useEffect, useMemo, VFC } from "react";
import toast from "react-hot-toast";
import loadingMessage from "@defs/LoadingMessages";
import { OrgProps } from "@defs/Types";
import { useHistory } from "react-router-dom";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFunctions,
} from "reactfire";
import { useForm } from "react-hook-form";
import { DialogProps } from "@framework/types";
import { TournamentCreate } from "@models/tournament_create";
import { joiResolver } from "@hookform/resolvers/joi";
import useUserData from "@lib/AuthHooks";
import { League, LeagueCol } from "@models/league";
import AcadAutoComplete from "@components/textfield/AcadAutocomplete";
import { AcadSelect, AcadTextField } from "@components";
import { TournamentDoc } from "@defs/Tournament.type";
import { useTranslation } from "react-i18next";

interface CreateTournamentProps extends DialogProps {
  orgs: OrgProps[];
  orgsLoading: boolean;
}

const CreateTournament: VFC<CreateTournamentProps> = ({
  open,
  onClose = () => {},
  orgs,
  orgsLoading,
}) => {
  const { t } = useTranslation("events");
  const smUp = useMediaQuery(" (min-width: 640px)");
  const history = useHistory();
  const fn = useFunctions();
  const { isAdmin } = useUserData();
  const db = useFirestore();
  const LeagueRef = collection(db, "leagues") as LeagueCol;
  const q1 = query(LeagueRef, limit(20));
  const q2 = query(
    q1,
    where(
      "hostId",
      "in",
      orgs.map((org) => org.schoolShortcode).filter((org, i) => i < 10)
    )
  );
  const { data: leaguesRaw = [], status: leagueStatus } =
    useFirestoreCollectionData(isAdmin ? q1 : q2, { idField: "leagueId" });

  const leagues = leaguesRaw as (League & { leagueId: string })[];
  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    setError,
  } = useForm<TournamentCreate>({
    defaultValues: { ...new TournamentCreate() },
    resolver: joiResolver(TournamentCreate.schema),
  });

  const setDefaultOrg = useMemo(() => {
    return (orgId: string) => {
      setValue("orgId", orgId);
    };
  }, [setValue]);

  useEffect(() => {
    if (!orgs || !orgs.length || !setDefaultOrg) return;
    setDefaultOrg(orgs[0].schoolShortcode ?? "");
  }, [orgs, setDefaultOrg]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const league = leagues.find((l) => l.leagueId === getValues("leagueId"));
    setValue("leagueTitle", league?.title ?? "");
    setValue("_leagueTitle", league?._title ?? "");
    if (getValues("leagueId") && league?.hostId !== getValues("orgId")) {
      setError("tournament.name", {
        message: t("league_host_is_mismatched_with_tournament_host_org"),
      });
    }
    handleSubmit(
      (data) => {
        const callable = httpsCallable<
          { data: TournamentCreate; useProxy: boolean },
          TournamentDoc
        >(fn, "tournament-create");

        const promise = callable({
          data: data,
          useProxy: true,
        });

        toast.promise(promise, {
          loading: loadingMessage(),
          success: (res) => {
            onClose();
            history.push(`tournaments/${res.data.id}`);
            return t("successfully_created_tournament");
          },
          error: (err) => err.message,
        });
      },
      (err) => {
        console.error(err);
      }
    )(e);
  };

  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={!smUp}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>{t("create_tournament")}</DialogTitle>
        <DialogContent className="relative overflow-x-hidden">
          {orgsLoading || leagueStatus === "loading" ? (
            <div className="h-20 w-20 flex justify-center items-center">
              <CircularProgress />
            </div>
          ) : (
            <Fade in appear>
              <div className="grid grid-cols-12 gap-4 mt-2">
                <AcadTextField
                  className="col-span-full"
                  label={t("tournament_title")}
                  {...register("tournament.name")}
                  errors={errors}
                  required
                />
                <AcadAutoComplete
                  fullWidth
                  className="col-span-full"
                  control={control}
                  name="orgId"
                  disableClearable
                  autoSelect
                  options={orgs.map((org) => org.schoolShortcode)}
                  getOptionLabel={(option) =>
                    orgs.find((org) => org.schoolShortcode === option)?.name ??
                    ""
                  }
                  renderOption={(props, option) => (
                    <MenuItem {...props}>
                      {orgs.find((org) => org.schoolShortcode === option)
                        ?.name ?? ""}
                    </MenuItem>
                  )}
                  renderInput={(props) => (
                    <AcadTextField
                      {...props}
                      fullWidth
                      required
                      label="Host Org"
                      errors={errors}
                    />
                  )}
                />

                <AcadSelect
                  control={control}
                  name="leagueId"
                  fullWidth
                  formControlClassName="w-full col-span-full"
                  label={t("league")}
                >
                  <MenuItem value="">{t("none", { ns: "common" })}</MenuItem>
                  {leagues.map((league) => (
                    <MenuItem value={league.leagueId} key={league.leagueId}>
                      <ListItemText
                        primary={league.title}
                        secondary={league.hostName}
                      />
                    </MenuItem>
                  ))}
                </AcadSelect>

                <AcadTextField
                  label={t("tournament_slug")}
                  className="col-span-full"
                  {...register("tournament.url")}
                  errors={errors}
                />
              </div>
            </Fade>
          )}
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            {t("save", { ns: "common" })}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateTournament;
