import { useImagePreview } from "@hocs/withImagePreview";
import { TeamFieldWithAnswer } from "@models/custom_field";
import { OpenInNew } from "@mui/icons-material";
import { Card, CardContent, Stack, Typography, Button } from "@mui/material";
import { VFC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  fieldWithAnswer: TeamFieldWithAnswer;
}
const CustomFieldAnswerTeam: VFC<Props> = ({ id, fieldWithAnswer }) => {
  const openPreview = useImagePreview();
  const { t } = useTranslation("events");
  return (
    <Card key={id}>
      <CardContent>
        <Stack>
          <Typography variant="caption" color="text.secondary" lineHeight={1}>
            Q: {fieldWithAnswer.fieldName}
          </Typography>
          {fieldWithAnswer.answer ? (
            fieldWithAnswer.type === "text" ? (
              <Typography>A: {fieldWithAnswer.answer}</Typography>
            ) : (
              <Button
                size="small"
                sx={{
                  alignSelf: "flex-start",
                  mt: 1,
                }}
                variant="contained"
                endIcon={<OpenInNew />}
                onClick={() => openPreview(fieldWithAnswer.answer)}
              >
                {t("click_here_to_preview")}
              </Button>
            )
          ) : (
            <Typography>{t("no_answer")}</Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
export default CustomFieldAnswerTeam;
