import { AcadDirectoryContainer } from "@components";
import { useTranslation } from "react-i18next";
import { SchoolListPendingProvider } from "./context";
import SchoolListPendingDirectory from "./SchoolListPendingDirectory";

const SchoolListPending = () => {
  const { t } = useTranslation("schools");

  return (
    <AcadDirectoryContainer
      title={t("emoji_school_pending_title")}
      directoryTitle={t("pending_schools")}
    >
      <SchoolListPendingProvider>
        <SchoolListPendingDirectory />
      </SchoolListPendingProvider>
    </AcadDirectoryContainer>
  );
};

export default SchoolListPending;
