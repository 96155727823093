import AcadConsts from "@consts";
import { fileUploadContext } from "@contexts/file_upload";
import { toBase64 } from "@lib/file";
import { ButtonBase } from "@mui/material";
import React, { ChangeEvent, Ref, useContext, useState } from "react";
import { Path } from "react-hook-form";
import i18n from "../i18n";

interface AcadImageButtonProps<T extends Record<any, any> = {}> {
  name: Path<T> | string;
  path?: string;
  onImageSelect?(file?: File, e?: ChangeEvent<HTMLInputElement>): void;
  className?: string;
  noImageText?: string;
  style?: object;
}

/**
 * Image Button. The image is converted into base64 and is loaded as a div background.
 * Component will queue the image for upload if `fileUploadContext` exists.
 * @param param0
 * @returns
 */
const AcadImageButton = React.forwardRef<
  HTMLInputElement,
  AcadImageButtonProps
>(
  (
    {
      name,
      path = "",
      onImageSelect = AcadConsts.noop,
      className = "",
      noImageText = i18n.t("upload_image", { ns: "components" }),
      style = {},
    }: AcadImageButtonProps,
    ref
  ) => {
    const [imgBase64, setImgBase64] = useState<string>("");
    const { queueFile } = useContext(fileUploadContext);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      // if file is not null, show image
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        toBase64(file).then((base64) => {
          setImgBase64(base64 as string);
          onImageSelect(file, e);
          queueFile(name, file);
        });
      }
      // if null, reset image value
      else {
        setImgBase64("");
        onImageSelect(undefined, e);
        queueFile(name);
      }
    };

    return (
      <ButtonBase
        className={className}
        component="label"
        style={{
          backgroundImage: `url("${imgBase64 || path}")`,
          ...style,
        }}
      >
        <input
          ref={ref}
          type="file"
          hidden
          name={name}
          onChange={onChange}
          accept="image/*"
        />
        <span className="text-sm text-center">
          {imgBase64 || path ? "" : noImageText}
        </span>
      </ButtonBase>
    );
  }
);

const Wrapped = <T extends Record<any, any> = {}>({
  ref: assertedRef,
  ...props
}: AcadImageButtonProps<T> & { ref?: Ref<HTMLInputElement> }) => (
  <AcadImageButton {...props} ref={assertedRef} />
);

export default Wrapped;
