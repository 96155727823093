import { doc, DocumentReference, Timestamp } from "@firebase/firestore";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { DialogProps } from "@framework/types";
import { useCreateDocument } from "@hooks";
import { AcadDateTimePicker, AcadModal, AcadTextField } from "@components";
import { useFirestore } from "reactfire";
import { useForm } from "react-hook-form";
import ReferralCode from "@models/referral_code";
import { joiResolver } from "@hookform/resolvers/joi";
import { useTranslation } from "react-i18next";

const ReferralCodeCreate: React.VFC<DialogProps> = ({ open, onClose }) => {
  const db = useFirestore();
  const {
    getValues,
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ReferralCode>({
    defaultValues: new ReferralCode(),
    resolver: joiResolver(ReferralCode.schema),
  });
  const { t } = useTranslation("users");

  const { loading, createDocument } = useCreateDocument<ReferralCode>({
    onCreated: () => {
      if (onClose) {
        onClose();
      }
      return t("successfully_created_referral_code");
    },
    docExistsMsg: t("referral_code_with_code_already_exists", {
      code: getValues("code"),
    }),
  });

  const createDoc = (referralCode: ReferralCode) => {
    if (!referralCode.endDate) {
      delete referralCode.endDate;
    }
    createDocument(
      referralCode,
      doc(
        db,
        ReferralCode.PATH,
        referralCode.code
      ) as DocumentReference<ReferralCode>
    );
  };

  const onClick = () => {
    // set createdAt to now
    setValue("createdAt", Timestamp.now());

    // submit
    handleSubmit(createDoc)();
  };

  return (
    <AcadModal open={open} onClose={onClose} loading={loading}>
      <DialogContent className="overflow-y-visible">
        <div className="grid grid-cols-12 gap-4">
          <div className="text-lg mt-4 mb-2 col-span-full">
            {t("referral_code_info")}
          </div>
          <AcadTextField
            variant="outlined"
            size="medium"
            label={t("referral_code")}
            className="col-span-6"
            {...register("code")}
            helperText={t("referral_code")}
            required
            errors={errors}
          />
          <AcadTextField
            variant="outlined"
            size="medium"
            label={t("referral_code_owner")}
            className="col-span-6"
            {...register("owner")}
            helperText={t("referral_code_owner_helper")}
            required
            errors={errors}
          />
          <AcadTextField
            multiline
            rows={3}
            variant="outlined"
            size="medium"
            label={t("purpose")}
            className="col-span-full"
            {...register("purpose")}
            helperText={t("purpose_helper")}
            errors={errors}
          />
          <AcadDateTimePicker
            label={t("end_date")}
            controllerProps={{ name: "endDate", control }}
            renderInput={(params) => (
              <AcadTextField
                {...params}
                name="endDate"
                className="col-span-12"
                errors={errors}
              />
            )}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClick}>
          {t("save", { ns: "common" })}
        </Button>
      </DialogActions>
    </AcadModal>
  );
};

export default ReferralCodeCreate;
