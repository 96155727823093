import { LinkProps } from "@defs/Links";
import AdminAppDirectory from "./Clients/AdminAppDirectory";
import Roles from "./Roles";
import AdminScripts from "./Scripts";

const routes: LinkProps[] = [
  {
    component: <Roles />,
    title: "admin_roles",
    url: "/admin/roles",
    adminOnly: true,
    superAdminOnly: true,
    exact: true,
    icon: "⚔️",
    onNavbar: true,
  },
  {
    component: <AdminAppDirectory />,
    title: "third_party_apps",
    url: "/admin/oauthClients",
    adminOnly: true,
    superAdminOnly: true,
    exact: true,
    icon: "🤖",
    onNavbar: true,
  },
  {
    component: <AdminScripts />,
    title: "admin_control",
    url: "/admin/control",
    adminOnly: true,
    superAdminOnly: true,
    exact: true,
    icon: "🎮",
    onNavbar: true,
  },
];

export default routes;
