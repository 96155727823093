import acadJoi from "@acad_joi";
import { AcadBaseDocument } from "@framework/models";
import { slugRE } from "@lib/RegularExpressions";
import { Timestamp } from "firebase/firestore";
import { PartialSchemaMap } from "joi";
import ScholarshipInfo from "./scholarship_info";

export enum ScholarshipPublishState {
  Draft = "draft",
  Unpublished = "unpublished",
  Published = "published",
}

/**
 * Summary of scholarships. Shown in scholarship listing.
 */
export default class Scholarship extends AcadBaseDocument {
  static readonly PATH: string = "scholarships";
  id: string = "";
  name: string = "";
  slug: string = "";
  logoUrl: string = "";
  benefits: string[] = [];
  eligibilities: string[] = [];
  requirements: string[] = [];
  sponsors: string[] = [];
  benefitDetails: string[] = [];
  eligibilityDetails: string[] = [];
  requirementDetails: string[] = [];
  startDate?: Timestamp;
  endDate?: Timestamp;
  order: number | null = null;
  publishState: ScholarshipPublishState = ScholarshipPublishState.Draft;

  static fromScholarshipInfo(info: ScholarshipInfo): Scholarship {
    const scholarship = new Scholarship();
    scholarship.id = info.id;
    scholarship.name = info.name;
    scholarship.slug = info.slug;
    scholarship.logoUrl = info.logoUrl;
    scholarship.benefits = info.benefits;
    scholarship.eligibilities = info.eligibilities;
    scholarship.requirements = info.requirements;
    scholarship.sponsors = info.sponsors;
    scholarship.benefitDetails = info.benefitDetails;
    scholarship.eligibilityDetails = info.eligibilityDetails;
    scholarship.requirementDetails = info.requirementDetails;
    scholarship.startDate = info.startDate;
    scholarship.endDate = info.endDate;
    scholarship.publishState = info.publishState;
    scholarship.order = info.order;

    return scholarship;
  }

  static schemaMap: PartialSchemaMap<Scholarship> = {
    id: acadJoi.string().label("Scholarship ID").trim().required(),
    name: acadJoi.string().label("Scholarship Display Name").trim().required(),
    // TODO: add custom validator for slug to check if it already exists
    slug: acadJoi
      .string()
      .regex(slugRE)
      .label("Scholarship Slug")
      .messages({
        "string.pattern.base":
          "Invalid slug. Alphanumeric only, '-' and '_' is allowed.",
      })
      .trim()
      .required(),
    logoUrl: acadJoi.string().label("Scholarship Logo").trim().allow(""),
    benefits: acadJoi.array().label("Scholarship Benefits").required(),
    eligibilities: acadJoi
      .array()
      .label("Scholarship Eligibilities")
      .required(),
    requirements: acadJoi.array().label("Scholarship Requirements").required(),
    sponsors: acadJoi.array().label("Scholarship Sponsors").required(),
    benefitDetails: acadJoi.array().label("Benefit Details").required(),
    eligibilityDetails: acadJoi.array().label("Eligibility Details").required(),
    requirementDetails: acadJoi.array().label("Requirement Details").required(),
    startDate: acadJoi
      .timestamp()
      .label("Start Date")
      .required()
      .max(acadJoi.ref("endDate"))
      .message("Start Date must not exceed End Date"),
    endDate: acadJoi.timestamp().label("End Date").required(),
    publishState: acadJoi
      .string()
      .label("Published?")
      .valid(...Object.values(ScholarshipPublishState)),
    order: acadJoi
      .number()
      .label("Order")
      .optional()
      .allow(null)
      .allow("")
      .min(1),
  };

  static schema = acadJoi.object<Scholarship>(Scholarship.schemaMap);
}
