import { AcadDirectory, AcadDirectoryContainer } from "@components";
import { doc, DocumentReference, DocumentSnapshot } from "firebase/firestore";
import { createContext } from "react";
import { useTranslation } from "react-i18next";
import { useFirestore, useFirestoreDoc } from "reactfire";
import SchoolsTable from "./SchoolsTable";

export interface ReadOnlySchools {
  schools: string[];
}

export const schoolsContext = createContext<
  DocumentSnapshot<ReadOnlySchools> | undefined
>(undefined);

const SchoolList = () => {
  const ref = doc(
    useFirestore(),
    "read_only",
    "schools"
  ) as DocumentReference<ReadOnlySchools>;
  const { status, data: snapshot, error } = useFirestoreDoc(ref);

  const { t } = useTranslation("schools");

  return (
    <AcadDirectoryContainer
      title={t("emoji_school_title")}
      directoryTitle={t("school_list")}
    >
      <AcadDirectory
        loading={status === "loading"}
        error={error}
        hasData={snapshot?.exists()}
        noDataEl={t("no_schools")}
      >
        <schoolsContext.Provider value={snapshot}>
          <SchoolsTable />
        </schoolsContext.Provider>
      </AcadDirectory>
    </AcadDirectoryContainer>
  );
};

export default SchoolList;
