import { Button, Stack } from "@mui/material";
import { MouseEventHandler } from "react";

interface Props {
  next: MouseEventHandler<HTMLButtonElement>;
  prev: MouseEventHandler<HTMLButtonElement>;
  nextDisabled?: boolean;
  prevDisabled?: boolean;
}
const PaginationButton = ({
  next,
  prev,
  nextDisabled,
  prevDisabled,
}: Props) => {
  return (
    <Stack justifyContent="flex-end" direction="row" sx={{ p: 2 }} spacing={2}>
      <Button variant="contained" onClick={prev} disabled={prevDisabled}>
        Prev
      </Button>
      <Button variant="contained" onClick={next} disabled={nextDisabled}>
        Next
      </Button>
    </Stack>
  );
};

export default PaginationButton;
