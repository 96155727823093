import {
  AcadEditDocSection,
  AcadImageButton,
  AcadTextField,
} from "@components";
import {
  CommunityParams,
  CommunitySlide,
} from "@models/syspars/community_params";
import { Add, Close } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  Fab,
  Stack,
  Typography,
} from "@mui/material";
import { Control, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

interface CommunitySlidesProps {
  control: Control<CommunityParams>;
}
const CommunitySlides = ({ control }: CommunitySlidesProps) => {
  const { register } = control;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "slides",
    keyName: "id",
  });

  const add = () => {
    append({
      description: "",
      image: "",
      title: "",
      url: "",
    });
  };

  const { t } = useTranslation("community");

  const deleteSlide = (index: number) => () => {
    Swal.fire({
      title: t("delete_slide"),
      showConfirmButton: true,
      confirmButtonText: t("delete", { ns: "common" }),
      confirmButtonColor: "red",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        remove(index);
      }
    });
  };

  const Title = () => (
    <Stack direction="row" gap={2}>
      <span>{t("slides")}</span>
      <Button variant="outlined" startIcon={<Add />} onClick={add}>
        {t("add", { ns: "common" })}
      </Button>
    </Stack>
  );

  return (
    <AcadEditDocSection title={<Title />}>
      <Stack gap={2}>
        {fields.map(({ id, ...field }, index) => (
          <Card key={id} className="relative">
            <Fab
              size="small"
              className="absolute top-1 right-1 min-h-0 h-6 w-6"
              color="default"
              onClick={deleteSlide(index)}
            >
              <Close fontSize="inherit" />
            </Fab>
            <CardContent>
              <Stack className="flex-col md:flex-row" gap={2}>
                <AcadImageButton<CommunityParams>
                  name={`slides.${index}.image`}
                  path={
                    control._getFieldArrayValue<CommunitySlide>("slides")[index]
                      ?.image ?? ""
                  }
                  className="w-full h-36 md:w-48 bg-contain bg-center bg-no-repeat shadow-md rounded-md"
                />
                <Stack gap={2} className="flex-1">
                  <AcadTextField
                    {...register(`slides.${index}.title`)}
                    label={t("title", { ns: "common" })}
                    fullWidth
                  />
                  <AcadTextField
                    {...register(`slides.${index}.description`)}
                    label={t("description", { ns: "common" })}
                    multiline
                    minRows={4}
                    maxRows={6}
                    helperText={t("support_basic_markdown")}
                    fullWidth
                  />
                  <AcadTextField
                    {...register(`slides.${index}.url`)}
                    label={t("link_or_url")}
                    fullWidth
                  />
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        ))}
        {!fields.length && <Typography>{t("no_slides_yet")}</Typography>}
      </Stack>
    </AcadEditDocSection>
  );
};

export default CommunitySlides;
