import useSchoolList from "@hooks/use_school_list";
import { createContext, useContext } from "react";

interface SchoolsContext {
  schools: string[];
  schoolSet: Set<string>;
  isSchoolPending(school: string): boolean;
}

const userDocSchoolsContext = createContext<SchoolsContext>({
  schools: [],
  schoolSet: new Set(),
  isSchoolPending: () => false,
});

interface UserDocSchoolsCtxProviderProps {
  children: React.ReactNode;
}

export default function UserDocSchoolsCtxProvider({
  children,
}: UserDocSchoolsCtxProviderProps) {
  const { schools } = useSchoolList();

  if (!schools) {
    return <>{children}</>;
  }

  // transform schools list to a set
  const schoolSet = new Set(schools);

  // school is pending if it's not in school set
  const isSchoolPending = (school: string) => {
    return !schoolSet.has(school);
  };

  return (
    <userDocSchoolsContext.Provider
      value={{ schools, schoolSet, isSchoolPending }}
    >
      {children}
    </userDocSchoolsContext.Provider>
  );
}

export const useUserDocSchools = () => useContext(userDocSchoolsContext);
