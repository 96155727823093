import Sheet from "@components/Sheet";
import { Button, TextField, Alert } from "@mui/material";
import {
  DataGrid,
  GridColumns,
  GridRowData,
  GridRowParams,
} from "@mui/x-data-grid";
import { Add } from "@mui/icons-material";

import { ChangeEvent, useState } from "react";
import { useContext } from "react";
import { schoolsContext } from "./";
import AddSchoolDialog from "./AddSchoolDialog";
import RenameDialog from "./Rename/RenameDialog";
import { useTranslation } from "react-i18next";

const SchoolsTable = () => {
  const [search, set] = useState("");
  const [selected, setSelected] = useState<string | null>(null);
  const [addState, setAddState] = useState(false);
  const [mergeState, setMergeState] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const { t } = useTranslation("schools");

  const schoolsCtx = useContext(schoolsContext);
  if (!schoolsCtx) return null;

  const schools = schoolsCtx.data()?.schools ?? [];
  const filtered = schools
    .filter((school) => school !== "AcadArena")
    .filter((school) => school.toLowerCase().includes(search.toLowerCase()));
  const sorted = filtered.sort((a, b) => (a < b ? -1 : 1));

  const rows: GridRowData[] = sorted.map((school, id) => ({ id, school }));
  const columns: GridColumns = [
    { field: "school", headerName: t("school_name"), flex: 1 },
  ];

  function handleText(e: ChangeEvent<HTMLInputElement>) {
    set(e.target.value);
  }

  function openSchool(grid: GridRowParams) {
    setSelected(grid.row.school);
  }

  return (
    <div className="flex flex-col">
      <div className="flex space-x-4">
        <TextField
          label={t("search")}
          value={search}
          onChange={handleText}
          size="small"
        />
        <Button
          startIcon={<Add />}
          variant="contained"
          onClick={() => setAddState(true)}
        >
          {t("add", { ns: "common" })}
        </Button>
        <Button variant="contained" onClick={() => setMergeState(true)}>
          {t("merge")}
        </Button>
      </div>

      <Sheet className="mt-4">
        <Alert severity="info" className="mb-2">
          {t("double_click_to_rename")}
        </Alert>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          onPageSizeChange={(n) => setPageSize(n)}
          density="compact"
          autoHeight
          onRowDoubleClick={openSchool}
          classes={{ row: "cursor-pointer" }}
          hideFooterSelectedRowCount
        />
      </Sheet>

      {!!selected && (
        <RenameDialog
          school={selected}
          open={!!selected && !mergeState}
          onClose={() => setSelected(null)}
        />
      )}

      <RenameDialog
        school=""
        open={mergeState}
        isMerge
        onClose={() => setMergeState(false)}
      />

      <AddSchoolDialog open={addState} onClose={() => setAddState(false)} />
    </div>
  );
};

export default SchoolsTable;
