import acadJoi from "@acad_joi";
import { Timestamp } from "firebase/firestore";

export const OAUTH_CLIENT_PATH = "oauth_clients";
export const OAUTH_ACCESS_TOKEN_PATH = "oauth_access_tokens";
export const OAUTH_TEAMS_PATH = "oauth_teams";

export type Grant =
  | "authorization_code"
  | "refresh_token"
  | "client_credentials"
  | "password";

export interface OAuthClient {
  id: string;
  name: string;
  description: string;
  icon: string;
  tags: string[];
  clientId: string;
  clientSecret: string;
  redirectUris?: string | string[] | undefined;
  grants: Grant | Grant[];
  accessTokenLifetime?: number | undefined;
  refreshTokenLifetime?: number | undefined;
  owner: string;
  termsOfServiceUrl: string;
  privacyPolicyUrl: string;
  isAdminApp?: boolean;
  isPartnerApp?: boolean;
  // [key: string]: any;
}

export class OAuthClientCreate {
  name: string = "";
  description: string = "";
  icon: string = "";
  redirectUris: string[] = [];

  static schema = acadJoi.object<OAuthClientCreate>({
    name: acadJoi.string().min(3).required(),
    description: acadJoi.string().allow(""),
    icon: acadJoi.string().allow(""),
    redirectUris: acadJoi.array().items(acadJoi.string().uri()).min(1),
  });
}

export class OAuthClientUpdate {
  name: string = "";
  description: string = "";
  icon: string = "";
  redirectUris: string[] = [];
  termsOfServiceUrl: string = "";
  privacyPolicyUrl: string = "";
  isAdminApp?: boolean = false;
  isPartnerApp?: boolean = false;

  static schema = acadJoi.object<OAuthClientUpdate>({
    name: acadJoi.string().min(3).required(),
    description: acadJoi.string().allow(""),
    icon: acadJoi.string().allow(""),
    redirectUris: acadJoi.array().items(acadJoi.string().uri()).min(1),
    termsOfServiceUrl: acadJoi.string().allow(""),
    privacyPolicyUrl: acadJoi.string().allow(""),
    isAdminApp: acadJoi.boolean().optional(),
    isPartnerApp: acadJoi.boolean().optional(),
  });
}

export class OAuthTeam {
  id: string;
  name: string = "";
  admins: string[] = [];
  icon: string = "";
  owner: string = "";

  constructor(id: string, owner: string) {
    this.id = id;
    this.owner = owner;
  }

  static schema = acadJoi.object<OAuthTeam>({
    name: acadJoi.string().min(3).required(),
    owner: acadJoi.string(),
    admins: acadJoi.array().items(acadJoi.string()),
    icon: acadJoi.string().allow(""),
  });
}

export interface OAuthUser {
  uid: string;
  [key: string]: any;
}

export interface OAuthToken {
  accessToken: string;
  accessTokenExpiresAt?: Timestamp | undefined;
  refreshToken?: string | undefined;
  refreshTokenExpiresAt?: Timestamp | undefined;
  scope?: string | string[] | undefined;
  client: OAuthClient;
  user: OAuthUser;
  [key: string]: any;
}

export interface OAuthRefreshToken {
  refreshToken: string;
  refreshTokenExpiresAt?: Timestamp | undefined;
  scope?: string | string[] | undefined;
  client: OAuthClient;
  user: OAuthUser;
  [key: string]: any;
}

/**
 * While I wanted to make this type safety witn enum
 * OAuth Server uses string | string[]
 * and it could be hassle to convert all the time
 */

type PublicScope = "user:read:public";
type PrivateScope = "user:read:email" | "user:read:all" | "user:read:wallets";

export const publicScopes: PublicScope[] = ["user:read:public"];
export const privateScopes: PrivateScope[] = [
  "user:read:email",
  "user:read:all",
  "user:read:wallets",
];

export type OAuthScope = PublicScope | PrivateScope;
export const oauthScopes = [...publicScopes, ...privateScopes];

export interface CreateAccessToken {
  scope: OAuthScope[];
  grant_type: "client_credentials" | "code" | "refresh_token";
  client_id: string;
  client_secret: string;
}
export interface CreateAcessTokenSchema {
  scope: OAuthScope[];
}

export const createAccessTokenSchema = acadJoi.object<CreateAcessTokenSchema>({
  scope: acadJoi.array().items(acadJoi.string().valid(...oauthScopes)),
});

export interface UpdateAccessTokenSchema {
  scope: (OAuthScope | string)[];
  accessTokenExpiresAt: Date;
}

export const updateAccessTokenSchema = acadJoi.object<UpdateAccessTokenSchema>({
  scope: acadJoi.array().items(acadJoi.string().valid(...oauthScopes)),
  accessTokenExpiresAt: acadJoi.date(),
});
