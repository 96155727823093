import {
  AcadEditDocSection,
  AcadImageButton,
  AcadTextField,
} from "@components";
import { fileUploadContext } from "@contexts/file_upload";
import { joiResolver } from "@hookform/resolvers/joi";
import Game, { GameField } from "@models/game";
import { Add } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { FormEvent, useContext } from "react";
import { Path, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import GameFieldForm from "./GameFieldFields";

interface GameEditFormProps {
  formData: Game;
  onSubmit(formData: Game): void;
}

const GameEditForm = ({ onSubmit, formData }: GameEditFormProps) => {
  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Game>({
    defaultValues: formData,
    resolver: joiResolver(Game.schema),
  });

  const fields = watch("fields");
  const addField = () => {
    const { fields } = Game.addField(getValues());
    setValue("fields", fields);
  };

  const { uploadFilesAndSet } = useContext(fileUploadContext);

  const innerOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await uploadFilesAndSet(setValue);
    handleSubmit(onSubmit, console.error)(e);
  };

  const passRegister = (fieldId: string) => (nextPath: Path<GameField>) => {
    return register(`fields.${fieldId}.${nextPath}`);
  };

  const removeField = (fieldId: string) => () => {
    const { fields } = Game.removeField(getValues(), fieldId);
    setValue("fields", fields);
  };

  const { t } = useTranslation("games");

  return (
    <form className="grid grid-cols-12 gap-4" onSubmit={innerOnSubmit}>
      <AcadEditDocSection
        title={t("game_info")}
        className="col-span-full grid grid-cols-2 gap-4"
      >
        <AcadImageButton
          name="icon"
          path={getValues("icon") || formData.icon}
          style={{ aspectRatio: "1/1" }}
          className="col-span-full shadow-md h-36 bg-white rounded-md bg-cover bg-center justify-center items-center"
          noImageText={t("upload_load")}
        />
        <AcadTextField
          label={t("title", { ns: "common" })}
          {...register("title")}
          required
          errors={errors}
        />

        <AcadTextField
          label={t("short_title")}
          {...register("shortTitle")}
          required
          errors={errors}
        />

        <AcadTextField
          label={t("publisher")}
          {...register("publisher")}
          errors={errors}
        />
        <AcadTextField
          multiline
          minRows={3}
          maxRows={5}
          label={t("description", { ns: "common" })}
          className="col-span-full"
          {...register("description")}
          errors={errors}
        />
      </AcadEditDocSection>
      <AcadEditDocSection
        title={t("game_fields")}
        className="col-span-full grid grid-cols-12 gap-4"
      >
        <Stack className="col-span-full" gap={2}>
          <Button
            onClick={addField}
            variant="outlined"
            className="flex flex-row align-center self-start"
            startIcon={<Add />}
          >
            {t("add_field")}
          </Button>
          {Object.keys(fields).map((key) => (
            <GameFieldForm
              key={key}
              register={passRegister(key)}
              removeField={removeField(key)}
            />
          ))}
        </Stack>
      </AcadEditDocSection>
      <div className="col-span-12 flex justify-end">
        <Button type="submit" variant="contained" color="primary">
          {t("save", { ns: "common" })}
        </Button>
      </div>
    </form>
  );
};

export default GameEditForm;
