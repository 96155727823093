import { DialogProps } from "@defs/Types";
import { Dialog } from "@mui/material";
import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import emoji from "remark-emoji";
import { useTranslation } from "react-i18next";
interface Props extends DialogProps {
  body: string;
}
const Preview = ({ open, onClose, body }: Props) => {
  const markdown = useMemo(() => {
    return body;
  }, [body]);

  const { t } = useTranslation("events");
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      {...{ open, onClose }}
      classes={{ paper: "py-2 px-6" }}
    >
      <ReactMarkdown
        plugins={[gfm, emoji]}
        className="markdown-body"
        children={markdown || t("description_is_empty")}
      />
    </Dialog>
  );
};

export default Preview;
