import { Timestamp } from "firebase/firestore";

export class UserVerificationDocumentBase {
  verified: boolean = false;
  rejected: boolean = false;
  owner: string = "";
  regForm: string = "";
  regFormRef: string = "";
  school: string = "";
  _school: string = "";
  authorComments: string = "";
  firstName: string = "";
  lastName: string = "";
  username: string = "";
  email: string = "";
  avatar: string = "";
  documentExpiryDate: Date | Timestamp = new Date();
  enrollDate: Date | Timestamp | null = new Date();
  submitDate: Date | Timestamp = new Date();
  dateReviewed: Date | Timestamp = new Date();
  reviewer: string = "-";
  reviewComments: string = "";
}

export class UserVerificationDocument extends UserVerificationDocumentBase {
  documentExpiryDate: Timestamp = Timestamp.now();
  enrollDate: Timestamp | null = Timestamp.now();
  submitDate: Timestamp = Timestamp.now();
  dateReviewed: Timestamp = Timestamp.now();
}
export class SerializedUserVerificationDocument extends UserVerificationDocumentBase {
  documentExpiryDate: Date = new Date();
  enrollDate: Date | null = new Date();
  submitDate: Date = new Date();
  dateReviewed: Date = new Date();
}

export function serializeUserDocument(
  document: UserVerificationDocument
): SerializedUserVerificationDocument {
  return {
    ...document,
    documentExpiryDate: document.documentExpiryDate?.toDate(),
    enrollDate: document.enrollDate?.toDate() || null,
    submitDate: document.submitDate?.toDate(),
    dateReviewed: document.dateReviewed?.toDate(),
  };
}
