import { Close, Launch } from "@mui/icons-material";
import { Dialog, DialogProps, Fab } from "@mui/material";

interface ImagePreviewProps extends DialogProps {
  onClose: VoidFunction;
  imageUrl: string;
}
const ImagePreview = ({ imageUrl, ...props }: ImagePreviewProps) => {
  return (
    <Dialog
      scroll="body"
      maxWidth="md"
      fullWidth
      classes={{ paper: "relative p-0 m-0" }}
      {...props}
      open={props.open && !!imageUrl}
      TransitionProps={{ unmountOnExit: true }}
    >
      <div className="absolute top-2 right-2 flex space-x-2">
        <Fab size="small" onClick={() => window.open(imageUrl, "_blank")}>
          <Launch />
        </Fab>
        <Fab size="small" onClick={props.onClose}>
          <Close />
        </Fab>
      </div>
      <img
        src={imageUrl}
        alt={imageUrl}
        style={{ width: "100%", height: "auto", minHeight: 300 }}
      />
    </Dialog>
  );
};

export default ImagePreview;
