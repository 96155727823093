import { LinkProps } from "@defs/Links";
import SettingsFields from "./EventsSettings";

const route = "/events/settings";

export const EventSettingsRoute: LinkProps[] = [
  {
    component: <SettingsFields page="Tournaments" />,
    title: "tournament_settings",
    url: `${route}/tournaments`,
    adminOnly: true,
    authenticated: true,
    exact: true,
    icon: "🦑",
    onNavbar: true,
  },
  {
    component: <SettingsFields page="Leagues" />,
    title: "league_settings",
    url: `${route}/leagues`,
    adminOnly: true,
    authenticated: true,
    exact: true,
    icon: "🐙",
    onNavbar: true,
  },
];

export default EventSettingsRoute;
