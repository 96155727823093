import {
  TournamentType,
  TournamentDoc,
  ChallongeTournament,
  GrandFinalsModifier,
  TournamentRankCategory,
  Visibility,
} from "@defs/Tournament.type";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  FormControlLabel,
  Checkbox,
  Dialog,
  Autocomplete,
  ListItemAvatar,
  Avatar,
  Button,
  TextFieldProps,
  Stack,
  Switch,
} from "@mui/material";
import { DateTimePicker } from "@mui/lab";
import {
  collection,
  CollectionReference,
  Timestamp,
} from "@firebase/firestore";
import React, { ChangeEvent, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Container } from "../TournamentEdit";
import gfm from "remark-gfm";
import emoji from "remark-emoji";
import Preview from "./Preview";

import TextField from "@components/MemoizedTextfield";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import Game from "@models/game";
import UserSearch from "@components/UserSearch";
import { useTranslation, Trans } from "react-i18next";

interface Props {
  setForm: React.Dispatch<React.SetStateAction<TournamentDoc>>;
  form: TournamentDoc;
  schools: string[];
}

const TournamentPageMain = ({ form, setForm, schools }: Props) => {
  const db = useFirestore();
  const gamesColRef = collection(db, "games") as CollectionReference<Game>;
  const { data: games = [] } = useFirestoreCollectionData(gamesColRef);
  const [descriptionPreview, setDescriptionPreview] = useState(false);
  const [rulesPreview, setRulesPreview] = useState(false);
  const { t } = useTranslation("events");

  const onChalChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      tournament: {
        ...form.tournament,
        [e.target.name]:
          e.target.type === "number" ? e.target.valueAsNumber : e.target.value,
      },
    });
  };

  const onDocChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]:
        e.target.type === "number" ? e.target.valueAsNumber : e.target.value,
    });
  };

  type docTFProps = { [key in keyof TournamentDoc]?: TextFieldProps };

  type chalTFProps = { [key in keyof ChallongeTournament]?: TextFieldProps };

  const chalTextFields: chalTFProps = {
    name: {
      name: "name",
      label: t("emoji_tournament_title"),
      className: "col-span-full",
      required: true,
      value: form.tournament.name,
      onChange: onChalChange,
    },

    pts_for_match_win: {
      type: "number",
      name: "pts_for_match_win",
      className: "col-span-full",
      label: t("points_per_match_wins"),
      required: form.tournament.tournament_type === TournamentType.swiss,
      value: form.tournament.pts_for_match_win,
      onChange: onChalChange,
      inputProps: {
        min: 0,
        step: 0.5,
      },
    },
    pts_for_match_tie: {
      type: "number",
      name: "pts_for_match_tie",
      className: "col-span-full",
      label: t("points_per_match_wins"),
      required: form.tournament.tournament_type === TournamentType.swiss,
      value: form.tournament.pts_for_match_tie,
      onChange: onChalChange,
      inputProps: {
        min: 0,
        step: 0.5,
      },
    },
    pts_for_game_win: {
      type: "number",
      name: "pts_for_game_win",
      className: "col-span-full",
      label: t("points_per_match_wins"),
      required: form.tournament.tournament_type === TournamentType.swiss,
      value: form.tournament.pts_for_game_win,
      onChange: onChalChange,
      inputProps: {
        min: 0,
        step: 0.5,
      },
    },
    pts_for_game_tie: {
      type: "number",
      name: "pts_for_game_tie",
      className: "col-span-full",
      label: t("points_per_match_wins"),
      required: form.tournament.tournament_type === TournamentType.swiss,
      value: form.tournament.pts_for_game_tie,
      onChange: onChalChange,
      inputProps: {
        min: 0,
        step: 0.5,
      },
    },
    pts_for_bye: {
      type: "number",
      name: "pts_for_bye",
      className: "col-span-full",
      label: t("points_per_match_wins"),
      required: form.tournament.tournament_type === TournamentType.swiss,
      value: form.tournament.pts_for_bye,
      onChange: onChalChange,
      inputProps: {
        min: 0,
        step: 0.5,
      },
    },
  };

  const docTextFields: docTFProps = {
    maxTeamCount: {
      type: "number",
      name: "maxTeamCount",
      label: t("max_team_count"),
      className: "col-span-full sm:col-span-6  xl:col-span-4",
      required: true,
      inputProps: {
        min: 2,
      },
      value: form.maxTeamCount,
      onChange: onDocChange,
      helperText: t("maximum_number_of_teams"),
    },
    activePlayerCountPerTeam: {
      type: "number",
      name: "activePlayerCountPerTeam",
      label: t("active_player_count"),
      className: "col-span-full sm:col-span-6  xl:col-span-4",
      required: true,
      inputProps: {
        min: 1,
        max: 10,
      },
      value: form.activePlayerCountPerTeam,
      onChange: onDocChange,
      helperText: (
        <Stack>
          <Trans
            i18nKey="events:player_count_required_per_team"
            components={{
              span: <span />,
              redSpan: <span className="text-red-500 font-bold" />,
            }}
          />
        </Stack>
      ),
    },
    maxSubPlayerPerTeam: {
      type: "number",
      name: "maxSubPlayerPerTeam",
      label: t("max_substitute_count"),
      className: "col-span-full sm:col-span-6  xl:col-span-4",
      inputProps: {
        min: 0,
        max: 10,
      },
      value: form.maxSubPlayerPerTeam,
      onChange: onDocChange,
      helperText: t("maximum_number_of_subs_per_team"),
    },
    description: {
      name: "description",
      label: t("description", { ns: "common" }),
      multiline: true,
      minRows: 8,
      maxRows: 30,
      className: "col-span-full",
      value: form.description,
      onChange: onDocChange,
      helperText: (
        <>
          <Trans
            i18nKey="events:use_markdown_for_styling"
            components={{
              a: (
                <a
                  href="https://www.markdownguide.org/basic-syntax/"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
              strong: <strong />,
            }}
          />
        </>
      ),
    },
    rules: {
      name: "rules",
      label: t("rules"),
      multiline: true,
      minRows: 8,
      maxRows: 30,
      className: "col-span-full",
      value: form.rules,
      onChange: onDocChange,
      helperText: (
        <>
          <Trans
            i18nKey="events:use_markdown_for_styling"
            components={{
              a: (
                <a
                  href="https://www.markdownguide.org/basic-syntax/"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
              strong: <strong />,
            }}
          />
        </>
      ),
    },
    commsChannelLink: {
      name: "commsChannelLink",
      label: t("discord_link"),
      className: "mt-4",
      value: form.commsChannelLink,
      onChange: onDocChange,
      fullWidth: true,
    },
    typeformId: {
      name: "typeformId",
      label: t("typeform_id"),
      className: "col-span-full",
      value: form.typeformId,
      onChange: onDocChange,
      fullWidth: true,
    },
  };

  return (
    <>
      {/* Tournament Settings */}
      <div className="text-xl col-span-full mt-4">
        {t("tournament_settings")}
      </div>
      <Container>
        <TextField {...chalTextFields.name} />
        <FormControl className="col-span-full">
          <InputLabel>{t("participant_type")}</InputLabel>
          <Select
            label={t("tournament_type")}
            onChange={(e) =>
              setForm((state) => ({
                ...state,
                allowContainedTeams: !!e.target.value,
              }))
            }
            value={form.allowContainedTeams ? 1 : 0}
          >
            <MenuItem value={0}>{t("varsity_only")}</MenuItem>
            <MenuItem value={1}>{t("standard")}</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="col-span-full">
          <InputLabel>{t("emoji_tournament_type")}</InputLabel>
          <Select
            value={form.tournament.tournament_type}
            label={t("emoji_tournament_type")}
            onChange={(e) =>
              setForm((state) => ({
                ...state,
                tournament: {
                  ...form.tournament,
                  tournament_type: e.target.value as TournamentType,
                },
              }))
            }
          >
            <MenuItem value={TournamentType.single}>
              {t("single_elimination")}
            </MenuItem>
            <MenuItem value={TournamentType.double}>
              {t("double_elimination")}
            </MenuItem>
            <MenuItem value={TournamentType.roundRobin}>
              {t("round_robin")}
            </MenuItem>
            <MenuItem value={TournamentType.swiss}>{t("swiss")}</MenuItem>
          </Select>
        </FormControl>

        <FormControl className="col-span-full w-full" size="small">
          <InputLabel>{t("emoji_game")}</InputLabel>
          <Select
            label={t("emoji_game")}
            fullWidth
            className="w-full"
            value={form.game ?? ""}
            onChange={(e) => setForm({ ...form, game: e.target.value ?? "" })}
          >
            <MenuItem key={-1} value={-1}>
              <ListItemText primary="None Selected" />
            </MenuItem>
            {Object.values(games).map((g) => (
              <MenuItem key={g.id} value={g.id}>
                <ListItemAvatar>
                  <Avatar variant="rounded" src={g.icon} />
                </ListItemAvatar>
                <ListItemText primary={g.title} secondary={g.publisher} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <DateTimePicker
          value={form.startDate?.toDate()}
          renderInput={(params) => (
            <TextField {...params} className="col-span-full md:col-span-6" />
          )}
          label="📅 Tournament Start"
          onChange={(val) =>
            setForm({
              ...form,
              startDate: Timestamp.fromDate(val ?? new Date()),
            })
          }
        />
        <DateTimePicker
          value={form.endDate?.toDate()}
          renderInput={(params) => (
            <TextField {...params} className="col-span-full md:col-span-6" />
          )}
          label="📅 Tournament End"
          onChange={(val) =>
            setForm({
              ...form,
              endDate: Timestamp.fromDate(val ?? new Date()),
            })
          }
        />
        <Container>
          {form.tournament.tournament_type === TournamentType.single && (
            <FormControlLabel
              label={t("hold_third_placer_match")}
              className="col-span-full"
              control={
                <Checkbox
                  checked={form.tournament.hold_third_place_match}
                  onChange={() =>
                    setForm({
                      ...form,
                      tournament: {
                        ...form.tournament,
                        hold_third_place_match:
                          !form.tournament.hold_third_place_match,
                      },
                    })
                  }
                />
              }
            />
          )}
          {form.tournament.tournament_type === TournamentType.double && (
            <FormControl className="col-span-full">
              <InputLabel>{t("grand_finals_modifier")}</InputLabel>
              <Select
                label={t("grand_finals_modifier")}
                value={form.tournament.grand_finals_modifier ?? "single match"}
                onChange={(e) =>
                  setForm((state) => ({
                    ...state,
                    tournament: {
                      ...form.tournament,
                      grand_finals_modifier: e.target
                        .value as GrandFinalsModifier,
                    },
                  }))
                }
              >
                <MenuItem value="single match">
                  <ListItemText primary={t("single_match")} />
                </MenuItem>
                <MenuItem value="single or double match">
                  <ListItemText
                    primary={t("one_to_two_matches")}
                    secondary={t("winners_bracket_finalist_defeated_twice")}
                  />
                </MenuItem>
                <MenuItem value="skip">
                  <ListItemText
                    primary={t("none")}
                    secondary={t(
                      "will_not_create_match_between_winners_losers"
                    )}
                  />
                </MenuItem>
              </Select>
            </FormControl>
          )}
          {form.tournament.tournament_type === TournamentType.swiss && (
            <>
              <TextField {...chalTextFields.pts_for_match_win} />
              <TextField {...chalTextFields.pts_for_match_tie} />
              <TextField {...chalTextFields.pts_for_game_win} />
              <TextField {...chalTextFields.pts_for_game_tie} />
              <TextField {...chalTextFields.pts_for_bye} />
            </>
          )}
          {form.tournament.tournament_type === TournamentType.roundRobin && (
            <FormControl className="col-span-full">
              <InputLabel>{t("rank_by")}</InputLabel>
              <Select
                label={t("rank_by")}
                value={form.tournament.ranked_by ?? "match wins"}
                onChange={(e) =>
                  setForm({
                    ...form,
                    tournament: {
                      ...form.tournament,
                      ranked_by: e.target.value as TournamentRankCategory,
                    },
                  })
                }
              >
                <MenuItem value="match wins">{t("match_wins")}</MenuItem>
                <MenuItem value="game wins">{t("game_wins")}</MenuItem>
                <MenuItem value="points scored">{t("points_scored")}</MenuItem>
                <MenuItem value="points difference">
                  {t("points_difference")}
                </MenuItem>
              </Select>
            </FormControl>
          )}
        </Container>
      </Container>

      {/* Registration */}
      <div className="text-xl col-span-full mt-4">
        {t("registration_settings")}
      </div>
      <Container>
        <DateTimePicker
          value={form.regOpenDate?.toDate()}
          renderInput={(params) => (
            <TextField {...params} className="col-span-full md:col-span-6" />
          )}
          label={t("emoji_registration_open")}
          onChange={(val) =>
            setForm({
              ...form,
              regOpenDate: Timestamp.fromDate(val ?? new Date()),
            })
          }
        />

        <DateTimePicker
          value={form.regEndDate?.toDate()}
          renderInput={(params) => (
            <TextField {...params} className="col-span-full md:col-span-6" />
          )}
          label={t("emoji_registration_end")}
          onChange={(val) =>
            setForm({
              ...form,
              regEndDate: Timestamp.fromDate(val ?? new Date()),
            })
          }
        />

        <TextField {...docTextFields.maxTeamCount} />
        <TextField {...docTextFields.activePlayerCountPerTeam} />
        <TextField {...docTextFields.maxSubPlayerPerTeam} />

        <Stack className="col-span-full items-start">
          <FormControlLabel
            label={t("auto_accept_registrants")}
            control={
              <Switch
                checked={!!form.autoAcceptParticipants}
                onClick={() =>
                  setForm((state) => ({
                    ...state,
                    autoAcceptParticipants: !state.autoAcceptParticipants,
                  }))
                }
              />
            }
          />
          <FormControlLabel
            label={t("allow_pending_teams_to_check_in_question")}
            control={
              <Switch
                checked={!!form.allowPendingCheckIn}
                onClick={() =>
                  setForm((state) => ({
                    ...state,
                    allowPendingCheckIn: !state.allowPendingCheckIn,
                  }))
                }
              />
            }
          />
        </Stack>
      </Container>

      {/* Description */}
      <div className="text-xl col-span-full mt-4">
        {t("description", { ns: "common" })}
      </div>
      <Container>
        <TextField {...docTextFields.description} />
        <div className="col-span-full flex justify-end">
          <Button size="small" onClick={() => setDescriptionPreview(true)}>
            {t("preview", { ns: "common" })}
          </Button>
          <Preview
            open={descriptionPreview}
            onClose={() => setDescriptionPreview(false)}
            body={form.description ?? ""}
          />
        </div>
      </Container>

      {/* Rules */}
      <div className="text-xl col-span-full mt-4">{t("rules")}</div>
      <Container>
        <TextField {...docTextFields.rules} />
        <div className="col-span-full flex justify-end">
          <Button size="small" onClick={() => setRulesPreview(true)}>
            {t("preview", { ns: "common" })}
          </Button>
          <Preview
            open={rulesPreview}
            onClose={() => setRulesPreview(false)}
            body={form.rules ?? ""}
          />
          <Dialog
            maxWidth="md"
            fullWidth
            open={rulesPreview}
            onClose={() => setRulesPreview(false)}
            classes={{ paper: "py-2 px-6" }}
          >
            <ReactMarkdown
              plugins={[gfm, emoji]}
              className="markdown-body"
              children={form.rules ?? t("rules_is_yet_to_be_written")}
            />
          </Dialog>
        </div>
      </Container>

      <div className="text-xl col-span-full mt-4">{t("other_settings")}</div>

      {/**
       * Is school only tournament?
       * if false, unverified teams/users can join the tournament
       */}
      <FormControlLabel
        className="col-span-full"
        label={t("verified_student_only_question")}
        control={
          <Checkbox
            checked={!!form.isSchoolOnly}
            onClick={() =>
              setForm({ ...form, isSchoolOnly: !form.isSchoolOnly })
            }
          />
        }
      />
      <FormControlLabel
        className="col-span-full"
        label={t("same_school_tournament")}
        control={
          <Checkbox
            checked={!!form.sameSchoolOnly}
            onClick={() =>
              setForm({ ...form, sameSchoolOnly: !form.sameSchoolOnly })
            }
          />
        }
      />

      <Autocomplete
        multiple
        // disabled={form.isSchoolOnly === false}
        options={schools}
        className="col-span-full"
        value={form.schoolsAllowed ?? []}
        onChange={(event, newValue) => {
          setForm({ ...form, schoolsAllowed: newValue });
        }}
        renderInput={({ size, ...params }) => (
          <TextField
            {...params}
            label={t("schools_allowed")}
            helperText={t("leave_blank_to_allow_all_schools")}
          />
        )}
      />

      {/* Admins */}
      <UserSearch
        value={form.admins ?? []}
        onChange={(newValue) =>
          setForm((state) => ({ ...state, admins: newValue }))
        }
        label={t("admins")}
        autocompleteProps={{ sx: { pt: 2 } }}
      />

      <TextField {...docTextFields.commsChannelLink} />

      <div className="text-xl col-span-full mt-4">
        {t("visibility_settings")}
      </div>
      <FormControl className="col-span-full md:col-span-6 pb-4 mt-4">
        <InputLabel>{t("visibility", { ns: "common" })}</InputLabel>
        <Select
          label={t("visibility", { ns: "common" })}
          value={form.visibility}
          onChange={(e) =>
            setForm({ ...form, visibility: e.target.value as Visibility })
          }
        >
          <MenuItem value="draft">{t("draft", { ns: "common" })}</MenuItem>
          <MenuItem value="public">{t("public", { ns: "common" })}</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default TournamentPageMain;
