import { Fade } from "@mui/material";
import EpisodeCard from "./EpisodeCard";
import { Episode } from "@defs/Episode";

type EpisodeDirectoryProps = {
  episodes: Episode[];
  clickHandler: (index: number) => void;
};
export default function EpisodeDirectory({
  episodes,
  clickHandler,
}: EpisodeDirectoryProps): JSX.Element {
  const Empty = (
    <div className="w-full h-52 text-center flex justify-center items-center text-xl bg-gray-200 rounded-md">
      No Episodes
    </div>
  );

  const Populated = (
    <Fade in>
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
        {episodes?.map((episode, index) => (
          <EpisodeCard
            key={episode.episodeNumber}
            episode={episode}
            onClick={() => {
              clickHandler(index);
            }}
          />
        ))}
      </div>
    </Fade>
  );

  return !episodes.length ? <>{Empty}</> : <>{Populated}</>;
}
