import { AcadDirectory } from "@components";
import q from "@lib/firestore/q";
import { OAuthClient, OAuthTeam, OAUTH_CLIENT_PATH } from "@models/oauth";
import { Stack } from "@mui/material";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import AppCard from "./AppCard";

interface Props {
  team: OAuthTeam;
}

function TeamApps({ team }: Props) {
  const db = useFirestore();
  const ref = q<OAuthClient>(db, OAUTH_CLIENT_PATH, { owner: team.id });
  const { data: apps = [], status } = useFirestoreCollectionData(ref);
  return (
    <Stack>
      <div className="text-lg mb-2 mt-4">{team.name}</div>
      <AcadDirectory loading={status === "loading"} hasData={!!apps.length}>
        {apps.map((app) => (
          <AppCard app={app} key={app.id} />
        ))}
      </AcadDirectory>
    </Stack>
  );
}

export default TeamApps;
