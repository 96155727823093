import { VFC } from "react";
import { useHistory } from "react-router-dom";
import { AcadCard } from "@components";
import Sponsor from "@models/sponsor";

const SponsorCard: VFC<{sponsor: Sponsor}> = ({ sponsor }) => {
  const history = useHistory();
  return (
    <AcadCard onClick={() => history.push(`/scholarship/sponsors/${sponsor.id}`)}>
      <div className="flex flex-col pl-4">
        <div className="text-md leading-none text-left">{sponsor.name}</div>
        <div className="text-xs leading-none text-left opacity-40">
          {sponsor.description}
        </div>
      </div>
    </AcadCard>
  );
};

export default SponsorCard;
