import Region, { RegionData, regionData } from "@defs/region/region";
import i18n from "@i18n";
import { createContext, useMemo } from "react";
import { BrowserRouter, useParams } from "react-router-dom";
interface RegionProviderProps {
  children?: React.ReactNode;
}

interface RegionContext extends RegionData {
  region: Region;
  isBr: boolean;
  isPh: boolean;
}

export const regionContext = createContext<RegionContext>({
  region: Region.PH,
  isPh: true,
  isBr: false,
  ...regionData[Region.PH],
});

export default function RegionProvider({ children }: RegionProviderProps) {
  const { region = Region.PH } = useParams<{ region: Region }>();
  const isPh = region === Region.PH;
  const isBr = region === Region.BR;

  const { ...data } = regionData[region];

  useMemo(() => {
    localStorage.setItem("selectedRegion", region);
    if (region === Region.BR) {
      i18n.changeLanguage("br");
    }
  }, [region]);

  return (
    <BrowserRouter basename={region}>
      <regionContext.Provider
        value={{
          region,
          isPh,
          isBr,
          ...data,
        }}
      >
        {children}
      </regionContext.Provider>
    </BrowserRouter>
  );
}
