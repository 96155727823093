export const getUrlWithParams = (
  baseUrl: string,
  params: Record<string, string>
) => {
  const url = new URL(baseUrl);
  for (const key in params) {
    if (params[key] !== undefined) {
      url.searchParams.set(key, params[key]);
    }
  }
  return url.toString();
};
