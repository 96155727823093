import acadJoi from "@acad_joi";
import { AcadBaseDocument } from "@framework/models";
import { NextConfig, NextConfigExtend } from "@models/next_server_config";
import SEO, { SEOExtend } from "@models/seo";
import { SchemaMap } from "joi";

export class CommonPageParams
  extends AcadBaseDocument
  implements NextConfigExtend, SEOExtend
{
  static readonly PATH: string = "syspars";

  SEO: SEO = new SEO();
  nextConfig: NextConfig = new NextConfig();

  constructor(title?: string) {
    super();
    this.SEO.title = title ?? "";
  }

  static schemaMap: SchemaMap<CommonPageParams> = {
    SEO: SEO.schema,
    nextConfig: NextConfig.schema,
  };

  static schema = acadJoi.object<CommonPageParams>(CommonPageParams.schemaMap);
}
