import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useState, MouseEvent, ReactNode } from "react";
import TableHead, { HeadCell, Order } from "./AcadTableHead";

type Comparator<T> = (a: T, b: T) => number;

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator<T>(order: Order, orderBy: keyof T): Comparator<T> {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

export interface AcadTableProps<T = any & Record<string, any>> {
  orderBy: keyof T;
  headCells: HeadCell<T>[];
  data: T[];
  enableCheckbox?: boolean;
  dense?: boolean;
  cursor: number;
  disableNext: boolean;
  disablePrev: boolean;
  next: () => void | Promise<void>;
  prev: () => void;
  rowsPerPage: number;
  renderRows: (row: T, index: number, array: T[]) => ReactNode;
  selectRow?: (row: T) => any;
  numSelected?: number;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function AcadTable<T>({
  orderBy: orderField,
  headCells,
  data = [],
  dense,
  cursor,
  rowsPerPage,
  next,
  disableNext,
  disablePrev,
  prev,
  renderRows,
  onSelectAllClick,
  enableCheckbox,
  numSelected = 0,
}: AcadTableProps<T>) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof T>(orderField);

  const onRequestSort = (event: MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = rowsPerPage - data.length;

  async function handleChangePage(event: unknown, newPage: number) {
    if (newPage > cursor) return next();
    if (newPage < cursor) return prev();
  }

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 750, width: "100%" }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <TableHead
            {...{
              order,
              orderBy,
              onRequestSort,
              headCells,
              onSelectAllClick,
              numSelected,
              rowCount: data.length,
              enableCheckbox,
            }}
          />

          <TableBody sx={{ width: "100%" }}>
            {stableSort<T>(data, getComparator(order, orderBy)).map(renderRows)}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={headCells.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={-1}
        rowsPerPage={rowsPerPage}
        page={cursor}
        onPageChange={handleChangePage}
        nextIconButtonProps={{
          disabled: disableNext,
        }}
        backIconButtonProps={{
          disabled: disablePrev,
        }}
      />
    </>
  );
}

export default AcadTable;
