import { Button, TextField } from "@mui/material";
import { FormEvent, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { doc, DocumentReference } from "firebase/firestore";
import { userContext } from "@lib/AuthContext";
import { useFirestore } from "reactfire";
import { useDeleteDocument, useForm, useWriteDocument } from "@hooks";
import { AcadEditDocContainer, AcadEditDocSection } from "@components";
import ScholarshipRequirement from "@models/scholarship_requirement";
import { useTranslation } from "react-i18next";

const RequirementEdit = () => {
  const db = useFirestore();
  const { requirementId } = useParams<{ requirementId: string }>();
  const { t } = useTranslation("scholarships");
  const { form, updateFormField, onSnapshotChanged } =
    useForm<ScholarshipRequirement>(new ScholarshipRequirement());

  const docRef = doc(
    db,
    `${ScholarshipRequirement.PATH}/${requirementId}`
  ) as DocumentReference<ScholarshipRequirement>;
  const { deleteDocument } = useDeleteDocument<ScholarshipRequirement>({
    docRef,
    titleMsg: t("delete_requirement_question"),
    onDelete: () => {
      history.push("/scholarship/requirements");
    },
  });
  const { loading, snap, error, writeDocument } =
    useWriteDocument<ScholarshipRequirement>({
      docRef,
      onSnapshotChanged,
    });

  const history = useHistory();

  const { isAdmin: admin } = useContext(userContext);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    writeDocument(form);
  };

  return (
    <AcadEditDocContainer
      snapshot={snap}
      error={error}
      loading={loading}
      onBackBtn={() => history.push("/scholarship/requirements")}
      doesNotExistText={t("requirement_does_not_exist")}
      backBtnText={t("back_to_requirements")}
    >
      <form className="grid grid-cols-12 gap-4" onSubmit={onSubmit}>
        <AcadEditDocSection
          title="Requirement Info"
          className="col-span-full grid grid-cols-12 gap-4"
        >
          <TextField
            name="name"
            label={t("requirement_name")}
            className="col-span-12"
            value={form?.name}
            required
            onChange={updateFormField}
          />
        </AcadEditDocSection>
        <div className="col-span-12 flex justify-end">
          <Button
            variant="outlined"
            className="mr-4"
            color="secondary"
            onClick={() => deleteDocument(form)}
            disabled={!admin}
          >
            {t("delete", { ns: "common" })}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={form === snap?.data()}
          >
            {t("save", { ns: "common" })}
          </Button>
        </div>
      </form>
    </AcadEditDocContainer>
  );
};

export default RequirementEdit;
