import { AcadImageButton, AcadTextField } from "@components";
import HeroCard from "@components/HeroCard";
import { withFileUpload } from "@hocs";
import { joiResolver } from "@hookform/resolvers/joi";
import { useWriteDocument } from "@hooks";
import useFileUploadContext from "@hooks/use_file_upload_context";
import { toBase64 } from "@lib/file";
import { AcademyParams } from "@models/syspars/academy_params";
import { Button, Stack } from "@mui/material";
import { doc, DocumentReference, DocumentSnapshot } from "firebase/firestore";
import { MouseEventHandler, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFirestore } from "reactfire";
import AcademyButtonForm from "./AcademyHeroButtonForm";

const AcademyHero = () => {
  const { t } = useTranslation(["academy", "common"]);
  const db = useFirestore();
  const docRef = doc(
    db,
    AcademyParams.PATH
  ) as DocumentReference<AcademyParams>;
  const {
    setValue,
    reset,
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control,
  } = useForm<AcademyParams["hero"]>({
    defaultValues: { ...new AcademyParams().hero },
    resolver: joiResolver(AcademyParams.heroSchema),
  });

  const title = watch("title");
  const backgroundImage = watch("backgroundImage");
  const description = watch("description");
  const buttons = watch("buttons");

  const onSnapshotChanged = useMemo(() => {
    return (snap: DocumentSnapshot<AcademyParams>) => {
      reset(snap?.data()?.hero ?? new AcademyParams().hero);
    };
  }, [reset]);

  const { writeDocument } = useWriteDocument<AcademyParams>({
    docRef,
    onSnapshotChanged,
  });

  const { uploadFilesAndSet } = useFileUploadContext();
  const save: MouseEventHandler<HTMLButtonElement> = async (e) => {
    await uploadFilesAndSet(setValue);
    handleSubmit(async (hero) => {
      writeDocument({ hero });
    }, console.error)(e);
  };

  const [imgBase64, setImgBase64] = useState<string>("");
  const onImageSelect = (file: File | undefined) => {
    if (!file) return setImgBase64("");
    toBase64(file).then(setImgBase64);
  };

  return (
    <Stack sx={{ py: 2 }} gap={4}>
      <HeroCard
        backgroundImage={imgBase64 || backgroundImage}
        headline={title}
        subHeadline={description}
      >
        <Stack direction="row" gap={1}>
          {buttons.map(({ name, color }, i) => (
            <Button
              size="small"
              variant="contained"
              sx={{ backgroundColor: color || "primary" }}
              key={name + i}
            >
              {name}
            </Button>
          ))}
        </Stack>
      </HeroCard>

      <Stack gap={2}>
        <AcadImageButton
          name="backgroundImage"
          noImageText={t("upload_background_img")}
          path={backgroundImage}
          onImageSelect={onImageSelect}
          className="w-full h-36 md:w-48 bg-contain bg-center bg-no-repeat shadow-md rounded-md"
        />
        <AcadTextField
          label={t("title")}
          {...register("title")}
          errors={errors}
          InputLabelProps={{
            shrink: !!title,
          }}
        />
        <AcadTextField
          multiline
          minRows={3}
          label={t("description")}
          {...register("description")}
          errors={errors}
          InputLabelProps={{
            shrink: !!description,
          }}
        />

        <AcademyButtonForm control={control} />
      </Stack>

      <Button
        sx={{ alignSelf: "flex-start" }}
        variant="contained"
        onClick={save}
      >
        {t("save")}
      </Button>
    </Stack>
  );
};

export default withFileUpload(AcademyHero);
