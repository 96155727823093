import ImagePreview from "@components/ImagePreview";
import {
  Box,
  Card,
  CardActionArea,
  Checkbox,
  Stack,
  TableCell,
  TableRow,
  TableRowProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { Reducer, useReducer } from "react";
import { useUserDocSchools } from "./UserDocSchoolsCtxProvider";
import UserDocumentActionDialog from "./UserDocumentActionDialog";
import { TableDispatch, UserVerifDocWithId } from "./UserDocumentTable";
import { Icon } from "@iconify/react";
import { red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

type TableRowState = Record<"action" | "preview", boolean>;
type TableRowAction = { type: "preview" | "action"; payload: boolean };
type ReducerType = Reducer<TableRowState, TableRowAction>;

const TableRowReducer: ReducerType = (state, action) => {
  switch (action.type) {
    case "action":
      return { ...state, action: action.payload };
    case "preview":
      return { ...state, preview: action.payload };
    default:
      return state;
  }
};

interface UserDocumentTableRowProps extends TableRowProps {
  row: UserVerifDocWithId;
  tableDispatch: TableDispatch;
}
const UserDocumentTableRow = ({
  row,
  tableDispatch,
  ...props
}: UserDocumentTableRowProps) => {
  const [state, rowDispatch] = useReducer<ReducerType>(TableRowReducer, {
    action: false,
    preview: false,
  });
  const { isSchoolPending } = useUserDocSchools();

  const { t } = useTranslation();
  return (
    <TableRow
      className="whitespace-nowrap"
      {...props}
      sx={{
        "&:hover .sticky": {
          backgroundColor: "#f6f7f8",
        },
      }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={props.selected}
          onChange={() => tableDispatch({ type: "toggle", payload: row })}
        />
      </TableCell>
      <TableCell
        className={`sticky z-50 left-0`}
        onClick={() => rowDispatch({ type: "action", payload: true })}
        sx={{
          backgroundColor: props.selected ? "#edeff1" : "#fff",
          color: "#0000EE",
          textDecoration: "underline",
          cursor: "pointer",
          textOverflow: "ellipsis",
        }}
      >
        {row.lastName.toUpperCase()}, {row.firstName}
      </TableCell>
      <TableCell>
        {isSchoolPending(row.school) ? (
          <Tooltip title="Pending School">
            <Stack alignItems="center" spacing={1} direction="row">
              <span>{row.school}</span>
              <Icon icon="eva-alert-triangle-outline" color={red[500]} />
            </Stack>
          </Tooltip>
        ) : (
          row.school
        )}
      </TableCell>
      <TableCell padding="checkbox">
        <Stack alignItems="flex-start">
          <Card className="rounded">
            <CardActionArea
              onClick={() => rowDispatch({ type: "preview", payload: true })}
            >
              <Box
                className="bg-contain bg-center bg-no-repeat"
                sx={{
                  height: 30,
                  width: 30,
                  backgroundImage: `url("${row.regForm}")`,
                }}
              ></Box>
            </CardActionArea>
          </Card>
        </Stack>
      </TableCell>
      <TableCell>{row.username}</TableCell>
      <TableCell>
        <Typography variant="caption">
          {row.enrollDate &&
            format(
              row.enrollDate.toDate(),
              t("date_format_mmm", { ns: "common" })
            )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {format(
            row.submitDate.toDate(),
            t("date_format_mmm", { ns: "common" })
          )}
        </Typography>
      </TableCell>

      <ImagePreview
        open={state.preview}
        onClose={() => rowDispatch({ type: "preview", payload: false })}
        imageUrl={row.regForm}
      />
      <UserDocumentActionDialog
        document={row}
        open={state.action}
        onClose={() => rowDispatch({ type: "action", payload: false })}
      />
    </TableRow>
  );
};

export default UserDocumentTableRow;
