// Components
import { Button } from "@mui/material";
import Sheet from "@components/Sheet";
import { useState } from "react";

interface AcadDirectoryContainerProps {
  title: string;
  createBtnTitle?: string;
  directoryTitle?: string;
  createBtn?: JSX.Element;
  createModal?: JSX.Element;
}

const AcadDirectoryContainer: React.FC<AcadDirectoryContainerProps> = ({
  children,
  title,
  directoryTitle,
  createBtn,
  createBtnTitle,
  createModal,
}): JSX.Element => {
  const [createState, setCreateState] = useState(false);
  return (
    <div className="grid grid-cols-12 gap-4 my-4">
      <Sheet className="col-span-full">
        <div className="text-xl mb-2">{title}</div>
        {
          // if createBtn is defined, show that instead
          createBtn ??
            // if createBtnTitle is not defined, do not show button
            (createBtnTitle && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setCreateState(true)}
              >
                {createBtnTitle}
              </Button>
            ))
        }
      </Sheet>

      <div className="col-span-full">
        {directoryTitle && (
          <div className="text-lg mb-2 mt-4">{directoryTitle}</div>
        )}
        {children}
      </div>
      {!!createModal && (
        <createModal.type
          {...createModal.props}
          open={createState}
          onClose={() => setCreateState(false)}
        />
      )}
    </div>
  );
};

export default AcadDirectoryContainer;
