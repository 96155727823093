import CarouselCard from "@components/CarouselCard";
import { CarouselSlide } from "@defs/carousel_slide";
import { collectionGroup, CollectionReference } from "@firebase/firestore";
import { AcadToggleSelect } from "@components";
import { useRetrieveCollection } from "@hooks";
import DialogProps from "@framework/types/dialog_props";
import ScholarshipInfo from "@models/scholarship_info";
import ScholarshipParams from "@models/syspars/scholarship_params";
import { Dispatch, SetStateAction } from "react";
import { useFirestore } from "reactfire";
import { useTranslation } from "react-i18next";
interface ScholarshipSelectProps extends DialogProps {
  form: ScholarshipParams;
  setForm: Dispatch<SetStateAction<ScholarshipParams>>;
}

function emptyFunction() {}

const ScholarshipSelect = ({
  open,
  onClose = emptyFunction,
  form,
  setForm,
}: ScholarshipSelectProps) => {
  const db = useFirestore();
  const collectionRef = collectionGroup(
    db,
    ScholarshipInfo.PATH
  ) as CollectionReference<ScholarshipInfo>;

  const { loading, data, error } = useRetrieveCollection({
    collectionRef,
  });

  const { t } = useTranslation("scholarships");

  const options = data
    ? data.docs.map((d): CarouselSlide => {
        const sc = d.data();
        return {
          id: sc.id,
          title: sc.name,
          description: sc.scholarshipDesc,
          image: sc.bannerUrl,
          url: sc.slug,
        };
      })
    : [];

  return (
    <AcadToggleSelect<CarouselSlide>
      title={t("select_scholarships_for_carousel")}
      noOptionsMsg={t("no_scholarships_to_display")}
      itemIdKey="id"
      open={open}
      onClose={onClose}
      loading={loading}
      error={error}
      options={options}
      value={form?.carousel}
      itemRenderer={(item, index) => (
        <CarouselCard slide={item} key={"item-render" + index} />
      )}
      onSelect={(selection) => {
        setForm({ ...form, carousel: selection });
        onClose();
      }}
    />
  );
};

export default ScholarshipSelect;
