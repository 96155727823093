import {
  doc,
  DocumentReference,
  getDoc,
  getFirestore,
} from "firebase/firestore";
import asyncHook from "./AsyncHook";

type SchoolsDoc = {
  schools: string[];
};

export async function getSchools() {
  const db = getFirestore();
  let schools: string[] = [];
  const ref = doc(db, "read_only/schools") as DocumentReference<SchoolsDoc>;
  const promise = getDoc<SchoolsDoc>(ref);
  const [data] = await asyncHook(promise);
  if (data?.exists()) {
    schools = data.data().schools;
  }
  return schools;
}

export default getSchools;
