import { Fade } from "@mui/material";

interface ErrorProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
}

/**
 * does not need to be localized; this is a page that's not meant to have one (yet)
 * @param param0
 * @returns
 */
export default function Error({ title, children }: ErrorProps) {
  return (
    <Fade in appear>
      <div className="flex flex-col md:flex-row justify-center gap-4 md:gap-6">
        <div className="flex flex-row flex-wrap items-center gap-4">
          <div className="flex flex-col space-y-2 items-center">
            <img
              src="/images/Aca - O - O.png"
              height={160}
              width={160}
              alt="Aca is not impressed."
            />
          </div>
          <div className="flex flex-col items-center md:items-start text-center md:text-left">
            <h1 className="font-akira uppercase text-xl md:text-2xl max-w-sm">
              {title}
            </h1>
            {children}
          </div>
        </div>
      </div>
    </Fade>
  );
}
