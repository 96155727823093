import { Button, Stack } from "@mui/material";
import { CSVLink } from "react-csv";
import { flatten } from "@lib/flattenObject";
import { useState } from "react";

import { httpsCallable } from "firebase/functions";
import { useFunctions } from "reactfire";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "@components/LoadingOverlay";
import { format } from "date-fns";
import toast from "react-hot-toast";

type ExportRes = string[];
type ExportType = string[];

const ExportUsers = () => {
  const [exportData, setExportData] = useState<ExportType>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const fn = useFunctions();
  const { t } = useTranslation("admin");

  const loadData = async () => {
    setLoading(true);
    const callable = httpsCallable<null, ExportRes>(fn, "user-exportUsers");
    toast.promise(callable(), {
      error: t("loading_users_failed"),
      loading: t("loading_users"),
      success: (response) => {
        setExportData(response.data);
        setLoading(false);
        return t("users_ready_for_export", { count: response.data.length });
      },
    });
  };

  return (
    <Stack gap={2}>
      <LoadingOverlay show={loading} />
      <Stack gap={2} direction="row">
        <Button size="small" variant="contained" onClick={loadData}>
          {t("load_all_users")}
        </Button>
        <Button
          size="small"
          disabled={exportData.length === 0}
          variant="contained"
        >
          <CSVLink
            data={exportData.map((d) => flatten(d))}
            filename={`Users_Export_${format(
              Date.now(),
              t("date_format", { ns: "common" })
            )}.csv`}
            style={{ color: "#fff" }}
          >
            {t("export_all_users")}
          </CSVLink>
        </Button>
      </Stack>
      <Stack></Stack>
    </Stack>
  );
};

export default ExportUsers;
