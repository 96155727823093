export function fallbackCopyToClipboard(text: string): Promise<void> {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  let successful: boolean = false;
  try {
    successful = document.execCommand("copy");
  } catch (err) {
    // do nothing
  }

  document.body.removeChild(textArea);
  return successful ? Promise.resolve() : Promise.reject();
}

export function copyToClipboard(text: string): Promise<void> {
  if (!navigator.clipboard) {
    return fallbackCopyToClipboard(text);
  }
  return navigator.clipboard.writeText(text);
}
