// ----------------------------------------------------------------------

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    mobile: 0,
    dialog: 510,
    tablet: 640,
    laptop: 1024,
    desktop: 1200,
  },
};

export default breakpoints;
