import { AcadDirectoryContainer } from "@components";
import { useTranslation } from "react-i18next";
import ScholarshipCreate from "./ScholarshipCreate";
import ScholarshipDirectory from "./ScholarshipDirectory";

export default function Benefits() {
  const { t } = useTranslation("scholarships");

  return (
    <AcadDirectoryContainer
      title={t("emoji_scholarships_title")}
      directoryTitle={t("scholarship_directory")}
      createBtnTitle={t("add_scholarship")}
      createModal={<ScholarshipCreate />}
    >
      <ScholarshipDirectory />
    </AcadDirectoryContainer>
  );
}
