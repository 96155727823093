import {
  Box,
  Checkbox,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useTranslation } from "react-i18next";

export type Order = "asc" | "desc";

export interface HeadCell<T = any> {
  disablePadding?: boolean;
  id: keyof T | "";
  label: string;
  align?: TableCellProps["align"];
  disableSort?: boolean;
  sticky?: "left" | "right";
}

interface AcadTableProps<T extends Record<string, any>> {
  numSelected?: number;
  onRequestSort(event: React.MouseEvent<unknown>, property: keyof T): void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: keyof T;
  rowCount: number;
  headCells: HeadCell<T>[];
  enableCheckbox?: boolean;
}

function AcadTableHead<T = any>({
  headCells = [],
  numSelected = 0,
  onRequestSort,
  onSelectAllClick = () => {},
  order,
  orderBy,
  rowCount,
  enableCheckbox,
}: AcadTableProps<T>) {
  const { t } = useTranslation("components");
  function createSortHandler(property: keyof T) {
    return (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  }

  return (
    <TableHead>
      <TableRow
        sx={{
          borderRadius: 10,
          overflow: "hidden",
        }}
      >
        {enableCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {headCells.map((headCell, i) => (
          <TableCell
            key={i}
            align={headCell.align ?? "left"}
            padding={
              headCell.disablePadding
                ? "none"
                : headCell.id
                ? "normal"
                : "checkbox"
            }
            sx={{
              position: headCell.sticky ? "sticky" : "inherit",
              left: headCell.sticky === "left" ? 0 : "auto",
              right: headCell.sticky === "right" ? 0 : "auto",
              backgroundColor: "#f4f6f8",
              zIndex: headCell.sticky ? 1 : 0,
              whiteSpace: "nowrap",
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {!headCell.disableSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={
                  headCell.id ? createSortHandler(headCell.id) : () => {}
                }
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? t("sort_desc") : t("sort_asc")}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default AcadTableHead;
