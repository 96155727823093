import { api } from "@acad_axios";
import LoadingOverlay from "@components/LoadingOverlay";
import { DialogProps } from "@defs/Types";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  CreateAccessToken,
  createAccessTokenSchema,
  CreateAcessTokenSchema,
  privateScopes,
  publicScopes,
} from "@models/oauth";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useOAuthClient } from "./oauthClientContext";

const AppTokenModalCreate = ({ open, onClose }: DialogProps) => {
  const [loading, setLoading] = useState(false);
  const { clientId, clientSecret, isAdminApp, isPartnerApp } = useOAuthClient();
  const { t } = useTranslation("developer");
  const { control, handleSubmit } = useForm<CreateAcessTokenSchema>({
    defaultValues: { scope: ["user:read:public"] },
    resolver: joiResolver(createAccessTokenSchema),
  });

  const allowPrivateScopes = !!isAdminApp || !!isPartnerApp;

  const generateToken = handleSubmit(async (data) => {
    setLoading(true);
    const body = new URLSearchParams();
    body.append("grant_type", "client_credentials");
    body.append("scope", data.scope.join(" "));
    body.append("client_id", clientId);
    body.append("client_secret", clientSecret);
    try {
      await api.post<CreateAccessToken>("/oauth/token", body, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
    } catch (e) {
      toast.error((e as any)?.message || t("create_access_token_failed"));
    }
    setLoading(false);
    onClose();
  });

  return (
    <Dialog {...{ open, onClose }} maxWidth="sm" fullWidth>
      <LoadingOverlay show={loading} />
      <DialogTitle>{t("create_access_token")}</DialogTitle>
      <DialogContent>
        <Stack sx={{ py: 2 }}>
          <FormControl>
            <InputLabel>{t("scopes")}</InputLabel>
            <Controller
              control={control}
              name="scope"
              render={({ field }) => (
                <Select
                  multiple
                  label={t("scopes")}
                  {...field}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {publicScopes.map((scope) => (
                    <MenuItem value={scope} key={scope}>
                      {scope}
                    </MenuItem>
                  ))}
                  {allowPrivateScopes &&
                    privateScopes.map((scope) => (
                      <MenuItem value={scope} key={scope}>
                        {scope}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={generateToken} variant="contained">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppTokenModalCreate;
