import {
  Button,
  Alert,
  ButtonBase,
  Fade,
  Card,
  Stack,
  Avatar,
  CardProps,
  CardContent,
} from "@mui/material";
import { ArrowLeft, DeleteForever, Save } from "@mui/icons-material";
import {
  doc,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
} from "firebase/firestore";
import { FC, FormEvent, useEffect, useState } from "react";
import { useContext } from "react";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import { TournamentDoc, TournamentType } from "@defs/Tournament.type";
import { userContext } from "@lib/AuthContext";
import getSchools from "@lib/GetSchoolList";
import { httpsCallable } from "firebase/functions";
import loadingMessage from "@defs/LoadingMessages";
import swal from "sweetalert2";
import { OrgProps } from "@defs/Types";
import TournamentPageMain from "./main";
import TournamentPagePrizes from "./prizes/index";
import equal from "fast-deep-equal/es6";
import TournamentParticipants from "./participants";
import TournamentSchedule from "./schedule";
import { onFileSelect, Files } from "@lib/file";
import { createContext } from "react";
import TournamentCustomFields from "./fields";
import { AcadSaveSnack, AcadSEO } from "@components";
import { useFirestore, useFirestoreDoc, useFunctions } from "reactfire";
import TournamenCheckIn from "./checkin";
import AcadButtonGroup, {
  AcadButtonGroupItem,
} from "@components/AcadButtonGroup";
import QuickActions from "./quick_actions";
import { useTranslation } from "react-i18next";

enum Tab {
  main = "Main",
  prizes = "Prizes",
  participants = "Participants",
  schedule = "Schedule",
  custom_fields = "Custom Fields",
  seo = "SEO",
  check_in = "Check In",
}
const ns = "routes";

export const tournamentContext =
  createContext<DocumentSnapshot<TournamentDoc> | null>(null);

export const useTournamentEdit = () => {
  const data = useContext(tournamentContext);
  return data;
};

const TournamentEdit = () => {
  const db = useFirestore();
  const functions = useFunctions();
  const { tournamentId } = useParams<{ tournamentId: string }>();
  const { isAdmin } = useContext(userContext);

  const { t } = useTranslation("events");

  const innerNavButtons: AcadButtonGroupItem<Tab>[] = [
    { label: t("settings", { ns }), value: Tab.main },
    { label: t("participants", { ns }), value: Tab.participants },
    { label: t("check_in", { ns }), value: Tab.check_in },
    { label: t("matches", { ns }), value: Tab.schedule },
    { label: t("custom_fields", { ns }), value: Tab.custom_fields },
    { label: t("prizes", { ns }), value: Tab.prizes },
    { label: t("seo", { ns }), value: Tab.seo },
  ];

  const path = `tournaments/${tournamentId}`;
  const docRef = doc(db, path) as DocumentReference<TournamentDoc>;
  const { status, data: snap, error } = useFirestoreDoc(docRef);
  const loading = status === "loading";

  const [tabValue, setTabValue] = useState<Tab>(Tab.main);

  const [schools, setSchools] = useState<string[]>([]);
  useEffect(() => {
    (async () => {
      setSchools(await getSchools());
    })();
  }, []);
  const [files, setFiles] = useState<Files<TournamentDoc>>({});

  useEffect(() => {
    if (!snap?.exists()) return;
    setForm(snap.data());
  }, [snap]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    const updateCallable = httpsCallable(functions, "tournament-update");
    const data = {
      ...form,
      tournament: {
        ...form.tournament,
        grand_finals_modifier:
          form.tournament.grand_finals_modifier === "single or double match"
            ? null
            : form.tournament.grand_finals_modifier,
      },
    };
    toast.promise(updateCallable({ data, useProxy: true }), {
      success: t("sucessfully_updated_tournament"),
      loading: loadingMessage(),
      error: (err) => {
        console.error(err);
        return err.message;
      },
    });
  };

  const deleteTournament = () => {
    swal
      .fire({
        title: t("delete_tournament_question"),
        text: t("this_action_is_irreversible"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#B33A3A",
      })
      .then((result) => {
        if (result.isConfirmed) {
          const deleteCallable = httpsCallable(functions, "tournament-delete");
          toast.promise(deleteCallable({ fireTourId: tournamentId }), {
            loading: loadingMessage(),
            error: (err) => err.message,
            success: () => {
              history.push("/events/tournaments");
              return t("sucessfully_deleted_tournament");
            },
          });
        }
      });
  };

  const Host: FC<{ org?: Partial<OrgProps>; leagueTitle?: string }> = ({
    org,
    leagueTitle,
  }) => (
    <>
      <Stack className="col-span-full">
        <Stack gap={1}>
          <div className="text-xl">{t("host")}</div>
          <Card>
            <CardContent>
              <Stack direction="row" alignItems="center">
                <Avatar variant="rounded" src={org?.logo} />
                <div className="text-lg font-medium pl-3">{org?.name}</div>
              </Stack>
            </CardContent>
          </Card>
        </Stack>

        {leagueTitle && (
          <Stack>
            <div className="text-xl">{t("league")}</div>
            <Card>
              <CardContent>
                <div className="col-span-full flex">
                  <div className="text-lg font-medium pl-3">{leagueTitle}</div>
                </div>
              </CardContent>
            </Card>
          </Stack>
        )}
      </Stack>
    </>
  );

  const [form, setForm] = useState<TournamentDoc>({
    orgId: "",
    leagueId: "",
    leagueTitle: "",
    _leagueTitle: "",
    tournament: {
      id: 0,
      url: "",
      name: "",
      hold_third_place_match: false,
      tournament_type: TournamentType.single,
      grand_finals_modifier: "single match",
      pts_for_match_win: 1,
      pts_for_match_tie: 0.5,
      pts_for_game_win: 0,
      pts_for_game_tie: 0,
      pts_for_bye: 1,
      ranked_by: "match wins",
    },
    isSchoolOnly: true,
    schoolsAllowed: [],
    visibility: "draft",
    maxTeamCount: 16,
    activePlayerCountPerTeam: 5,
    maxSubPlayerPerTeam: 2,
    regOpen: true,
    regEndDate: Timestamp.now(),
    regOpenDate: Timestamp.now(),
    game: "",
    description: "",
    enableCustomFields: false,
    customFields: {},
    commsChannelLink: "",
    typeformId: "",
    seo: {
      title: "",
      description: "",
      thumbnail: "",
    },
    allowContainedTeams: true,
    allowPendingCheckIn: true,
    sameSchoolOnly: false,
    checkInProcessed: false,
  });

  const history = useHistory();

  return loading ? (
    <div>loading...</div>
  ) : error ? (
    <div className="flex w-full">
      <Alert severity="error">{error.message}</Alert>
    </div>
  ) : snap?.exists() ? (
    <tournamentContext.Provider value={snap}>
      <Stack sx={{ mt: 2 }} gap={2}>
        <div className="flex  mb-2 ">
          <Button
            startIcon={<ArrowLeft />}
            variant="outlined"
            onClick={() => history.push("/events/tournaments")}
          >
            {isAdmin ? t("go_back_to_tournaments") : t("go_back_to_dashboard")}
          </Button>
        </div>

        {/* Cover Photo */}
        <ButtonBase
          className="w-full h-52 bg-gray-200 bg-cover bg-center rounded-md"
          component="label"
          style={{
            backgroundImage: `url("${
              files.backgroundImage?.path || form?.backgroundImage
            }")`,
          }}
        >
          <input
            type="file"
            hidden
            name="backgroundImage"
            onChange={onFileSelect(setFiles, setForm)}
            accept="image/*"
          />
          {Boolean(files.backgroundImage)
            ? ""
            : form?.backgroundImage
            ? ""
            : t("click_here_to_add_cover_photo")}
        </ButtonBase>

        {/* Profile Photo */}
        <ButtonBase
          className="shadow-md h-36 w-36 bg-white rounded-md self-center sm:self-start sm:ml-4 -mt-14  bg-cover bg-center flex justify-center items-center"
          style={{
            backgroundImage: `url("${files.logo?.path || form?.logo}")`,
          }}
          component="label"
        >
          <input
            type="file"
            hidden
            name="logo"
            onChange={onFileSelect(setFiles, setForm)}
            accept="image/*"
          />

          {Boolean(files.logo) ? "" : form?.logo ? "" : t("add_logo")}
        </ButtonBase>

        <QuickActions />

        <AcadButtonGroup
          items={innerNavButtons}
          value={tabValue}
          sx={{ m: 2 }}
          onClick={(value) => setTabValue(value)}
        />

        {snap.data().visibility === "draft" && (
          <Alert severity="info">
            {t("this_tournament_is_still_in_draft_mode")}
          </Alert>
        )}

        {/* Form */}
        <form
          className="grid grid-cols-12 mx-0 md:mx-4 gap-54"
          onSubmit={onSubmit}
        >
          <Fade in={tabValue === Tab.main} unmountOnExit>
            <div className="grid-cols-12 col-span-full w-full">
              <Host org={form?.orgData} leagueTitle={form?.leagueTitle} />
              <TournamentPageMain {...{ form, setForm, schools }} />
            </div>
          </Fade>

          <Fade in={tabValue === Tab.check_in} unmountOnExit>
            <div className="grid-cols-12 col-span-full w-full">
              <TournamenCheckIn />
            </div>
          </Fade>
          <Fade in={tabValue === Tab.prizes} unmountOnExit>
            <div className="col-span-full">
              <TournamentPagePrizes {...{ form, setForm }} />
            </div>
          </Fade>
          <Fade in={tabValue === Tab.participants} unmountOnExit>
            <div className="col-span-full">
              <TournamentParticipants
                tournament={snap.data()}
                tournamentId={snap.id}
                {...{ form, setForm }}
              />
            </div>
          </Fade>
          <Fade in={tabValue === Tab.schedule} unmountOnExit>
            <div className="col-span-full">
              <TournamentSchedule
                tournamentId={snap.id}
                tournament={snap.data()}
              />
            </div>
          </Fade>
          <Fade in={tabValue === Tab.custom_fields} unmountOnExit>
            <div className="col-span-full">
              <TournamentCustomFields {...{ form, setForm }} />
            </div>
          </Fade>
          <Fade in={tabValue === Tab.seo} unmountOnExit>
            <div className="col-span-full">
              <AcadSEO
                className="w-full"
                value={form.seo}
                onChange={(seo) => setForm((state) => ({ ...state, seo }))}
              />
            </div>
          </Fade>

          <div className="col-span-full flex flex-row-reverse">
            <Button
              type="submit"
              variant="contained"
              startIcon={<Save />}
              disabled={equal(form, snap.data())}
            >
              {t("save_tournament")}
            </Button>

            <Button
              onClick={deleteTournament}
              variant="contained"
              color="error"
              className="mr-4"
              startIcon={<DeleteForever />}
            >
              {t("delete_tournament")}
            </Button>
          </div>
          <AcadSaveSnack
            stateA={snap.data()}
            stateB={form}
            action={
              <Button type="submit" variant="contained" size="small">
                {t("save", { ns: "common" })}
              </Button>
            }
          />
        </form>
      </Stack>
    </tournamentContext.Provider>
  ) : (
    <div>{t("nothing_here")}</div>
  );
};

export const Container: FC<{ dark?: boolean } & CardProps> = ({
  dark,
  children,
  ...props
}) => (
  <Card
    {...props}
    className={`${props.className} col-span-full grid grid-cols-12 mb-8 ${
      dark ? "bg-gray-400" : "bg-white"
    } p-4 lg:py-8 lg:px-6 gap-4`}
  >
    {children}
  </Card>
);

export default TournamentEdit;
