/**
 * List of regular expressions
 * used in the entire app
 *
 * Dictionary:
 *  - RE - Regular Expression
 *  - LC - Lower Case
 *  - UC - Upper Case
 */

// --------------
export const reCharSet =
  "a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ";
export const reCharSetLC = "a-z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ";
export const reCharSetUP = "A-Z0-9ÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ";

export const slugRE = new RegExp(`^[${reCharSet}]+(?:[-_][${reCharSet}]+)*$`);
export const shortcodeRE = new RegExp(`[${reCharSet}_-]+`);
export const nameRE = new RegExp(`[${reCharSet} _-]+`);

/**
 * Lower cased username regular expression
 */
export const usernameLCRE = new RegExp(
  `^(?=[${reCharSetLC}._]{3,15}$)(?!.*[_.]{2})[^_.].*[^_.]$`
);

export const usernameRE = new RegExp(
  `^(?=[${reCharSet}._]{3,15}$)(?!.*[_.]{2})[^_.].*[^_.]$`
);

export const emailRE =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
