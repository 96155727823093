import { AcadDirectory } from "@components";
import { OrgProps } from "@defs/Types";
import { collection, limit, query, where } from "@firebase/firestore";
import { usePaginatedCollection } from "@hooks";
import { useUserContext } from "@lib/AuthContext";
import { LeagueCol } from "@models/league";
import { Button, Fade, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFirestore } from "reactfire";
import LeagueCard from "./LeagueCard";

interface LeagueDirectoryProps {
  orgs: OrgProps[];
  orgLoading: boolean;
}

const LeagueDirectory = ({ orgs, orgLoading }: LeagueDirectoryProps) => {
  const db = useFirestore();
  const { isAdmin } = useUserContext();
  const LeagueRef = collection(db, "leagues") as LeagueCol;
  const q1 = query(LeagueRef, limit(20));
  const q2 = query(
    q1,
    where(
      "hostId",
      "in",
      orgs.map((org) => org.schoolShortcode).filter((org, i) => i < 10)
    )
  );

  const {
    data: snapshot,
    loading,
    next,
    prev,
    nextDisabled,
    prevDisabled,
  } = usePaginatedCollection({
    query: isAdmin ? q1 : q2,
    limit: 20,
    orderBy: {
      field: "createdAt",
      direction: "desc",
    },
  });

  const leagues = snapshot?.docs ?? [];

  const { t } = useTranslation("events");

  return (
    <AcadDirectory hasData={!!leagues.length} loading={loading}>
      <Stack gap={4}>
        <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
          {leagues.map((league) => (
            <Fade in key={league.id}>
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <LeagueCard league={league} />
              </Grid>
            </Fade>
          ))}
        </Grid>
        <Stack gap={2} direction="row">
          <Button variant="contained" disabled={prevDisabled} onClick={prev}>
            {t("previous", { ns: "common" })}
          </Button>
          <Button variant="contained" disabled={nextDisabled} onClick={next}>
            {t("next", { ns: "common" })}
          </Button>
        </Stack>
      </Stack>
    </AcadDirectory>
  );
};

export default LeagueDirectory;
