import { AcadTextField } from "@components";
import AcadAutoComplete from "@components/textfield/AcadAutocomplete";
import { Button, Card, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useUserDocSchools } from "./UserDocSchoolsCtxProvider";
import { useTranslation } from "react-i18next";
interface VerifyFilterProps {
  onFilter(filters: VerifyFilters): void;
}

export interface VerifyFilters {
  username: string;
  school: string;
}

export default function VerifyFilter({ onFilter }: VerifyFilterProps) {
  const { schools } = useUserDocSchools();
  const { register, handleSubmit, reset, control } = useForm<VerifyFilters>({
    defaultValues: {
      username: "",
      school: "",
    },
  });

  const filter = handleSubmit((filters) => {
    onFilter(filters);
  });

  const clear = () => {
    reset();
    onFilter({ username: "", school: "" });
  };
  const { t } = useTranslation("student_verification");

  return (
    <Card>
      <Stack display="flex" flexDirection="column" gap={2} p={2}>
        <Typography>Filters</Typography>
        <Stack display="flex" flexDirection="row" gap={2}>
          <AcadTextField
            {...register("username")}
            label={t("username", { ns: "common" })}
          />

          <AcadAutoComplete
            control={control}
            name="school"
            fullWidth
            freeSolo
            options={["", ...schools]}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(props) => {
              return <AcadTextField {...props} label={t("school")} />;
            }}
          />
        </Stack>
        <Stack display="flex" flexDirection="row-reverse" gap={2}>
          <Button variant="contained" onClick={filter}>
            {t("apply", { ns: "common" })}
          </Button>
          <Button variant="outlined" onClick={clear}>
            {t("reset", { ns: "common" })}
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
}
