import { AcadNavTabs } from "@components";
import AuthGuard from "@components/AuthGuard";
import routes from "./EventRoutes";
const TournamentsAndLeagues = () => {
  return (
    <AuthGuard>
      <AcadNavTabs {...{ routes }} />
    </AuthGuard>
  );
};

export default TournamentsAndLeagues;
