import TextField from "@components/MemoizedTextfield";
import {
  AcadDirectory,
  AcadDirectoryContainer,
  AcadEditDocSection,
} from "@components";
import { useForm, useWriteDocument } from "@hooks";
import ScholarshipParams from "@models/syspars/scholarship_params";
import { doc, DocumentReference } from "firebase/firestore";
import { useFirestore } from "reactfire";
import CarouselCard from "@components/CarouselCard";
import { Button } from "@mui/material";
import { ChangeEvent, useCallback, useState } from "react";
import ScholarshipSelect from "./ScholarshipSelect";
import { useTranslation } from "react-i18next";

const ScholarshipSettings = () => {
  const db = useFirestore();
  const docRef = doc(
    db,
    ScholarshipParams.PATH
  ) as DocumentReference<ScholarshipParams>;
  const { form, setForm, updateFormField, onSnapshotChanged } =
    useForm<ScholarshipParams>(new ScholarshipParams());

  const { loading, error, snap, writeDocument } = useWriteDocument({
    docRef,
    onSnapshotChanged,
  });
  const { t } = useTranslation("scholarships");

  const updateRevalidation = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setForm({
        ...form,
        revalidation: {
          ...form.revalidation,
          [e.target.name]: e.target.value,
        },
      });
    },
    [form, setForm]
  );

  const [openSelect, setOpenSelect] = useState<boolean>(false);
  return (
    <AcadDirectoryContainer title={t("emoji_scholarship_settings")}>
      <AcadEditDocSection
        className="col-span-12 grid grid-cols-12 gap-4"
        title={
          <>
            {t("scholarship_carousel")}
            <Button
              size="small"
              variant="outlined"
              className="ml-4"
              onClick={() => setOpenSelect(true)}
            >
              {t("select", { ns: "common" })}
            </Button>
          </>
        }
        subtitle={t("click_save_below_to_save_selection")}
      >
        <AcadDirectory
          loading={loading}
          error={error}
          hasData={form?.carousel?.length > 0 ?? false}
          noDataEl={t("no_scholarship_carousel_items")}
          className="col-span-12"
        >
          <div className="grid gap-4 grid-cols-12">
            {form?.carousel?.map((slide, i) => (
              <CarouselCard
                slide={slide}
                key={slide.title + i}
                className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3"
              />
            ))}
          </div>
        </AcadDirectory>
      </AcadEditDocSection>

      <AcadEditDocSection
        className="grid grid-cols-12 gap-4 col-span-full mt-4"
        title={t("scholarship_screen_settings")}
      >
        <TextField
          multiline
          rows={9}
          name="description"
          label={t("scholarships_description")}
          className="col-span-full"
          value={form?.description}
          onChange={updateFormField}
        />

        <TextField
          name="page"
          type="number"
          label={t("revalidation_page")}
          className="col-span-6 sm:col-span-3"
          value={form?.revalidation?.page}
          onChange={updateRevalidation}
        />

        <TextField
          name="list"
          type="number"
          label={t("revalidation_list")}
          className="col-span-6 sm:col-span-3"
          value={form?.revalidation?.list}
          onChange={updateRevalidation}
        />
      </AcadEditDocSection>

      <div className="col-span-12 flex justify-end mt-4">
        <Button
          onClick={() => writeDocument(form)}
          variant="contained"
          color="primary"
          disabled={form === snap?.data()}
        >
          {t("save", { ns: "common" })}
        </Button>
      </div>
      <ScholarshipSelect
        form={form}
        setForm={setForm}
        open={openSelect}
        onClose={() => setOpenSelect(false)}
      />
    </AcadDirectoryContainer>
  );
};
export default ScholarshipSettings;
