import { Paper, ButtonBase } from "@mui/material";
import { VFC } from "react";
import { Episode } from "@defs/Episode";
import { useTranslation } from "react-i18next";

type EpisodeCardProps = {
  episode: Episode;
  onClick: () => void;
  seriesName?: string;
};

const EpisodeCard: VFC<EpisodeCardProps> = ({
  episode,
  onClick,
  seriesName,
}) => {
  const { t } = useTranslation("boostcamp");

  return (
    <Paper
      className="shadow-sm flex flex-col items-start  overflow-hidden rounded-md relative w-full bg-white"
      elevation={0}
      component={ButtonBase}
      onClick={onClick}
    >
      <div
        className="h-36 w-full bg-cover bg-center bg-gray-200"
        style={{ backgroundImage: `url("${episode.thumbnail}")` }}
      ></div>
      <div className="text-md leading-none text-left p-4 flex flex-col">
        {seriesName && <div>{seriesName}</div>}
        <div className={seriesName && "text-gray-400"}>
          {t("episode_number_video_title", {
            episodeNumber: episode.episodeNumber,
            videoTitle: episode.videoTitle,
          })}
        </div>
      </div>
    </Paper>
  );
};

export default EpisodeCard;
