import { region } from "@defs/region/region";
import { EnvConfig } from "./env_config";
import EnvDev from "./env_dev";
import EnvProd from "./env_prod";
import EnvTest from "./env_test";

export enum EnvType {
  Dev = "dev",
  Test = "test",
  Prod = "prod",
}

export function isDev() {
  return process.env.REACT_APP_ENV === EnvType.Dev;
}

export function isTest() {
  return process.env.REACT_APP_ENV === EnvType.Test;
}

export function isProd() {
  return process.env.REACT_APP_ENV === EnvType.Prod;
}

export function isEmu() {
  return !!process.env.REACT_APP_EMULATED;
}

const Environment: EnvConfig = isDev()
  ? EnvDev[region]
  : isTest()
  ? EnvTest[region]
  : EnvProd[region];

export default Environment;
