import Sheet from "@components/Sheet";
import { Add, Close } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const EMPTY_LIST: string[] = [];

interface AcadMultiInputProps {
  className?: string;
  label?: string;
  onChange?(value: string[]): void;
  value?: string[];
}

/**
 * Variable multi input field. Only emits string values for now.
 *
 * @param param0
 * @returns
 */
const AcadMultiInput = ({
  className = "",
  label = "",
  onChange,
  value = EMPTY_LIST,
}: AcadMultiInputProps) => {
  const { t } = useTranslation("components");
  const [list, setList] = useState<string[]>(value);

  // set initial value
  useMemo(() => {
    setList(value);
  }, [value, setList]);

  const emitChange = useCallback(() => {
    if (list === value) {
      return;
    }
    if (onChange) {
      onChange(list);
    }
  }, [list, onChange, value]);

  useEffect(() => {
    emitChange();
  }, [emitChange]);

  const addInput = () => {
    setList((old) => [...old, ""]);
  };

  const removeInput = (index: number) => {
    const arr = [...list];
    arr.splice(index, 1);
    setList(arr);
  };

  const onChangeInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    let arr = [...list];
    arr[index] = e.target.value;
    setList(arr);
  };

  return (
    <div className={className}>
      <div className="col-span-full">
        {label}
        <IconButton onClick={addInput} size="large">
          <Add />
        </IconButton>
      </div>

      <Sheet className="col-span-full bg-gray-100 grid grid-cols-12 gap-4">
        {list.map((val, i) => (
          <TextField
            className="col-span-full bg-white"
            key={`multi-input-${i}`}
            name={`multi-input-${i}`}
            value={val || ""}
            label={`${label} ${i + 1}`}
            onChange={(e) => onChangeInput(e, i)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => removeInput(i)}
                    color="warning"
                    size="large"
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}

        {list.length === 0 && (
          <div className="col-span-full">
            <span className="text-gray-500">
              {t("no_label_to_show", { label })}
            </span>
          </div>
        )}
      </Sheet>
    </div>
  );
};

export default AcadMultiInput;
