// TODO: Localize error messages

import acadJoi from "@acad_joi";
import { DiscordUser } from "@defs/discord";
import { allSubregions, Subregion } from "@defs/region/subregion";
import { OrgProps } from "@defs/Types";
import { NonStudentTypes } from "@defs/User";
import { Timestamp } from "@firebase/firestore";
import Game from "./game";
interface CountryPhoneNumberData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

interface IGNProps {
  game: Game["id"];
  ign: string;
}

export enum StudentYearEnum {
  JuniorHighschool,
  SeniorHighschool,
  FirstYear,
  SecondYear,
  ThirdYear,
  FourthYear,
  FifthYear,
  PostGraduate,
}

export enum UserTags {
  StudentLeader = "Student Leader", //student
  ClubMember = "Club Member", //student
  StudentCompetitor = "Student Competitor", // student
  AspiringProfessional = "Aspiring Professional",
  CasualGamer = "Casual Gamer",
  ContentCreator = "Content Creator",
  NonGamer = "Non-gamer",
  Teacher = "Teacher", // non-student
  Professional = "Professional", // non-student
  Parent = "Parent", // non-student
}

export class User {
  displayName: string = "";
  email: string = "";
  photoURL: string = "";
  uid: string = "";
  username: string = "";
  _username?: string = "";
  verified: boolean = false;
  verificationExpiry?: Timestamp;
  flags: string[] = [];
  igns: IGNProps[] = [];
  photoMain: string = "";
  photoSub: string = "";
  photoHeadshot: string = "";
  firstTimeSetup: boolean = false;
  phoneNumber: string = "";
  phoneNumberFormatted?: string;
  phoneNumberCountryData?: Partial<CountryPhoneNumberData>;
  isStudent: boolean = true;
  studentYear?: StudentYearEnum;
  ingressYear?: number;
  school?: string;
  pendingSchoolName?: string;
  course?: string;
  wasInCollege: boolean = false;
  tags: UserTags[] = [];
  isOrgMember: boolean = false;
  org?: Partial<OrgProps>;
  firstName: string = "";
  middleInitial: string = "";
  lastName: string = "";
  region!: Subregion;
  geography: string = "";
  statusText: string = "";
  coverPhoto: string = "";
  createdAt: Timestamp = Timestamp.now();
  updatedAt: Timestamp = Timestamp.now();
  teams: string[] = [];
  isWaypointUser?: string = "";
  games: string[] = [];
  discord?: DiscordUser;
  nonStudentType?: NonStudentTypes;
  isVarsity?: boolean;
  coinbase?: object;

  static schema = acadJoi.object<User>({
    _username: acadJoi
      .string()
      .required()
      .min(3)
      .max(30)
      .allow("")
      .label("Username")
      .optional(),
    coverPhoto: acadJoi.string().allow("").uri(),
    createdAt: acadJoi.timestamp(),
    firstName: acadJoi.string().label("First Name"),
    middleInitial: acadJoi
      .string()
      .allow("")
      .optional()
      .label("Middle Initial"),
    lastName: acadJoi.string().label("Last Name"),
    isStudent: acadJoi.boolean(),
    pendingSchoolName: acadJoi.string().allow("").optional(),
    school: acadJoi.string().allow("").label("School").optional(),
    photoURL: acadJoi.string().uri().allow("").optional(),
    region: acadJoi.string().valid(...allSubregions),
    studentYear: acadJoi.number().allow(null).optional(),
    ingressYear: acadJoi.number().allow(null).optional(),
    teams: acadJoi.array().items(acadJoi.string()),
    verified: acadJoi.boolean(),
    verificationExpiry: acadJoi
      .timestamp()
      .optional()
      .label("Verification Expiry"),
    displayName: acadJoi.string().empty().allow(""),
    email: acadJoi.string().email({ tlds: { allow: false } }),
    firstTimeSetup: acadJoi.boolean(),
    flags: acadJoi.array().items(acadJoi.string()),
    geography: acadJoi.string(),
    igns: acadJoi.array().items(acadJoi.any()),
    isOrgMember: acadJoi.boolean(),
    org: acadJoi.any(),
    phoneNumber: acadJoi.string().allow("").optional(),
    phoneNumberCountryData: acadJoi.any().optional(),
    phoneNumberFormatted: acadJoi.string().allow("").optional(),
    photoHeadshot: acadJoi.string().uri().allow(""),
    photoMain: acadJoi.string().uri().allow(""),
    photoSub: acadJoi.string().uri().allow(""),
    statusText: acadJoi.string().allow("").label("Status"),
    tags: acadJoi.array().items(acadJoi.string()).optional(),
    uid: acadJoi.string().required(),
    updatedAt: acadJoi.timestamp(),
    wasInCollege: acadJoi.boolean(),
    isWaypointUser: acadJoi.string().allow("").optional(),
    games: acadJoi.array().items(acadJoi.string()).optional(),
    discord: acadJoi.object().optional(),
    nonStudentType: acadJoi
      .string()
      .valid(...Object.values(NonStudentTypes))
      .optional(),
    isVarsity: acadJoi.boolean().optional(),
    coinbase: acadJoi.object().optional(),
  });
}
