import { Fade, Alert, Skeleton } from "@mui/material";
import { OrgProps } from "@defs/Types";
import placeholder_org from "@defs/PlaceholderOrg";
import {
  collection,
  CollectionReference,
  query,
  where,
} from "firebase/firestore";
import { useFirestore, useFirestoreCollection } from "reactfire";
import OrgCard from "./OrgCard";
import { User } from "firebase/auth";
import { useTranslation } from "react-i18next";

interface OrgDirectoryProps {
  isAdmin: boolean;
  user?: User | null;
}

const OrgDirectory = ({ isAdmin, user }: OrgDirectoryProps) => {
  const db = useFirestore();
  const p = "orgs";
  const r = collection(db, p) as CollectionReference<OrgProps>;
  const q = isAdmin
    ? query(r)
    : query(r, where("admins", "array-contains", user?.uid ?? ""));
  const { status, data: snap, error } = useFirestoreCollection(q);
  const loading = status === "loading";
  const { t } = useTranslation("community");

  return loading ? (
    <Fade in appear>
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
        {[...Array(3)].map((__, i) => (
          <Skeleton
            variant="rectangular"
            className="rounded-md"
            animation="wave"
            width="100%"
            key={i}
          >
            <OrgCard org={placeholder_org} />
          </Skeleton>
        ))}
      </div>
    </Fade>
  ) : error ? (
    <Alert severity="error">{error.message}</Alert>
  ) : !snap?.docs.length ? (
    <div className="w-full h-52 text-center flex justify-center items-center text-xl bg-gray-200 rounded-md">
      {t("no_community")}
    </div>
  ) : (
    <Fade in appear>
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
        {snap?.docs.map((doc) => (
          <OrgCard key={doc.id} org={doc.data()} />
        ))}
      </div>
    </Fade>
  );
};

export default OrgDirectory;
