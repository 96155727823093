import { AcadTable } from "@components";
import { HeadCell } from "@components/table/AcadTableHead";
import loadingMessage from "@defs/LoadingMessages";
import { UserData } from "@defs/User";
import { Check, MoreVert, Remove } from "@mui/icons-material";
import {
  Card,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { deleteField, doc, Timestamp, updateDoc } from "firebase/firestore";
import { MouseEvent, useState } from "react";
import toast from "react-hot-toast";
import { useFirestore } from "reactfire";
import { useUserTable } from "../UserTableContext";
import { Icon } from "@iconify/react";
import UserEdit from "../components/UserEdit";
import { red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import useAcademyCbSignUp from "@hooks/use_academy_cb_sign_up";

function UserTable() {
  const {
    users: { nextDisabled, prevDisabled, limit, data: snap, ...data },
  } = useUserTable();

  const users = snap?.docs.map((user) => user.data()) ?? [];

  const { t } = useTranslation("users");

  const headCells: HeadCell<UserData>[] = [
    {
      id: "username",
      label: t("username"),
    },
    {
      id: "lastName",
      label: t("last_name"),
    },
    {
      id: "firstName",
      label: t("first_name"),
    },
    {
      id: "school",
      label: t("school"),
    },
    {
      id: "region",
      label: t("region"),
    },
    {
      id: "verified",
      label: t("verified"),
      disableSort: true,
    },
    {
      id: "",
      label: "",
      disableSort: true,
    },
  ];

  return (
    <Card>
      <AcadTable<UserData>
        {...data}
        dense
        data={users}
        disableNext={nextDisabled}
        disablePrev={prevDisabled}
        headCells={headCells}
        rowsPerPage={limit}
        renderRows={(user, i) => (
          <UserRows
            {...{
              user,
            }}
            key={i}
          />
        )}
        orderBy="username"
      />
    </Card>
  );
}

interface UserRowProps {
  user: UserData;
}

const UserRows = ({ user }: UserRowProps) => {
  const db = useFirestore();

  const { t } = useTranslation("users");

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const openMenu = (e: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(e.currentTarget);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const [editDialog, setEditDialog] = useState<UserData | undefined>();

  const openEditDialog = (user: UserData) => () => {
    closeMenu();
    setEditDialog(user);
  };

  const { cbSignUp, ref: refAcademyCbSignUp } = useAcademyCbSignUp(
    user?.uid ?? ""
  );

  function changeVerificationState(verified: boolean, uid: string) {
    return () => {
      const ref = doc(db, "users", uid);
      const newExpiry = new Date();
      newExpiry.setFullYear(newExpiry.getFullYear() + 1);
      const promise = updateDoc(ref, {
        verified: !verified,
        verificationExpiry: !verified
          ? Timestamp.fromDate(newExpiry)
          : deleteField(),
      });
      toast.promise(promise, {
        loading: loadingMessage(),
        error: (err) => err.message,
        success: verified
          ? t("user_is_now_unverified")
          : t("user_is_now_verified"),
      });
    };
  }
  return (
    <TableRow>
      <TableCell>{user._username ?? user.username}</TableCell>
      <TableCell>{user.lastName}</TableCell>
      <TableCell>{user.firstName}</TableCell>
      <TableCell>
        {user.school ||
          (user.pendingSchoolName && (
            <Tooltip title={t("pending_school")}>
              <Stack alignItems="center" spacing={1} direction="row">
                <span>{user.pendingSchoolName}</span>
                <Icon icon="eva-alert-triangle-outline" color={red[500]} />
              </Stack>
            </Tooltip>
          ))}
      </TableCell>
      <TableCell>{user.region}</TableCell>
      <TableCell padding="checkbox" align="center">
        <IconButton
          size="small"
          sx={{ m: 0 }}
          onClick={changeVerificationState(user.verified, user.uid)}
        >
          {user.verified ? (
            <Check fontSize="inherit" />
          ) : (
            <Remove fontSize="inherit" />
          )}
        </IconButton>
      </TableCell>
      <TableCell padding="checkbox" align="center">
        <IconButton onClick={openMenu}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={menuAnchorEl}
          onClose={closeMenu}
          open={!!menuAnchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem onClick={openEditDialog(user)}>
            <Icon icon="eva-edit-fill" />
            <Typography variant="body2" sx={{ ml: 2 }}>
              {t("edit_username", { username: user.username })}
            </Typography>
          </MenuItem>
        </Menu>
      </TableCell>
      <UserEdit
        open={!!editDialog}
        onClose={() => setEditDialog(undefined)}
        user={editDialog}
        cbSignUp={cbSignUp}
        refAcademyCbSignUp={refAcademyCbSignUp}
      />
    </TableRow>
  );
};

export default UserTable;
