import { UserVerificationDocument } from "@models/user_document";
import { useTranslation } from "react-i18next";

const UserDocumentStatus = (data: UserVerificationDocument) => {
  const { t } = useTranslation("student_verification");
  if (!data.verified && !data.rejected)
    return (
      <div className="bg-gray-600 px-2 py-1 rounded text-white">
        {t("pending")}
      </div>
    );
  if (data.rejected)
    return <div className="bg-red-500 px-2 py-1 rounded">{t("rejected")}</div>;
  if (data.verified)
    return <div className="bg-blue-500 px-2 py-1 rounded">{t("verified")}</div>;
  return "";
};

export default UserDocumentStatus;
