import { Timestamp } from "@firebase/firestore";
import { DateTimePicker, DateTimePickerProps } from "@mui/lab";
import DateTimestampTransformer from "@transformers/date_timestamp_transformer";

interface BaseAcadDateTimePickerProps<T extends Date | Timestamp | null>
  extends Omit<DateTimePickerProps<Date>, "value"> {
  value: T;
}

function BaseAcadDateTimePicker<T extends Date | Timestamp | null>({
  value,
  onChange,
  ...dateTimePickerProps
}: BaseAcadDateTimePickerProps<T>) {
  return (
    <DateTimePicker<Date>
      {...dateTimePickerProps}
      value={DateTimestampTransformer.anyToDate(value)}
      onChange={(val) => onChange(DateTimestampTransformer.anyToDate(val))}
    />
  );
}

export default BaseAcadDateTimePicker;
