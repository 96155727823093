import { withImagePreview } from "@hocs/withImagePreview";
import {
  PlayerCustomFieldAnswer,
  TeamCustomFieldAnswer,
} from "@models/custom_field";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Stack,
} from "@mui/material";
import { VFC } from "react";
import CustomFieldAnswerPlayer from "./CustomFieldAnswer.player";
import CustomFieldAnswerTeam from "./CustomFieldAnswer.team";
import { useTranslation } from "react-i18next";
interface Props {
  teamFieldAnswers: TeamCustomFieldAnswer;
  perPlayerFieldAnswers: PlayerCustomFieldAnswer;
}

const CustomFieldAnswers: VFC<Props> = ({
  teamFieldAnswers,
  perPlayerFieldAnswers,
}) => {
  const { t } = useTranslation("events");

  return (
    <Accordion sx={{ mt: 2 }}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="subtitle1">{t("custom_field_answers")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={2}>
          {!!Object.keys(teamFieldAnswers).length && (
            <Stack gap={1}>
              <Typography variant="subtitle1">{t("team_questions")}</Typography>
              <Stack gap={1}>
                {Object.entries(teamFieldAnswers).map(
                  ([id, fieldWithAnswer]) => (
                    <CustomFieldAnswerTeam
                      key={id}
                      {...{ id, fieldWithAnswer }}
                    />
                  )
                )}
              </Stack>
            </Stack>
          )}

          {!!Object.keys(perPlayerFieldAnswers).length && <Stack></Stack>}
          <Stack gap={1}>
            <Typography variant="subtitle1">{t("player_questions")}</Typography>
            {Object.entries(perPlayerFieldAnswers).map(([id, field]) => (
              <CustomFieldAnswerPlayer key={id} {...{ id, field }} />
            ))}
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default withImagePreview(CustomFieldAnswers);
