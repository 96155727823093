import { UserData } from "@defs/User";
import { Avatar, Chip, ChipProps, CircularProgress } from "@mui/material";
import { doc, DocumentReference } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { useFirestore, useFirestoreDocData } from "reactfire";

interface Props extends ChipProps {
  uid: string;
}
const UserChip = ({ uid, ...props }: Props) => {
  const { t } = useTranslation("common");
  const db = useFirestore();
  const ref = doc(db, `users/${uid || "404"}`) as DocumentReference<UserData>;
  const { data, status } = useFirestoreDocData(ref);

  const username = data?._username || data?.username || "";
  const loading = status === "loading";
  const chipTitle = loading
    ? t("loading").toLowerCase()
    : username || t("user_deleted");

  return (
    <Chip
      {...props}
      label={chipTitle}
      avatar={
        loading ? (
          <CircularProgress size={12} />
        ) : (
          <Avatar src={data?.photoURL}>{username[0]?.toUpperCase()}</Avatar>
        )
      }
    />
  );
};

export default UserChip;
