import acadJoi from "@acad_joi";
import { AcadEditDocSection, AcadTextField } from "@components";
import { joiResolver } from "@hookform/resolvers/joi";
import { getUrlWithParams } from "@lib/url";
import { Button, Typography } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface FormProps {
  code: string;
  url: string;
}

const schema = acadJoi.object<FormProps>({
  code: acadJoi.string().label("Referral Code").required(),
  url: acadJoi.string().label("URL").uri().required(),
});

export default function ReferralCodeUrlGenerator() {
  const [generatedUrl, setGeneratedUrl] = useState<string>("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>({
    defaultValues: {
      code: "",
      url: "",
    },
    resolver: joiResolver(schema),
  });

  const generate = handleSubmit(({ code, url }) => {
    setGeneratedUrl(getUrlWithParams(url, { ref: code }));
  });

  const { t } = useTranslation("users");

  return (
    <AcadEditDocSection
      className="flex flex-col gap-4"
      title={t("referral_code_url_generator")}
    >
      <div className="flex flex-row w-full gap-4">
        <AcadTextField
          label={t("base_url")}
          className="w-1/2 sm:w-full"
          {...register("url")}
          required
          errors={errors}
        />
        <AcadTextField
          label={t("referral_code")}
          className="w-1/2 sm:w-full"
          {...register("code")}
          required
          errors={errors}
        />
      </div>

      <div className="flex flex-row w-full gap-2">
        <Typography variant="subtitle2" className="mr-1">
          {t("generated_url")}
        </Typography>
        {generatedUrl ? (
          <Typography variant="body1" className="mr-1">
            {generatedUrl}
          </Typography>
        ) : (
          <Typography variant="body1" className="mr-1" color="orange">
            {t("ungenerated")}
          </Typography>
        )}
      </div>

      <div>
        <Button variant="contained" onClick={generate} color="primary">
          {t("generate")}
        </Button>
      </div>
    </AcadEditDocSection>
  );
}
