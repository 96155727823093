import Sheet from "@components/Sheet";
import SchoolListContextProvider from "@contexts/schoolList/SchoolListContextProvider";
import { useTranslation } from "react-i18next";
import Filters from "./Filters";
import UserTable from "./Table/UserTable";
import { UserTableProvider } from "./UserTableContext";

function UserList() {
  const { t } = useTranslation("users");

  return (
    <SchoolListContextProvider>
      <UserTableProvider>
        <div>
          <Sheet>{t("emoji_list_of_users_title")}</Sheet>
          <Sheet className="flex mb-4">
            <Filters />
          </Sheet>

          <UserTable />
        </div>
      </UserTableProvider>
    </SchoolListContextProvider>
  );
}

export default UserList;
