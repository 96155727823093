type StringArray = (data?: string | string[]) => string[];
/**
 * This converts `string | string[] | undefined`
 * into `string[]`
 */
export const stringArray: StringArray = (data) => {
  if (!data) return [];
  if (!Array.isArray(data)) return data.split(" ");
  return data.filter(Boolean);
};
