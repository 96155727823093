import { AcadModal, AcadTextField } from "@components";
import { AcadSelect } from "@components/select";
import loadingMessage from "@defs/LoadingMessages";
import { httpsCallable } from "@firebase/functions";
import { DialogProps } from "@framework/types";
import { joiResolver } from "@hookform/resolvers/joi";
import GlobalRole, { IAddRoleForm } from "@models/global_role";
import { Button, DialogContent, MenuItem, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useFunctions } from "reactfire";

const RoleAdd = ({ open, onClose = function () {} }: DialogProps) => {
  const { t } = useTranslation(["admin", "common"]);
  const fn = useFunctions();

  const {
    control,
    clearErrors,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<IAddRoleForm>({
    defaultValues: {
      role: "moderator",
      email: "",
    },
    resolver: joiResolver(GlobalRole.addRoleSchema),
  });

  const addRole = handleSubmit((data) => {
    clearErrors();
    const setRole = httpsCallable<IAddRoleForm>(fn, "auth-setRole");
    const promise = setRole(data);
    toast.promise(promise, {
      loading: loadingMessage(),
      error: (err) => err.message,
      success: () => {
        onClose();
        return t("done");
      },
    });
  });

  return (
    <AcadModal title={t("role_add")} {...{ open, onClose }}>
      <DialogContent className="pt-0">
        <Stack spacing={2} sx={{ my: 2 }}>
          <AcadTextField
            required
            fullWidth
            label={t("email")}
            {...register("email")}
            errors={errors}
            type="email"
          />
          <AcadSelect control={control} name="role" label={t("role")} fullWidth>
            <MenuItem value="admin">{t("admin")}</MenuItem>
            <MenuItem value="moderator">{t("moderator")}</MenuItem>
          </AcadSelect>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button onClick={onClose}>{t("cancel")}</Button>
          <Button variant="contained" onClick={addRole}>
            {t("add")}
          </Button>
        </Stack>
      </DialogContent>
    </AcadModal>
  );
};

export default RoleAdd;
