import EpisodeCard from "@views/Boostcamp/components/EpisodeCard";
import { Episode } from "@defs/Episode";
import { FrontPage } from "@defs/Frontpage.type";
import loadingMessage from "@defs/LoadingMessages";
import { BoostcampSeries, DialogProps } from "@defs/Types";
import {
  Button,
  DialogActions,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import {
  collection,
  CollectionReference,
  doc,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useFirestore, useFirestoreCollection } from "reactfire";
import { useTranslation } from "react-i18next";

interface BoostcampEpisodesAddProps extends DialogProps {
  frontPage: FrontPage;
}

const BoostcampEpisodesAdd = ({
  open,
  onClose,
  frontPage,
}: BoostcampEpisodesAddProps) => {
  const db = useFirestore();
  const [episodes, setEpisodes] = useState<Episode[]>([]);
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const p = "boostcamp";
  const r = collection(db, p) as CollectionReference<BoostcampSeries>;
  const q = query(r, limit(25), where("visibility", "==", "public"));
  const { status, data: snap, error } = useFirestoreCollection(q);
  const loading = status === "loading";

  const { t } = useTranslation("frontpage");

  useEffect(() => {
    if (!frontPage) return;
    setEpisodes(frontPage.boostcampEpisodes);
  }, [frontPage]);

  const handleClick = (episode: Episode) => () => {
    if (
      episodes.find(
        (ep) =>
          ep.episodeNumber !== episode.episodeNumber &&
          ep.seriesId !== episode.seriesId
      )
    ) {
      return setEpisodes((eps) =>
        eps.filter(
          (ep) =>
            ep.episodeNumber !== episode.episodeNumber &&
            ep.seriesId !== episode.seriesId
        )
      );
    }

    setEpisodes((eps) => [...eps, episode]);
  };

  const save = () => {
    const ref = doc(db, "public/frontpage");
    const promise = updateDoc(ref, { boostcampEpisodes: episodes });
    onClose();
    toast.promise(promise, {
      loading: loadingMessage(),
      error: (err) => err.message,
      success: t("saved_successfully", { ns: "common" }),
    });
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: "relative overflow-hidden" }}
      maxWidth="md"
      fullWidth
      fullScreen={!mdUp}
    >
      {loading && (
        <LinearProgress className="absolute bottom-0 left-0 w-full" />
      )}

      <DialogTitle>{t("select_boostcamp_episodes")}</DialogTitle>
      <DialogContent>
        {error && <div className="w-full p-4 text-center">{error.message}</div>}
        {snap?.docs.length ? (
          snap.docs.map((series) => (
            <div className="flex flex-col space-y-4" key={series.id}>
              <div className="text-lg">{series.data().name}</div>
              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
                {series.data().episodes.map((episode, i) => (
                  <div
                    key={i}
                    className={`rounded-md ${
                      episodes.find(
                        (ep) =>
                          ep.episodeNumber === episode.episodeNumber &&
                          ep.seriesId === episode.seriesId
                      ) && "border border-solid border-black"
                    }`}
                  >
                    <EpisodeCard
                      episode={episode}
                      onClick={handleClick(episode)}
                      key={i}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <div className="w-full p-4 text-center">
            {t("no_public_boostcamp_series_is_found")}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {!mdUp && (
          <Button variant="contained" onClick={onClose}>
            {t("close", { ns: "common" })}
          </Button>
        )}
        <Button variant="contained" onClick={save}>
          {t("save", { ns: "common" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BoostcampEpisodesAdd;
