import acadJoi from "@acad_joi";
import { CommonPageParams } from "./common_page_params";

export enum AcademyOnboardingStatus {
  ClosedBetaSignUps = "CBSU",
  ClosedBeta = "CB",
}

interface Hero {
  title: string;
  description: string;
  backgroundImage: string;
  buttons: AcademyButton[];
}

export interface AcademyButton {
  name: string;
  url: string;
  color: string;
}

interface Onboarding {
  headline: string;
  /**
   * Supports markdown
   */
  body: string;
  status: AcademyOnboardingStatus;
}

export class AcademyParams extends CommonPageParams {
  static readonly PATH: string = "syspars/Academy";

  hero: Hero = {
    title: "",
    description: "",
    backgroundImage: "",
    buttons: [],
  };

  onboarding: Onboarding = {
    headline: "",
    body: "",
    status: AcademyOnboardingStatus.ClosedBetaSignUps,
  };

  static buttonSchema = acadJoi.object<AcademyButton>({
    color: acadJoi.string().allow(""),
    name: acadJoi.string().required(),
    url: acadJoi.string().allow(""),
  });

  static heroSchema = acadJoi.object<Hero>({
    title: acadJoi.string().required(),
    description: acadJoi.string().required(),
    backgroundImage: acadJoi.string().allow(""),
    buttons: acadJoi.array().items(AcademyParams.buttonSchema),
  });

  static onboardingSchema = acadJoi.object({
    headline: acadJoi.string().required(),
    body: acadJoi.string().required(),
    status: acadJoi.string().label("Status").required(),
  });

  static schema = acadJoi.object<AcademyParams>({
    ...CommonPageParams.schemaMap,
  });
}
