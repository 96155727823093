import { AcadImageButton, AcadModal, AcadTextField } from "@components";
import { DialogProps } from "@framework/types";
import { withFileUpload } from "@hocs";
import { joiResolver } from "@hookform/resolvers/joi";
import { useFileUpload } from "@hooks";
import { OAuthClientCreate } from "@models/oauth";
import { Alert, Button, Stack, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { MouseEventHandler, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useFunctions } from "reactfire";

function AppCreateModal(props: DialogProps) {
  const { t } = useTranslation("developer");
  const fn = useFunctions();
  const [loading, setLoading] = useState(false);
  const { uploadFilesAndSet } = useFileUpload();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<OAuthClientCreate>({
    defaultValues: new OAuthClientCreate(),
    resolver: joiResolver(OAuthClientCreate.schema),
  });

  const create: MouseEventHandler<HTMLButtonElement> = async (e) => {
    await uploadFilesAndSet(setValue);
    saveChanges(e);
  };

  const saveChanges = handleSubmit(
    async (data) => {
      const callable = httpsCallable<OAuthClientCreate>(fn, "apps-createApp");
      setLoading(true);
      try {
        await callable(data);
        props.onClose?.();
      } catch (e) {
        console.error(e);
        toast.error((e as any).message);
      }
      setLoading(false);
    },
    (err) => {
      console.error(err);
      toast.error(
        Object.values(err)
          .map((e) =>
            Array.isArray(e) ? e.map((ee) => ee.message) : e.message
          )
          .join("\n")
      );
    }
  );

  return (
    <AcadModal {...props} title={t("create")} loading={loading}>
      <Stack sx={{ py: 2 }} spacing={2}>
        <AcadTextField
          errors={errors}
          {...register("name")}
          label={t("app_name")}
          required
        />
        <AcadTextField
          errors={errors}
          {...register("redirectUris.0")}
          label={t("redirect_uri")}
          required
        />
        <AcadTextField
          errors={errors}
          multiline
          minRows={3}
          {...register("description")}
          label={t("description", { ns: "common" })}
        />
        <Stack>
          <Typography color="text.secondary">
            {t("icon", { ns: "common" })}
          </Typography>
          <AcadImageButton<OAuthClientCreate>
            name="icon"
            className="h-40 bg-center bg-no-repeat bg-contain border-solid border rounded border-gray-300"
          />
        </Stack>
        <Alert severity="info">{t("create_reminder")}</Alert>
      </Stack>
      <Stack direction="row-reverse" spacing={2}>
        <Button onClick={create} variant="contained">
          {t("create", { ns: "common" })}
        </Button>
        <Button>{t("cancel", { ns: "common" })}</Button>
      </Stack>
    </AcadModal>
  );
}

export default withFileUpload(AppCreateModal);
