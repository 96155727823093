import { ChallongeParticipantResponse } from "@models/challonge_v1_participant";
import { CustomFieldMap } from "@models/custom_field";
import {
  CollectionReference,
  DocumentReference,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";
import { ChallongeMatch } from "./match_interface";
import { SEO } from "./SEO.type";
import { OrgProps } from "./Types";

export type TourDocRef = DocumentReference<TournamentDoc>;
export type TourColRef = CollectionReference<TournamentDoc>;
export type TourSnap = QueryDocumentSnapshot<TournamentDoc>;

export interface ChalTourRes {
  tournament: ChallongeTournament;
}

export type ChallongeTournament = {
  accept_attachments?: boolean;
  allow_participant_match_reporting?: boolean;
  anonymous_voting?: boolean;
  category?: string;
  check_in_duration?: number; // in minutes
  completed_at?: Date | null;
  created_at?: string;
  created_by_api?: boolean;
  credit_capped?: boolean;
  description?: string;
  game_id?: number;
  group_stages_enabled?: boolean;
  hide_forum?: boolean;
  hide_seeds?: boolean;
  hold_third_place_match?: boolean;
  id: number;
  max_predictions_per_user?: number;
  name: string;
  notify_users_when_matches_open?: boolean;
  notify_users_when_the_tournament_ends?: boolean;
  open_signup?: boolean;
  participants_count?: number;
  prediction_method?: number;
  predictions_opened_at?: null;
  private?: boolean;
  progress_meter?: number;
  pts_for_bye?: number;
  pts_for_game_tie?: number;
  pts_for_game_win?: number;
  pts_for_match_tie?: number;
  pts_for_match_win?: number;
  quick_advance?: boolean;
  ranked_by?: TournamentRankCategory;
  require_score_agreement?: boolean;
  rr_pts_for_game_tie?: number;
  rr_pts_for_game_win?: number;
  rr_pts_for_match_tie?: number;
  rr_pts_for_match_win?: number;
  sequential_pairings?: boolean;
  show_rounds?: boolean;
  signup_cap?: null;
  start_at?: Date | null;
  started_at?: Date | null;
  started_checking_in_at?: null;
  state?: string;
  swiss_rounds?: number;
  teams?: boolean;
  tie_breaks?: string[];
  tournament_type?: TournamentType;
  updated_at?: string;
  url: string;
  description_source?: string;
  subdomain?: null;
  full_challonge_url?: string;
  live_image_url?: string;
  sign_up_url?: null;
  review_before_finalizing?: boolean;
  accepting_predictions?: boolean;
  participants_locked?: boolean;
  game_name?: string;
  participants_swappable?: boolean;
  team_convertable?: boolean;
  group_stages_were_started?: boolean;
  grand_finals_modifier?: GrandFinalsModifier;
  matches?: ChallongeMatch[];
  participants?: ChallongeParticipantResponse[];
};

export type GrandFinalsModifier =
  | "single or double match"
  | "single match"
  | "skip";

export type TournamentRankCategory =
  | "match wins"
  | "game wins"
  | "points scored"
  | "points difference";

export enum TournamentType {
  single = "single elimination",
  double = "double elimination",
  roundRobin = "round robin",
  swiss = "swiss",
}

export type TournamentDoc = {
  tournament: ChallongeTournament;
  orgId: string;
  autoAcceptParticipants?: boolean;
  activePlayerCountPerTeam?: number;
  maxTeamCount?: number;
  maxSubPlayerPerTeam?: number;
  /**
   * means...
   * # IS VERIFIED STUDENTS ONLY?
   */
  isSchoolOnly?: boolean;
  logo?: string;
  profileImage?: string;
  backgroundImage?: string;
  createdAt?: Date;
  updatedAt?: Date;
  visibility: Visibility;
  tournamentId?: string;
  regOpen?: boolean;
  regOpenDate?: Timestamp;
  regEndDate?: Timestamp;
  startDate?: Timestamp;
  endDate?: Timestamp;
  schoolsAllowed?: string[];
  admins?: string[];
  orgData?: OrgProps;
  id?: string;
  game?: string;
  description?: string;
  rules?: string;
  prizes?: Prize[];
  commsChannelLink?: string;
  enableCustomFields: boolean;
  customFields: CustomFieldMap;
  typeformId?: string;
  seo?: SEO;
  leagueId: string;
  leagueTitle: string;
  _leagueTitle: string;
  allowContainedTeams?: boolean;
  roundData?: RoundData;
  lookingForCheckIns?: boolean;
  allowPendingCheckIn?: boolean;
  checkInStartedAt?: Timestamp;
  sameSchoolOnly?: boolean;
  checkInProcessed: boolean;
};

type RoundData = Record<string, RoundInfo>;
type RoundInfo = {
  label: string;
  bestOf: number;
};

export type Visibility = "draft" | "public";

export type Prize = {
  placement: string;
  name: string;
  description?: string;
  items: PrizeItem[];
  cash: string;
  category: CustomFieldPrizeCategory;
  winner?: string;
  defaultImage?: string;
  icon?: string;
};

export type CustomFieldPrizeCategory = "primary" | "secondary" | "giveaway";

export type PrizeItem = {
  name: string;
  count: number;
  description?: string;
  image?: string;
  url?: string;
};
