import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteValue,
} from "@mui/material";
import { Control, Controller, Path } from "react-hook-form";

interface Props<
  T,
  V,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean
> extends AutocompleteProps<V, Multiple, DisableClearable, FreeSolo> {
  control: Control<T>;
  name: Path<T>;
}

function AcadAutoComplete<
  T,
  V,
  Multiple extends boolean = boolean,
  DisableClearable extends boolean = boolean,
  FreeSolo extends boolean = boolean
>({
  control,
  name,
  ...props
}: Props<T, V, Multiple, DisableClearable, FreeSolo>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ...field } }) => (
        <Autocomplete<V, Multiple, DisableClearable, FreeSolo>
          {...props}
          {...field}
          value={
            (field.value || props.value || "") as AutocompleteValue<
              V,
              Multiple,
              DisableClearable,
              FreeSolo
            >
          }
          onChange={(e, value) => onChange(value)}
        />
      )}
    />
  );
}

export default AcadAutoComplete;
