import loadingMessage from "@defs/LoadingMessages";
import { DialogProps } from "@defs/Types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { arrayUnion, updateDoc } from "firebase/firestore";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { schoolsContext } from ".";
import i18n from "@i18n";

function AddSchoolDialog(props: DialogProps) {
  const [state, set] = useState("");

  const schoolCtx = useContext(schoolsContext);
  if (!schoolCtx) return null;
  const ref = schoolCtx.ref;
  const schools = schoolCtx.data()?.schools ?? [];

  function save() {
    const promise = updateDoc(ref, {
      schools: arrayUnion(state),
    });
    toast.promise(promise, {
      loading: loadingMessage(),
      error: (err) => err.message,
      success: i18n.t("done", { ns: "common" }),
    });
    set("");
    props.onClose();
  }

  const error = schools.includes(state);

  return (
    <Dialog {...props} maxWidth="sm" fullWidth>
      <DialogTitle>{i18n.t("add_school", { ns: "schools" })}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          className="mt-2"
          value={state}
          onChange={(e) => set(e.target.value)}
          label={i18n.t("school_name", { ns: "schools" })}
          error={error}
          helperText={
            error ? i18n.t("school_already_exists", { ns: "schools" }) : ""
          }
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={!state || error} onClick={save} variant="contained">
          {i18n.t("save", { ns: "common" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddSchoolDialog;
