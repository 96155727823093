import { Button } from "@mui/material";
import { FormEvent, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { doc, DocumentReference } from "firebase/firestore";
import { userContext } from "@lib/AuthContext";
import { useFirestore } from "reactfire";
import { useDeleteDocument, useWriteDocument } from "@hooks";
import Sponsor from "@models/sponsor";
import {
  AcadEditDocContainer,
  AcadEditDocSection,
  AcadImageButton,
  AcadTextField,
} from "@components";
import { fileUploadContext, FileUploadProvider } from "@contexts/file_upload";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
interface SponsorEditFormProps {
  docRef: DocumentReference<Sponsor>;
  onSubmit(sponsor: Sponsor): void;
  formData: Sponsor;
}

const SponsorEditForm = ({
  docRef,
  onSubmit,
  formData,
}: SponsorEditFormProps) => {
  const { t } = useTranslation("scholarships");
  const history = useHistory();
  const { deleteDocument } = useDeleteDocument<Sponsor>({
    docRef,
    titleMsg: t("delete_sponsor_question"),
    onDelete: () => {
      history.push("/scholarship/sponsors");
    },
  });

  const { uploadFilesAndSet } = useContext(fileUploadContext);

  const { isAdmin: admin } = useContext(userContext);

  const {
    getValues,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<Sponsor>({
    defaultValues: formData,
  });

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    await uploadFilesAndSet(setValue);
    handleSubmit((value) => {
      onSubmit(value);
    })(e);
  };

  return (
    <form className="grid grid-cols-12 gap-4" onSubmit={submit}>
      <AcadEditDocSection
        title={t("sponsor_info")}
        className="col-span-full grid grid-cols-12 gap-4"
      >
        <AcadImageButton
          name="logoUrl"
          path={getValues().logoUrl}
          className="col-span-3 h-40 bg-gray-200 bg-cover bg-center rounded-md"
          noImageText="Upload Sponsor Logo"
        />
        <div className="col-span-9 grid grid-cols-12 gap-4">
          <AcadTextField
            {...register("name")}
            label={t("sponsor_name")}
            className="col-span-full"
            required
            errors={errors}
          />
          {/* Description */}
          <AcadTextField
            {...register("description")}
            multiline
            rows={9}
            label={t("sponsor_description")}
            className={"col-span-12"}
            required
            errors={errors}
          />
        </div>
      </AcadEditDocSection>

      <div className="col-span-12 flex justify-end">
        <Button
          variant="outlined"
          className="mr-4"
          color="secondary"
          onClick={() => deleteDocument(getValues())}
          disabled={!admin}
        >
          {t("delete", { ns: "common" })}
        </Button>
        <Button type="submit" variant="contained" color="primary">
          {t("save", { ns: "common" })}
        </Button>
      </div>
    </form>
  );
};

const SponsorEdit = () => {
  const history = useHistory();
  const db = useFirestore();
  const { sponsorId } = useParams<{ sponsorId: string }>();

  const docRef = doc(
    db,
    `${Sponsor.PATH}/${sponsorId}`
  ) as DocumentReference<Sponsor>;
  const { loading, snap, error, writeDocument } = useWriteDocument<Sponsor>({
    docRef,
  });

  const onSubmit = async (formData: Sponsor) => {
    writeDocument(formData);
  };

  const { t } = useTranslation("scholarships");

  return (
    <AcadEditDocContainer
      snapshot={snap}
      error={error}
      loading={loading}
      onBackBtn={() => history.push("/scholarship/sponsors")}
      doesNotExistText={t("sponsor_does_not_exist")}
      backBtnText={t("back_to_sponsors")}
    >
      <FileUploadProvider>
        <SponsorEditForm
          docRef={docRef}
          onSubmit={onSubmit}
          formData={snap?.data() ?? new Sponsor()}
        />
      </FileUploadProvider>
    </AcadEditDocContainer>
  );
};

export default SponsorEdit;
