import Iconify from "@components/Iconify";
import { Series } from "@models/series";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Card,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { seriesContext } from "./";
import { useContext } from "react";
import RoundSeriesPlayer from "./RoundSeriesPlayer";
import { useFunctions } from "reactfire";
import { httpsCallable } from "firebase/functions";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface Props {
  series: Series;
  chalId: number | null;
  sx?: AccordionProps["sx"];
}
const defaultSyles: AccordionProps["sx"] = {
  p: 0,
  my: 0,
  borderRadius: 1,
  border: "1px solid rgba(0,0,0,.25)",
  overflow: "hidden",
  "&:before": { display: "none" },
  "& .MuiAccordionSummary-root": { p: 0, minHeight: 0 },
  "& .MuiAccordionSummary-content": { m: 0, "&.Mui-expanded": { m: 0 } },
  "& .MuiAccordionDetails-root": {
    p: 1,
    borderTop: "1px solid rgba(0,0,0,.1)",
  },
};

interface PlayerActionProps {
  fireTourId: string;
  seriesId: string;
  targetUid: string;
}

const RoundSeriesTeam = ({ series, chalId, sx }: Props) => {
  const fn = useFunctions();
  const { participants, tournament } = useContext(seriesContext);
  const getTeam = (id: number | null) =>
    participants.find((p) => p.chalId === id);

  const team = getTeam(chalId);
  const playerMap = { ...team?.players, ...team?.subs };
  const players = Object.entries(playerMap);
  const checkIns = series.checkIns ?? {};
  const checkInIds = Object.keys(checkIns);
  const teamCheckIns = checkInIds.reduce<number>(
    (acc, id) => (!!playerMap[id] ? acc + 1 : acc),
    0
  );

  const teamKey = series.teamA.id === chalId ? "teamA" : "teamB";

  const getFinalScore = () => {
    const scores = series.scores;
    let teamA: number = 0;
    let teamB: number = 0;

    scores.forEach((s) => {
      let ss = s.match(/^(\d*)-(\d*)/);
      if (ss && parseInt(ss[1]) > parseInt(ss[2])) {
        teamA = teamA + 1;
      } else if (ss && parseInt(ss[1]) < parseInt(ss[2])) {
        teamB = teamB + 1;
      }
    });
    return { teamA, teamB };
  };

  const { t } = useTranslation("events");

  const playerAction =
    (uid: string, action: "seriesAdminCheckIn" | "seriesAdminUnCheckIn") =>
    () => {
      const actionPath = `tournamentSeries-${action}`;
      const callable = httpsCallable<PlayerActionProps>(fn, actionPath);

      const promise = callable({
        fireTourId: tournament?.id ?? "",
        seriesId: `${series.id}`,
        targetUid: uid,
      });

      toast.promise(promise, {
        loading: t("loading"),
        error: (err) =>
          err.message ?? t("unable_to_perform_action", { ns: "common" }),
        success: t("done", { ns: "common" }),
      });
    };

  return (
    <Accordion
      disableGutters
      sx={{
        ...defaultSyles,
        ...sx,
        opacity: series.winnerId ? (series.winnerId === chalId ? 1 : 0.5) : 1,
      }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary>
        <Card
          sx={{
            width: "100%",
            px: 2,
            py: 1,
            borderRadius: 0.5,
            boxShadow: "none",
          }}
        >
          <Stack
            direction="row"
            sx={{ width: "100%" }}
            gap={2}
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
            flexWrap="wrap"
          >
            <Box component={Typography} variant="caption" sx={{ flex: 1 }}>
              {team?.name || "TBD"}
            </Box>
            <Stack gap={1} direction="row" alignItems="center">
              <Iconify fontSize={14} icon="eva:person-done-fill" />
              <Typography variant="caption">{teamCheckIns}</Typography>
            </Stack>
            <Box component={Typography} variant="caption">
              {getFinalScore()[teamKey] ?? 0}
            </Box>
          </Stack>
        </Card>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row" gap={0.5}>
          {players.map(([uid, playerData]) => (
            <RoundSeriesPlayer
              key={uid}
              checkIn={playerAction(uid, "seriesAdminCheckIn")}
              unCheckIn={playerAction(uid, "seriesAdminUnCheckIn")}
              username={playerData._username || playerData.username}
              isCheckedIn={!!checkIns[playerData.uid]}
              avatar={playerData.photoURL}
            />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default RoundSeriesTeam;
