import {
  Button,
  Card,
  CardActionArea,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableCell,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Series, UserScoreReport } from "@models/series";
import { useState, VFC } from "react";
import { useParticipants, withParticipants } from "../participants/_context";
import { useImagePreview, withImagePreview } from "@hocs/withImagePreview";
import { Check, Close, Refresh } from "@mui/icons-material";
import { useFunctions } from "reactfire";
import { httpsCallable } from "firebase/functions";
import { useTournamentEdit } from "../TournamentEdit";
import loadingMessage from "@defs/LoadingMessages";
import toast from "react-hot-toast";
import { themeOptions } from "../../../../Theme";
import Iconify from "@components/Iconify";
import { useTranslation, Trans } from "react-i18next";

interface CallableProps {
  action: "accept" | "deny" | "pending";
  tournamentId: string;
  seriesPath: string;
  reportId: string;
  comment: string;
}

interface Props {
  data: UserScoreReport;
  seriesIdentifier: string;
  reportId: string;
  series: Series;
}
const ScoreReportItem: VFC<Props> = ({
  data,
  seriesIdentifier,
  reportId,
  series,
}) => {
  const fn = useFunctions();
  const [open, setOpen] = useState(false);
  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);
  const openPreview = useImagePreview();
  const teams = useParticipants();
  const team = (teamId: string) => teams.find((t) => t.id === teamId)?.data();

  const { t } = useTranslation("events");

  const preview = (url: string) => () => openPreview(url);
  const tour = useTournamentEdit();
  const action = (action: "accept" | "deny" | "pending") => () => {
    const callable = httpsCallable<CallableProps>(
      fn,
      "tournamentSeries-reviewScoreReport"
    );

    const promise = callable({
      action,
      comment: "",
      reportId,
      seriesPath: `tournaments/${tour?.id}/series/${series.id}`,
      tournamentId: tour?.id ?? "",
    });

    toast.promise(promise, {
      loading: loadingMessage(),
      error: (err) => {
        console.log(err);
        return err.message || t("something_went_wrong", { ns: "common" });
      },
      success: () => {
        closeDialog();
        return t("done", { ns: "common" });
      },
    });
  };

  const containedTheme = createTheme(themeOptions);
  return (
    <>
      <Button
        variant="contained"
        color="inherit"
        onClick={openDialog}
        sx={{ opacity: data.isDenied ? 0.5 : 1 }}
        size="small"
        startIcon={
          data.isVerified ? (
            <Check />
          ) : data.isDenied ? (
            <Close />
          ) : (
            <Iconify icon="eva:clipboard-fill" />
          )
        }
      >
        <Typography variant="caption">
          {t("score_report_by_team_name", { teamName: data.teamName })}
        </Typography>
      </Button>
      <ThemeProvider theme={containedTheme}>
        <Dialog open={open} onClose={closeDialog} maxWidth="sm" fullWidth>
          <DialogTitle>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack>
                <Trans
                  i18nKey="events:series_indentifier_score_report"
                  components={{
                    h6: <Typography variant="h6" lineHeight={1} />,
                    caption: <Typography variant="caption" />,
                  }}
                  values={{
                    seriesIdentifier: seriesIdentifier,
                    username: data.username,
                    teamName: data.teamName,
                  }}
                />
              </Stack>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack gap={2}>
              <Card sx={{ p: 2 }}>
                <Stack>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    lineHeight={1}
                  >
                    {t("overall_winner")}
                  </Typography>
                  <Typography variant="subtitle1">
                    {team(data.winner ?? "")?.name ||
                      t("no_winner_yet").toLocaleUpperCase}
                  </Typography>
                </Stack>
              </Card>
              {Object.entries(data.data).map(([round, report]) => (
                <Card key={round}>
                  <Stack sx={{ p: 2 }} gap={2}>
                    <Typography variant="subtitle2">
                      {t("game_round", { round: round })}
                    </Typography>
                    <Card>
                      <Table size="small">
                        <TableRow
                          sx={{ borderBottom: "1px solid rgba(0,0,0,.1)" }}
                        >
                          <TableCell
                            padding="checkbox"
                            sx={{ borderRight: "1px solid rgba(0,0,0,.1)" }}
                          >
                            {Object.values(report.score)[0] || 0}
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {team(Object.keys(report.score)[0] ?? "")?.name ||
                                t("team_a")}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            padding="checkbox"
                            sx={{ borderRight: "1px solid rgba(0,0,0,.1)" }}
                          >
                            {Object.values(report.score)[1] || 0}
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {team(Object.keys(report.score)[1] ?? "")?.name ||
                                t("team_b")}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </Table>
                    </Card>
                    {report.screenshot && (
                      <Card sx={{ borderRadius: 1 }}>
                        <CardActionArea
                          onClick={preview(report.screenshot)}
                          sx={{
                            backgroundImage: `url("${report.screenshot}")`,
                            width: "100%",
                            paddingTop: "56.25%",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                          }}
                        />
                      </Card>
                    )}
                  </Stack>
                </Card>
              ))}
            </Stack>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Stack direction="row">
              <Button
                size="small"
                variant="contained"
                color="inherit"
                onClick={closeDialog}
                sx={{ justifySelf: "flex-end" }}
              >
                {t("close", { ns: "common" })}
              </Button>
            </Stack>
            <Stack direction="row" gap={1}>
              <Button
                onClick={action("pending")}
                size="small"
                variant="contained"
                color="inherit"
                startIcon={<Refresh fontSize="inherit" />}
              >
                {t("reset_to_pending")}
              </Button>
              <Button
                onClick={action("deny")}
                size="small"
                variant="contained"
                color="error"
                startIcon={<Close fontSize="inherit" />}
              >
                {t("decline", { ns: "common" })}
              </Button>
              <Button
                onClick={action("accept")}
                size="small"
                variant="contained"
                startIcon={<Check fontSize="inherit" />}
              >
                {t("accept", { ns: "common" })}
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default withParticipants(withImagePreview(ScoreReportItem));
