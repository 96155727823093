import TournamentCard from "@views/Events/Tournaments/components/TournamentCard.old";
import { TournamentDoc } from "@defs/Tournament.type";
import { DialogProps } from "@defs/Types";
import {
  Button,
  DialogActions,
  DialogContent,
  LinearProgress,
  useMediaQuery,
} from "@mui/material";
import { Dialog, DialogTitle } from "@mui/material";
import {
  collection,
  CollectionReference,
  DocumentSnapshot,
  query,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { Dispatch, SetStateAction } from "react";
import { useFirestore, useFirestoreCollection } from "reactfire";
import { useTranslation } from "react-i18next";

interface Props extends DialogProps {
  selection:
    | QueryDocumentSnapshot<TournamentDoc>[]
    | DocumentSnapshot<TournamentDoc>[];
  setSelection: Dispatch<
    SetStateAction<
      QueryDocumentSnapshot<TournamentDoc>[] | DocumentSnapshot<TournamentDoc>[]
    >
  >;
  save: () => void;
}

const TournamentSelect = ({
  open,
  onClose,
  selection,
  setSelection,
  save,
}: Props) => {
  const db = useFirestore();

  const p = "tournaments";
  const r = collection(db, p) as CollectionReference<TournamentDoc>;
  const q = query(r);

  const { status, data: snap, error } = useFirestoreCollection(q);
  const loading = status === "loading";

  const { t } = useTranslation("frontpage");

  // const [snap, setSnap] = useState<QuerySnapshot<TournamentDoc>>();
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState<FirestoreError>();

  // useEffect(() => {
  //   setLoading(true);
  //   const p = "tournaments";
  //   const r = collection(db, p) as CollectionReference<TournamentDoc>;
  //   const q = query(r, limit(25), where("visibility", "==", "public"));

  //   const listener = onSnapshot(
  //     q,
  //     (qsnap) => {
  //       setLoading(false);
  //       setSnap(qsnap);
  //     },
  //     (err) => {
  //       setLoading(false);
  //       setError(err);
  //     },
  //     () => setLoading(false)
  //   );

  //   return () => {
  //     listener();
  //   };
  // }, []);

  const mdUp = useMediaQuery("(min-width: 768px)");

  const clickHandler =
    (tournament: QueryDocumentSnapshot<TournamentDoc>) => () => {
      if (selection.map((s) => s.id).includes(tournament.id)) {
        setSelection(selection.filter((s) => s.id !== tournament.id));
        return;
      }

      setSelection([...selection, tournament]);
    };

  return (
    <Dialog
      fullScreen={!mdUp}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      classes={{ paper: "relative" }}
    >
      {loading && <LinearProgress className="absolute bottom-0 left-0" />}
      <DialogTitle>{t("select_tournaments")}</DialogTitle>
      <DialogContent>
        {error && (
          <div>
            {t("unable_to_load_tournaments_error", { error: error.message })}
          </div>
        )}
        {snap && (
          <div className="p-2 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
            {snap.docs.length ? (
              snap.docs.map((docSnap) => (
                <div
                  className={`rounded-md ${
                    selection.map((s) => s.id).includes(docSnap.id)
                      ? "border-2 border-solid border-gray-500"
                      : ""
                  }`}
                  key={docSnap.id}
                  onClick={clickHandler(docSnap)}
                >
                  <TournamentCard tournament={docSnap} disableOnClick />
                </div>
              ))
            ) : (
              <div className="col-span-full text-center">
                {t("no_public_tournament_is_found")}
              </div>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={save}>
          {t("save", { ns: "common" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TournamentSelect;
