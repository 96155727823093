import Environment from "@env";
import { FirebaseAppProvider } from "reactfire";
import FirebaseComponentsProvider from "./FirebaseComponents";

interface FirebaseControllerProps {
  children?: React.ReactNode;
}

export default function FirebaseController({
  children,
}: FirebaseControllerProps) {
  return (
    <FirebaseAppProvider firebaseConfig={Environment.firebaseConfig}>
      <FirebaseComponentsProvider>{children}</FirebaseComponentsProvider>
    </FirebaseAppProvider>
  );
}
