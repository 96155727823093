import NextConfigForm from "@components/seo/NextConfigForm";
import SEOForm from "@components/seo/SEOForm";
import { withFileUpload } from "@hocs";
import { joiResolver } from "@hookform/resolvers/joi";
import { useWriteDocument } from "@hooks";
import useFileUploadContext from "@hooks/use_file_upload_context";
import { SEOExtend } from "@models/seo";
import { CommonPageParams } from "@models/syspars/common_page_params";
import { Button, Stack } from "@mui/material";
import { doc, DocumentReference, DocumentSnapshot } from "firebase/firestore";
import { MouseEventHandler, useMemo } from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useFirestore } from "reactfire";

type Page = "Tournaments" | "Leagues";
type DocRef = DocumentReference<CommonPageParams>;

interface SettingsFieldsProps {
  page: Page;
}

const SettingsFields = ({ page }: SettingsFieldsProps) => {
  const db = useFirestore();
  const docRef = doc(db, "syspars", page) as DocRef;
  const {
    setValue,
    reset,
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<CommonPageParams>({
    defaultValues: new CommonPageParams(`${page} Directory`),
    resolver: joiResolver(CommonPageParams.schema),
  });

  const onSnapshotChanged = useMemo(() => {
    return (snap: DocumentSnapshot<CommonPageParams>) => {
      reset(snap?.data() ?? new CommonPageParams());
    };
  }, [reset]);

  const { snap, writeDocument } = useWriteDocument<CommonPageParams>({
    docRef,
    onSnapshotChanged,
  });

  const { t } = useTranslation("common");

  const { uploadFilesAndSet } = useFileUploadContext();
  const save: MouseEventHandler<HTMLButtonElement> = async (e) => {
    await uploadFilesAndSet(setValue);
    handleSubmit(async (data) => {
      const promise = writeDocument(data);
      toast.promise(promise, {
        loading: t("saving_changes"),
        error: (err) => {
          console.error(err);
          return t("unable_to_save_changes");
        },
        success: t("done"),
      });
    }, console.error)(e);
  };

  return (
    <Stack sx={{ p: 2 }}>
      <SEOForm
        errors={errors}
        register={register as UseFormRegister<CommonPageParams | SEOExtend>}
        thumbnailUrl={snap?.data()?.SEO.thumbnail}
      />
      <NextConfigForm errors={errors} control={control} />

      <Stack sx={{ m: 2 }} direction="row" justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={save}>
          {t("save")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default withFileUpload(SettingsFields);
