import AcadConsts from "@consts";
import { UseFileUploadProps } from "@hooks/use_file_upload";
import { createContext } from "react";

interface FileUploadContextProps extends UseFileUploadProps {}

const fileUploadContext = createContext<FileUploadContextProps>({
  queueFile: AcadConsts.noop,
  uploadFiles: async () => {
    return [];
  },
  uploadFilesAndSet: async () => {
    return [];
  },
});

export default fileUploadContext;
