import { s3 } from "@acad_axios";
import { Prize } from "@defs/Tournament.type";
import { DialogProps } from "@defs/Types";
import { Files, UploadResponse } from "@lib/file";
import { Add } from "@mui/icons-material";
import {
  Avatar,
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogContent,
  Fab,
  Fade,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface PrizeIconPopupProps extends DialogProps {
  setPrize: React.Dispatch<React.SetStateAction<Prize>>;
}

const PrizeIconPopup = ({
  setPrize,
  open,
  onClose = () => {},
}: PrizeIconPopupProps) => {
  const [files, setFiles] = useState<Files<{ icon: string }>>({});
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation("events");

  const gold =
    "https://aaimages.sgp1.cdn.digitaloceanspaces.com/gold-trophy-1628496545166.png";
  const silver =
    "https://aaimages.sgp1.cdn.digitaloceanspaces.com/silver-trophy-1628496574179.png";
  const bronze =
    "https://aaimages.sgp1.cdn.digitaloceanspaces.com/bronze-trophy-1628496591336.png";

  const onSelect = (url: string) => () => {
    setPrize((state) => ({ ...state, icon: url }));
    onClose();
  };

  const onFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (file.size > 2 * 1024 * 1024) {
        toast.error(t("file_is_larger_than_two_mb"), {
          style: {
            backgroundColor: "red",
            color: "#fff",
          },
        });
        return;
      }
      setLoading(true);
      setFiles((files) => ({
        ...files,
        [e.target.name]: {
          file,
          path: URL.createObjectURL(file),
        },
      }));
      uploadFile(file);
    }
  };

  const uploadFile = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    const promise = s3.post<UploadResponse>("/upload", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    toast.promise(promise, {
      loading: t("upload_in_progress", { ns: "common" }),
      error: (e) => e.message,
      success: (res) => {
        const url = res.data.fileUrl;
        setPrize((state) => ({ ...state, icon: url }));
        setLoading(false);
        onClose();
        return t("upload_success", { ns: "common" });
      },
    });
  };

  return (
    <Dialog {...{ open, onClose }} maxWidth="sm" fullWidth>
      <DialogContent>
        <div className="flex flex-wrap justify-center items-center p-4 relative">
          <Fade in={loading}>
            <div className="z-50 absolute left-0 top-0 h-full w-full bg-white bg-opacity-50 flex justify-center items-center">
              <CircularProgress />
            </div>
          </Fade>
          {Btn(gold, onSelect)}
          {Btn(silver, onSelect)}
          {Btn(bronze, onSelect)}

          <Fab component="label" className="mx-2">
            {files.icon ? (
              <Avatar variant="square" src={files.icon.path} />
            ) : (
              <Add />
            )}
            <input
              hidden
              type="file"
              name="icon"
              accept="image/*"
              onChange={onFileSelect}
            />
          </Fab>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const Btn = (url: string, onClick: (url: string) => () => void) => (
  <ButtonBase
    className="rounded-lg bg-white p-2 mx-2"
    onClick={onClick(url)}
    style={{
      boxShadow:
        "0px 3px 5px -1px rgba(0,0,0, .20), 0px 6px 10px 0px rgba(0,0,0, .14), 0px 1px 18px 0px rgba(0,0,0, .12)",
    }}
  >
    <div
      className="h-10 w-10 bg-center bg-no-repeat bg-contain"
      style={{ backgroundImage: `url("${url}")` }}
    ></div>
  </ButtonBase>
);

export default PrizeIconPopup;
