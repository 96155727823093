import { Button, ButtonBase } from "@mui/material";
import React, { useState } from "react";
import { FrontPage } from "@defs/Frontpage.type";
import CarouselCard from "./CarouselCard";
import CarouselCreate from "./CarouselCreate";
import { DocumentSnapshot } from "firebase/firestore";
import CarouselEdit from "./CarouselEdit";
import { CarouselSlide } from "@defs/carousel_slide";
import { useTranslation } from "react-i18next";

const Carousel = ({
  frontPage,
}: {
  frontPage: DocumentSnapshot<FrontPage>;
}) => {
  const [createState, setCreateState] = useState(false);
  const [editState, setEditState] = useState<{
    slide: CarouselSlide;
    index: number;
  } | null>(null);

  const { t } = useTranslation("frontpage");

  return (
    <div className="mt-8">
      <div className="flex">
        <div className="text-lg">{t("carousel")}</div>
        <Button
          size="small"
          variant="outlined"
          className="ml-4"
          onClick={() => setCreateState(true)}
        >
          {t("add", { ns: "common" })}
        </Button>
      </div>
      <div className="flex flex-col mt-2 relative overflow-hidden">
        <div className="p-2 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
          {frontPage?.data()?.carousel.map((slide, index) => (
            <ButtonBase
              onClick={() => setEditState({ slide, index })}
              key={index}
            >
              <CarouselCard slide={slide} />
            </ButtonBase>
          ))}
        </div>
      </div>
      {frontPage.data() && (
        <>
          <CarouselCreate
            open={createState}
            onClose={() => setCreateState(false)}
            frontPage={frontPage.data() as FrontPage}
          />

          <CarouselEdit
            open={Boolean(editState)}
            onClose={() => setEditState(null)}
            frontPage={frontPage.data() as FrontPage}
            {...editState}
          />
        </>
      )}
    </div>
  );
};

export default Carousel;
