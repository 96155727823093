export async function asyncHook<ErrorType = any, DataType = any>(
  promise: Promise<DataType>
): Promise<[DataType | undefined, ErrorType | undefined]> {
  try {
    const data = await promise;
    return [data, undefined];
  } catch (err) {
    return [undefined, err as ErrorType];
  }
}

export default asyncHook;
