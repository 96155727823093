import Region from "@defs/region/region";
import { EnvConfig } from "./env_config";

/**
 * EnvTest has the same api url as EnvDev
 */
const EnvTest: Record<Region, EnvConfig> = {
  [Region.PH]: {
    appURL: "https://oongaboonga.acadarena.com",
    cdn: "https://cdn.acadarena.com/",
    api: "https://waypoint-staging-api.web.app/",
    region: "asia-east2",
    firebaseConfig: {
      apiKey: "AIzaSyDVSkHjC-jO3i4sIflgUGKn1ljZPVFuEso",
      authDomain: "waypoint-test-60ae5.firebaseapp.com",
      databaseURL:
        "https://waypoint-test-60ae5-default-rtdb.asia-southeast1.firebasedatabase.app",
      projectId: "waypoint-test-60ae5",
      storageBucket: "waypoint-test-60ae5.appspot.com",
      messagingSenderId: "702271323832",
      appId: "1:702271323832:web:603ce7948eec1ebe833e4d",
      measurementId: "G-09ZJLQFENJ",
    },
  },
  [Region.BR]: {
    appURL: "https://oongaboonga.acadarena.com",
    cdn: "https://cdn.acadarena.com/",
    api: "https://waypoint-staging-api-br.web.app/",
    region: "southamerica-east1",
    firebaseConfig: {
      apiKey: "AIzaSyA3d4-UalO1aODDs5_Haj3lc1EDqTjiHws",
      authDomain: "aa-brazil-staging.firebaseapp.com",
      projectId: "aa-brazil-staging",
      storageBucket: "aa-brazil-staging.appspot.com",
      messagingSenderId: "271437755709",
      appId: "1:271437755709:web:94192ab9e69395a4378154",
      measurementId: "G-EWPX7DTWMW",
    },
  },
};

export default EnvTest;
