import { ReactNode } from "react";
// material
import { CssBaseline } from "@mui/material";
import {
  createTheme,
  ThemeOptions,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
//
import shape from "./theme/shape";
import palette from "./theme/palette";
import typography from "./theme/typography";
import breakpoints from "./theme/breakpoints";
import componentsOverride from "./theme/overrides";
import shadows, { customShadows } from "./theme/shadows";

// ----------------------------------------------------------------------

type ThemeConfigProps = {
  children: ReactNode;
};

export const themeOptions: ThemeOptions = {
  palette: { ...palette.light, mode: "light" },
  shape,
  typography,
  breakpoints,
  shadows: shadows.light,
  customShadows: customShadows.light,
};

export default function ThemeConfig({ children }: ThemeConfigProps) {
  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
