import { Avatar, ButtonBase, Card } from "@mui/material";
import { DocumentSnapshot, QueryDocumentSnapshot } from "firebase/firestore";
import React, { VFC } from "react";
import { useHistory } from "react-router-dom";
import { TournamentDoc } from "@defs/Tournament.type";

const OrgCard: VFC<{
  tournament:
    | QueryDocumentSnapshot<TournamentDoc>
    | DocumentSnapshot<TournamentDoc>;
  disableOnClick?: boolean;
}> = ({ tournament, disableOnClick }) => {
  const history = useHistory();
  const t = tournament.data();
  return (
    <Card
      className="flex flex-col items-start overflow-hidden relative w-full"
      elevation={0}
      component={ButtonBase}
      onClick={
        disableOnClick
          ? undefined
          : () => history.push(`/tournament/${tournament.id}`)
      }
    >
      <div
        className="h-40 bg-gray-200 w-full bg-cover bg-center"
        style={{ backgroundImage: `url("${t?.backgroundImage}")` }}
      ></div>
      <div className="p-4 flex items-center">
        <Avatar src={t?.logo} variant="rounded">
          {t?.logo}
        </Avatar>
        <div className="flex flex-col pl-4">
          <div className="text-md leading-none text-left">
            {t?.tournament.name}
          </div>
          <div className="text-xs leading-none text-left opacity-40">
            {t?.orgId}
            {t?.visibility === "draft" && " (draft)"}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default OrgCard;
