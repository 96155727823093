/**
 * Dashboard app global constants. Differs from `env` constants
 * as those are dependent on environment.
 */
const AcadConsts = {
  // no operation
  noop() {},
  async voidPromise() {},
  maxFileSize: 2 * 1024 * 1024,
};
export default AcadConsts;
