import AcadNavTabs from "@components/AcadNavTabs";
import Sheet from "@components/Sheet";
import { EventSettingsRoute as routes } from "./EventSettingsRoute";

const CompeteSettings = () => {
  return (
    <Sheet sx={{ my: 2 }}>
      <AcadNavTabs {...{ routes }} />
    </Sheet>
  );
};

export default CompeteSettings;
