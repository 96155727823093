import TextField from "@components/MemoizedTextfield";
import { Activity, activityList } from "@defs/Activities";
import { OrgProps, Socials } from "@defs/Types";
import { userContext } from "@lib/AuthContext";
import { onFileSelect, Files } from "@lib/file";
import {
  FormControlLabel,
  Autocomplete,
  ButtonBase,
  useMediaQuery,
  Switch,
  MenuItem,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormLabel,
  InputLabel,
  Popover,
  Rating,
  Select,
  Typography,
  SelectChangeEvent,
} from "@mui/material";

import {
  ChangeEvent,
  SetStateAction,
  useContext,
  useState,
  VFC,
  MouseEvent,
} from "react";
import { Dispatch } from "react";
import toast from "react-hot-toast";
import Picker from "emoji-picker-react";
import UserSearch from "@components/UserSearch";
import { useTranslation } from "react-i18next";
import { Subregion } from "@defs/region/subregion";
import Game from "@models/game";
import { collection, CollectionReference } from "@firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { useRegion } from "@hooks/use_region";
interface Props {
  org?: OrgProps;
  schools: string[];
  form: OrgProps;
  setForm: Dispatch<SetStateAction<OrgProps>>;
}

const OrgMainFields = ({ form, setForm, org, schools = [] }: Props) => {
  const classes = {
    orgInfo: "text-xl text-left col-span-full",
    shortname: "col-span-12 md:col-span-8",
    shortcode: "col-span-12 md:col-span-4",
    isAlliance: "col-span-full sm:col-span-4",
    schoolInfo: "text-xl text-left col-span-full mt-6",
    schoolName: "col-span-full",
    schoolCampus: "col-span-12 md:col-span-8",
    schoolShortcode: "col-span-12 md:col-span-4",
    admins: "col-span-full md:col-span-6",
    profileInfo: "text-xl text-left col-span-full mt-8",
    description_thumbnail:
      "bg-gray-200 col-span-full md:col-span-4 h-52 bg-cover bg-center",
    description: "col-span-full md:col-span-8 h-full",
    contact_email: "col-span-full md:col-span-6 ",
    visibility: "text-xl text-left col-span-full mt-6",
  };

  const smUp = useMediaQuery("(min-width: 640px)");

  const handleText = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name as keyof OrgProps]: e.target.value });
  };

  const { user, isAdmin } = useContext(userContext);
  const isModeratorOnly =
    !org?.admins?.includes(user?.id ?? "") &&
    org?.moderators?.includes(user?.id ?? "");

  const [files, setFiles] = useState<Files<OrgProps>>({});
  const { t } = useTranslation("community");

  return (
    <>
      <div className={classes.orgInfo}>{t("org_info")}</div>
      {/* Org Name */}
      <TextField
        name="name"
        label={t("org_name")}
        className={"col-span-12"}
        value={form?.name}
        required
        onChange={handleText}
        disabled={isModeratorOnly}
      />

      {/* Org Shortname */}
      <TextField
        name="shortname"
        label={t("org_shortname")}
        className={classes.shortname}
        value={form?.shortname}
        required
        onChange={handleText}
        disabled={isModeratorOnly}
      />

      {/* Org Shortcode */}
      <TextField
        name="shortcode"
        label={t("org_shortcode")}
        className={classes.shortcode}
        value={form?.shortcode}
        required
        onChange={handleText}
        disabled={isModeratorOnly}
      />

      {/* Region */}
      <Region {...{ org: form, setForm, smUp, isModeratorOnly }} />

      {/* Alliance Level */}
      <StarLevel {...{ org: form, setForm, isGlobalAdmin: isAdmin }} />

      {/* Is Alliance Org? */}
      <FormControlLabel
        className={classes.isAlliance}
        label={t("is_alliance_org")}
        disabled={!isAdmin}
        control={
          <Switch
            color="primary"
            checked={form.isAlliance}
            onChange={() => setForm({ ...form, isAlliance: !form.isAlliance })}
          />
        }
      />

      <div className={classes.schoolInfo}>{t("school_info")}</div>

      {/* School Name */}
      <Autocomplete
        className={classes.schoolName}
        options={schools}
        autoSelect
        autoComplete
        disableClearable
        value={form.schoolName}
        disabled={!isAdmin}
        onChange={(event, value) =>
          setForm({ ...form, schoolName: value + "" })
        }
        renderInput={({ size, ...params }) => (
          <TextField required {...params} label={t("school")} />
        )}
      />

      {/* school */}
      <TextField
        name="schoolCampus"
        label={t("school_branch_or_campus")}
        className={classes.schoolCampus}
        value={form?.schoolCampus}
        onChange={handleText}
        disabled={isModeratorOnly}
      />

      {/**
       *
       * !School Shortcode
       * is used as document ID
       * and must be unique
       * and cannot be changed
       *
       * */}
      <TextField
        name="schoolShortcode"
        label={t("school_shortcode")}
        className={classes.schoolShortcode}
        value={form?.schoolShortcode}
        required
        disabled
        onChange={handleText}
        variant="filled"
      />

      {/* Admins */}

      <UserSearch
        value={form.admins ?? []}
        onChange={(value) => setForm((state) => ({ ...state, admins: value }))}
        label={t("admins")}
        autocompleteProps={{ className: classes.admins }}
      />
      {/* <Autocomplete
        multiple
        value={form.admins ?? []}
        inputValue={adminInput}
        onChange={(e, value) => {
          setForm({ ...form, admins: value });
        }}
        onInputChange={(e, value) => setAdminInput(value)}
        className={classes.admins}
        {...autocompleteProps("Admins")}
      /> */}

      <UserSearch
        value={form.moderators ?? []}
        onChange={(value) =>
          setForm((state) => ({ ...state, moderators: value }))
        }
        label={t("moderators")}
        autocompleteProps={{ className: classes.admins }}
      />

      <UserSearch
        value={form.officers ?? []}
        onChange={(value) =>
          setForm((state) => ({ ...state, officers: value }))
        }
        label={t("officers")}
        autocompleteProps={{ className: classes.admins }}
      />

      <div className={classes.profileInfo}>{t("profile_info")}</div>

      {/**
       * Description thumbnail
       * This will be used as open graph tag for thumbnail
       * for Search Engine Optimization
       */}
      <ButtonBase
        component="label"
        className={classes.description_thumbnail}
        style={{
          backgroundImage: `url("${
            files.description_thumbnail?.path || form?.description_thumbnail
          }")`,
        }}
      >
        <input
          type="file"
          accept="image/*"
          hidden
          onChange={onFileSelect(setFiles, setForm)}
          name="description_thumbnail"
        />
        {Boolean(files.description_thumbnail)
          ? ""
          : form?.description_thumbnail
          ? ""
          : t("add_thumbnail")}
      </ButtonBase>

      {/**
       * Description
       * This will be used as open graph tag for description
       * for Search Engine Optimization
       */}
      <TextField
        className={classes.description}
        multiline
        rows={8}
        value={form.description}
        name="description"
        onChange={handleText}
        label={t("description", { ns: "common" })}
      />

      {/* Games we play */}
      <GameFields {...{ org: form, setForm }} />

      {/* Activities */}
      <Activities {...{ org: form, setForm }} />

      {/* Contact email */}
      <TextField
        className={classes.contact_email}
        value={form.contact_email}
        name="contact_email"
        onChange={handleText}
        label={t("contact_email", { ns: "common" })}
        type="email"
      />

      {/**
       * Social Fields such as
       * Facebook, Twitter, Discord, & Instagram
       */}
      <SocialFields {...{ org: form, setForm, smUp }} />

      <div className={classes.visibility}>
        {t("visibility", { ns: "common" })}
      </div>
      {/* Visibility */}
      <Visibility {...{ org: form, setForm, smUp, isModeratorOnly }} />
    </>
  );
};

export default OrgMainFields;

interface InputProps {
  org: OrgProps;
  setForm: Dispatch<SetStateAction<OrgProps>>;
  smUp?: boolean;
  isGlobalAdmin?: boolean;
  isModeratorOnly?: boolean;
}

const SocialFields: VFC<InputProps> = ({ org, setForm, smUp }) => {
  const fields: (keyof Socials)[] = [
    "facebook",
    "twitter",
    "discord",
    "instagram",
    "tiktok",
  ];
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...org,
      social_links: {
        ...org.social_links,
        [e.target.name]: e.target.value,
      },
    });
  };

  const capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const { t } = useTranslation("community");

  return (
    <>
      {fields.map((field, i) => (
        <TextField
          key={i}
          className="col-span-12 md:col-span-6"
          label={t("field_link", { field: capitalize(field) })}
          onChange={onChange}
          name={field}
          type="link"
          value={(org.social_links && org.social_links[field]) ?? ""}
        />
      ))}
    </>
  );
};

const StarLevel: VFC<InputProps> = ({ org, setForm, isGlobalAdmin }) => {
  const { t } = useTranslation("community");

  return (
    <div className="col-span-full sm:col-span-2">
      <Typography component="legend">{t("star_level")}</Typography>
      <Rating
        value={org.star_level ?? 1}
        max={3}
        disabled={!isGlobalAdmin}
        onChange={(e, newValue) =>
          setForm({ ...org, star_level: newValue || 1 })
        }
        // size="large"
      ></Rating>
    </div>
  );
};

const Region: VFC<InputProps> = ({ org, setForm, smUp, isModeratorOnly }) => {
  const [open, setOpen] = useState(false);

  const onChange = (e: SelectChangeEvent<Subregion>) => {
    setForm((state) => ({ ...state, region: e.target.value as Subregion }));
  };

  const { t } = useTranslation("community");
  const { subregions } = useRegion();

  return (
    <div className="col-span-full sm:col-span-6">
      <FormControl fullWidth disabled={isModeratorOnly}>
        <InputLabel id="label">{t("region")}</InputLabel>
        <Select
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          value={org.region}
          label={t("region")}
          {...{ open, onChange }}
        >
          {subregions.map((region) => (
            <MenuItem key={region} value={region}>
              {region}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

const Visibility: VFC<InputProps> = ({
  org,
  setForm,
  smUp,
  isModeratorOnly,
}) => {
  const [open, setOpen] = useState(false);

  const onChange = (e: SelectChangeEvent<OrgProps["visibility"]>) => {
    setForm({ ...org, visibility: e.target.value as OrgProps["visibility"] });
  };
  const { t } = useTranslation("community");

  return (
    <div className="col-span-full sm:col-span-6">
      <FormControl fullWidth>
        <InputLabel id="label">{t("visibility")}</InputLabel>
        <Select
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          value={org.visibility}
          label={t("visibility")}
          open={open}
          onChange={onChange}
          disabled={isModeratorOnly}
        >
          <MenuItem value="draft">{t("draft", { ns: "common" })}</MenuItem>
          <MenuItem value="public">{t("public", { ns: "common" })}</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

const GameFields: VFC<InputProps> = ({ org, setForm }) => {
  const db = useFirestore();
  const gamesColRef = collection(db, "games") as CollectionReference<Game>;
  const { data: games = [] } = useFirestoreCollectionData(gamesColRef);

  const onTick = (code: Game) => () => {
    const isChecked = org.games?.includes(code.id);
    const unCheck = org.games?.filter((g) => g !== code.id) ?? [];
    const check = [...(org.games ?? []), code.id];
    setForm({ ...org, games: isChecked ? unCheck : check });
  };

  const { t } = useTranslation("community");

  return (
    <FormControl
      className="col-span-12 sm:col-span-6 p-4 rounded"
      style={{
        border: "1px solid rgba(0,0,0,.25)",
      }}
    >
      <FormLabel component="legend">{t("games")}</FormLabel>
      <FormGroup>
        {Object.values(games).map((g) => (
          <FormControlLabel
            key={g.id}
            label={g.title}
            control={
              <Checkbox
                checked={org.games?.includes(g.id)}
                onClick={onTick(g)}
              />
            }
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

const Activities: VFC<InputProps> = ({ org, setForm }) => {
  const [state, setState] = useState(false);
  const [pop, setPop] = useState<HTMLButtonElement | null>(null);

  const [newActivity, setNewActivity] = useState<Activity>({
    icon: "",
    name: "",
  });

  const onTick = (a: Activity) => () => {
    const isChecked = org.activities?.map((aa) => aa.name)?.includes(a.name);
    const unCheck = org.activities?.filter((aa) => aa.name !== a.name);
    const check = [...(org.activities ?? []), { name: a.name, icon: a.icon }];
    setForm({ ...org, activities: isChecked ? unCheck : check });
  };

  interface IEmojiData {
    unified: string;
    originalUnified: string;
    names: Array<string>;
    emoji: string;
    activeSkinTone: any;
  }
  const addActivity = () => {
    if (!newActivity.icon && !newActivity.name)
      return toast.error(t("both_fields_are_required"));
    setForm({ ...org, activities: [...(org.activities ?? []), newActivity] });
    setState(false);
  };

  const onEmojiClick = (event: MouseEvent, emoji: IEmojiData) => {
    setNewActivity({ ...newActivity, icon: emoji.emoji });
    setPop(null);
  };

  const { t } = useTranslation("community");

  return (
    <FormControl
      className="col-span-12 sm:col-span-6 p-4 rounded"
      style={{
        border: "1px solid rgba(0,0,0,.25)",
      }}
    >
      <FormLabel component="legend">{t("activities")}</FormLabel>
      <FormGroup>
        {Object.values(activityList).map((a, i) => (
          <FormControlLabel
            key={i}
            label={t(a.i18nKey ?? "")}
            control={
              <Checkbox
                checked={org.activities?.map((aa) => aa.name)?.includes(a.name)}
                onChange={onTick(a)}
              />
            }
          />
        ))}

        {/* custom activities */}
        {org.activities
          ?.filter(
            (a) =>
              !Object.values(activityList)
                .map((aa) => aa.name)
                .includes(a.name)
          )
          .map((a, i) => (
            <FormControlLabel
              key={i}
              label={t("name_custom", { name: a.name })}
              control={
                <Checkbox
                  checked={org.activities
                    ?.map((aa) => aa.name)
                    ?.includes(a.name)}
                  onChange={onTick(a)}
                />
              }
            />
          ))}
      </FormGroup>
      <Button
        className="self-start"
        variant="contained"
        onClick={() => setState(true)}
      >
        {t("add_custom_activity")}
      </Button>

      <Dialog open={state} onClose={() => setState(false)}>
        <DialogTitle>{t("add_custom_activity")}</DialogTitle>
        <DialogContent>
          <div className="flex items-center space-x-4">
            <Button
              variant="outlined"
              className={`h-20 w-20 border-1 leading-none ${
                newActivity.icon && "text-4xl"
              }`}
              onClick={(e: MouseEvent<HTMLButtonElement>) =>
                setPop(e.currentTarget)
              }
            >
              {newActivity.icon || t("emoji_as_icon")}
            </Button>
            <Popover
              onClose={() => setPop(null)}
              open={Boolean(pop)}
              anchorEl={pop}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Picker preload native onEmojiClick={onEmojiClick} />
            </Popover>
            <TextField
              variant="outlined"
              label={t("activity_name")}
              value={newActivity.name}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setNewActivity({ ...newActivity, name: e.target.value })
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={
              !newActivity.icon ||
              !newActivity.name ||
              org.activities?.map((a) => a.name).includes(newActivity.name) ||
              (org.activities ?? []).length >= 20
            }
            onClick={addActivity}
          >
            {t("add", { ns: "common" })}
          </Button>
        </DialogActions>
      </Dialog>
    </FormControl>
  );
};
