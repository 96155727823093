import Region from "@defs/region/region";
import { isEmu } from "@env";
import { EnvConfig } from "./env_config";

const EnvDev: Record<Region, EnvConfig> = {
  [Region.PH]: {
    appURL: "http://localhost:3000",
    cdn: isEmu()
      ? "https://noki-cors.herokuapp.com/cdn.acadarena.com:443"
      : "https://cdn.acadarena.com/",
    api: "https://waypoint-dev-api.web.app/",
    region: "asia-east2",
    firebaseConfig: {
      apiKey: "AIzaSyAfb4O5Ik-tQ2hLKH2tNJ_JG69Dp9xJWWg",
      authDomain: "waypoint-dev.firebaseapp.com",
      databaseURL:
        "https://waypoint-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
      projectId: "waypoint-dev",
      storageBucket: "waypoint-dev.appspot.com",
      messagingSenderId: "250995808884",
      appId: "1:250995808884:web:3c533cd90c7ede7303cf21",
      measurementId: "G-BKV3QGNYNP",
    },
  },
  [Region.BR]: {
    appURL: "http://localhost:3000",
    cdn: "https://cdn.acadarena.com/",
    api: "https://waypoint-dev-api-br.web.app/",
    region: "southamerica-east1",
    firebaseConfig: {
      apiKey: "AIzaSyDkH5WgGfb3jMk6P23wwOzMcQtfXxokNRw",
      databaseURL: "https://aa-brazil-dev-default-rtdb.firebaseio.com",
      authDomain: "aa-brazil-dev.firebaseapp.com",
      projectId: "aa-brazil-dev",
      storageBucket: "aa-brazil-dev.appspot.com",
      messagingSenderId: "993057274594",
      appId: "1:993057274594:web:f4aad8cec448effad2bcbb",
      measurementId: "G-NBGHWD6FRX",
    },
  },
};

export default EnvDev;
