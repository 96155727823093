/**
 * HIGHER-ORDER COMPONENT for {@link FileUploadProvider}
 * this is to avoid creating a new component just to
 * wrap the main component that will use fileUploadContext
 */
import { FunctionComponent } from "react";
import { FileUploadProvider } from "../contexts/file_upload";

function withFileUpload<T>(ReactComponent: FunctionComponent<T>) {
  return ((props) => {
    return (
      <FileUploadProvider>
        <ReactComponent {...props} />
      </FileUploadProvider>
    );
  }) as FunctionComponent<T>;
}

export default withFileUpload;
