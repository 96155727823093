import { AcadTable } from "@components";
import { HeadCell } from "@components/table/AcadTableHead";
import { Timestamp } from "@firebase/firestore";
import { copyToClipboard } from "@lib/clipboard";
import ReferralCode from "@models/referral_code";
import { Button, Card, TableCell, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useReferralCodeTable } from "./ReferralCodeTableContext";

interface ReferralCodeRowProps {
  referralCode: ReferralCode;
}

const ReferralCodeRow: React.VFC<ReferralCodeRowProps> = ({
  referralCode: { code, owner, count, endDate },
}) => {
  const { t } = useTranslation("users");

  const endDateStr = endDate
    ? new Timestamp(endDate.seconds, endDate.nanoseconds)
        .toDate()
        .toLocaleString()
    : "-";

  return (
    <TableRow>
      <TableCell width="22.5%">
        <Link to={{ pathname: `/users/referrals/${code}` }}>{code}</Link>
      </TableCell>
      <TableCell width="22.5%">{owner}</TableCell>
      <TableCell width="10%">{count}</TableCell>
      <TableCell width="22.5%">{endDateStr}</TableCell>

      <TableCell width="22.5%">
        <Button variant="text" onClick={() => copyToClipboard(code)}>
          {t("copy_referral_code")}
        </Button>
      </TableCell>
    </TableRow>
  );
};

const ReferralCodeTable: React.VFC = () => {
  const {
    referralCodes: { data: snap, nextDisabled, prevDisabled, limit, ...data },
  } = useReferralCodeTable();

  const referralCodes = snap?.docs.map((code) => code.data()) ?? [];
  const { t } = useTranslation("users");

  const headCells: HeadCell<ReferralCode>[] = [
    { id: "code", label: t("referral_code") },
    { id: "owner", label: t("owner") },
    { id: "count", label: t("referrals") },
    { id: "endDate", label: t("end_date") },
    { id: "", label: "", disableSort: true },
  ];

  return (
    <Card>
      <AcadTable<ReferralCode>
        {...data}
        dense
        data={referralCodes}
        disableNext={nextDisabled}
        disablePrev={prevDisabled}
        headCells={headCells}
        rowsPerPage={limit}
        renderRows={(referralCode, i) => (
          <ReferralCodeRow key={"row" + i} referralCode={referralCode} />
        )}
        orderBy="code"
      />
    </Card>
  );
};

export default ReferralCodeTable;
