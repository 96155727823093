import { TextField as MUITextField, TextFieldProps } from "@mui/material";
import React from "react";
import { useMemo } from "react";

const TextField = ({ value, ...props }: TextFieldProps) => {
  const memoizedValue = useMemo(() => value, [value]);
  return <MUITextField value={memoizedValue} {...props} />;
};

export default TextField;
