import q from "@lib/firestore/q";
import { OAuthToken, OAUTH_ACCESS_TOKEN_PATH } from "@models/oauth";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useFirestore, useFirestoreCollection } from "reactfire";
import AppTokenCard from "./AppTokenCard";
import AppTokenModalCreate from "./AppTokenModalCreate";
import { useOAuthClient } from "./oauthClientContext";

const AppTokenDirectory = () => {
  const { owner, clientId } = useOAuthClient();
  const db = useFirestore();
  const { data } = useFirestoreCollection(
    q<OAuthToken>(db, OAUTH_ACCESS_TOKEN_PATH, {
      "client.owner": owner,
      "client.clientId": clientId,
    })
  );

  const docs = data?.docs ?? [];
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return (
    <Card>
      <CardHeader title="Tokens" />
      <CardContent>
        <Stack spacing={2}>
          {docs.length ? (
            docs.map((doc) => <AppTokenCard token={doc} key={doc.id} />)
          ) : (
            <Typography>No tokens yet</Typography>
          )}

          <Button onClick={openModal} variant="outlined">
            Generate access token
          </Button>
        </Stack>
      </CardContent>
      <AppTokenModalCreate open={open} onClose={closeModal} />
    </Card>
  );
};

export default AppTokenDirectory;
