import acadJoi from "@acad_joi";
import { nanoid } from "nanoid";
import { CustomField } from "./custom_field";

export type GameFieldMap = Record<string, GameField>;

export interface GameField extends CustomField {
  type: "text";
  perPlayer: true;
  isPublic: true;
  gameId: string;
}

export default class Game {
  static readonly PATH: string = "games";
  id: string;
  title: string = "";
  shortTitle: string = "";
  description: string = "";
  icon: string = "";
  fields: GameFieldMap = {};
  publisher: string = "";

  constructor(id?: string, fields?: GameFieldMap) {
    this.id = id || nanoid();
    if (fields) {
      this.fields = fields;
    }
  }

  static addField(game: Game, field?: GameField) {
    const fieldId = nanoid(5);
    game.fields[`game-${fieldId}`] = field ?? {
      description: "",
      fieldName: `New Field`,
      gameId: game.id,
      isPublic: true,
      type: "text",
      perPlayer: true,
    };
    return game;
  }

  static removeField(game: Game, fieldId: string) {
    delete game.fields[fieldId];
    return game;
  }

  static gameFieldSchema = acadJoi.object<GameField>({
    fieldName: acadJoi.string().required(),
    perPlayer: acadJoi.valid(true).required(),
    description: acadJoi.string().allow("").required(),
    isPublic: acadJoi.valid(true).required(),
    type: acadJoi.valid("text").required(),
    gameId: acadJoi.string().required(),
  });

  static schema = acadJoi.object<Game>({
    id: acadJoi.string().required().label("ID"),
    title: acadJoi.string().label("Title").required(),
    shortTitle: acadJoi.string().label("Short Title").required(),
    icon: acadJoi.string().label("Icon").allow(""),
    description: acadJoi.string().allow("").label("Description"),
    publisher: acadJoi.string().label("Publisher").allow(""),
    fields: acadJoi
      .object()
      .pattern(
        acadJoi.string().pattern(/^game-[A-Za-z0-9_-]{5}$/),
        Game.gameFieldSchema
      )
      .label("Game Fields"),
  });
}
