import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DialogProps } from "@framework/types";
import { AcadTextField } from "@components";
import { useFirestore } from "reactfire";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Game from "@models/game";
import { useHistory } from "react-router";
import { AcadTextFieldProps } from "@components/textfield/AcadTextField";
import { collection, CollectionReference, doc } from "firebase/firestore";
import toast from "react-hot-toast";
import { useCreateDocument } from "@hooks";
import { useTranslation } from "react-i18next";

const GameCreate = ({ open, onClose }: DialogProps) => {
  const db = useFirestore();
  const history = useHistory();

  const { t } = useTranslation("games");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Game>({
    defaultValues: new Game(),
    resolver: joiResolver(Game.schema),
  });

  const { createDocument, loading } = useCreateDocument<Game>({
    docExistsMsg: t("game_already_exists"),
  });

  const submit: SubmitHandler<Game> = async (game) => {
    const colRef = collection(db, "games") as CollectionReference<Game>;
    const docRef = doc(colRef, game.id);
    await createDocument(game, docRef);
    onClose?.();
    history.push(`/game/${game.id}`);
  };

  const onErr: SubmitErrorHandler<Game> = (err) => {
    toast.error(
      Object.values(err)
        .map((e) => e.message)
        .join("\n")
    );
  };

  const commonProps: AcadTextFieldProps = {
    variant: "outlined",
    errors: errors,
  };

  return (
    <Dialog open={!!open} onClose={onClose}>
      <DialogTitle>{t("add_game")}</DialogTitle>
      <DialogContent>
        <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-4">
          <AcadTextField
            {...commonProps}
            label={t("title", { ns: "common" })}
            {...register("title")}
            required
          />

          <AcadTextField
            {...commonProps}
            label={t("short_title")}
            {...register("shortTitle")}
            required
          />

          <AcadTextField
            {...commonProps}
            label={t("publisher")}
            {...register("publisher")}
          />
          <AcadTextField
            multiline
            {...commonProps}
            className="col-span-2"
            minRows={3}
            maxRows={5}
            label={t("description", { ns: "common" })}
            {...register("description")}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          {t("cancel", { ns: "common" })}
        </Button>
        <Button disabled={loading} onClick={handleSubmit(submit, onErr)}>
          {t("submit", { ns: "common" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GameCreate;
