import { useImagePreview } from "@hocs/withImagePreview";
import { PlayerFieldWithAnswer } from "@models/custom_field";
import { OpenInNew } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { VFC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  field: PlayerFieldWithAnswer;
}
const CustomFieldAnswerPlayer: VFC<Props> = ({ id, field }) => {
  const openPreview = useImagePreview();
  const { t } = useTranslation("events");
  return (
    <Card key={id} sx={{ width: "100%" }}>
      <CardContent component={Stack}>
        <Typography variant="subtitle2">{field.fieldName}</Typography>
        <Grid container spacing={2}>
          {Object.entries(field.answer).map(([uid, answer]) => (
            <Grid item xs={12} md={4} key={uid}>
              <Card>
                <Stack sx={{ p: 2 }}>
                  <Typography variant="caption" color="text.secondary">
                    {answer.username} ({answer.firstName} {answer.lastName})
                  </Typography>
                  <Typography variant="caption">
                    {answer.answer ? (
                      field.type === "text" ? (
                        <Typography>A: {answer.answer}</Typography>
                      ) : (
                        <Button
                          size="small"
                          sx={{
                            alignSelf: "flex-start",
                            mt: 1,
                          }}
                          variant="contained"
                          endIcon={<OpenInNew />}
                          onClick={() => openPreview(answer.answer)}
                        >
                          {t("click_here_to_preview")}
                        </Button>
                      )
                    ) : (
                      <Typography>{t("no_answer")}</Typography>
                    )}
                  </Typography>
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
export default CustomFieldAnswerPlayer;
