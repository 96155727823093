import { AcadDirectoryContainer } from "@components";
import { useTranslation } from "react-i18next";
import BenefitCreate from "./BenefitCreate";
import BenefitDirectory from "./BenefitDirectory";

export default function Benefits() {
  const { t } = useTranslation("scholarships");

  return (
    <AcadDirectoryContainer
      title={t("emoji_benefits_title")}
      directoryTitle={t("benefit_directory")}
      createBtnTitle={t("add_benefit")}
      createModal={<BenefitCreate />}
    >
      <BenefitDirectory />
    </AcadDirectoryContainer>
  );
}
