import { doc, DocumentReference } from "@firebase/firestore";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { DialogProps } from "@framework/types";
import { useCreateDocument } from "@hooks";
import { AcadModal, AcadTextField } from "@components";
import { useFirestore } from "reactfire";
import ScholarshipBenefit, {
  scholarshipBenefitSchema,
} from "@models/scholarship_benefit";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useTranslation } from "react-i18next";

const BenefitCreate = ({ open, onClose }: DialogProps) => {
  const db = useFirestore();
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ScholarshipBenefit>({
    defaultValues: new ScholarshipBenefit(),
    resolver: joiResolver(scholarshipBenefitSchema),
  });

  const { t } = useTranslation("scholarships");

  const { loading, createDocument } = useCreateDocument<ScholarshipBenefit>({
    onCreated: () => {
      if (onClose) {
        onClose();
      }
      return t("successfully_created_a_new_benefit");
    },
    docExistsMsg: t("benefit_with_benefit_id_already_exists", {
      benefitId: getValues().id,
    }),
  });

  const createDoc = () => {
    createDocument(
      getValues(),
      doc(
        db,
        ScholarshipBenefit.PATH,
        getValues().id
      ) as DocumentReference<ScholarshipBenefit>
    );
  };

  return (
    <AcadModal open={open} onClose={onClose} loading={loading}>
      <DialogContent className="overflow-y-visible">
        <div className="grid grid-cols-12 gap-4">
          <div className="text-lg mt-4 mb-2 col-span-full">
            {t("benefit_info")}
          </div>
          <AcadTextField
            variant="outlined"
            size="medium"
            label={t("id")}
            className="col-span-full"
            {...register("id")}
            helperText={t("benefit_id_helper")}
            required
            errors={errors}
          />

          <AcadTextField
            variant="outlined"
            size="medium"
            label={t("benefit_display_name")}
            className="col-span-full"
            {...register("name")}
            helperText={t("benefit_display_name_helper")}
            required
            errors={errors}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(() => createDoc())}
        >
          {t("save", { ns: "common" })}
        </Button>
      </DialogActions>
    </AcadModal>
  );
};

export default BenefitCreate;
