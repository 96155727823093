import { AcadTextField } from "@components";
import { AcademyParams } from "@models/syspars/academy_params";
import { DeleteForever, PlusOne } from "@mui/icons-material";
import { Button, Card, IconButton, Stack } from "@mui/material";
import { Control, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

function AcademyHeroButtonForm({
  control,
}: {
  control: Control<AcademyParams["hero"]>;
}) {
  const { t } = useTranslation("common");
  const { fields, append, remove } = useFieldArray({
    control,
    name: "buttons",
  });

  const addOne = () => {
    append({
      color: "primary",
      name: `${t("button")} #${fields.length + 1}`,
      url: "",
    });
  };
  return (
    <Card sx={{ p: 1 }}>
      <Stack gap={1}>
        <Button startIcon={<PlusOne />} onClick={addOne}>
          Add Call to Action Button
        </Button>
        <Stack>
          {fields.map((field, index) => (
            <Card key={field.id} sx={{ p: 1 }}>
              <Stack gap={1} direction="row">
                <AcadTextField
                  size="small"
                  sx={{ flex: 1 }}
                  label={`${t("button")} ${t("name")}`}
                  {...control.register(`buttons.${index}.name`)}
                />
                <AcadTextField
                  size="small"
                  sx={{ flex: 1 }}
                  label={`${t("button")} URL`}
                  {...control.register(`buttons.${index}.url`)}
                />
                <AcadTextField
                  size="small"
                  label={`${t("button")} ${t("color")}`}
                  {...control.register(`buttons.${index}.color`)}
                />
                <IconButton onClick={() => remove(index)}>
                  <DeleteForever />
                </IconButton>
              </Stack>
            </Card>
          ))}
        </Stack>
      </Stack>
    </Card>
  );
}

export default AcademyHeroButtonForm;
