import { Timestamp } from "@firebase/firestore";

/**
 * Date and Timestamp transformer
 */
const DateTimestampTransformer = {
  /**
   * Converts Timestamp to date
   * @param ts
   * @returns
   */
  toDate(ts?: Timestamp | null) {
    // need to wrap ts in a new instance because
    // methods of Timestamp are stripped off sometimes
    return ts ? new Timestamp(ts.seconds, ts.nanoseconds).toDate() : null;
  },
  /**
   * Converts date to timestamp
   * @param date
   */
  toTimestamp(date?: Date | null) {
    // if null/undefined, return whatever date is
    return date ? Timestamp.fromDate(date) : date;
  },
  /**
   * Converts time (timestamp or date) to milliseconds.
   */
  toMillis(time?: Timestamp | Date | null) {
    if (!time) {
      return time;
    }
    if (time instanceof Date) {
      return time.getTime();
    }
    return time.toMillis();
  },

  anyToDate(date?: Date | Timestamp | null) {
    if (date instanceof Date) {
      return date || null;
    }
    return this.toDate(date);
  },

  anyToTimestamp(date?: Date | Timestamp | null) {
    if (date instanceof Date) return Timestamp.fromDate(date);
    return date || null;
  },
};

export default DateTimestampTransformer;
