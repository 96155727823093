import {
  Firestore,
  query as fsQuery,
  QueryConstraint,
  where,
} from "firebase/firestore";
import col from "./col";

export default function q<T extends object>(
  db: Firestore,
  path: string,
  query: Partial<T> | QueryConstraint[]
) {
  const ref = col<T>(db, path);
  const constraints = Array.isArray(query)
    ? query
    : Object.entries(query).map(([key, val]) => where(key, "==", val));
  const qq = fsQuery(ref, ...constraints);
  return qq;
}
