import acadJoi from "@acad_joi";
import Scholarship from "./scholarship";
import SEO from "./seo";

/**
 * All information about the scholarship. When user clicks on a particular
 * scholarship. More comprehensive than `Scholarship` object.
 */
export default class ScholarshipInfo extends Scholarship {
  static readonly PATH: string = "scholarshipInfo";
  bannerUrl: string = "";
  typeformId: string = "";
  scholarshipDesc: string = "";
  benefitsDesc: string = "";
  reqsAndEligibilityDesc: string = "";
  seo?: SEO = {
    description: "",
    thumbnail: "",
  };
  requiresDiscord?: boolean = false;
  requiresVerifiedStudent?: boolean = false;
  verifiedStudentDesc?: string = "";

  static schema = acadJoi.object<ScholarshipInfo>({
    ...Scholarship.schemaMap,
    bannerUrl: acadJoi.string().label("Banner URL").allow(""),
    typeformId: acadJoi.string().label("Typeform ID").allow(""),
    scholarshipDesc: acadJoi
      .string()
      .label("Scholarship Description")
      .allow(""),
    benefitsDesc: acadJoi.string().label("Benefits Description").allow(""),
    reqsAndEligibilityDesc: acadJoi
      .string()
      .label("Reqs and Eligibilities Description")
      .allow(""),
    requiresDiscord: acadJoi.boolean().label("Require Discord?").optional(),
    requiresVerifiedStudent: acadJoi
      .boolean()
      .label("Require Verified Student?")
      .optional(),
    verifiedStudentDesc: acadJoi
      .string()
      .label("Verified Student Description")
      .optional()
      .allow(""),
    seo: SEO.schemaMap,
  });
}
