import { Alert } from "@mui/material";
import CreateTournament from "./TournamentCreate";
import { useUserContext } from "@lib/AuthContext";
import { AcadDirectoryContainer } from "@components";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { OrgProps } from "@defs/Types";
import {
  collection,
  CollectionReference,
  query,
  where,
} from "firebase/firestore";
import TournamentDirectory from "./TournamentDirectory";
import { useTranslation } from "react-i18next";

const Tournaments = () => {
  const db = useFirestore();
  const { user, isAdmin } = useUserContext();

  const orgsRef = collection(db, "orgs") as CollectionReference<OrgProps>;
  const q = query(orgsRef, where("admins", "array-contains", user?.id));
  const { data: orgs = [], status } = useFirestoreCollectionData(
    isAdmin ? orgsRef : q,
    { idField: "id" }
  );

  const { t } = useTranslation("events");

  return orgs.length ? (
    <AcadDirectoryContainer
      title={t("emoji_tournaments_edit_add_tournaments")}
      createBtnTitle={t("create_tournament")}
      createModal={
        <CreateTournament orgs={orgs} orgsLoading={status === "loading"} />
      }
      directoryTitle={t("tournaments")}
    >
      <TournamentDirectory orgs={orgs} orgsLoading={status === "loading"} />
    </AcadDirectoryContainer>
  ) : status !== "loading" ? (
    <Alert severity="info" sx={{ my: 2 }}>
      {t("you_are_not_admin_of_any_school_org")}
    </Alert>
  ) : null;
};

export default Tournaments;
