import Sheet from "@components/Sheet";
import { TournamentDoc } from "@defs/Tournament.type";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
  Stack,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import React, { ChangeEvent } from "react";
import swal from "sweetalert2";
import { CustomField, CustomFieldType } from "@models/custom_field";
import { useTranslation } from "react-i18next";

interface Props {
  setForm: React.Dispatch<React.SetStateAction<TournamentDoc>>;
  form: TournamentDoc;
}

const Fields = ({ form, setForm }: Props) => {
  const { t } = useTranslation("events");
  const remove = (id: string) => () => {
    swal
      .fire({
        title: t("delete_custom_field_question"),
        showCancelButton: true,
        confirmButtonColor: "#B33A3A",
      })
      .then((result) => {
        if (result.isConfirmed) {
          setForm(
            ({
              customFields: { [id]: omit, ...customFields } = {},
              ...state
            }) => ({
              ...state,
              customFields,
            })
          );
        }
      });
  };

  const handleText = (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setForm((state) => ({
      ...state,
      customFields: {
        ...state.customFields,
        [id]: { ...state.customFields[id], [e.target.name]: e.target.value },
      },
    }));
  };

  const onSelect = (id: string, value: CustomField["type"]) => {
    setForm((state) => ({
      ...state,
      customFields: {
        ...state.customFields,
        [id]: { ...state.customFields[id], type: value },
      },
    }));
  };

  const toggleCheckbox =
    (id: string, fieldPath: "perPlayer" | "isPublic") => () => {
      setForm((state) => ({
        ...state,
        customFields: {
          ...state.customFields,
          [id]: {
            ...state.customFields[id],
            [fieldPath]: !state.customFields[id][fieldPath],
          },
        },
      }));
    };

  const customFieldEntries = Object.entries(form.customFields ?? {});

  return !!customFieldEntries.length ? (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4  w-full">
      {customFieldEntries.map(([id, field]) => (
        <Sheet
          key={id}
          className="flex flex-col space-y-4 relative overflow-visible"
        >
          <TextField
            label={t("field_name")}
            size="small"
            name="fieldName"
            value={field.fieldName}
            onChange={handleText(id)}
            disabled={!!field.gameId}
          />
          <FormControl size="small" disabled={!!field.gameId}>
            <InputLabel>Type</InputLabel>
            <Select
              label={t("type")}
              value={field.type}
              onChange={(e) => onSelect(id, e.target.value as CustomFieldType)}
            >
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="file">File</MenuItem>
            </Select>
          </FormControl>

          <Typography variant="caption" className="leading-none">
            {t("description", { ns: "common" })}
          </Typography>
          <TextField
            multiline
            rows={4}
            label={t("description", { ns: "common" })}
            value={field.description}
            name="description"
            onChange={handleText(id)}
            disabled={!!field.gameId}
          />
          <Stack>
            <FormControlLabel
              disabled={!!field.gameId}
              label={
                <Typography variant="caption">
                  {t("require_for_all_players_question")}
                </Typography>
              }
              control={
                <Checkbox
                  checked={field.perPlayer}
                  size="small"
                  onChange={toggleCheckbox(id, "perPlayer")}
                />
              }
            />
            <FormControlLabel
              disabled={!!field.gameId}
              label={
                <Typography variant="caption">
                  {t("is_public_question")}
                </Typography>
              }
              control={
                <Checkbox
                  checked={field.isPublic}
                  size="small"
                  onChange={toggleCheckbox(id, "isPublic")}
                />
              }
            />
          </Stack>
          <Button
            variant="contained"
            color="error"
            onClick={remove(id)}
            size="small"
            className="self-end"
            startIcon={<Delete fontSize="inherit" />}
            disabled={!!field.gameId}
          >
            {t("delete", { ns: "common" })}
          </Button>
        </Sheet>
      ))}
    </div>
  ) : (
    <div className="w-full text-center p-4">{t("no_custom_fields_yet")}</div>
  );
};

export default Fields;
