import { DocumentData, FirestoreDataConverter } from "firebase/firestore";

export function genericConvert<
  T extends DocumentData
>(): FirestoreDataConverter<T> {
  return {
    fromFirestore: (snap) => snap.data() as T,
    toFirestore: (obj) => obj,
  };
}
