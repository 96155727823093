import { Timestamp } from "@firebase/firestore";

/**
 * Removes all undefined properties
 * @param obj
 * @returns
 */
export const sanitize = <T extends Record<string, any> | Timestamp>(obj: T) => {
  let newObj: Record<string, any> = {};

  if (obj instanceof Timestamp) return obj;
  if (obj instanceof Date) return obj;
  if (Array.isArray(obj)) return obj;

  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) newObj[key] = sanitize(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};
