import { uploadFile } from "@lib/file";
import { useState } from "react";
import {
  Path,
  PathValue,
  UnpackNestedValue,
  UseFormSetValue,
} from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export interface UseFileUploadProps {
  /**
   * Queue file for upload. If file is undefined,
   * dequeue file from upload.
   *
   * It's recommended that `key` should be the same as the
   * model's field key where the queued file is linked. This is
   * crucial if one were to use `uploadFilesAndSet`, as the `key`
   * will be used to map to the react-hook-form key.
   * @param key
   * @param file
   */
  queueFile(key: string, file?: File): void;

  /**
   * Upload queued files.
   * @returns A list of uploaded files with `key` as the form's key.
   */
  uploadFiles(): Promise<UploadedFileEntry[]>;

  /**
   * Helper function for forms that use react-hook-form.
   * On upload of files, calls `setValue`.
   * @param setValue
   * @returns A list of uploaded files with `key` as the form's key.
   */
  uploadFilesAndSet<T>(
    setValue: UseFormSetValue<T>
  ): Promise<UploadedFileEntry[]>;
}

export interface UploadedFileEntry {
  key: string;
  url: string;
}

interface FileUploadMap {
  [name: string]: File | undefined;
}

export default function useFileUpload() {
  const { t } = useTranslation("common");
  const [files, setFiles] = useState<FileUploadMap>({});

  const queueFile = (key: string, file?: File) => {
    if (file) {
      setFiles({ ...files, [key]: file });
    } else {
      const { [key]: toRemove, ...others } = files;
      setFiles(others);
    }
  };

  const resetQueue = (uploadedFiles: UploadedFileEntry[]) => {
    // remove uploaded files from queue
    uploadedFiles.forEach(({ key }) => {
      files[key] = undefined;
    });

    // remove all keys/files with undefined
    setFiles(
      Object.fromEntries(Object.entries(files).filter(([, file]) => file))
    );
  };

  const uploadFiles = async () => {
    const uploadedFiles: UploadedFileEntry[] = [];

    // remove undefined files
    const filesList = Object.entries(files);

    // run file upload in parallel
    await Promise.all(
      filesList.map(async ([key, file]) => {
        try {
          // if file is not undefined, upload file
          if (file) {
            const resp = await uploadFile(file);
            // file is uploaded
            uploadedFiles.push({ key, url: resp.data.fileUrl });
          }
        } catch (e) {
          toast.error(t("upload_failed", { filename: key }), {
            style: {
              backgroundColor: "red",
              color: "#fff",
            },
          });
        }
      })
    );

    resetQueue(uploadedFiles);

    return uploadedFiles;
  };

  const uploadFilesAndSet = async <T>(setValue: UseFormSetValue<T>) => {
    const uploadedFiles = await uploadFiles();
    uploadedFiles.forEach(({ key, url }) => {
      // only set the url values once files are uploaded
      setValue(key as Path<T>, url as UnpackNestedValue<PathValue<T, Path<T>>>);
    });
    return uploadedFiles;
  };

  return { queueFile, uploadFiles, uploadFilesAndSet };
}
