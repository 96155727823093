import acadJoi from "@acad_joi";
import { AcadBaseDocument } from "@framework/models";

export default class Sponsor extends AcadBaseDocument {
  static readonly PATH: string = "sponsors";
  id: string = "";
  name: string = "";
  description: string = "";
  logoUrl?: string = "";

  static schema = acadJoi.object<Sponsor>({
    id: acadJoi.string().label("Sponsor ID").trim().required(),
    name: acadJoi.string().label("Sponsor Name").trim().required(),
    description: acadJoi
      .string()
      .label("Sponsor Description")
      .trim()
      .required(),
    logoUrl: acadJoi.string().label("Sponsor Logo").trim().allow(""),
  });
}
