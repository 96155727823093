import Sheet from "@components/Sheet";
import { CarouselSlide } from "@defs/carousel_slide";

const CarouselCard = ({ slide, ...props }: { slide: CarouselSlide; [key: string]: any }) => {
  return (
    <Sheet {...props} className="h-full w-full bg-white overflow-hidden" disablePadding>
      <div
        className="h-36 w-full bg-gray-200 bg-cover bg-center"
        style={{ backgroundImage: `url("${slide.image}")` }}
      ></div>
      <div className="p-4 text-left">{slide.title}</div>
    </Sheet>
  );
};

export default CarouselCard;
