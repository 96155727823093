import { SignUpStatus } from "@defs/academy/academy_cb_sign_up";
import { AcademyCbSignUp } from "@models/academy_cb_sign_up";
import { doc, DocumentReference } from "firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";

export default function useAcademyCbSignUp(uid: string) {
  const db = useFirestore();
  const ref = doc(
    db,
    AcademyCbSignUp.PATH,
    uid || "404"
  ) as DocumentReference<AcademyCbSignUp>;
  const { data: cbSignUp, status } = useFirestoreDocData(ref);
  return {
    ref,
    cbSignUp,
    loading: status === "loading",
    cbSignedUp: cbSignUp && cbSignUp.status === SignUpStatus.SignedUp,
    cbAccepted: cbSignUp && cbSignUp.status === SignUpStatus.Accepted,
    cbRejected: cbSignUp && cbSignUp.status === SignUpStatus.Rejected,
  };
}
