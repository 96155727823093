import { AcadDirectoryContainer } from "@components";
import { useTranslation } from "react-i18next";
import SponsorCreate from "./SponsorCreate";
import SponsorDirectory from "./SponsorDirectory";

export default function Sponsors() {
  const { t } = useTranslation("scholarships");
  return (
    <AcadDirectoryContainer
      title={t("emoji_sponsors_title")}
      directoryTitle={t("sponsor_directory")}
      createBtnTitle={t("add_sponsor")}
      createModal={<SponsorCreate />}
    >
      <SponsorDirectory />
    </AcadDirectoryContainer>
  );
}
