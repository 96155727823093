import { useMemo } from "react";
import RegionSelect from "./appbar/RegionSelect";
import Error from "./Error";
import { useLocation } from "react-router-dom";

export default function UnsupportedRegion() {
  const { pathname } = useLocation();

  useMemo(() => {
    const region = localStorage.getItem("selectedRegion");
    if (region) {
      window.location.href = `/${region}${pathname}`;
    }
  }, [pathname]);

  return (
    <div className="pt-40">
      <Error title="Select a Region">
        <div className="text-lg md:text-xl leading-6 my-2 max-w-lg">
          <div>
            <RegionSelect />
          </div>
        </div>
      </Error>
    </div>
  );
}
