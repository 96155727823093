import acadJoi from "@acad_joi";
import { slugRE } from "@lib/RegularExpressions";

export class TournamentCreate {
  tournament: { name: string; url: string } = { name: "", url: "" };
  /**
   * means...
   * # IS VERIFIED STUDENTS ONLY?
   */
  isSchoolOnly: boolean = false;
  schoolsAllowed?: string[] = [];
  leagueId: string = "";
  leagueTitle: string = "";
  _leagueTitle: string = "";
  orgId: string = "";

  static tournamentSchema = acadJoi.object({
    name: acadJoi.string(),
    url: acadJoi.string().regex(slugRE).allow(""),
  });

  static schema = acadJoi.object<TournamentCreate>({
    tournament: this.tournamentSchema,
    isSchoolOnly: acadJoi.boolean(),
    schoolsAllowed: acadJoi.array().items(acadJoi.string()).optional(),
    leagueId: acadJoi.string().allow(""),
    leagueTitle: acadJoi.string().allow(""),
    _leagueTitle: acadJoi.string().allow(""),
    orgId: acadJoi.string(),
  });
}
