import { LinkProps } from "@defs/Links";
import AcademyHero from "./Hero";
import AcademyOnboarding from "./Onboarding/AcademyOnboarding";
import AcademySettings from "./Settings/AcademySettings";

const routes: LinkProps[] = [
  {
    component: <AcademyHero />,
    title: "academy_hero",
    url: "/academy/hero",
    exact: true,
    icon: "🤩",
    onNavbar: true,
    authenticated: true,
    adminOnly: true,
  },
  {
    component: <AcademyOnboarding />,
    title: "academy_onboard",
    url: "/academy/onboard",
    exact: true,
    icon: "🛹",
    onNavbar: true,
    authenticated: true,
    adminOnly: true,
  },
  {
    component: <AcademySettings />,
    title: "academy_settings",
    url: "/academy/settings",
    exact: true,
    icon: "⚙️",
    onNavbar: true,
    authenticated: true,
    adminOnly: true,
  },
];

export default routes;
