import { BoostcampSeries } from "./Types";

const data: BoostcampSeries[] = [
  {
    name: "Boostcamp Season 1",
    description: "foo",
    thumbnail: "https://via.placeholder.com/150",
    category: "Season",
    presented: "AcadArena",
    tags: ["Esports", "Education", "Profession"],
    slug: "foo",
    visibility: "public",
    episodes: [
      {
        seriesId: "foo",
        thumbnail: "",
        videoTitle: "foo",
        videoLink: "foo",
        videoDescription: "foo",
        typeformLink: "foo",
        episodeNumber: 1,
      },
    ],
  },
];

export default data;
