/**
 * An updated version for <AcadSEO />
 * that uses react-hook-form
 */

import {
  AcadEditDocSection,
  AcadImageButton,
  AcadTextField,
} from "@components";
import { SEOExtend } from "@models/seo";
import { Stack } from "@mui/material";
import {
  DeepMap,
  DeepPartial,
  FieldError,
  Path,
  UseFormRegister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

interface AcadSEOFormProps<T extends SEOExtend> {
  register: UseFormRegister<T>;
  errors: DeepMap<DeepPartial<T>, FieldError>;
  thumbnailUrl?: string;
}
function SEOForm<T extends SEOExtend>({
  errors,
  register,
  thumbnailUrl,
}: AcadSEOFormProps<T>) {
  const { t } = useTranslation("components");

  return (
    <AcadEditDocSection title={t("seo_config")}>
      <Stack className="flex-col-reverse md:flex-row" gap={2}>
        <AcadImageButton<T & SEOExtend>
          name="SEO.thumbnail"
          noImageText={t("upload_thumbnail")}
          path={thumbnailUrl}
          className="w-full h-36 md:w-48 bg-contain bg-center bg-no-repeat shadow-md rounded-md"
        />
        <Stack gap={2} sx={{ flex: 1 }}>
          <AcadTextField
            errors={errors}
            {...register("SEO.title" as Path<SEOExtend> as Path<T>)}
            label={t("directory_title")}
          />
          <AcadTextField
            errors={errors}
            {...register("SEO.description" as Path<SEOExtend> as Path<T>)}
            minRows={4}
            multiline
            maxRows={6}
            label={t("directory_description")}
          />
        </Stack>
      </Stack>
    </AcadEditDocSection>
  );
}

export default SEOForm;
