import LoadingIndicator from "@components/LoadingIndicator";
import { Alert } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSigninCheck } from "reactfire";

interface RoleGuardProps {
  allowedRoles: string[];
}
const RoleGuard: FC<RoleGuardProps> = ({ children, allowedRoles }) => {
  const { t } = useTranslation("components");
  const { data, status } = useSigninCheck({
    validateCustomClaims: (claims) => {
      const isAllowed = Object.keys(claims).some((role) =>
        allowedRoles.includes(role)
      );

      return {
        errors: {},
        hasRequiredClaims: isAllowed,
      };
    },
  });
  const regex = /^.+@acadarena\.com$/;
  const isAcadArenaStaf =
    regex.test(data?.user?.email || "") && data?.user?.emailVerified;

  if (status === "loading" && !data)
    return <LoadingIndicator>{t("checking_roles")}</LoadingIndicator>;
  return data.hasRequiredClaims || isAcadArenaStaf ? (
    <>{children}</>
  ) : (
    <Alert severity="info">{t("no_permission_page")}</Alert>
  );
};

export default RoleGuard;
