import { DialogProps } from "@framework/types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  useMediaQuery,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

interface AcadToggleSelectProps<T> extends DialogProps {
  itemIdKey: keyof T;
  options: T[];
  value?: T[];
  itemRenderer(item: T, index: number): React.ReactNode;
  title?: string;
  errorMsg?: string;
  noOptionsMsg?: string;
  loading?: boolean;
  error?: Error;
  onSelect?(selection: T[]): void;
  onChange?(selection: T[]): void;
}

function emptyFunction() {}

const AcadToggleSelect = <T,>({
  itemIdKey,
  options = [],
  value = [],
  onChange = emptyFunction,
  itemRenderer,
  open = false,
  onClose,
  onSelect = emptyFunction,
  loading = false,
  error,
  title = i18n.t("select", { ns: "components" }),
  errorMsg = i18n.t("unable_to_load_options", { ns: "components" }),
  noOptionsMsg = i18n.t("no_options", { ns: "components" }),
}: AcadToggleSelectProps<T>) => {
  const { t } = useTranslation("components");
  const mdUp = useMediaQuery("(min-width: 768px)");
  const [selection, setSelection] = useState<T[]>([]);

  useMemo(() => {
    setSelection(value);
  }, [value, setSelection]);

  const clickHandler = (item: T, index: number, isSelected: boolean) => {
    const arr = isSelected
      ? selection.filter((v) => item[itemIdKey] !== v[itemIdKey])
      : [...selection, item];
    onChange(arr);
    setSelection(arr);
  };

  const optionNodes = () => {
    return options.map((item, i) => {
      const isSelected: boolean = selection
        .map((s) => s[itemIdKey])
        .includes(item[itemIdKey]);
      return (
        <div
          className={`rounded-md ${
            isSelected ? "border-2 border-solid border-gray-500" : ""
          }`}
          key={"item-" + i}
          onClick={() => clickHandler(item, i, isSelected)}
        >
          {itemRenderer(item, i)}
        </div>
      );
    });
  };
  return (
    <Dialog
      fullScreen={!mdUp}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      classes={{ paper: "relative" }}
    >
      {loading && <LinearProgress className="absolute bottom-0 left-0" />}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {error && (
          <div>
            {errorMsg} {error.message}
          </div>
        )}
        {selection && (
          <div className="p-2 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
            {options ? (
              optionNodes()
            ) : (
              <div className="col-span-full text-center">{noOptionsMsg}</div>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => onSelect(selection)}>
          {t("select")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AcadToggleSelect;
