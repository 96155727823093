import { Paper, ButtonBase } from "@mui/material";
import { VFC } from "react";
import { useHistory } from "react-router-dom";
import { BoostcampSeries } from "@defs/Types";
import { useTranslation } from "react-i18next";

const PlaylistCard: VFC<{ playlist: BoostcampSeries }> = ({ playlist }) => {
  const history = useHistory();
  const { t } = useTranslation("boostcamp");
  return (
    <Paper
      className="shadow-sm flex flex-col items-start overflow-hidden rounded-md relative w-full"
      elevation={0}
      component={ButtonBase}
      onClick={() => history.push(`/boostcamp/${playlist.slug}`)}
    >
      <div
        className="h-40 bg-gray-200 w-full bg-cover bg-center"
        style={{ backgroundImage: `url("${playlist.thumbnail}")` }}
      ></div>
      <div className="p-4 flex items-center">
        <div className="flex flex-col pl-4">
          <div className="text-md leading-none text-left">{playlist.name}</div>
          <div className="text-xs leading-none text-left opacity-40">
            {t("episode", { count: playlist.episodes.length })}
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default PlaylistCard;
