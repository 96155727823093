import acadJoi from "@acad_joi";
import { SchemaMap } from "joi";

export type Redirect = { destination: string; permanent: boolean };

export class NextStaticProps {
  /**
   * An optional amount in seconds after which a page re-generation can occur.
   */
  revalidate: number = 60;
  /**
   * An optional boolean value to allow the page to return a 404 status and page.
   */
  notFound?: boolean = false;
  /**
   * an optional boolean value to allow the page to return an under maintenance page.
   */
  underMaintenance?: boolean = false;

  static schemaMap: SchemaMap<NextStaticProps> = {
    revalidate: acadJoi.number().min(1).label("Revalidate Duration"),
    notFound: acadJoi.boolean().optional().label("Page Hidden"),
    underMaintenance: acadJoi
      .boolean()
      .optional()
      .label("Page Under Maintenance"),
  };

  static schema = acadJoi.object({
    ...NextStaticProps.schemaMap,
  });
}

export class NextConfig {
  list: NextStaticProps = new NextStaticProps();
  page: NextStaticProps = new NextStaticProps();

  static schema = acadJoi.object({
    list: NextStaticProps.schema,
    page: NextStaticProps.schema,
  });
}

export class NextConfigExtend {
  nextConfig: NextConfig = new NextConfig();
}
