import { FormControl, InputLabel, Select, SelectProps } from "@mui/material";
import { ReactNode } from "react";
import { Control, Controller, Path } from "react-hook-form";

interface Props<T extends Record<string, any>, SelectInterface = any>
  extends SelectProps<SelectInterface> {
  control: Control<T>;
  name: Path<T>;
  children: ReactNode;
  formControlClassName?: string;
}

function AcadSelect<T, SelectInterface>({
  control,
  name,
  children,
  label,
  fullWidth,
  formControlClassName,
  ...props
}: Props<T, SelectInterface>) {
  return (
    <FormControl fullWidth={fullWidth} className={formControlClassName}>
      <InputLabel>{label}</InputLabel>
      <Controller
        {...{ control, name }}
        render={({ field }) => (
          <Select<SelectInterface>
            {...field}
            {...props}
            value={(props.value || field.value) as SelectInterface}
            label={label}
          >
            {children}
          </Select>
        )}
      />
    </FormControl>
  );
}

export default AcadSelect;
