import Sheet from "@components/Sheet";
import loadingMessage from "@defs/LoadingMessages";
import { Stack, Button, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useFunctions } from "reactfire";
import Swal from "sweetalert2";
import { useTournamentEdit } from "../../TournamentEdit";

const AbortCheckIn = () => {
  const fn = useFunctions();
  const snap = useTournamentEdit();

  const { t } = useTranslation("events");

  const abortCheckIn = () => {
    Swal.fire({
      title: t("abort_check_in_question"),
      showCloseButton: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: t("continue_to_abort"),
      confirmButtonColor: "red",
    }).then((res) => {
      if (res.isConfirmed) {
        if (snap) {
          const callable = httpsCallable<{ fireTourId: string }>(
            fn,
            "tournament-abortCheckIns"
          );

          const promise = callable({ fireTourId: snap.id });
          toast.promise(promise, {
            loading: loadingMessage(),
            error: (err) => err.message,
            success: t("done", { ns: "common" }),
          });
        }
      }
    });
  };

  return (
    <Sheet>
      <Stack gap={2} alignItems="flex-start">
        <Button
          variant="contained"
          color="error"
          className="flex-shrink-0"
          onClick={abortCheckIn}
        >
          {t("abort_check_in")}
        </Button>
        <Stack>
          <Typography className="text-sm">
            {t("this_will_clear_all_check_ins")}
          </Typography>
        </Stack>
      </Stack>
    </Sheet>
  );
};

export default AbortCheckIn;
