import Sheet from "@components/Sheet";
import { DialogProps } from "@defs/Types";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  TextField,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { useContext } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation, Trans } from "react-i18next";
import { useFunctions } from "reactfire";
import swal from "sweetalert2";
import { schoolsContext } from "..";
import AffectedFields from "./AffectedFields";

interface Props extends DialogProps {
  school: string;
  isMerge?: boolean;
}

interface RenameProps {
  prev: string;
  next: string;
}

function RenameDialog({ school, isMerge, open, onClose }: Props) {
  const [state, set] = useState<RenameProps>({ prev: school, next: "" });
  const schoolsCtx = useContext(schoolsContext);
  const schools = (schoolsCtx?.data()?.schools ?? [])
    .filter((s) => s !== "AcadArena")
    .sort((a, b) => (a < b ? -1 : 1));

  const functions = useFunctions();
  const { t } = useTranslation("schools");
  /**
   * Textfield Changes
   * @param event
   */
  function onChange(event: ChangeEvent<HTMLInputElement>) {
    set((state) => ({ ...state, [event.target.name]: event.target.value }));
  }

  /**
   * Double checks if the user really wants
   * to rename the school
   */
  function onClickSave() {
    swal
      .fire({
        title: t("are_you_sure_question"),
        text: t("double_check_everything"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#B33A3A",
      })
      .then((result) => {
        if (result.isConfirmed) {
          save();
          onExit();
        }
      });
  }

  /**
   * Save changes using a callable function
   */
  function save() {
    const renameFn = httpsCallable(functions, "school-rename");
    const promise = renameFn(state);

    toast.promise(promise, {
      loading: isMerge ? t("merging_school") : t("renaming_school"),
      error: (err) => err.message,
      success: isMerge
        ? t("successfully_merged_prev_to_next", {
            prev: state.prev,
            next: state.next,
          })
        : t("successfully_renamed_prev_to_next", {
            prev: state.prev,
            next: state.next,
          }),
    });
  }

  /**
   * Closes the dialog and also cleans useState hooks
   */
  function onExit() {
    set({ prev: "", next: "" });
    onClose();
  }

  return (
    <Dialog open={open} TransitionComponent={Grow} maxWidth="sm" fullWidth>
      <DialogTitle>
        {isMerge ? t("merge_school") : t("rename_school")}
      </DialogTitle>
      <DialogContent>
        <div>
          {isMerge ? (
            <Merge {...{ school, schools, state, onChange, set }} />
          ) : (
            <Rename {...{ school, schools, state, onChange }} />
          )}
          <AffectedFields school={state.prev} path="users" fieldName="school" />
          <AffectedFields
            school={state.prev}
            path="orgs"
            fieldName="schoolName"
          />
          <AffectedFields
            school={state.prev}
            path="tournaments"
            fieldName="schoolAllowed"
            operationString="array-contains"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          {t("cancel", { ns: "common" })}
        </Button>
        <Button
          onClick={onClickSave}
          variant="contained"
          disabled={
            !state.prev ||
            !state.next ||
            (!isMerge && schools.includes(state.next))
          }
        >
          {isMerge ? t("merge") : t("rename")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface InputProps {
  school: string;
  schools: string[];
  state: RenameProps;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  set?: Dispatch<SetStateAction<RenameProps>>;
}

/**
 * Rename Compoenent, the state.prev is
 * set by default and cannot be changed
 * @param InputProps
 * @returns `JSX.Element`
 */
function Rename({ onChange, school, schools, state }: InputProps) {
  const error = schools.includes(state.next);

  const { t } = useTranslation("schools");

  return (
    <div className="flex flex-col p-4 space-y-2">
      <Trans
        i18nKey="schools:rename_from_school_to"
        components={{
          sheet: <Sheet className="m-0 bg-gray-50" />,
          textXs: <div className="text-xs text-gray-400" />,
          textSm: <div className="text-sm" />,
          textField: (
            <TextField
              value={state.next}
              name="next"
              onChange={onChange}
              size="small"
              fullWidth
              error={error}
              helperText={error ? t("school_already_exists") : ""}
            />
          ),
        }}
        values={{
          school: school,
        }}
      />
    </div>
  );
}

/**
 * Merge Compoenent, the state.prev is
 * can be changed.
 *
 * Merging basically uses rename function
 * it just renames it to a selected school
 * instead of typing a school name
 *
 * @param InputProps
 * @returns `JSX.Element`
 */
function Merge({ schools, state, set = () => {} }: InputProps) {
  return (
    <div className="flex flex-col p-0 sm:p-4 space-y-2">
      <Trans
        i18nKey="schools:merge_from_school_to"
        components={{
          sheetFlex: <Sheet className="m-0 bg-gray-50 w-full flex flex-col" />,
          sheet: <Sheet className="m-0 bg-gray-50" />,
          text: <div className="text-xs text-gray-400" />,
          prev: (
            <Autocomplete
              fullWidth
              autoComplete
              autoSelect
              value={state.prev}
              onChange={(e, val) => set((state) => ({ ...state, prev: val }))}
              disableClearable
              options={schools.filter((s) => s !== state.next)}
              renderInput={({ size, ...params }) => (
                <TextField
                  {...params}
                  variant="outlined"
                  required
                  fullWidth
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          ),
          next: (
            <Autocomplete
              fullWidth
              autoComplete
              autoSelect
              value={state.next}
              onChange={(e, val) => set((state) => ({ ...state, next: val }))}
              disableClearable
              options={schools.filter((s) => s !== state.prev)}
              renderInput={({ size, ...params }) => (
                <TextField
                  {...params}
                  variant="outlined"
                  required
                  fullWidth
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          ),
        }}
      />
    </div>
  );
}

export default RenameDialog;
