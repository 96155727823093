import { ArrowLeft } from "@mui/icons-material";
import { Alert, Button, Skeleton } from "@mui/material";
import { DocumentSnapshot } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useUser } from "reactfire";

interface AcadEditDocContainerProps<T> {
  children?: React.ReactNode;
  snapshot: DocumentSnapshot<T>;
  error?: Error;
  loading: boolean;
  onBackBtn?(): void;
  doesNotExistText?: string;
  backBtnText?: string;
}

export default function AcadEditDocContainer<T>({
  children,
  snapshot,
  error,
  loading,
  onBackBtn,
  doesNotExistText = "Document does not exist",
  backBtnText = "Back",
}: AcadEditDocContainerProps<T>) {
  const { t } = useTranslation("common");
  const { data: authUser } = useUser();
  const history = useHistory();

  return (
    <div className="md:mx-4 my-4">
      <div className="mb-2">
        <Button
          startIcon={<ArrowLeft />}
          variant="outlined"
          onClick={() => (onBackBtn ? onBackBtn() : history.goBack())}
        >
          {backBtnText}
        </Button>
      </div>
      {!authUser ? (
        <div className="flex flex-col justify-center items-center">
          <div className="">{t("sign_in_required")}</div>
        </div>
      ) : loading ? (
        <div className="flex flex-col">
          <Skeleton
            variant="rectangular"
            className="w-full h-52 rounded-md"
          ></Skeleton>
        </div>
      ) : error ? (
        <Alert severity="warning">{error?.message}</Alert>
      ) : !snapshot?.exists() ? (
        <Alert severity="warning">{doesNotExistText}</Alert>
      ) : (
        <div className="flex flex-col">{children}</div>
      )}
    </div>
  );
}
