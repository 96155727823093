import { TourSnap } from "@defs/Tournament.type";
import { Avatar, Card, CardActionArea, CardHeader } from "@mui/material";
import { useHistory } from "react-router";

interface TournamentCardProps {
  doc: TourSnap;
}

const TournamentCard = ({ doc }: TournamentCardProps) => {
  const history = useHistory();

  const onClick = () => {
    history.push(`/events/tournaments/${doc.id}`);
  };
  return (
    <Card elevation={0}>
      <CardActionArea onClick={onClick}>
        <CardHeader
          avatar={
            <Avatar src={doc.data().logo}>
              {doc.data().tournament.name[0]}
            </Avatar>
          }
          title={doc.data().tournament.name}
          titleTypographyProps={{
            fontSize: 16,
            className: "leading-none truncate",
          }}
          sx={{ p: 2 }}
          subheader={doc.data().orgData?.name}
          subheaderTypographyProps={{
            fontSize: 12,
            className: "leading-1 truncate",
          }}
        />
      </CardActionArea>
    </Card>
  );
};

export default TournamentCard;
