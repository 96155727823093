import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { FormEvent } from "react";
import { ChangeEvent, FC, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import slugify from "slugify";
import { DialogProps, BoostcampSeries } from "@defs/Types";
import asyncHook from "@lib/AsyncHook";
import { useFirestore } from "reactfire";
import { useTranslation } from "react-i18next";

const BoostcampCreate: FC<DialogProps> = ({ open, onClose }) => {
  const db = useFirestore();
  const history = useHistory();
  const md = useMediaQuery("(min-width: 768px)");
  const [form, setForm] = useState<BoostcampSeries>({
    thumbnail: "",
    name: "",
    description: "",
    tags: [],
    category: "",
    presented: "",
    episodes: [],
    slug: "",
    visibility: "draft",
  });

  const { t } = useTranslation("boostcamp");

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!form.slug) return toast.error(t("slug_is_required"));

    const ref = doc(db, `boostcamp/${form.slug}`);
    const [snap, error] = await asyncHook(getDoc(ref));
    if (error) return toast.error(error.message);
    if (snap?.exists())
      return toast.error(t("slug_already_exists", { slug: form.slug }));

    toast.promise(setDoc(ref, { ...form, slug: slugify(form.slug, "-") }), {
      loading: t("creating_boostcamp_playlist"),
      success: () => {
        history.push(`/boostcamp/${form.slug}`);
        return t("successfully_created_a_new_playlist");
      },
      error: (err) => err.message,
    });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <Dialog
      TransitionComponent={Grow}
      open={open}
      onClose={onClose}
      fullScreen={!md}
      maxWidth="sm"
      fullWidth
      scroll="body"
      classes={{ paper: "relative" }}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle className="">{t("create_new_playlist")}</DialogTitle>
        <DialogContent className="overflow-y-visible">
          <div className="grid grid-cols-12 gap-4">
            <h2 className="text-lg mt-4 mb-2 col-span-full">
              {t("playlist_information")}
            </h2>
            <TextField
              variant="outlined"
              label={t("playlist_name")}
              className="col-span-full"
              value={form.name}
              name="name"
              onChange={onChange}
              required
            />
            <TextField
              variant="outlined"
              size="small"
              multiline
              minRows={4}
              maxRows={10}
              label={t("playlist_description")}
              className="col-span-full"
              value={form.description}
              name="description"
              onChange={onChange}
              required
            />
            <TextField
              variant="outlined"
              size="small"
              label={t("playlist_category")}
              className="col-span-full"
              value={form.category}
              name="category"
              onChange={onChange}
            />
            <TextField
              variant="outlined"
              size="small"
              label={t("presented_by")}
              className="col-span-full"
              value={form.presented}
              name="presented"
              onChange={onChange}
              required
            />
            <TextField
              variant="outlined"
              size="small"
              label={t("slug")}
              className="col-span-full"
              value={form.slug}
              name="slug"
              onChange={onChange}
              required
              helperText={t("slug_helper", {
                slug: slugify(form.slug, {
                  replacement: "-",
                }),
              })}
            />

            <Autocomplete
              multiple
              options={["esports", "org", "team", "management", "production"]}
              freeSolo
              className="col-span-full"
              value={form.tags ?? []}
              onChange={(event, value) => setForm({ ...form, tags: value })}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label={t("tags")} />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            {t("save", { ns: "common" })}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BoostcampCreate;
