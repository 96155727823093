import Iconify from "@components/Iconify";
import {
  Avatar,
  Divider,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { MouseEvent, useState } from "react";

interface Props {
  checkIn: VoidFunction;
  unCheckIn: VoidFunction;
  username: string;
  avatar?: string;
  isCheckedIn: boolean;
}

const size = {
  height: 20,
  width: 20,
};
const RoundSeriesPlayer = ({
  avatar,
  checkIn,
  isCheckedIn,
  unCheckIn,
  username,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = (e: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(e.currentTarget);
  const close = () => setAnchorEl(null);

  const doAction = (fn: VoidFunction) => () => {
    fn();
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip
        arrow
        placement="top"
        title={`${username} ${isCheckedIn ? "✅" : "❎"}`}
      >
        <IconButton sx={{ ...size }} onClick={open}>
          <Avatar
            src={avatar}
            sx={{
              ...size,
              opacity: isCheckedIn ? 1 : 0.5,
            }}
          />
        </IconButton>
      </Tooltip>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={close}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            px: 1,
            py: 0.5,
            border: "1px solid rgba(0,0,0,.2)",
          },
        }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <Stack
          gap={1}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Typography variant="caption">
            {isCheckedIn ? "✅" : "❎"} {username}
          </Typography>
          <Stack direction="row" gap={0.5}>
            <IconButton
              size="small"
              color="error"
              edge="end"
              sx={{ p: 0 }}
              onClick={doAction(unCheckIn)}
            >
              <Iconify icon="eva:close-circle-fill" fontSize="inherit" />
            </IconButton>
            <IconButton
              size="small"
              color="inherit"
              edge="end"
              sx={{ p: 0 }}
              onClick={doAction(checkIn)}
            >
              <Iconify icon="eva:checkmark-circle-2-fill" fontSize="inherit" />
            </IconButton>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default RoundSeriesPlayer;
