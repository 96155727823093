import Sheet from "@components/Sheet";
import { Button, Chip, Stack } from "@mui/material";
import { useTournamentEdit } from "../TournamentEdit";
import Actions from "./actions";
import ParticipantList from "../components/ParticipantList";
import Swal from "sweetalert2";
import { useFunctions } from "reactfire";
import { httpsCallable } from "firebase/functions";
import toast from "react-hot-toast";
import loadingMessage from "@defs/LoadingMessages";
import { useTranslation } from "react-i18next";

// TODO: only query accepted participants
const TournamenCheckIn = () => {
  const fn = useFunctions();
  const snap = useTournamentEdit();

  const lookingForCheckIns = !!snap?.data()?.lookingForCheckIns;

  const { t } = useTranslation("events");

  const startCheckIn = () => {
    Swal.fire({
      title: t("start_check_in_question"),
      text: t("this_will_notify_all_participants_in_tournament"),
      showConfirmButton: true,
      confirmButtonText: t("start_check_in"),
    }).then((res) => {
      if (res.isConfirmed) {
        const callable = httpsCallable(fn, "tournament-enableCheckIn");
        const promise = callable({ fireTourId: snap?.id ?? "" });
        toast.promise(promise, {
          loading: loadingMessage(),
          error: (err) => err.message || t("something_went_wrong"),
          success: t("check_in_started"),
        });
      }
    });
  };
  const closeCheckin = () => {
    Swal.fire({
      title: t("close_check_in_question"),
      showConfirmButton: true,
      confirmButtonText: t("continue"),
      confirmButtonColor: "error",
    }).then((res) => {
      if (res.isConfirmed) {
        const callable = httpsCallable(fn, "tournament-closeCheckIn");
        const promise = callable({ fireTourId: snap?.id ?? "" });
        toast.promise(promise, {
          loading: loadingMessage(),
          error: (err) => err.message || t("something_went_wrong"),
          success: t("check_in_closed"),
        });
      }
    });
  };

  return (
    <Stack>
      <Sheet>
        <Stack gap={2}>
          <Stack alignItems="center" direction="row" gap={1}>
            {!lookingForCheckIns ? (
              <Button
                variant="contained"
                color="inherit"
                onClick={startCheckIn}
              >
                {t("start_check_in")}
              </Button>
            ) : (
              <Button variant="contained" color="error" onClick={closeCheckin}>
                {t("close_check_in")}
              </Button>
            )}

            {lookingForCheckIns && (
              <Chip
                label="Now looking for check-ins"
                color="warning"
                className="rounded-md"
              />
            )}
          </Stack>
          <Stack gap={1}>
            {snap && <ParticipantList />}
            <Actions />
          </Stack>
        </Stack>
      </Sheet>
    </Stack>
  );
};

export default TournamenCheckIn;
