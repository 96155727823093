import Label from "@components/Label";
import Sheet from "@components/Sheet";
import { Series } from "@models/series";
import { Edit } from "@mui/icons-material";
import {
  Divider,
  Grid,
  Stack,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useState, VFC } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useFunctions } from "reactfire";
import Swal from "sweetalert2";
import { useTournamentEdit } from "../TournamentEdit";
import RoundInfoDialog from "./RoundInfoDialog";
import RoundSeries from "./RoundSeries";

interface Props {
  seriesList: Series[];
}
const Round: VFC<Props> = ({ seriesList }) => {
  const fn = useFunctions();
  const round = seriesList[0].round ?? 0;
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  const tour = useTournamentEdit();
  const roundLabel =
    tour?.data()?.roundData?.[round]?.label ?? `Round ${round}`;
  const bestOf = tour?.data()?.roundData?.[round]?.bestOf ?? 1;

  const { t } = useTranslation("events");

  const openCheckIn = async (seriesId: string) => {
    const callable = httpsCallable<{
      tournamentId: string;
      seriesId: string;
      state: boolean;
    }>(fn, "tournamentSeries-toggleSeriesCheckIn");
    return callable({
      tournamentId: tour?.id ?? "",
      seriesId: `${seriesId}`,
      state: true,
    });
  };

  const beginPlayerCheckIn = () => {
    Swal.fire({
      title: t("Open all match check-ins?"),
      text: t("this_will_forcibly_open_all_player_check_ins_for_round_label", {
        roundLabel: roundLabel,
      }),
      icon: "warning",
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        const seriesIds = seriesList.map((s) => `${s.id}`);
        const promises = seriesIds.map((seriesId) => openCheckIn(seriesId));
        const promise = Promise.allSettled(promises);
        toast.promise(promise, {
          loading: t("opening_all_check_ins"),
          error: (err) => err.message || t("unable_to_open_check_ins"),
          success: t("done", { ns: "common" }),
        });
      }
    });
  };

  return (
    <Sheet className="flex flex-col mb-4">
      <Stack gap={2}>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="h6">{roundLabel}</Typography>
          <Label>{t("best_of_best_of", { bestOf: bestOf })}</Label>
          <IconButton onClick={onOpen} size="small">
            <Edit fontSize="inherit" />
          </IconButton>
          <Button variant="outlined" onClick={beginPlayerCheckIn}>
            {t("begin_player_check_in")}
          </Button>
        </Stack>

        <Divider />

        <Grid container spacing={2}>
          {seriesList.map((m) => (
            <Grid item xs={12} sm={6} md={4} key={m.id}>
              <RoundSeries series={m} />
            </Grid>
          ))}
        </Grid>
      </Stack>

      <RoundInfoDialog
        {...{ open, onClose }}
        roundNumber={round}
        roundLabel={roundLabel}
        bestOf={bestOf}
      />
    </Sheet>
  );
};

export default Round;
