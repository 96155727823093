import { Button, ButtonBase, TextField, Skeleton, Alert } from "@mui/material";
import { ArrowLeft } from "@mui/icons-material";
import { ChangeEvent, FormEvent, useContext, useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import { PartnerProps } from "@defs/Types";
import asyncHook from "@lib/AsyncHook";
import { deleteDoc, doc, DocumentReference, setDoc } from "firebase/firestore";
import swal from "sweetalert2";
import { useMediaQuery } from "@mui/material";
import { userContext } from "@lib/AuthContext";
import loadingMessage from "@defs/LoadingMessages";
import { onFileSelect, Files } from "@lib/file";
import { useFirestore, useFirestoreDoc } from "reactfire";
import { useTranslation } from "react-i18next";

type Variant = "standard" | "filled" | "outlined";

const PartnerPage = () => {
  const db = useFirestore();
  const { partnerId } = useParams<{ partnerId: string }>();

  const p = `partners/${partnerId}`;
  const r = doc(db, p) as DocumentReference<PartnerProps>;
  const { status, data: snap, error } = useFirestoreDoc(r);
  const loading = status === "loading";

  const history = useHistory();
  const partner = snap?.data();
  const [variant, setVariant] = useState<Variant>(
    (localStorage.getItem("fieldVariant") as Variant) || "outlined"
  );
  const [form, setForm] = useState<PartnerProps>({
    logo: "",
    partnerName: "",
    visibility: "draft",
    partnerDescription: "",
    benefits: "",
    profile_photo: "",
    cover_photo: "",
    description: "",
    description_thumbnail: "",
  });
  const [files, setFiles] = useState<Files<PartnerProps>>({});
  const { user, isAdmin: admin } = useContext(userContext);

  const smUp = useMediaQuery("(min-width: 640px)");

  useEffect(() => {
    setVariant((localStorage.getItem("fieldVariant") as Variant) || "outlined");
  }, []);

  useEffect(() => {
    if (!snap?.exists()) return;
    setForm(snap.data());
  }, [snap]);

  const classes = {
    orgInfo: "text-xl text-left col-span-full",
    shortname: "col-span-12 md:col-span-8",
    shortcode: "col-span-12 md:col-span-4",
    isAlliance: "col-span-full sm:col-span-4",
    schoolInfo: "text-xl text-left col-span-full mt-6",
    schoolName: "col-span-full",
    schoolCampus: "col-span-12 md:col-span-8",
    schoolShortcode: "col-span-12 md:col-span-4",
    admins: "col-span-full md:col-span-6",
    moderators: "col-span-full md:col-span-6 mt-4 md:mt-0",
    profileInfo: "text-xl text-left col-span-full mt-8",
    description_thumbnail:
      "bg-gray-200 col-span-full md:col-span-4 h-52 bg-cover bg-center",
    description: "col-span-full md:col-span-8 h-full",
    contact_email: "col-span-full md:col-span-6 ",
    visibility: "text-xl text-left col-span-full mt-6",
  };

  const { t } = useTranslation("partners");

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const ref = doc(
      db,
      `partners/${partnerId}`
    ) as DocumentReference<PartnerProps>;
    const promise = setDoc(ref, form, { merge: true });
    toast.promise(promise, {
      loading: loadingMessage(),
      error: (err) => err.message,
      success: t("saved_successfully", { ns: "common" }),
    });
  };

  const deletePartner = () => {
    swal
      .fire({
        title: t("delete_partner_question"),
        text: t("this_action_is_irreversible"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#B33A3A",
      })
      .then(async (resp) => {
        if (resp.isConfirmed) {
          const ref = doc(
            db,
            `partners/${partnerId}`
          ) as DocumentReference<PartnerProps>;
          const [, err] = await asyncHook(deleteDoc(ref));
          if (err) {
            toast.error(t("unable_to_delete_partner"));
            return;
          }
          history.push("/partner");
        }
      });
  };

  const handleText = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name as keyof PartnerProps]: e.target.value });
  };

  const selectVariant = (v: Variant) => () => {
    localStorage.setItem("fieldVariant", v ?? "");
    setVariant(v);
  };

  return !user ? (
    <div className="flex flex-col justify-center items-center">
      <div className="">{t("sign_in_required")}</div>
    </div>
  ) : loading ? (
    <div className="flex flex-col">
      <Skeleton
        variant="rectangular"
        className="w-full h-52 rounded-md"
      ></Skeleton>
    </div>
  ) : error ? (
    <Alert severity="warning">{error?.message}</Alert>
  ) : !snap?.exists() ? (
    <Alert severity="warning">{t("this_partner_does_not_exist")}</Alert>
  ) : (
    <div className="flex flex-col">
      <div className="flex  mb-2 ">
        <Button
          startIcon={<ArrowLeft />}
          variant="outlined"
          onClick={() => history.push("/partner")}
        >
          {t("go_back_to_partners")}
        </Button>

        <div className="hidden sm:flex flex-1  justify-end space-x-3 align-center">
          <Button
            size="small"
            variant="contained"
            color={variant === "outlined" ? "primary" : undefined}
            onClick={selectVariant("outlined")}
          >
            {t("outlined")}
          </Button>
          <Button
            size="small"
            variant="contained"
            color={variant === "filled" ? "primary" : undefined}
            onClick={selectVariant("filled")}
          >
            {t("filled")}
          </Button>
          <Button
            size="small"
            variant="contained"
            color={variant === "standard" ? "primary" : undefined}
            onClick={selectVariant("standard")}
          >
            {t("underlined")}
          </Button>
        </div>
      </div>

      {/* Cover Photo */}
      <ButtonBase
        className="w-full h-52 bg-gray-200 bg-cover bg-center rounded-md"
        component="label"
        style={{
          backgroundImage: `url("${
            files.cover_photo?.path || partner?.cover_photo
          }")`,
        }}
      >
        <input
          type="file"
          hidden
          name="cover_photo"
          onChange={onFileSelect(setFiles, setForm)}
          accept="image/*"
        />
        {Boolean(files.cover_photo)
          ? ""
          : partner?.cover_photo
          ? ""
          : t("click_here_to_add_cover_photo")}
      </ButtonBase>

      {/* Profile Photo */}

      <ButtonBase
        className="shadow-md h-36 w-36 bg-white rounded-md self-center sm:self-start sm:ml-4 -mt-14  bg-cover bg-center flex justify-center items-center"
        style={{
          backgroundImage: `url("${files.logo?.path || partner?.logo}")`,
        }}
        component="label"
      >
        <input
          type="file"
          hidden
          name="logo"
          onChange={onFileSelect(setFiles, setForm)}
          accept="image/*"
        />

        {Boolean(files.logo) ? "" : partner?.logo ? "" : t("add_logo")}
      </ButtonBase>

      {/* Fields */}
      <form
        className="grid grid-cols-12 mx-0 md:mx-4 mt-8 gap-4"
        onSubmit={onSubmit}
      >
        <div className={classes.orgInfo}>{t("partner_info")}</div>
        {/* Partner Name */}
        <TextField
          name="partnerName"
          label={t("partner_name")}
          className={"col-span-12"}
          value={form?.partnerName}
          required
          onChange={handleText}
          variant={smUp ? variant : "outlined"}
        />
        {/* Description */}
        <TextField
          name="partnerDescription"
          label={t("partner_description")}
          className={"col-span-12"}
          value={form?.partnerDescription}
          required
          onChange={handleText}
          variant={smUp ? variant : "outlined"}
        />
        {/* Partner Benefits */}
        <TextField
          name="benefits"
          label={t("benefits")}
          className={"col-span-12"}
          value={form?.benefits}
          required
          onChange={handleText}
          variant={smUp ? variant : "outlined"}
        />

        <div className={classes.profileInfo}>{t("seo_info")}</div>

        {/**
         * Description thumbnail
         * This will be used as open graph tag for thumbnail
         * for Search Engine Optimization
         */}
        <ButtonBase
          component="label"
          className={classes.description_thumbnail}
          style={{
            backgroundImage: `url("${
              files.description_thumbnail?.path || form?.description_thumbnail
            }")`,
          }}
        >
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={onFileSelect(setFiles, setForm)}
            name="description_thumbnail"
          />
          {Boolean(files.description_thumbnail)
            ? ""
            : form?.description_thumbnail
            ? ""
            : t("add_thumbnail")}
        </ButtonBase>

        {/**
         * Description
         * This will be used as open graph tag for description
         * for Search Engine Optimization
         */}
        <TextField
          className={classes.description}
          multiline
          rows={9}
          maxRows={10}
          value={form.description}
          name="description"
          onChange={handleText}
          variant={smUp ? variant : "outlined"}
          label={t("description", { ns: "common" })}
        />
        <div className="col-span-12 flex justify-end mt-4">
          <Button
            variant="outlined"
            className="mr-4"
            color="secondary"
            onClick={deletePartner}
            disabled={!admin}
          >
            {t("delete", { ns: "common" })}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={form === snap?.data()}
          >
            {t("save", { ns: "common" })}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PartnerPage;
