import { useFileUpload } from "@hooks";
import { FC } from "react";
import fileUploadContext from "./file_upload_context";

/**
 * FileUploadContext allows to queue files and upload files from subcomponents easily.
 * Simply call `queueFile` to add a file once a file is chosen, and `uploadFiles`
 * to upload queued files. Example use is in `AcadImageButton`. On select of file,
 * it simply queues the file. It will only upload the file once `uploadFile` (or `uploadFileAndSet`) is called
 * by parent components like `ScholarshipEditForm`.
 *
 * We do not want to upload the files provided by the user until the click "save".
 * @param param0
 * @returns
 */
const FileUploadProvider: FC = ({ children }) => {
  const data = useFileUpload();

  return (
    <fileUploadContext.Provider value={data}>
      {children}
    </fileUploadContext.Provider>
  );
};

export default FileUploadProvider;
