import { doc, DocumentReference } from "@firebase/firestore";
import { Button, DialogActions, DialogContent, TextField } from "@mui/material";
import { DialogProps } from "@framework/types";
import { useCreateDocument, useForm } from "@hooks";
import { AcadModal } from "@components";
import { useFirestore } from "reactfire";
import ScholarshipEligibility from "@models/scholarship_eligibility";
import { useTranslation } from "react-i18next";

const EligibilityCreate = ({ open, onClose }: DialogProps) => {
  const db = useFirestore();
  const { t } = useTranslation("scholarships");
  const { form, updateFormField } = useForm<ScholarshipEligibility>(
    new ScholarshipEligibility()
  );
  const { loading, createDocument } = useCreateDocument<ScholarshipEligibility>(
    {
      onCreated: () => {
        if (onClose) {
          onClose();
        }
        return t("successfully_created_new_eligibility");
      },
      docExistsMsg: t("eligibility_with_id_already_exists", { id: form.id }),
    }
  );

  const createDoc = () => {
    if (!form.id) {
      return;
    }
    createDocument(
      form,
      doc(
        db,
        ScholarshipEligibility.PATH,
        form.id
      ) as DocumentReference<ScholarshipEligibility>
    );
  };

  return (
    <AcadModal open={open} onClose={onClose} loading={loading}>
      <DialogContent className="overflow-y-visible">
        <div className="grid grid-cols-12 gap-4">
          <div className="text-lg mt-4 mb-2 col-span-full">
            {t("eligibility_info")}
          </div>
          <TextField
            variant="outlined"
            size="medium"
            label={t("id")}
            className="col-span-full"
            value={form.id}
            name="id"
            onChange={updateFormField}
            helperText={t("eligibility_id_helper")}
            required
          />
          <TextField
            variant="outlined"
            size="medium"
            label={t("eligibility_display_name")}
            className="col-span-full"
            value={form.name}
            name="name"
            onChange={updateFormField}
            helperText={t("eligibility_display_name_helper")}
            required
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={createDoc}>
          {t("save", { ns: "common" })}
        </Button>
      </DialogActions>
    </AcadModal>
  );
};

export default EligibilityCreate;
