import {
  AcadDateTimePicker,
  AcadEditDocSection,
  AcadTextField,
} from "@components";
import { DocumentReference } from "@firebase/firestore";
import { joiResolver } from "@hookform/resolvers/joi";
import { useDeleteDocument } from "@hooks";
import { copyToClipboard } from "@lib/clipboard";
import ReferralCode from "@models/referral_code";
import { Button, Typography } from "@mui/material";
import { FormEvent } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useTranslation, Trans } from "react-i18next";
interface ReferralCodeEditFormProps {
  docRef: DocumentReference<ReferralCode>;
  formData: ReferralCode;
  onSubmit(formData: ReferralCode): void;
}

const ReferralCodeEditForm = ({
  docRef,
  onSubmit,
  formData,
}: ReferralCodeEditFormProps) => {
  const history = useHistory();
  const { t } = useTranslation("users");

  const { deleteDocument } = useDeleteDocument<ReferralCode>({
    docRef,
    titleMsg: t("delete_referral_code_question"),
    onDelete: () => {
      history.push("/users/referrals");
    },
  });

  const {
    getValues,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ReferralCode>({
    defaultValues: formData,
    resolver: joiResolver(ReferralCode.schema),
  });

  const innerOnSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit)(e);
  };

  return (
    <form className="grid grid-cols-12 gap-4" onSubmit={innerOnSubmit}>
      <AcadEditDocSection
        title={t("referral_info")}
        className="col-span-full grid grid-cols-12 gap-4"
      >
        <div className="col-span-12 sm:col-span-6 flex flex-row">
          <Trans
            i18nKey="users:referral_code_code"
            components={{
              subtitle2: (
                <Typography
                  variant="subtitle2"
                  className="mr-1 flex flex-col justify-center"
                />
              ),
              body1: (
                <Typography
                  variant="body1"
                  className="mr-1 flex flex-col justify-center"
                />
              ),
              button: (
                <Button
                  variant="text"
                  onClick={() => copyToClipboard(formData.code)}
                />
              ),
            }}
            values={{
              code: formData.code,
            }}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 flex flex-row">
          <Trans
            i18nKey="users:number_of_referrals_count"
            components={{
              subtitle2: (
                <Typography
                  variant="subtitle2"
                  className="mr-1 flex flex-col justify-center"
                />
              ),
              body: (
                <Typography
                  variant="body1"
                  className="mr-1 flex flex-col justify-center"
                />
              ),
            }}
            values={{
              referralCount: formData.count,
            }}
          />
        </div>
        <AcadTextField
          label={t("referral_code_owner")}
          className="col-span-12 sm:col-span-6"
          {...register("owner")}
          required
          errors={errors}
        />
        <AcadDateTimePicker
          label={t("end_date")}
          controllerProps={{ name: "endDate", control }}
          renderInput={(params) => (
            <AcadTextField
              {...params}
              name="endDate"
              className="col-span-12 sm:col-span-6"
              errors={errors}
            />
          )}
        />
        <AcadTextField
          multiline
          rows={3}
          variant="outlined"
          size="medium"
          label={t("purpose")}
          className="col-span-full"
          {...register("purpose")}
          helperText={t("purpose_helpe")}
          errors={errors}
        />
      </AcadEditDocSection>
      <div className="col-span-12 flex justify-end">
        <Button
          variant="outlined"
          className="mr-4"
          color="secondary"
          onClick={() => deleteDocument(getValues())}
        >
          {t("delete", { ns: "common" })}
        </Button>
        <Button type="submit" variant="contained" color="primary">
          {t("save", { ns: "common" })}
        </Button>
      </div>
    </form>
  );
};

export default ReferralCodeEditForm;
