import { useRegion } from "@hooks/use_region";
import { Stack, Card, CardContent, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import ExportPlayers from "./ExportPlayers";
import { ExportUsers, ExportUsersByFilter } from "./ExportUsers";

const AdminScripts = () => {
  const { t } = useTranslation("admin");
  const {isBr} = useRegion()
  
  return (
    <Stack gap={4}>
      <Segment title={t("tournament")}>
        <Stack>
          <ExportPlayers />
        </Stack>
      </Segment>
    {isBr &&
      <Segment title={t("users")}>
        <Stack>
          <ExportUsers />
        </Stack>
      </Segment>
    }
      <Segment title={t("export_users_by_filter")}>
        <Stack className="mt-4">
          <ExportUsersByFilter />
        </Stack>
      </Segment>
    </Stack>
  );
};

interface SegmentProps {
  title: string;
}
const Segment: FC<SegmentProps> = ({ title, children }) => {
  return (
    <Card>
      <CardContent>
        <Stack>
          <Typography variant="h6">{title}</Typography>
          {children}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default AdminScripts;
