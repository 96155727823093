import loadingMessage from "@defs/LoadingMessages";
import { DialogProps } from "@defs/Types";
import { UserData } from "@defs/User";
import {
  arrayUnion,
  doc,
  DocumentReference,
  writeBatch,
} from "@firebase/firestore";
import { AcadModal } from "@components";
import {
  Autocomplete,
  Button,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useFirestore } from "reactfire";
import swal from "sweetalert2";
import { ReadOnlySchools } from "../SchoolList";
import { useSchoolListPending } from "./context";
import { useTranslation } from "react-i18next";

interface IPendingSchoolDialog extends DialogProps {
  pendingSchoolName?: string;
  uid: string;
}

const PendingSchoolDialog = ({
  open,
  onClose,
  pendingSchoolName,
  uid,
}: IPendingSchoolDialog) => {
  const [school, setSchool] = useState(pendingSchoolName ?? "");
  const { schoolList = [], data: pendingSchoolQuery } = useSchoolListPending();
  const db = useFirestore();

  const { t } = useTranslation("schools");

  useEffect(() => {
    setSchool(pendingSchoolName ?? "");
  }, [pendingSchoolName]);

  const getSimilarPendingSchools = () => {
    const pendingSchools =
      pendingSchoolQuery?.docs.map((doc) => doc.data()) ?? [];
    const similarSchools = pendingSchools.filter(
      (s) =>
        s.pendingSchoolName?.toLowerCase() ===
          (pendingSchoolName ?? "").toLowerCase() && uid !== s.uid
    );
    return similarSchools;
  };

  const getSimilarActiveSchools = () => {
    return schoolList.filter(
      (s) => s.toLowerCase() === (pendingSchoolName ?? "").toLowerCase()
    );
  };

  const accept = () => {
    swal
      .fire({
        title: "Accept pending school?",
        text: t("school_double_check_spelling", {
          school: school,
        }),
        showCancelButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const userRef = doc(
            db,
            `users/${uid}`
          ) as DocumentReference<UserData>;
          const schoolsRef = doc(
            db,
            `read_only/schools`
          ) as DocumentReference<ReadOnlySchools>;

          const batch = writeBatch(db);

          batch.update(userRef, {
            school: school,
            pendingSchoolName: "",
          });

          batch.update(schoolsRef, {
            schools: arrayUnion(school),
          });

          const promise = batch.commit();
          toast.promise(promise, {
            loading: loadingMessage(),
            error: (err) => err.message,
            success: t("done", { ns: "common" }),
          });
          onClose();
        }
      });
  };
  const acceptAll = () => {
    swal
      .fire({
        title: t("accept_pending_schools_including_similar_question"),
        text: t("school_double_check_spelling", {
          school: school,
        }),
        showCancelButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const userPath = `users/${uid}`;
          const schoolsPath = `read_only/schools`;
          const userRef = doc(db, userPath) as DocumentReference<UserData>;
          const schoolsRef = doc(
            db,
            schoolsPath
          ) as DocumentReference<ReadOnlySchools>;

          const batch = writeBatch(db);

          const similar = getSimilarPendingSchools();

          batch.update(userRef, {
            school: school,
            pendingSchoolName: "",
          });

          batch.update(schoolsRef, {
            schools: arrayUnion(school),
          });

          similar.forEach((item) => {
            const ref = doc(
              db,
              `users/${item.uid}`
            ) as DocumentReference<UserData>;
            batch.update(ref, {
              school: school,
              pendingSchoolName: "",
            });
          });

          const promise = batch.commit();
          toast.promise(promise, {
            loading: loadingMessage(),
            error: (err) => err.message,
            success: t("done", { ns: "common" }),
          });
          onClose();
        }
      });
  };

  return (
    <AcadModal
      open={open}
      onClose={onClose}
      title={t("pending_school_school_name", {
        pendingSchoolName: pendingSchoolName,
      })}
    >
      <Stack sx={{ m: 2 }} spacing={2}>
        <Autocomplete
          value={school}
          options={schoolList}
          inputValue={school}
          onInputChange={(e, v) => setSchool(v)}
          onChange={(e, v) => setSchool(v)}
          disableClearable
          freeSolo
          renderInput={(props) => <TextField {...props} />}
        />
        <Stack>
          <Typography variant="caption">
            {t("length_similar_pending_schools", {
              length: getSimilarPendingSchools().length,
            })}
          </Typography>
          <Typography variant="caption">
            {t("length_similar_active_schools", {
              length: getSimilarActiveSchools().length,
            })}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button color="warning" onClick={onClose}>
            {t("cancel", { ns: "common" })}
          </Button>
          <Tooltip title={t("accept_only_this_instance_of_pending_school")}>
            <Button variant="outlined" onClick={accept}>
              {t("accept", { ns: "common" })}
            </Button>
          </Tooltip>
          <Tooltip title={t("this_will_update_all_similar_pending_schools")}>
            <Button variant="contained" onClick={acceptAll}>
              {t("accept_all", { ns: "common" })}
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
    </AcadModal>
  );
};

export default PendingSchoolDialog;
