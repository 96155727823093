import { Fade } from "@mui/material";
import { FC } from "react";
import LoadingScreen from "./LoadingScreen";

interface Props {
  show?: boolean;
}
const LoadingOverlay: FC<Props> = ({ show, children }) => {
  return (
    <Fade in={show}>
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
          backgroundColor: "rgba(255,255,255,0.7)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 999,
        }}
      >
        <LoadingScreen sx={{ minHeight: 0 }} />
        {children}
      </div>
    </Fade>
  );
};

export default LoadingOverlay;
