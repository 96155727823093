import Iconify from "@components/Iconify";
import Sheet from "@components/Sheet";
import loadingMessage from "@defs/LoadingMessages";
import { TournamentDoc } from "@defs/Tournament.type";
import { Add } from "@mui/icons-material";
import {
  FormControlLabel,
  Checkbox,
  Tooltip,
  Button,
  Stack,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Dispatch, FC, SetStateAction, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useFunctions } from "reactfire";
import Swal from "sweetalert2";
import ChallongeBracketIframe from "../components/ChallongeBracketIframe";
import { ParticipantAddDialog } from "./ParticipantAdd";
import ParticipantList from "./ParticipantList";
import { useParticipants, withParticipants } from "./_context";

interface Props {
  tournament: TournamentDoc;
  tournamentId: string;
  form: TournamentDoc;
  setForm: Dispatch<SetStateAction<TournamentDoc>>;
}

interface CallableProps {
  participantId: string;
  tournamentId: string;
}
type SelectKey = "participants" | "pending";
type SelectItem = Record<string, boolean>;
type SelectProps = Record<SelectKey, SelectItem>;

const ParticipantPage: FC<Props> = ({
  form,
  setForm,
  tournament,
  tournamentId,
}) => {
  const { t } = useTranslation("events");

  const swalValues: Record<SelectKey, { title: string; text: string }> = {
    pending: {
      title: t("promote_to_participant_question"),
      text: t("this_will_turn_team_into_participating_roster"),
    },
    participants: {
      title: t("demote_to_pending_question"),
      text: t("make_participant_inactive_in_challonge"),
    },
  };

  const fn = useFunctions();
  const [selected, setSelected] = useState<SelectProps>({
    participants: {},
    pending: {},
  });
  const [dialogState, setDialogState] = useState(false);
  const openAddDialog = () => setDialogState(true);
  const closeAddDialog = () => setDialogState(false);

  const participants = useParticipants();
  const registrants = participants.filter((p) => !p.data().isAccepted);
  const playingParticipants = participants.filter((p) => p.data().isAccepted);

  const onSelect = (type: SelectKey) => (uid: string) => () => {
    setSelected((s) => ({
      ...s,
      [type]: { ...s[type], [uid]: !s[type][uid] },
    }));
  };

  const getSelectedTeams = (list: SelectItem) => {
    return Object.entries(list).reduce<string[]>(
      (acc, [uid, val]) => (val ? [...acc, uid] : acc),
      []
    );
  };

  const allSelected = [
    ...getSelectedTeams(selected.participants),
    ...getSelectedTeams(selected.pending),
  ];
  const selectedRegistrants = getSelectedTeams(selected.pending);
  const selectedParticipants = getSelectedTeams(selected.participants);

  const demote = (teams: string[]) => {
    const path = "tournamentParticipant-unAcceptRegister";
    const callable = httpsCallable<CallableProps>(fn, path);
    const promises = teams.map((id) =>
      callable({ tournamentId, participantId: id })
    );

    toast.promise(Promise.all(promises), {
      loading: loadingMessage(),
      error: (err) => err.message,
      success: () => {
        setSelected((s) => ({ ...s, participants: {} }));
        return t("saved_successfully");
      },
    });
  };

  const promote = (teams: string[]) => {
    const path = "tournamentParticipant-acceptRegister";
    const callable = httpsCallable<CallableProps>(fn, path);
    const promises = teams.map((id) =>
      callable({ tournamentId, participantId: id })
    );

    toast.promise(Promise.all(promises), {
      loading: loadingMessage(),
      error: (err) => err.message,
      success: () => {
        setSelected((s) => ({ ...s, registrants: {} }));
        return t("saved_successfully");
      },
    });
  };

  const save = (type: SelectKey) => () => {
    Swal.fire({
      ...swalValues[type],
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        if (type === "participants") {
          return demote(selectedParticipants);
        }
        return promote(selectedRegistrants);
      }
    });
  };

  const deleteTeams = () => {
    Swal.fire({
      title: t("are_you_sure_question"),
      text: t("you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
    }).then((res) => {
      if (res.isConfirmed) {
        const path = "tournamentParticipant-adminDeleteParticipant";
        type CallProps = { fireTourId: string; fireParId: string };
        const callable = httpsCallable<CallProps>(fn, path);

        const promises = allSelected.map((id) =>
          callable({ fireTourId: tournamentId, fireParId: id })
        );

        toast.promise(Promise.all(promises), {
          loading: loadingMessage(),
          error: (err) => err.message,
          success: () => {
            setSelected((s) => ({ ...s, participants: {}, registrants: {} }));
            return t("saved_successfully");
          },
        });
      }
    });
  };

  return (
    <div>
      <Sheet className="flex flex-col">
        <div className="flex justify-between">
          <div className="flex-1">
            <FormControlLabel
              label={t("auto_accept_registrants")}
              control={
                <Checkbox
                  inputProps={{ "aria-label": "controlled" }}
                  checked={form.autoAcceptParticipants}
                  onChange={(e) =>
                    setForm((state) => ({
                      ...state,
                      autoAcceptParticipants: e.target.checked,
                    }))
                  }
                />
              }
            />
          </div>
          <Stack direction="row" gap={2}>
            {!!allSelected.length && (
              <Button
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:trash-2-fill" color="error" />}
                onClick={deleteTeams}
              >
                {t("delete_teams")}
              </Button>
            )}
            <Tooltip
              title={t("note_stats_not_available_to_teams_added_manually")}
            >
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={openAddDialog}
              >
                {t("add_team_manually")}
              </Button>
            </Tooltip>
            <ParticipantAddDialog
              open={dialogState}
              onClose={closeAddDialog}
              tournamentId={`${tournament.id}`}
              tournamentName={tournament.tournament.name}
            />
          </Stack>
        </div>
      </Sheet>

      {participants.length ? (
        <>
          {!!registrants.length && (
            <ParticipantList
              buttonText={t("promote_to_participant")}
              save={save("pending")}
              selected={selectedRegistrants}
              teams={registrants}
              updateSelect={onSelect("pending")}
              headerText={t("pending_teams")}
            />
          )}
          {!!playingParticipants.length && (
            <ParticipantList
              buttonText={t("demote_to_pending")}
              save={save("participants")}
              selected={selectedParticipants}
              teams={playingParticipants}
              updateSelect={onSelect("participants")}
              headerText={t("participating_teams")}
            />
          )}
        </>
      ) : (
        <div className="w-full p-4 text-center text-lg">
          {t("no_participants_yet")}
        </div>
      )}

      <ChallongeBracketIframe
        chalUrl={tournament.tournament.url}
        refresher={1}
      />
    </div>
  );
};

export default withParticipants(ParticipantPage);
