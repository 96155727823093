import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { FC, MouseEvent } from "react";
import { NavLink } from "react-router-dom";
import { links } from "@defs/Links";
import useUserData from "@lib/AuthHooks";
import { useTranslation } from "react-i18next";
import { useRegion } from "@hooks/use_region";
import { regionRoleLock } from "@lib/regionRoleLock";

const mc = makeStyles((theme: Theme) =>
  createStyles({
    inactive: {
      color: "rgb(107, 114, 128)",
      fontSize: 16,
    },
    active: {
      fontSize: 16,
      backgroundColor: theme.palette.primary.main,
      color: "#fff!important",

      "& .icon": {
        filter: "brightness(2)!important",
      },
    },
  })
);

export const SideBar: FC = () => {
  const muiClasses = mc();
  const { isAdmin, isSuperAdmin, roles: userRoles } = useUserData();
  const { region } = useRegion();

  const handleClick = (disabled?: boolean) => (e: MouseEvent) => {
    if (disabled) e.preventDefault();
  };

  const { t } = useTranslation("routes");

  return (
    <div className="fixed z-10 inset-0 flex-none h-full bg-opacity-25 w-64 lg:static lg:h-auto lg:overflow-y-visible lg:pt-0 lg:w-64 lg:block">
      <div
        id="navWrapper"
        className="h-full top-18 overflow-y-auto scrolling-touch lg:h-auto lg:block overflow-hidden lg:mr-0"
      >
        <nav className="px-2 text-sm pt-5 overflow-y-auto font-medium">
          <ul className="list-none pl-4 pr-4 lg:pl-8">
            {links
              .filter(
                (link) =>
                  regionRoleLock(link, region, isAdmin) &&
                  link.onNavbar &&
                  (link.adminOnly ? isAdmin : true) &&
                  (link.allowedRoles?.length
                    ? link.allowedRoles.some((role) => userRoles.includes(role))
                    : true)
              )
              .map((link) => (
                <li
                  key={link.url}
                  className={`${
                    link.superAdminOnly && !isSuperAdmin && "opacity-50"
                  }`}
                >
                  <NavLink
                    onClick={handleClick(link.superAdminOnly && !isSuperAdmin)}
                    to={"/" + link.url}
                    className={
                      "transition-all no-underline my-1 px-4 py-1 flex rounded-xl w-full text-gray-500 items-center " +
                      muiClasses.inactive
                    }
                    activeClassName={
                      link.superAdminOnly && !isSuperAdmin
                        ? muiClasses.inactive
                        : muiClasses.active
                    }
                  >
                    {link.icon && typeof link.icon === "string" ? (
                      <div className="mr-2 h-7 w-7 flex justify-center items-center">
                        {link.icon}
                      </div>
                    ) : (
                      link.icon
                    )}
                    {t(link.title)}
                  </NavLink>
                </li>
              ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default SideBar;
