import { AcadDateTimePicker, AcadTextField } from "@components";
import LoadingOverlay from "@components/LoadingOverlay";
import { DialogProps } from "@defs/Types";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  OAuthToken,
  privateScopes,
  publicScopes,
  updateAccessTokenSchema,
  UpdateAccessTokenSchema,
} from "@models/oauth";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { QueryDocumentSnapshot, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { sanitizeScope } from "../utils/sanitizeScope";
import { useOAuthClient } from "./oauthClientContext";

interface Props extends DialogProps {
  token: QueryDocumentSnapshot<OAuthToken>;
}

const AppTokenModalCreate = ({ token, open, onClose }: Props) => {
  const [loading, setLoading] = useState(false);
  const { isAdminApp, isPartnerApp } = useOAuthClient();
  const { t } = useTranslation("developer");
  const { scope, accessTokenExpiresAt } = token.data();
  const { control, handleSubmit } = useForm<UpdateAccessTokenSchema>({
    defaultValues: {
      scope: sanitizeScope(scope),
      accessTokenExpiresAt: accessTokenExpiresAt?.toDate(),
    },
    resolver: joiResolver(updateAccessTokenSchema),
  });

  const isAdmin = !!isAdminApp || !!isPartnerApp;

  const updateToken = handleSubmit(async ({ scope, accessTokenExpiresAt }) => {
    setLoading(true);

    const ref = token.ref;
    try {
      await updateDoc(ref, { scope, accessTokenExpiresAt });
    } catch {
      toast.error(t("token_update_failed"));
    }

    setLoading(false);
    onClose();
  });

  return (
    <Dialog {...{ open, onClose }} maxWidth="sm" fullWidth>
      <LoadingOverlay show={loading} />
      <DialogTitle>{t("create_access_token")}</DialogTitle>
      <DialogContent>
        <Stack sx={{ py: 2 }} spacing={2}>
          <FormControl>
            <InputLabel>{t("scopes")}</InputLabel>
            <Controller
              control={control}
              name="scope"
              render={({ field }) => (
                <Select
                  disabled={!isAdmin}
                  multiple
                  label={t("scopes")}
                  {...field}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {publicScopes.map((scope) => (
                    <MenuItem value={scope} key={scope}>
                      {scope}
                    </MenuItem>
                  ))}
                  {isAdmin &&
                    privateScopes.map((scope) => (
                      <MenuItem value={scope} key={scope}>
                        {scope}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
          </FormControl>
          <AcadDateTimePicker
            controllerProps={{
              control,
              name: "accessTokenExpiresAt",
            }}
            renderInput={(props) => (
              <AcadTextField {...props} label={t("access_token_expiry")} />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={updateToken} variant="contained">
          {t("update", { ns: "common" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppTokenModalCreate;
