import { OAuthClient } from "@models/oauth";
import { Avatar, Card, CardActionArea, CardHeader } from "@mui/material";
import { useHistory } from "react-router";

interface Props {
  app: OAuthClient;
}

const AppCard = ({ app }: Props) => {
  const history = useHistory();

  const onClick = () => {
    history.push(`/developer/apps/${app.id}`);
  };
  return (
    <Card elevation={0}>
      <CardActionArea onClick={onClick}>
        <CardHeader
          avatar={
            <Avatar variant="rounded" src={app.icon}>
              {app.name[0]}
            </Avatar>
          }
          title={app.name}
          titleTypographyProps={{
            fontSize: 16,
            className: "leading-none truncate",
          }}
          sx={{ p: 2 }}
        />
      </CardActionArea>
    </Card>
  );
};

export default AppCard;
